import { AxiosWrapper } from "auth/axios.wrapper";

const evaluateEndpoint = (
  endpoint: string | ((params: any) => string),
  params: any
) => {
  if (typeof endpoint === "function") return endpoint(params);
  if (typeof endpoint === "string") return endpoint;
  return "";
};

export const getQueryStringWithoutUndefined = (obj: any) => {
  const params = new URLSearchParams();
  if (!obj) {
    return "";
  }

  Object.entries(obj).forEach(([key, value]) => {
    if (value === undefined || value === null || value === "") {
      return;
    }
    if (Array.isArray(value)) {
      value.forEach((value) => params.append(key, value?.toString()));
    } else {
      params.append(key, value.toString());
    }
  });
  return params.toString();
};

export const createDummyQueryService = <
  ResponseType,
  ParamsType = { [key: string]: any }
>(
  payload: ResponseType
) => {
  return async function QueryService(
    params?: ParamsType
  ): Promise<ResponseType> {
    return payload;
  };
};

export const createQueryService = <
  ResponseType,
  ParamsType = { [key: string]: any }
>(
  endpoint: string | ((params?: ParamsType) => string)
) => {
  return async function QueryService(
    params?: ParamsType
  ): Promise<ResponseType> {
    const url = evaluateEndpoint(endpoint, params);
    const httpClient = new AxiosWrapper();
    const result = await httpClient.get(
      `${url}${!params ? "" : "?"}${getQueryStringWithoutUndefined(params)}`
    );
    return result.data;
  };
};

export const createBlobService = <
  ResponseType,
  PayloadType = { [key: string]: any }
>(
  endpoint: string | ((payload?: PayloadType) => string),
  method: "GET" | "PUT" | "POST" | "PATCH" | "DELETE" = "POST"
) => {
  return async function BlobService(payload?: PayloadType): Promise<Blob> {
    const url = evaluateEndpoint(endpoint, payload);
    const httpClient = new AxiosWrapper();
    const response =
      method === "GET"
        ? await httpClient.getBlob(url)
        : await httpClient[method.toLowerCase()](`${url}`, payload, {
            responseType: "blob",
          });
    console.log(
      new Blob([response.data], { type: response.headers["content-type"] })
    );
    return new Blob([response.data], {
      type: response.headers["content-type"],
    });
    // return response.data;
  };
};

export const createMutationService = <
  ResponseType,
  PayloadType = { [key: string]: any }
>(
  endpoint: string | ((payload: PayloadType) => string),
  method: "PUT" | "POST" | "PATCH" | "DELETE" = "POST"
) => {
  return async function MutationService(
    payload: PayloadType
  ): Promise<ResponseType> {
    const url = evaluateEndpoint(endpoint, payload);
    const httpClient = new AxiosWrapper();
    const response = await httpClient[method.toLowerCase()](`${url}`, payload);
    return response.data;
  };
};

export const createFormDataService = <
  ResponseType,
  PayloadType = { [key: string]: any }
>(
  endpoint: string | ((payload: PayloadType) => string),
  method: "PUT" | "POST" | "PATCH" | "DELETE" = "POST"
) => {
  return async function FormDataService(
    payload: PayloadType
  ): Promise<ResponseType> {
    const url = evaluateEndpoint(endpoint, payload);
    const httpClient = new AxiosWrapper();
    // eslint-disable-next-line no-undef
    const formData = new FormData();
    console.log({ payload });
    Object.entries(payload).forEach(([key, value]) => {
      if (
        value !== null &&
        typeof value === "object" &&
        typeof value[Symbol.iterator] === "function"
      ) {
        value.forEach((v) => {
          formData.append(key, v);
        });
      } else {
        formData.append(key, value);
      }
    });
    const response = await httpClient[method.toLowerCase()](`${url}`, formData);
    return response.data;
  };
};
