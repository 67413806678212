import { CloudUpload } from "@mui/icons-material";
import { CircularProgress, Hidden, ListItemButton } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import React from "react";
import { useTranslation } from "react-i18next";

export const AttachFile = (props: {
  onAttach: (files) => void;
  isLoading?: boolean;
  icon?: React.ReactElement;
  primary?: string;
}) => {
  const { t } = useTranslation("ui");
  return (
    <>
      <Hidden implementation="css" xsDown xsUp>
        <input
          title="Attach File"
          id="attachFile"
          disabled={props.isLoading}
          // multiple
          type="file"
          onChange={(e) => {
            props.onAttach(e.target.files);
          }}
        />
      </Hidden>
      <label htmlFor="attachFile" style={{ cursor: "not-allowed" }}>
        <ListItemButton disabled={props.isLoading}>
          <ListItemIcon>
            {props.isLoading ? (
              <CircularProgress size="24px" />
            ) : props.icon ? (
              props.icon
            ) : (
              <CloudUpload />
            )}
          </ListItemIcon>
          <ListItemText primary={t("attachNewFile", "Attach new file")} />
        </ListItemButton>
      </label>
    </>
  );
};
