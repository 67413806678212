import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { MaterialReactTable } from "material-react-table";
import React from "react";
import { usePslHistory } from "../services/hooks";
import { IHistory } from "../domains/pslHistory.domain";
import moment from "moment";

export const PromoHistory = ({
  materialId,
  pslId,
}: {
  materialId?: string | number;
  pslId?: string | number;
}) => {
  const { data, isLoading } = usePslHistory(pslId, materialId);

  return (
    <Box sx={{ mt: 8, p: 1 }}>
      <Typography variant="h5" sx={{ mb: 3 }}>
        History
      </Typography>
      {isLoading && <CircularProgress sx={{ mx: "auto" }} />}
      {data && data.length > 0
        ? data.map((historyItem: IHistory) => {
            return (
              <Card
                key={historyItem.modifiedOn}
                sx={{ mb: 2 }}
                variant="outlined"
              >
                <CardHeader
                  avatar={<Avatar aria-label="recipe" alt={historyItem.user} />}
                  title={historyItem.user}
                  subheader={moment(historyItem.modifiedOn).format("LLL")}
                  action={<Chip label={historyItem.action} />}
                />
                <CardContent>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Field</TableCell>
                        <TableCell>Old Value</TableCell>
                        <TableCell>New Value</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>{historyItem.field}</TableCell>
                        <TableCell>{historyItem.oldValue}</TableCell>
                        <TableCell>{historyItem.value}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>
            );
          })
        : "No history found"}
    </Box>
  );
};

export default PromoHistory