import {
  Add,
  AddShoppingCart,
  BrowseGallery,
  Close,
  Collections,
  ContentCopy,
  Edit,
  FileCopy,
  NavigateNext,
  NotificationAddOutlined,
  PlayArrow,
  Remove,
  SkipNext,
  SkipPrevious,
} from "@mui/icons-material";
import {
  Box,
  Breadcrumbs,
  CardContent,
  CardMedia,
  IconButton,
  Paper,
  Tab,
  Tabs,
  Typography,
  useTheme,
  Card,
  Grid,
  CardActions,
  Button,
  ButtonGroup,
  CardHeader,
  TextField,
  Stepper,
  Step,
  StepLabel,
  StepIcon,
  Container,
  FormLabel,
  Alert,
  AlertTitle,
  Tooltip,
} from "@mui/material";
import { Slide } from "modules/Layout/views/landing.view";
import PromoItemRow from "modules/PromoItems/components/PromoItemRow.component";
import ActivityLog from "modules/UI/components/ActivityLog.component";
import { Attachments } from "modules/UI/components/Attachments.component";
import PUIAvatar from "modules/UI/components/Avatar.component";
import Drawer from "modules/UI/components/Drawer.component";
import { UserFullScopeComponent } from "modules/User/components/userScopeSelector/userFullScopeComponent";
import React from "react";
import { NavLink, useParams } from "react-router-dom";

export const CreateCampaignContainer = () => {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const { campaignId } = useParams();
  const [tab, setTab] = React.useState(0);
  const theme = useTheme();
  return (
    <>
      <Drawer
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        title="Product details"
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField fullWidth label="Category" />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth label="Material Number" />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth label="Short description" />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth label="Unit Price" />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth label="Currency" />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth label="Price Per" />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth label="Unit of Measure" />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth label="Scope of application" />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth label="Supplier Part Id" />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth label="Manufacturer Name" />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth label="Manufacturer Part Number" />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth label="Manufacturer Part Number" />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth label="Tags" />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth label="Language" />
          </Grid>
        </Grid>
      </Drawer>
      <Box
        sx={{ m: -3, mb: 3, p: 3, background: theme.palette.background.paper }}
      >
        <Breadcrumbs
          sx={{ mb: 3 }}
          separator={<NavigateNext fontSize="small" />}
          aria-label="breadcrumb"
        >
          {[
            <NavLink key="1" color="inherit" to="/suppliers" onClick={() => {}}>
              Catalog
            </NavLink>,
            ...(false
              ? [
                  <NavLink
                    key="2"
                    color="inherit"
                    to={`/suppliers/${"initialData.vendor.parentId"}/edit`}
                    onClick={() => {}}
                  >
                    Promo Record #{"initialData.vendor.parentId"}
                  </NavLink>,
                ]
              : []),
            <Typography key="3" color="text.primary">
              {campaignId ? `Promo Record #${campaignId}` : "New Promo Record"}
            </Typography>,
          ]}
        </Breadcrumbs>
        <Grid
          container
          spacing={4}
          justifyContent={"space-between"}
          alignContent={"center"}
        >
          <Grid item>
            <Typography variant="h4">Crete new Campaign</Typography>
            <Typography variant="h6">Subtitle</Typography>
          </Grid>
          <Grid item>
            <Tooltip title="Subcribe to this campaign to receive updates">
              <Button
              size="large"
                variant="contained"
                startIcon={<NotificationAddOutlined />}
              >
                Subscribe
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Stepper activeStep={1} alternativeLabel sx={{ my: 6 }}>
          <Step>
            <StepLabel>OW Created</StepLabel>
          </Step>
          <Step>
            <StepLabel optional="25-07-2024">Order Window Opens</StepLabel>
          </Step>
          <Step>
            <StepLabel optional="Pending/Reached">
              Minimum Order Quantity
            </StepLabel>
          </Step>
          <Step>
            <StepLabel optional="08-08-2024">Order Window Closed</StepLabel>
          </Step>
          <Step>
            <StepLabel optional="final prices quoted">
              Quantities consolidated
            </StepLabel>
          </Step>
          <Step>
            <StepLabel optional="final prices quoted">PO's Issued</StepLabel>
          </Step>
          <Step>
            <StepLabel optional="09-12-2024">Delivery</StepLabel>
          </Step>
        </Stepper>
      </Box>
      <Container maxWidth="lg">
        <Grid container spacing={2} alignItems="stretch">
          <Grid item xs={12} md={6}>
            <Paper sx={{ p: 2, minHeight: "100%" }}>
              <Typography sx={{ mb: 1 }} variant="h5">
                Campaign details
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <FormLabel>Name:</FormLabel>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <TextField fullWidth size="small" />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormLabel>Description:</FormLabel>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <TextField fullWidth multiline rows={4} size="small" />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormLabel>Duration:</FormLabel>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField label="Start Date" size="small" />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField label="End Date" size="small" />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormLabel>Scope:</FormLabel>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <UserFullScopeComponent
                    hasError={false}
                    initialMarkets={[]}
                    initialCompanies={[]}
                    initialZones={[]}
                    onChange={(newValues) => {
                      console.log({ newValues });
                    }}
                    disabled={false}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper sx={{ p: 2, minHeight: "100%" }}>
              <Typography sx={{ mb: 1 }} variant="h5">
                Supplier
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <FormLabel>Supplier Company:</FormLabel>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <TextField
                    label="pretend I am search dropdown"
                    fullWidth
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormLabel>Supplier Contact:</FormLabel>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <TextField
                    label="pretend I am search dropdown"
                    fullWidth
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormLabel>Supplier Scope:</FormLabel>
                </Grid>
                <Grid item xs={12} sm={8}>
                  LATAM, NORTH AMERICA, AOA, EMENA
                </Grid>
                <Grid item xs={12}>
                  <Alert severity="error" variant="outlined">
                    <AlertTitle>
                      This suppplier is not extended to your region.
                    </AlertTitle>
                    To Proceed, please maintain VFT.
                  </Alert>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper>
              <Tabs
                value={tab}
                onChange={(_, newValue) => setTab(newValue)}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab label="Materials" />
                <Tab label="Discussion" />
                <Tab label="Attachments" />
                <Tab label="Subscribers" />
                <Tab label="Activity Log" />
                <Tab label="Quantity Consolidation" />
              </Tabs>

              {tab === 0 && (
                <Box padding={2}>
                  <Grid container spacing={2}>
                    {new Array(3).fill(
                      <Grid item xs={12}>
                        <Card sx={{ display: "flex", width: "100%" }}>
                          <CardMedia
                            component="img"
                            sx={{ width: 151 }}
                            image="https://i.ytimg.com/vi/5n4J9duypeQ/maxresdefault.jpg"
                            alt="product image"
                          />
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              flex: "1 1 auto",
                              alignItems: "flex-start",
                            }}
                          >
                            <CardContent sx={{ flex: "1 0 auto" }}>
                              <Typography component="div" variant="h5">
                                Insulated aluminium bottle
                              </Typography>
                              <Typography
                                variant="subtitle1"
                                component="div"
                                sx={{ color: "text.secondary" }}
                              >
                                Aluminium food contact
                              </Typography>
                            </CardContent>
                            <CardActions sx={{ justifyContent: "flex-end" }}>
                              <ButtonGroup
                                variant="outlined"
                                size="medium"
                                color="primary"
                              >
                                <Button
                                  startIcon={<Collections />}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                >
                                  Media
                                </Button>
                                <Button
                                  startIcon={<Close />}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                >
                                  Remove
                                </Button>
                                <Button
                                  startIcon={<FileCopy />}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                >
                                  Duplicate
                                </Button>
                                <Button
                                  startIcon={<Edit />}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setDrawerOpen(true);
                                  }}
                                >
                                  Edit
                                </Button>
                              </ButtonGroup>
                            </CardActions>
                          </Box>
                        </Card>
                      </Grid>
                    )}
                  </Grid>
                </Box>
              )}

              {tab === 1 && (
                <Box padding={2}>
                  <Box sx={{ maxHeight: "50vh", overflowY: "auto" }}>
                    <Grid container spacing={2}>
                      {new Array(10).fill({}).map((_, index) => (
                        <Grid
                          item
                          xs={12}
                          display="flex"
                          justifyContent={
                            index % 2 === 0 ? "flex-start" : "flex-end"
                          }
                        >
                          <Card sx={{ width: 460, maxWidth: "100%" }}>
                            <CardHeader
                              // sx={{flexDirection: index % 2 === 0 ? "row" : "row-reverse"}}
                              avatar={<PUIAvatar />}
                              subheader={
                                <Box
                                  sx={{ fontStyle: "italic", opacity: ".5" }}
                                >
                                  21-11-2024 12:32
                                </Box>
                              }
                              title={
                                "chat message content here and make it extra long lorem ipsum dolor sit amet"
                              }
                            />
                          </Card>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                  <Box>
                    <TextField
                      label="Type your message here"
                      fullWidth
                      variant="outlined"
                      size="small"
                      multiline
                      rows={3}
                    />
                  </Box>
                </Box>
              )}

              {tab === 2 && (
                <Box padding={2}>
                  <Attachments
                    enableDelete
                    enableUpload
                    onClick={() => {}}
                    onAttach={() => {}}
                    onDelete={() => {}}
                    attachments={[
                      {
                        creationDate: "2021-11-23 12:32",
                        fileName: "design.jpg",
                        id: "12312312",
                        uploadedByUserEmail: "sampleuser@nestle.com",
                      },
                      {
                        creationDate: "2021-11-23 12:32",
                        fileName: "design.jpg",
                        id: "12312312",
                        uploadedByUserEmail: "sampleuser@nestle.com",
                      },
                      {
                        creationDate: "2021-11-23 12:32",
                        fileName: "design.jpg",
                        id: "12312312",
                        uploadedByUserEmail: "sampleuser@nestle.com",
                      },
                      {
                        creationDate: "2021-11-23 12:32",
                        fileName: "design.jpg",
                        id: "12312312",
                        uploadedByUserEmail: "sampleuser@nestle.com",
                      },
                    ]}
                  />
                </Box>
              )}
              {tab === 3 && <>Subscribers</>}
              {tab === 4 && (
                <ActivityLog
                  entries={[
                    {
                      performedAt: "2023-12-12 12:32",
                      performedByEmail: "johnsmith@nestle.com",
                      performedByUserName: "John Smith",
                      activityText: "Deleted the campaign",
                      additionalInformation: "more info here",
                    },
                    {
                      performedAt: "2022-12-12 12:32",
                      performedByEmail: "johnsmith@nestle.com",
                      performedByUserName: "John Smith",
                      activityText: "Updated the campaign",
                      additionalInformation: "more info here",
                    },
                    {
                      performedAt: "2021-12-12 12:32",
                      performedByEmail: "johnsmith@nestle.com",
                      performedByUserName: "John Smith",
                      activityText: "Created the campaign",
                      additionalInformation: "more info here",
                    },
                  ]}
                />
              )}
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default CreateCampaignContainer;
