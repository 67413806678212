import React, { useMemo, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import IconButton from "@mui/material/IconButton";
import { Card, InputAdornment, OutlinedInput, Tab, Tabs } from "@mui/material";
import { MRT_PaginationState, MRT_SortingState } from "material-react-table";
import { GpsFixed } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import MediaManager from "../components/MediaManager.component";
import PromoItemsService from "../services/PromoItems.service";
import { capitalizeFirstLetter } from "utils/utils";
import ImportPromoItemsContainer from "./import.container";
import saveAs from "file-saver";
import MaterialsListContainer from "./materials.container";
import SuppliersListContainer from "./suppliers.container";
import PromoItemDetails from "../components/PromoItemDetails.component";
import Drawer from "modules/UI/components/Drawer.component";
import { PslFiltersContainer } from "modules/Filters/containers/PslFilters.container";

const TargetFilter = ({ initialValue, onChange, onFindExact }) => {
  const [value, setValue] = useState(initialValue);

  return (
    <OutlinedInput
      size="small"
      value={value}
      onChange={(e) => setValue(e.target.value)}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={() => onChange(value)}
            edge="end"
          >
            <GpsFixed />
          </IconButton>
        </InputAdornment>
      }
    />
  );
};

export const ListContainer = () => {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const [importDrawerOpen, setImportDrawerOpen] = useState(false);
  const [selectedPromoItem, setSelectedPromoItem] = useState(null);
  const [activeDrawer, setActiveDrawer] = useState<"media" | "details">(null);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [filters, setFilters] = useState({});
  const [filterBy, setFilterBy] = useState("all");
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState<MRT_SortingState>([
    { id: "createdOn", desc: true },
  ]);
  const [rowCount, setRowCount] = useState(0);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const {
    data: backendData,
    isLoading,
    isRefetching,
    isError,
    refetch,
  } = useQuery({
    queryKey: [
      "materials-list", //re-fetch when column filters change
      globalFilter, //re-fetch when global filter changes
      filterBy,
      pagination.pageIndex, //re-fetch when page index changes
      pagination.pageSize, //re-fetch when page size changes
      sorting,
      filters,
    ],
    enabled: true,
    retry: 1,
    queryFn: async () => {
      const response = await PromoItemsService.getMaterials({
        ...filters,
        searchTerm: !!filterBy || !globalFilter ? null : globalFilter,
        take: pagination.pageSize,
        skip: pagination.pageIndex * pagination.pageSize,
        orderBy: sorting[0]
          ? {
              field: capitalizeFirstLetter(sorting[0].id),
              direction: sorting[0].desc ? "desc" : "asc",
            }
          : null,
        pslSearch:
          !!filterBy && !!globalFilter
            ? {
                [filterBy]: globalFilter,
              }
            : null,
      });
      setRowCount(response?.total || 0); //set the total row count for pagination
      return response;
    },
  });
  const data = useMemo(() => {
    return backendData?.data || [];
  }, [backendData]);
  const { mutate: uploadPromoMedia } = useMutation<any, any, any>({
    mutationKey: ["uploadPromoMedia"],
    mutationFn: async (data) => {
      const response = await PromoItemsService.uploadPromoMedia(data);
      return response;
    },
    onSuccess: (mediaFile) => {
      setSelectedPromoItem({
        //optimistic update
        ...selectedPromoItem,
        mediaFiles: [...selectedPromoItem.mediaFiles, mediaFile],
      });
    },
  });

  const { mutate: deletePromoMedia } = useMutation<any, any, any>({
    mutationKey: ["deletePromoMedia"],
    mutationFn: async (fileId) => {
      await PromoItemsService.deletePromoMedia({ fileId });
      return fileId;
    },
    onSuccess: (fileId) => {
      setSelectedPromoItem({
        ...selectedPromoItem,
        mediaFiles: selectedPromoItem.mediaFiles.filter(
          (f) => f.fileId !== fileId
        ),
      }); //optimistic update
      refetch();
    },
  });

  const { mutate: exportMaterials } = useMutation({
    mutationKey: [
      "export-materials",
      filters,
      globalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
    ],
    mutationFn: async () => {
      // export logic here
      const response = await PromoItemsService.exportMaterials({
        ...filters,
        searchTerm: globalFilter,
        take: pagination.pageSize,
        skip: pagination.pageIndex * pagination.pageSize,
        orderBy: sorting[0]
          ? {
              field: capitalizeFirstLetter(sorting[0].id),
              direction: sorting[0].desc ? "desc" : "asc",
            }
          : null,
      });
      saveAs(response, "materials.xlsx");
    },
  });

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <PslFiltersContainer
        open={filtersOpen}
        initialData={filters}
        onClose={() => setFiltersOpen(false)}
        onApply={(filters) => {
          setFilters(filters);
          setFiltersOpen(false);
        }}
      />
      <Drawer
        title="Import Promo Items"
        open={importDrawerOpen}
        onClose={() => setImportDrawerOpen(false)}
      >
        <ImportPromoItemsContainer onClose={() => setImportDrawerOpen(false)} />
      </Drawer>
      <MediaManager
        open={!!selectedPromoItem && activeDrawer === "media"}
        onClose={() => {
          setSelectedPromoItem(null);
          setActiveDrawer(null);
        }}
        images={selectedPromoItem?.mediaFiles || []}
        onUpload={(files) => {
          uploadPromoMedia({
            file: files[0],
            materialId: selectedPromoItem?.materialId,
          });
        }}
        onDelete={(mediaId) => {
          deletePromoMedia(mediaId);
        }}
      />
      <PromoItemDetails
        open={!!selectedPromoItem && activeDrawer === "details"}
        promoItem={selectedPromoItem}
        onClose={() => {
          setSelectedPromoItem(null);
          setActiveDrawer(null);
        }}
      />
      <Card elevation={0} sx={{ p: 3, pb: 0, m: -3, mb: 3 }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab
            label="Suppliers"
            sx={{
              fontSize: 22,
              textTransform: "none",
            }}
          />
          <Tab
            label="Materials"
            sx={{
              fontSize: 22,
              textTransform: "none",
            }}
          />
        </Tabs>
      </Card>

      {
        [
          <>
            <SuppliersListContainer
              onImport={() => {
                setImportDrawerOpen(true);
              }}
              onManageMedia={(promoItem) => {
                setSelectedPromoItem(promoItem);
              }}
            />
          </>,
          <>
            <MaterialsListContainer
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              filters={filters}
              setFilters={setFilters}
              setFilterBy={setFilterBy}
              filterBy={filterBy}
              isLoading={isLoading}
              sorting={sorting}
              setSorting={setSorting}
              pagination={pagination}
              setPagination={setPagination}
              rowCount={rowCount}
              setRowCount={setRowCount}
              refetch={refetch}
              data={data}
              onImport={() => {
                setImportDrawerOpen(true);
              }}
              onFilterClick={() => setFiltersOpen(true)}
              onManageMedia={(promoItem) => {
                setSelectedPromoItem(promoItem);
                setActiveDrawer("media");
              }}
            />
          </>,
        ][value]
      }
    </>
  );
};

export default ListContainer;
