import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
  Zoom,
} from "@mui/material";
import React from "react";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Zoom in ref={ref} {...props} />;
});

export const Confirmation = ({
  cancelLabel,
  confirmLabel,
  description,
  onCancel,
  onConfirm,
  persist,
  title,
  visible,
  isLoadingConfirm,
}: {
  persist?: boolean;
  visible: boolean;
  cancelLabel?: string;
  confirmLabel?: string;
  description?: string;
  onCancel: (e?: any) => void;
  onConfirm: (e?: any) => void;
  title?: string;
  isLoadingConfirm: boolean;
}) => {
  return (
    <Dialog
      open={visible}
      TransitionComponent={Transition}
      keepMounted
      onClose={persist ? null : () => onCancel()}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{title || "Are you sure?"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {description ||
            "Do you still want to continue? This action cannot be undone."}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
            variant="contained"
            color="primary"
          startIcon={isLoadingConfirm ? <CircularProgress size="14" /> : null}
          disabled={isLoadingConfirm}
          onClick={() => onConfirm()}
        >
          {confirmLabel || "Yes"}
        </Button>
        <Button variant="outlined" color="primary" onClick={() => onCancel()}>{cancelLabel || "No"}</Button>
      </DialogActions>
    </Dialog>
  );
};
