import React, { ReactElement, useContext } from "react";
import { Form, Formik, FormikConfig, FormikErrors, FormikValues } from "formik";
import {
  Button,
  CircularProgress,
  Dialog,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";

import CloseIcon from "@mui/icons-material/Close";

import { FormikStepProps } from "modules/Forms/components/formikStep.component";
import { stepContext } from "modules/Forms/components/formikStepperProvider.component";

export enum StepperDirection {
  Left,
  Right,
}

export enum FormikStepperLastStepConfirmation {
  YesNo,
  OkCancel,
}

export type FormikFormLastStepConfirmation = {
  text: string;
  title: string;
  type: FormikStepperLastStepConfirmation;
};

export interface FormikFormProps extends FormikConfig<FormikValues> {
  title?: string;
  footer?: any;
  onClose?: any;
  showStepper?: boolean;
  submitButtons?: any;
  lastStepConfirmation?: FormikFormLastStepConfirmation;
  setEnableButtonColor?: boolean;
  children?: React.ReactNode | React.ReactNode[];
}

export function FormikStepper({
  children,
  title,
  onClose,
  lastStepConfirmation,
  submitButtons,
  showStepper,
  setEnableButtonColor,
  ...props
}: FormikFormProps) {
  const childrenArray = React.Children.toArray(children) as ReactElement<
    FormikStepProps & {
      confirmationOnNext?: (values: any) => false | React.Component;
    }
  >[];
  const [loading, setLoading] = React.useState(false);
  const [lastStepConfirmationOpen, setLastStepConfirmationOpen] =
    React.useState(false);
  const [confirmedAfterLastStep, setConfirmedAfterLastStep] =
    React.useState(false);
  const [nextStepConfirmationOpen, setNextStepConfirmationOpen] =
    React.useState(false);

  const {
    currentStep,
    setCurrentStep,
    displayedStepCount,
    displayedCurrentStep,
    setDisplayedCurrentStep,
    setDirection,
    currentFormikValue,
    setCurrentFormikValue,
  } = useContext(stepContext);

  const currentChild = childrenArray[currentStep];

  function isLastStep() {
    return currentStep === childrenArray.length - 1;
  }

  const isNextButtonDisabled = (
    errors: FormikErrors<FormikValues>
  ): boolean => {
    // if we have any validation errors, disable next button
    return Object.keys(errors).length > 0;
  };

  return (
    <Formik
      {...props}
      {...(currentChild.props.validationSchema
        ? { validationSchema: currentChild.props.validationSchema }
        : {})}
      {...(currentChild.props.getValidationSchema
        ? {
            validationSchema:
              currentChild.props.getValidationSchema(currentFormikValue),
          }
        : {})}
        validateOnBlur={false}
        validateOnChange={false}
      onSubmit={async (values, helpers) => {
        if (!loading) {
          if (isLastStep()) {
            if (lastStepConfirmation && !confirmedAfterLastStep) {
              setLastStepConfirmationOpen(true);
            } else {
              setLoading(true);
              console.log("submittingggggg", loading, props.onSubmit);
              try {
                await props.onSubmit(values, helpers);
              } catch (e) {
                setLoading(false);
              }
              setLoading(false);
              setLastStepConfirmationOpen(false);
            }
          } else {
            setCurrentFormikValue(values);
            setDirection(StepperDirection.Right);
            setCurrentStep(currentStep + 1);
            setDisplayedCurrentStep(displayedCurrentStep + 1);
          }
        }
      }}
    >
      {(formikContext) => {
        const { submitForm, setFieldValue, errors, values, dirty } =
          formikContext;
        return (
          <>
            <Form autoComplete="off">
              <Dialog
                onClose={() => setNextStepConfirmationOpen(false)}
                open={nextStepConfirmationOpen}
                // width={600}
                // askForConfirmationAtClose={false}
              >
                <div>Are you sure?</div>
                <div>
                  {currentChild.props.confirmationOnNext && (
                    <>{currentChild.props.confirmationOnNext(values)}</>
                  )}
                </div>
                <div>
                  <div>
                    <div>
                      <Button
                        color="primary"
                        fullWidth
                        disabled={loading}
                        type="submit"
                        onClick={async () => {
                          setNextStepConfirmationOpen(false);
                          await submitForm();
                        }}
                      >
                        Go to next step
                      </Button>
                    </div>
                    <div>
                      <Button
                        color="secondary"
                        fullWidth
                        disabled={loading}
                        onClick={() => {
                          setNextStepConfirmationOpen(false);
                        }}
                      >
                        Stay on this step
                      </Button>
                    </div>
                  </div>
                </div>
              </Dialog>
              {lastStepConfirmation && (
                <Dialog
                  onClose={() => {}}
                  open={lastStepConfirmationOpen}
                  // width={600}
                >
                  <div>{lastStepConfirmation.title}</div>
                  <div>{lastStepConfirmation.text}</div>

                  <div>
                    {loading && (
                      <CircularProgress
                        size={42}
                        style={{ color: "#666e72" }}
                      />
                    )}
                  </div>

                  <div>
                    <div>
                      <Button
                        color="primary"
                        fullWidth
                        disabled={loading}
                        type="submit"
                        onClick={async () => {
                          setConfirmedAfterLastStep(true);
                          await submitForm();
                        }}
                      >
                        {lastStepConfirmation.type ===
                        FormikStepperLastStepConfirmation.OkCancel
                          ? "OK"
                          : lastStepConfirmation.type ===
                            FormikStepperLastStepConfirmation.YesNo
                          ? "YES"
                          : "Save"}
                      </Button>
                    </div>

                    <div>
                      <Button
                        color="warning"
                        fullWidth
                        disabled={loading}
                        onClick={() => {
                          setConfirmedAfterLastStep(false);
                          setLastStepConfirmationOpen(false);
                        }}
                      >
                        {lastStepConfirmation.type ===
                        FormikStepperLastStepConfirmation.OkCancel
                          ? "Cancel"
                          : lastStepConfirmation.type ===
                            FormikStepperLastStepConfirmation.YesNo
                          ? "No"
                          : "Cancel"}
                      </Button>
                    </div>
                  </div>
                </Dialog>
              )}

              {title && <Typography variant="h4">{title}</Typography>}
              {currentChild}
              <Grid
                container
                spacing={2}
                justifyContent={"space-between"}
                alignItems={"flex-start"}
                sx={{ mt: 4 }}
              >
                <Grid item>
                  <Button
                    type="button"
                    variant="outlined"
                    size="large"
                    disabled={currentStep === 0}
                    onClick={() => {
                      setDirection(StepperDirection.Left);
                      setCurrentStep(currentStep - 1);
                      setDisplayedCurrentStep(displayedCurrentStep - 1);
                    }}
                  >
                    Back
                  </Button>
                </Grid>
                {showStepper && childrenArray.length > 1 && (
                  <Grid item>
                    <Stepper alternativeLabel activeStep={currentStep}>
                      {childrenArray.map((child: any, index: number) => {
                        return (
                          <Step key={index} completed={currentStep > index}>
                            <StepButton
                              onClick={() => {
                                setCurrentStep(index);
                                setDisplayedCurrentStep(index + 1);
                              }}
                            >
                              {child.props.title || `Step ${index + 1}`}
                            </StepButton>
                          </Step>
                        );
                      })}
                    </Stepper>
                  </Grid>
                )}
                <Grid item>
                  {!isLastStep() ? (
                    <>
                      {currentChild.props.nextStepButtons ? (
                        currentChild.props.nextStepButtons(
                          submitForm,
                          values,
                          setFieldValue
                        )
                      ) : (
                        <Button
                          type="button"
                          variant="contained"
                          size="large"
                          // disabled={isNextButtonDisabled(errors)}
                          disabled={!dirty && !formikContext.isValid}
                          onClick={(e) => {
                            e.preventDefault();
                            if (
                              currentChild.props.confirmationOnNext &&
                              currentChild.props.confirmationOnNext(values)
                            ) {
                              setNextStepConfirmationOpen(true);
                            } else {
                              submitForm();
                            }
                          }}
                        >
                          Next
                        </Button>
                      )}
                    </>
                  ) : submitButtons ? (
                    submitButtons(
                      submitForm,
                      setFieldValue,
                      loading,
                      errors,
                      values
                    )
                  ) : (
                    <Button
                      variant="contained"
                      size="large"
                      // disabled={loading || isNextButtonDisabled(errors)}
                      disabled={
                        loading ||
                        (!formikContext.dirty && !formikContext.isValid)
                      }
                      type="submit"
                      startIcon={
                        loading ? <CircularProgress size={14} /> : null
                      }
                    >
                      {loading ? "Submitting" : "Submit"}
                    </Button>
                  )}
                </Grid>
              </Grid>
              {props.footer &&
                !isLastStep() &&
                currentStep !== childrenArray.length - 2 &&
                props.footer(formikContext)}
            </Form>
          </>
        );
      }}
    </Formik>
  );
}

export default FormikStepper;
