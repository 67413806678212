import React, { useEffect, useState } from "react";
import FeatureManagementService from "../service/featureManagement.service";
import SplashScreen from "modules/UI/components/SplashScreen.component";

export const FeatureManagementContext = React.createContext({
  features: null,
  configs: null,
  loading: true,
  error: null,
});

export const FeatureManagementProvider = ({ children }) => {
  const [data, setData] = useState({
    features: {},
    configs: {},
  });
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const fetchData = async () => {
    try {
      const response = await FeatureManagementService.getFeatureFlags();
      setData(response);
    } catch (e) {
      console.log({ error: e });
      setError(e);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <FeatureManagementContext.Provider
      value={{
        features: data?.features,
        configs: data?.configs,
        loading: isLoading,
        error,
      }}
    >
      {" "}
      <SplashScreen
        message="Loading Feature Flags"
        status={!!isLoading ? "loading" : !!error ? "error" : "idle"}
      />
      {!isLoading && !error && children}
    </FeatureManagementContext.Provider>
  );
};
