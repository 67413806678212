import { ErrorOutlineOutlined } from "@mui/icons-material";
import { Box, Link } from "@mui/material";
import Typography from "@mui/material/Typography/Typography";
import React, { useEffect } from "react";

export const ErrorView = ({ error }: { error: Error }) => {
  const [showMore, setShowMore] = React.useState(false);
  useEffect(() => {
    console.log(error.stack);
    console.log(error.name);
    console.log(error.message);
  }, [error]);
  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Typography variant="h1" textAlign={"center"}>
        <ErrorOutlineOutlined sx={{ fontSize: "3rem" }} />
      </Typography>
      <Typography variant="h6" textAlign={"center"}>
        Something went wrong.
      </Typography>
      {error && (
        <>
          <Typography variant="body2" textAlign={"center"}>
            {error.message}
          </Typography>
          {showMore ? (
            <>
              <Box>
                <pre>
                  <code>{error.stack}</code>
                </pre>
              </Box>
              <Link
                component="button"
                onClick={() => setShowMore(!showMore)}
                textAlign={"center"}
              >
                Less Details
              </Link>
            </>
          ) : (
            <Link
              component="button"
              onClick={() => setShowMore(!showMore)}
              textAlign={"center"}
            >
              More Details
            </Link>
          )}
        </>
      )}
    </Box>
  );
};

export default ErrorView;
