import { createDummyQueryService, createQueryService } from "utils/factories";
import materialsV2 from "./dummyData/materialsV2";
import SpendCategories from "./dummyData/spendCategories";

export default class PromoCatalogService {
  // static readonly getMaterials = createDummyQueryService(materialsV2);
  static readonly getSpendCategories = createDummyQueryService(SpendCategories);
  static readonly getTest = createQueryService("/test");
  
}
