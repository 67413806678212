import { Lock } from "@mui/icons-material";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography/Typography";
import React from "react";

export const UnauthorizedView = () => {
  return (
    <Box>
      <Typography variant="h1" textAlign={"center"}>
        <Lock sx={{ fontSize: "3rem" }} />
      </Typography>
      <Typography variant="h6" textAlign={"center"}>
        You don't have permission to access this page.
      </Typography>
      <Typography variant="body2" textAlign={"center"}>
        You can request access from your administrator.
      </Typography>
    </Box>
  );
};

export default UnauthorizedView;
