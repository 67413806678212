import { Alert, AlertTitle, Chip, Tooltip } from "@mui/material";
import React, { memo, useEffect } from "react";

function commonList(list, view = "vertical") {
  return (
    <div
      style={{
        display: view === "horizontal" ? "flex" : "display",
        fontSize: 14,
      }}
    >
      {list.map((el) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 5,
            marginRight: 10,
            marginBottom: 8,
          }}
        >
          <span
            style={{
              width: 10,
              height: 10,
              backgroundColor: "orange",
              borderRadius: "50%",
            }}
          ></span>
          <span>{el}</span>
        </div>
      ))}
    </div>
  );
}

export function ListUi({ list }) {
  if (list.length > 5) {
    return (
      <div style={{ display: "flex", gap: 5 }}>
        {commonList(list.slice(0, 5), "horizontal")}
        {
          <Tooltip
            title={commonList(list)}
            sx={{
              backgroundColor: "#fff",
              color: "#777777",
              boxShadow: "0 0 2px 2px rgba(224, 224, 224, 1)",
              borderRadius: "5px",
              maxWidth: "1000px",
              maxHeight: "500px",
              overflowX: "auto",
              overflowY: "auto",
            }}
            placement="right"
          >
            <span
              style={{ backgroundColor: "orange", padding: 5, borderRadius: 8 }}
            >
              {list.length - 5}+
            </span>
          </Tooltip>
        }
      </div>
    );
  } else {
    return commonList(list, "horizontal");
  }
}

function ChildrenVendorComp({
  userFullScope = [],
  zonesFromPsl,
  marketsFromPsl,
  companyCodesFromPsl,
  childrenVendor,
  setAbsentCompCodesAndAbsentMarkets,
}) {
  const pslZonesIds = zonesFromPsl.map((item) => item.id.toString());
  const pslMarketsIds = marketsFromPsl.map((item) => item.id.toString());
  const pslCompCodes = companyCodesFromPsl.map((comp) => comp.code.toString());
  let absentMarkets = [];
  let absentCompCodes = [];

  useEffect(() => {
    setAbsentCompCodesAndAbsentMarkets([...absentMarkets, ...absentCompCodes]);
  }, [absentMarkets, absentCompCodes]);

  function uiPart(keys, object) {
    return (
      <div
        key={keys}
        style={{ display: "flex", gap: 5, marginBottom: 5, flexWrap: "wrap" }}
      >
        <Chip
          label={keys}
          style={{
            borderRadius: 5,
            backgroundColor: "orange",
            color: "white",
          }}
        />

        {object[keys].map((el) => (
          <Chip
            key={el}
            label={el}
            style={{
              borderRadius: 5,
            }}
          />
        ))}
      </div>
    );
  }

  function calculationAndRenderUIPart() {
    let arr = [];
    let result = [];
    let obj = {};

    // get all the markets data from selected zones

    let filteredUserFullScopeFromZones = !!pslZonesIds.length
      ? userFullScope.filter((item) => {
          // TODO - check for full zone access
          let zone = item.zone;
          return pslZonesIds.includes(zone.id.toString());
        })
      : [];

    // @ts-ignore
    let marketsFromselectedZones = filteredUserFullScopeFromZones
      .map((el) => el.markets)
      .flat();
    // .filter(el => !!el.fullMarketAccess)

    // get market obj from selected markets
    let marketsFromSelectedMarkets = [];
    if (pslMarketsIds.length) {
      userFullScope.forEach((item) => {
        let markets = item.markets;
        markets.forEach((marketObj) => {
          let id = marketObj.market.id;
          if (pslMarketsIds.includes(id.toString())) {
            marketsFromSelectedMarkets.push(marketObj);
          }
        });
      });
    }

    const allMarketsFromSelectedZoneAndSelectedMarkets =
      marketsFromselectedZones.concat(marketsFromSelectedMarkets);

    childrenVendor.forEach((child) => {
      const { companyCodes, name, id } = child;
      // get companyCodes one by one and search in the allMarketsFromSelectedZoneAndSelectedMarkets
      // and if we find that company code then get the marketName from that array;
      const companyCodesArr = companyCodes.split(",");

      allMarketsFromSelectedZoneAndSelectedMarkets.forEach((marketObj) => {
        const { companyCodes: companyCodesFromMrktObj, market } = marketObj;
        companyCodesFromMrktObj.every((el) => {
          if (companyCodesArr.includes(el.code)) {
            let childrenVendorName = `${id} - ${name}`;
            let marketName = market.name;
            result.push(`${marketName} / ${childrenVendorName}`);
            return false;
          }
          return true;
        });
      });
    });

    // console.log(allMarketsFromSelectedZoneAndSelectedMarkets)
    const uniqArr = Array.from(new Set(result));
    uniqArr.forEach((el) => {
      let marketName = el.split(" / ")[0];
      let childVendorName = el.split(" / ")[1];
      if (obj[marketName]) {
        obj[marketName] = obj[marketName].concat(childVendorName);
      } else {
        obj[marketName] = [childVendorName];
      }
    });

    // console.log(obj)

    // calculating the absent Markets

    let presentMarkets = Object.keys(obj);
    absentMarkets = allMarketsFromSelectedZoneAndSelectedMarkets
      .filter((el) => {
        return !presentMarkets.includes(el.market.name);
      })
      .map((ele) => ele.market.name);

    // console.log(presentMarkets)
    // console.log(absentMarkets)

    // console.log('selected company codes');
    // console.log(pslCompCodes)

    let childVendorCompCodes = childrenVendor.map((el) => el.companyCodes);

    let uniqChildVendorCompCodes = Array.from(
      new Set(childVendorCompCodes.join(",").split(","))
    );

    let presentCompCodes = pslCompCodes.filter((el) => {
      return uniqChildVendorCompCodes.indexOf(el) > 0;
    });

    absentCompCodes = pslCompCodes.filter((el) => {
      return uniqChildVendorCompCodes.indexOf(el) < 0;
    });

    // console.log('present companies')
    // console.log(presentCompCodes);

    // console.log('absent companies')
    // console.log(absentCompCodes)

    let obj2 = {};

    presentCompCodes.forEach((el) => {
      childrenVendor.forEach((ele) => {
        if (ele.companyCodes.includes(el)) {
          let name = `${ele.id} - ${ele.name}`;
          if (obj2[el]) obj2[el].push(name);
          else obj2[el] = [name];
        }
      });
    });

    // console.log(obj2)
    let combinedObj = { ...obj, ...obj2 };
    for (let keys in combinedObj) {
      arr.push(uiPart(keys, combinedObj));
    }
    // updating the absentCompCodesAndAbsentMarkets
    const absentCompCodesAndAbsentMarketsUiPart =
      absentCompCodesAndAbsentMarketsFun(absentMarkets, absentCompCodes);

    return [...arr, absentCompCodesAndAbsentMarketsUiPart];
  }

  function absentCompCodesAndAbsentMarketsFun(absentMarkets, absentCompCodes) {
    return (
      (!!absentMarkets.length || !!absentCompCodes.length) && (
        <Alert severity="error" style={{ marginBottom: 10 }}>
          <AlertTitle>
            Parent Vendor is not associated to child Vendor in the following
            markets/company codes. Parent PSL will not be cascaded.
          </AlertTitle>

          <div style={{ display: "flex", gap: 10, flexWrap: "wrap" }}>
            {/* {[...absentMarkets, ...absentCompCodes].map(el => <span key={el}>{el}</span>)} */}
            <ListUi list={[...absentMarkets, ...absentCompCodes]} />
          </div>
          <p>
            To Proceed, please maintain{" "}
            <b
              style={{
                color: "orange",
                cursor: "pointer",
              }}
              onClick={() =>
                window.open(
                  "https://nestle.sharepoint.com/:x:/r/teams/nestrade-proc/_layouts/15/Doc.aspx?sourcedoc=%7b237628EB-9A23-4A6E-888E-F7D1F9656BB0%7d&file=Vendor%20Master%20Data%20Management%20V6.xlsx&_DSL=1&action=default&mobileredirect=true",
                  "_blank"
                )
              }
            >
              VFT
            </b>
            . Otherwise remove location
          </p>
        </Alert>
      )
    );
  }

  return <div style={{ marginBottom: 10 }}>{calculationAndRenderUIPart()}</div>;
}

export default memo(ChildrenVendorComp);
