import React, {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import FormikStepper, {
  FormikFormProps,
} from "modules/Forms/components/formikStepper.component";
import { StepperDirection } from "modules/Forms/models";

export interface FormikStepperContext {
  currentStep: number;
  setCurrentStep: (nextStep: number) => void;
  displayedCurrentStep: number;
  setDisplayedCurrentStep: (nextStep: number) => void;
  displayedStepCount: number;
  setDisplayedStepCount: (count: number) => void;
  originalStepLength: number;
  direction: StepperDirection;
  setDirection: (nextDirection: StepperDirection) => void;
  skippedSteps: any[];
  setSkippedSteps: (steps: any[]) => void;
  stepsHistory: number[];
  currentFormikValue: any;
  setCurrentFormikValue: any;
}

export const stepContext = createContext<FormikStepperContext>({
  currentStep: 0,
  setCurrentStep: () => {},
  displayedCurrentStep: 1,
  setDisplayedCurrentStep: () => {},
  displayedStepCount: 0,
  setDisplayedStepCount: () => {},
  originalStepLength: 0,
  direction: StepperDirection.Right,
  setDirection: () => {},
  skippedSteps: [],
  setSkippedSteps: () => {},
  currentFormikValue: {},
  setCurrentFormikValue: () => {},
  stepsHistory: [],
});

export const FormikStepperProvider: FC<
  FormikFormProps & {
    initialStep?: number;
    children: ReactNode | ReactNode[];
  }
> = (props) => {
  const initialStep = props.initialStep || 0;
  const stepCount = React.Children.toArray(props.children).length;
  const [currentStep, setCurrentStep] = useState(initialStep);
  const [displayedCurrentStep, setDisplayedCurrentStep] = useState(
    initialStep + 1
  );
  const [displayedStepCount, setDisplayedStepCount] = useState(stepCount); // todo: nonSkippableStepCount
  const [originalStepLength] = useState(stepCount); // todo: nonSkippableStepCount
  const [direction, setDirection] = useState(StepperDirection.Right);
  const [currentFormikValue, setCurrentFormikValue] = useState({});
  const [skippedSteps, setSkippedSteps] = useState([]);
  const [stepsHistory, setStepsHistory] = useState([0]);

  React.useEffect(() => {
    setDisplayedStepCount(React.Children.toArray(props.children).length);
  }, [props.children]);

  const handleStepChange = useCallback(
    (step) => {
      if (step < 0 || step > stepCount - 1) {
        return;
      }
      setCurrentStep(step);
      setStepsHistory([...stepsHistory, step]);
    },
    [stepCount, stepsHistory]
  );

  const value = useMemo(() => {
    return {
      currentStep,
      setCurrentStep: handleStepChange,
      displayedCurrentStep,
      setDisplayedCurrentStep,
      displayedStepCount,
      setDisplayedStepCount: () => {}, //Todo: see where this is used and remove it - the count should be changed automatically from the useEffect listening for changes in the children prop
      originalStepLength,
      direction,
      setDirection,
      skippedSteps,
      setSkippedSteps,
      currentFormikValue,
      setCurrentFormikValue,
      stepsHistory,
    };
  }, [
    currentStep,
    handleStepChange,
    displayedCurrentStep,
    setDisplayedCurrentStep,
    displayedStepCount,
    originalStepLength,
    direction,
    setDirection,
    skippedSteps,
    setSkippedSteps,
    currentFormikValue,
    setCurrentFormikValue,
    stepsHistory,
  ]);

  return (
    <stepContext.Provider value={value}>
      <FormikStepper {...props} />
    </stepContext.Provider>
  );
};

export default FormikStepperProvider;
