import { ISupplier } from "./psl.domain";
import { IMaterial } from "./pslMaterial.domain";
import { DateTime } from "luxon";

const waysTreeData = [
  { title: "Standard Purchase Order", value: 1 },
  { title: "Framework Order", value: 2 },
  { title: "Capex Purchase Order", value: 3 },
];
export const WOBLabel = (value) =>
  waysTreeData.find((x) => x.value === value).title;

export interface IChange {
  auditId: string;
  auditDate: string;
  auditAction: "Update" | "Insert" | "Deleted" | "Delete";
  userName: string;
}

export interface ISupplierChange extends ISupplier, IChange {
  id: number;
  childCode: string;
  childName: string;
  marketsString: string;
}
export interface IMaterialChange extends IMaterial, IChange {
  id: number;
  vendorId: number;
  languageString?: string;
  sapMaterialCode?: string;
  shortDescription?: string;
}
export interface IMaterialKeywordsChange extends IChange {
  id: number;
  pslMaterialId: number;
  value: string;
}
export interface IHistoryData {
  materialHistory: IMaterialChange[];
  vendorHistory: ISupplierChange[];
  materialKeywordsHistory: IMaterialKeywordsChange[];
}

export interface IHistory {
  field: string;
  action: string;
  value: string;
  oldValue: string;
  user: string;
  modifiedOn: string;
}

export function groupByMaterialId(data: IMaterialChange[]) {
  let material = [];
  data.forEach((materialChange) => {
    if (material[materialChange.id] === undefined) {
      material[materialChange.id] = [];
    }
    material[materialChange.id].push(materialChange);
  });
  return material;
}

export const historyDataToHistory = (data: IHistoryData): IHistory[] => {
  const materialsById = groupByMaterialId(data.materialHistory);
  let materialChangesById = [];
  materialsById.forEach((materials) => {
    const items = materialChangeToHistory(materials);
    materialChangesById = materialChangesById.concat(items);
  });
  return changeToHistory(data.vendorHistory)
    .concat(materialChangesById)
    .concat(materialKeywordsChangeToHistory(data.materialKeywordsHistory))
    .sort((a, b) => (a.modifiedOn > b.modifiedOn ? -1 : 1));
};

function formatDate(str) {
  const date = new Date(str);
  if (isNaN(date.getTime())) {
    return null; // return null if invalid date
  } else {
    return DateTime.fromJSDate(date).toFormat("dd/MM/yyyy");
  }
}
function getHistoryEntries(
  supplierChanges:
    | ISupplierChange[]
    | IMaterialChange[]
    | IMaterialKeywordsChange[]
) {
  let history: IHistory[] = [];
  for (let i = supplierChanges.length - 1; i > 0; i--) {
    const currentChange = supplierChanges[i];
    const oldChange = supplierChanges[i - 1];
    Object.keys(currentChange).map((currentSupplierKey) => {
      if (
        [
          "auditId",
          "auditDate",
          "userName",
          "auditAction",
          "currencyId",
          "languageId",
          "pslVendorStatusId",
          "code",
          "name",
          "parentMaterialId",
        ].indexOf(currentSupplierKey) !== -1
      ) {
        return null;
      }
      const currentValue = JSON.stringify(currentChange[currentSupplierKey]);
      const newValue = JSON.stringify(oldChange[currentSupplierKey]);
      if (currentValue !== newValue) {
        // to remove differences between [] and null or empty string
        if (
          (currentChange[currentSupplierKey] === null ||
            currentChange[currentSupplierKey] === "" ||
            (Array.isArray(currentChange[currentSupplierKey]) &&
              currentChange[currentSupplierKey].length === 0)) &&
          (oldChange[currentSupplierKey] === null ||
            oldChange[currentSupplierKey] === "" ||
            (Array.isArray(oldChange[currentSupplierKey]) &&
              currentChange[currentSupplierKey].length === 0))
        )
          return null;
        if (
          currentSupplierKey === "languageString" &&
          currentChange[currentSupplierKey] != null &&
          oldChange[currentSupplierKey] === null &&
          currentChange["languageId"] === oldChange["languageId"]
        ) {
          // to fix issue from backend
          return null;
        }
        let value = currentChange[currentSupplierKey];
        let oldValue = oldChange[currentSupplierKey];
        // to fix when valid To and From dates have the same day with different hour:minutes
        if (
          currentSupplierKey === "validTo" ||
          currentSupplierKey === "validFrom"
        ) {
          const valueDate = formatDate(value);
          const valueOldDate = formatDate(oldValue);
          console.log("value", valueDate, "old", valueOldDate);
          if (value != null && valueDate != null && valueDate === valueOldDate) {
            return null;
          }
        }
        let action: string = currentChange.auditAction;
        if (currentSupplierKey === "waysOfBuying") {
          value = value
            ?.split(",")
            .map((x) => WOBLabel(x))
            .join(",");
          oldValue =
            oldValue
              ?.split(",")
              .map((x) => WOBLabel(x))
              .join(",") ?? "";
        }
        if (currentSupplierKey === "deleted") {
          if ("vendorId" in currentChange && "id" in currentChange) {
            currentSupplierKey = "Material";
            const materialDescription =
              currentChange.sapMaterialCode +
              " - " +
              currentChange.shortDescription;
            if (value) {
              value = "";
              oldValue = materialDescription;
              action = "Deleted";
            } else {
              value = materialDescription;
              oldValue = "";
              action = "Undeleted";
            }
          } else {
            currentSupplierKey = "Psl Deleted";
            action = value ? "Deleted" : "Reactivated";
          }
        }
        if (currentSupplierKey === "pslStatus") {
          if (value === 1) {
            action =
              "parentId" in currentChange && currentChange.parentId
                ? "Localized"
                : "Approved";
          }
          if (value === 2) action = "Rejected";
        }
        const change: IHistory = {
          user: currentChange.userName,
          modifiedOn: currentChange.auditDate,
          action: action,
          field: currentSupplierKey,
          value: value,
          oldValue: oldValue,
        };
        history.push(change);
      }
    return null
    });
  }
  return history;
}

export const changeToHistory = (
  supplierChanges: ISupplierChange[]
): IHistory[] => {
  if (supplierChanges.length <= 0) {
    return [];
  }
  const initialChange = supplierChanges[0];
  let history: IHistory[] = [];
  const change: IHistory = {
    action: "Entered",
    field: "Supplier",
    value: `${initialChange?.childCode} - ${initialChange?.childName}`,
    user: initialChange.userName,
    modifiedOn: initialChange.auditDate,
    oldValue: "",
  };

  const locationChange: IHistory = {
    action: "Entered",
    field: "Location",
    value: initialChange.marketsString,
    user: initialChange.userName,
    modifiedOn: initialChange.auditDate,
    oldValue: "",
  };

  history.push(change);
  history.push(locationChange);
  return history.concat(getHistoryEntries(supplierChanges));
};

export const materialChangeToHistory = (
  supplierChanges: IMaterialChange[]
): IHistory[] => {
  const initialChange = supplierChanges[0];
  let history: IHistory[] = [];
  const change: IHistory = {
    action: "Entered",
    field: "Material",
    value:
      initialChange.sapMaterialCode + " - " + initialChange.shortDescription,
    user: initialChange.userName,
    modifiedOn: initialChange.auditDate,
    oldValue: "",
  };
  history.push(change);
  return history.concat(getHistoryEntries(supplierChanges));
};

const formatPartialDate = (date: string) => date.substring(0, 19);
export const materialKeywordsChangeToHistory = (
  supplierChanges: IMaterialKeywordsChange[]
): IHistory[] => {
  if (!supplierChanges) return [];

  const historyMap = new Map<string, IHistory>();
  for (const x of supplierChanges) {
    let key = `${x.pslMaterialId}_${formatPartialDate(x.auditDate)}`;

    if (x.auditAction === "Insert") {
      let entry = historyMap.get(key);
      if (entry) {
        if (entry.action === "Insert") {
          key = `${key}_${generateRandomString()}`;
          entry = null;
        }
      }

      if (!entry) {
        entry = {
          action: "Insert",
          field: "Keywords",
          value: x.value,
          user: x.userName,
          modifiedOn: x.auditDate,
          oldValue: "",
        };
      }
      historyMap.set(key, entry);
    } else if (x.auditAction === "Delete") {
      if (historyMap.has(key)) {
        const entry = historyMap.get(key)!;
        entry.action = "Update";
        entry.oldValue = x.value;
      } else {
        historyMap.set(key, {
          action: "Delete",
          field: "Keywords",
          value: x.value,
          user: x.userName,
          modifiedOn: x.auditDate,
          oldValue: "",
        });
      }
    }
  }

  return Array.from(historyMap.values());
};

function generateRandomString() {
  return (Math.random() * 1e32).toString(36).substring(0, 10);
}
