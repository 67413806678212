import { Box } from "@mui/material";
import { Slide } from "modules/Layout/views/landing.view";
import React from "react";

export const CampaignsListContainer = () => {
  const [activeSlide, setActiveSlide] = React.useState(0);
  return (
    <>
      <Box sx={{ m: -3 }}>
        <Box sx={{ position: "relative", height: "80vh" }}>
          <Slide
            isActive={activeSlide === 0}
            backgroundImage="https://i.ytimg.com/vi/5n4J9duypeQ/maxresdefault.jpg"
            onNext={() => {
              setActiveSlide(1);
            }}
          />
          <Slide
            isActive={activeSlide === 1}
            backgroundImage="https://mir-s3-cdn-cf.behance.net/project_modules/fs/de70ae11079143.560f10407d139.jpg"
            onPrevious={() => {
              setActiveSlide(0);
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default CampaignsListContainer;
