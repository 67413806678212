import { FindInPageOutlined } from "@mui/icons-material";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography/Typography";
import React from "react";

export const NotFoundView = () => {
  return (
    <Box>
      <Typography variant="h1" textAlign={"center"}>
        <FindInPageOutlined sx={{ fontSize: "3rem" }} />
      </Typography>
      <Typography variant="h6" textAlign={"center"}>
        Are you lost?
      </Typography>
      <Typography variant="body2" textAlign={"center"}>
        The route you try to access does not exist. Please check the URL and try
        again.
      </Typography>
    </Box>
  );
};

export default NotFoundView;
