import React, { FC } from "react";
import {
  Avatar,
  Tooltip,
  AvatarProps,
  CircularProgress,
  Box,
} from "@mui/material";

export type PUIAvatarProps = AvatarProps & {
  noBorder?: boolean;
  alt?: string;
  style?: any;
  size?: number;
  isLoading?: boolean;
  backgroundColor?: string;
  foregroundColor?: string;
  TooltipProps?: any;
  [x: string]: any; // allow any additional properties
};

/**
 * Component for showing colorfull Avatars.
 * @component
 * @example
 *   <Avatar alt={'UserName'} src={"./image.jpg"} backgroundColor={'#067BCA'} foregroundColor={'#fff'}/>
 */
export const PUIAvatar: FC<PUIAvatarProps> = ({
  alt,
  sx,
  size = 40,
  isLoading,
  backgroundColor,
  foregroundColor,
  TooltipProps,
  ...otherProps
}) => {
  const avatarStyles = {
    width: size || 40,
    height: size || 40,
    backgroundColor,
    color: foregroundColor,
    fontSize: size < 48 ? 12 : size / 4, //minimum font size of 12px
    border: "5px solid rgba(255,255,255,.3)",
    ...sx,
  };

  return (
    <Box sx={{ position: "relative" }}>
      {alt ? (
        <Tooltip title={alt} {...TooltipProps}>
          <Avatar sx={avatarStyles} {...otherProps}>
            {otherProps.children}
          </Avatar>
        </Tooltip>
      ) : (
        <Avatar sx={avatarStyles} {...otherProps}>
          {otherProps.children}
        </Avatar>
      )}
      {isLoading && (
        <CircularProgress
          size={size}
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            pointerEvents: "none",
            color: "rgba(255,255,255,.5)",
          }}
        />
      )}
    </Box>
  );
};

export default PUIAvatar;
