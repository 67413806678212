import { flatten } from "ramda";
import { IUserRole, User, filtersMapper } from "./user";
import { AxiosWrapper, Headers, MultiOriginValue } from "auth/axios.wrapper";
import { createMutationService } from "utils/factories";

export enum DocFlowFilterTabs {
  FAVORITE = "FAVORITES",
  DOCUMENTS = "DOCUMENTS",
  INVOICE_ISSUES = "INVOICE ISSUES",
}

export interface IAddCompCodeData {
  Code: string;
  Name: string;
  PslLocationMarketId: number;
  PurchasingOrganizations: string;
  PlantGroup: string;
  CatalogId: string;
  ApprovalNeeded: boolean;
}

export interface IUserFullScope {
  zone: { id: number; name: string };
  fullZoneAccess: boolean;
  markets: IUserScope[];
}

export interface IUserScope {
  fullMarketAccess: boolean;
  market: { id: number; name: string };
  companyCodes: IUserScopeCompany[];
}

export interface IUserScopeCompany {
  id: number;
  code: string;
  name: string;
}

export class UserService {
  static async fetchUser(): Promise<User> {
    const httpClient = new AxiosWrapper();
    const response = await httpClient.get("/api/Users/Me", {
      headers: { [Headers.MULTIORIGINS]: MultiOriginValue },
    });
    const user = response.data;
    return User.FromBackend(user);
  }

  static async fetchLiteUser(): Promise<User> {
    const httpClient = new AxiosWrapper();
    const response = await httpClient.get("/api/Users/MeLite");
    return User.FromBackend(response.data);
  }

  static async fetchUserContactDetails(email: string): Promise<any> {
    const url = "/api/Users/GetUserDetails?email=" + email;
    const httpClient = new AxiosWrapper();
    const response = await httpClient.get(url);
    return response.data;
  }

  static async fetchZones(): Promise<any> {
    const httpClient = new AxiosWrapper();
    const response = await httpClient.get("/api/Locations/Zones");
    return response.data;
  }

  static async fetchUserAppFilters(): Promise<any> {
    const httpClient = new AxiosWrapper();
    const response = await httpClient.get(`/api/Settings/GetUserFilters`);
    return filtersMapper(response.data);
  }

  static async fetchUserProfile(): Promise<any> {
    const httpClient = new AxiosWrapper();
    const response = await httpClient.get(`/api/Settings/GetUserProfile`);
    return response.data;
  }

  static async getInteractionStatistics(): Promise<any> {
    const path = "/api/Users/GetUserInteractionStatistics";
    const httpClient = new AxiosWrapper();
    const response = await httpClient.get(path, { responseType: "blob" });
    return response.data;
  }

  static async saveZoneSettings(zone: number): Promise<any> {
    const path = "/api/Users/PostUserProfileSettings";
    const httpClient = new AxiosWrapper();
    const response = await httpClient.post(path, {
      selectedZone: { id: zone },
    });
    return response.data;
  }

  static async saveUserSettings(payload: any): Promise<any> {
    const path = "/api/Settings/SaveUserProfile";
    const httpClient = new AxiosWrapper();
    const response = await httpClient.post(path, {
      userPreferredCompanyCode: payload.userPreferredCompanyCode,
      userPreferredCompanyName: payload.userPreferredCompanyName,
      userPreferredPslPersona: payload.userPreferredPslPersona,
      pslExpiryDays: payload.pslExpiryDays,
      dateTimeFormat: payload.dateTimeFormat,
      timeZone: payload.timeZone,
      decimalNotation: payload.decimalNotation,
      selectedZone: payload.zone
        ? {
            id: payload.zone,
          }
        : null,
      contractSpecificProfile: {
        buyers: payload.contractSpecificProfile.buyers,
        clusters: payload.contractSpecificProfile.clusters,
        zones: payload.contractSpecificProfile.zones,
        markets: payload.contractSpecificProfile.markets?.map((m) => m.name),
        spendCategoryCodes: payload.contractSpecificProfile.spendCategoryCodes,
        companyCodes: payload.contractSpecificProfile.companyCodes,
        plants: payload.contractSpecificProfile.plants,
        businesses: payload.contractSpecificProfile.businesses,
      },
    });
    return response.data;
  }

  static async saveNewCompanyCode(
    newCompCodeData: IAddCompCodeData
  ): Promise<any> {
    const path = "/api/PslLocationCompanies/AddLocationCompany";
    const httpClient = new AxiosWrapper();
    const response = await httpClient.post(path, newCompCodeData);
    return response.data;
  }

  static async saveContractFilters(filters): Promise<any> {
    const path = "/api/Settings/SaveFilters/Contracts";
    const httpClient = new AxiosWrapper();
    const response = await httpClient.post(path, filters);
    return response.data;
  }

  static async saveContractCalendarFilters(filters): Promise<any> {
    const path = "/api/Settings/SaveFilters/ContractCalendar";
    const httpClient = new AxiosWrapper();
    const response = await httpClient.post(path, filters);
    return response.data;
  }

  static async saveDocflowFilters(
    filters,
    docType = DocFlowFilterTabs.DOCUMENTS
  ): Promise<any> {
    const collection =
      docType === DocFlowFilterTabs.FAVORITE
        ? "FavDocumentFlow"
        : "DocumentFlow";
    const path = `/api/Settings/SaveFilters/${collection}`;
    const httpClient = new AxiosWrapper();
    const response = await httpClient.post(path, filters);
    return response.data;
  }

  static async saveInvoiceFilters(filters): Promise<any> {
    const path = "/api/Settings/SaveFilters/Invoices";
    const httpClient = new AxiosWrapper();
    const response = await httpClient.post(path, filters);
    return response.data;
  }

  static async savePslFilters(filters): Promise<any> {
    const path = "/api/Settings/SaveFilters/Psl";
    const httpClient = new AxiosWrapper();
    const response = await httpClient.post(path, filters);
    return response.data;
  }

  static async saveMrpFilters(filters): Promise<any> {
    const path = "/api/Settings/SaveFilters/Mrp";
    const httpClient = new AxiosWrapper();
    const response = await httpClient.post(path, filters);
    return response.data;
  }

  static async saveUserScopeFilters(filters): Promise<any> {
    const path = "/api/Settings/SaveFilters/UserScope";
    const httpClient = new AxiosWrapper();
    const response = await httpClient.post(path, filters);
    return response.data;
  }

  static async saveCbFilters(filters): Promise<User> {
    const path = "/api/Settings/SaveFilters/CompetitiveBidding";
    const httpClient = new AxiosWrapper();
    const response = await httpClient.post(path, filters);
    return response.data;
  }

  static async saveBusinessApprovalFilters(filters): Promise<any> {
    const path = "/api/Settings/SaveFilters/BusinessApproval";
    const httpClient = new AxiosWrapper();
    const response = await httpClient.post(path, filters);
    return response.data;
  }

  static async saveProcurementApprovalFilters(filters): Promise<any> {
    const path = "/api/Settings/SaveFilters/PurchasingApproval";
    const httpClient = new AxiosWrapper();
    const response = await httpClient.post(path, filters);
    return response.data;
  }

  static async fetchUserScope(
    flattened: boolean
  ): Promise<IUserScopeCompany[] | IUserScope[]> {
    const httpClient = new AxiosWrapper();
    const response = await httpClient.get("/api/Users/GetUserAuthorizedScope");
    return flattened
      ? flatten(response.data.map((item) => item.companyCodes))
      : response.data;
  }

  static async fetchUserFullScope(): Promise<IUserFullScope[]> {
    const httpClient = new AxiosWrapper();
    const response = await httpClient.get(
      "/api/Users/GetUserAuthorizedFullScope"
    );
    return response.data;
  }

  static async viewChanges(changeIds: number[]): Promise<boolean> {
    const path = "/api/Users/PostUserReadChangeLogItems";
    const httpClient = new AxiosWrapper();
    await httpClient.post(path, { ids: changeIds });
    return true;
  }

  static readonly changeUserHardcodedPslPersona = createMutationService<
    never,
    { pslPersona: IUserRole }
  >(
    ({ pslPersona }) =>
      `/api/Users/ChangeUserHardcodedPslPersona?pslPersona=${pslPersona}`
  );
}
