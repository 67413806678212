import { TreeSelect } from "antd";
import * as React from "react";
const atMarketChilds = [
  {
    title: "At Market-Child",
    value: "At Market-Child",
  },
  {
    title: "At Market-Local",
    value: "At Market-Local",
  },
];
const aboveMarketTreeData = [
  {
    title: "Above Market",
    value: "Above Market",
  },
  {
    title: "At Market",
    value: "At Market",
    children: atMarketChilds,
  },
];
const atMarketTreeData = [
  {
    title: "At Market",
    value: "At Market",
    children: atMarketChilds,
  },
];
export const ScopeFilterComponent = ({ onChange, initialValue, user }) => {
  const isAtMarket = user.hasAtMarketRole() || user.isRequestor();

  const [selectedpslScope, setSelectedpslScope] = React.useState(
    initialValue ? initialValue : []
  );

  const onChangeSuperDiversity = (value: any[]) => {
    setSelectedpslScope(value);
    onChange(value);
  };

  const userScopeProps = {
    treeData: isAtMarket ? atMarketTreeData : aboveMarketTreeData,
    value: selectedpslScope,
    onChange: onChangeSuperDiversity,
    disabled: false,
    treeCheckable: true,
    treeCheckStrictly: false,
    dropdownMatchSelectWidth: false,
    treeNodeFilterProp: "title",
    showCheckedStrategy: TreeSelect.SHOW_PARENT,
    placeholder: "Click here to select Scope",
    style: { width: "100%", minHeight: "38px" },
  };

  return <TreeSelect {...userScopeProps} dropdownStyle={{ zIndex: 99999 }} />;
};
