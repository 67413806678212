// Okay okay, fine the name is not ideal - this component is to display one item or if there are many items - their count and list of the items in a popup. Feel free to give it better name :D

import React, { useState } from "react";
import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  ContentCopyOutlined,
  LibraryAddCheckOutlined,
  LibraryAddOutlined,
} from "@mui/icons-material";

export const ItemsCounter = ({
  data,
  singularName,
  pluralName,
}: {
  data: string[];
  singularName: string;
  pluralName: string;
}) => {
  const [linkCopied, setLinkCopied] = useState(false);
  // const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  // const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handlePopoverClose = () => {
  //   setAnchorEl(null);
  // };

  // const open = Boolean(anchorEl);
  return !data?.length ? null : (
    <>
      <Tooltip
        title={
          <>
            {data.sort().map((item) => (
              <ListItem key={item} dense>
                <ListItemText primary={item} />
                <IconButton
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    navigator.clipboard.writeText(item);
                    setLinkCopied(true);
                  }}
                  onMouseLeave={() => {
                    setLinkCopied(false);
                  }}
                  color="inherit"
                >
                  {linkCopied ? (
                    <LibraryAddCheckOutlined
                      sx={{ fontSize: "1.5em", verticalAlign: "middle" }}
                    />
                  ) : (
                    <LibraryAddOutlined
                      sx={{ fontSize: "1.5em", verticalAlign: "middle" }}
                      onClick={() => {
                        navigator.clipboard.writeText(item);
                        setLinkCopied(true);
                      }}
                    />
                  )}
                </IconButton>
              </ListItem>
            ))}
            {/* {data.length === 1 ? data[0] : `${data.length} ${pluralName}`}
            {linkCopied ? (
              <LibraryAddCheckOutlined sx={{ fontSize: "1.5em", verticalAlign: "middle" }} />
            ) : (
              <LibraryAddOutlined
                sx={{ fontSize: "1.5em", verticalAlign: "middle" }}
                onClick={() => {
                  navigator.clipboard.writeText(data[0]);
                  setLinkCopied(true);
                }}
              />
            )} */}
          </>
        }
        onClose={() => setLinkCopied(false)}
        arrow
      >
        <Typography
          // aria-owns={open ? "mouse-over-popover" : undefined}
          // aria-haspopup="true"
          // onMouseEnter={data.length > 1 ? handlePopoverOpen : null}
          sx={{
            maxWidth: "100%",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {data.length === 1 ? data[0] : `${data.length} ${pluralName}`}
        </Typography>
      </Tooltip>
      {/* <Popover
        id="mouse-over-popover"
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        onMouseLeave={handlePopoverClose}
        disableRestoreFocus
        slotProps={{
          paper: {
            onMouseLeave: handlePopoverClose,
          },
        }}
      >
        <List dense>
          {data.sort().map((item) => (
            <ListItem key={item}>
              <ListItemText primary={item} />
              <Tooltip
                title={linkCopied ? "Coppied!" : "Copy to clipboard"}
                placement="right"
                arrow
              >
                <IconButton
                  size="small"
                  onClick={() => {
                    navigator.clipboard.writeText(item);
                    setLinkCopied(true);
                  }}
                  onMouseLeave={() => {
                    setLinkCopied(false);
                  }}
                >
                  <ContentCopyOutlined />
                </IconButton>
              </Tooltip>
            </ListItem>
          ))}
        </List>
      </Popover> */}
    </>
  );
};

export default ItemsCounter;
