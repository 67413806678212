import { TextField, TextFieldProps } from "@mui/material";
import { useField } from "formik";
import React from "react";

export const TextFieldWrapper = (
  props:
    | TextFieldProps
    | {
        name: string;
      }
) => {
  const { name, ...otherProps } = props;
  const [field, meta, helpers] = useField(props.name);
  return (
    <TextField
      {...otherProps}
      value={field.value}
      onChange={(e) => helpers.setValue(e.target.value)}
    />
  );
};

export default TextFieldWrapper;
