import * as React from "react";
import { Field, FieldProps, useField } from "formik";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { FormHelperText } from "@mui/material";
import FormControl from "@mui/material/FormControl";

export const CheckboxInput = (props: {
  name: string;
  label: string;
  title?: string;
  value?: boolean;
  readOnly?: boolean;
  onChange?: (e: any) => void;
}) => {
  const { name, label, value, readOnly, onChange } = props;
  const [, meta, helpers] = useField(name);

  const error = meta.error;

  return (
    <Field name={name}>
      {({ field }: FieldProps) => (
        <FormControl required component="fieldset">
          <FormControlLabel
            control={
              <Checkbox
                disabled={readOnly}
                checked={field.value || false}
                onChange={(e) => {
                  helpers.setValue(!field.value);
                  if (onChange) onChange(e);
                }}
                value={value}
                color="primary"
              />
            }
            label={label}
          />
          {!!error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
      )}
    </Field>
  );
};

export const CheckboxWrapper = CheckboxInput;

export default CheckboxWrapper;
