import type { FormikProps } from "formik";
import React, { useRef, useState } from "react";
import type * as Yup from "yup";
import { Confirmation } from "../components/ConfirmationDialog.component";

/**
 *
 * @param props
 * @returns {requestConfirmation, JSX}
 *
 */

export function useConfirmation(props: {
  title?: string;
  subtitle?: string;
  description?: string;
  submitButtonText?: string;
  cancelButtonText?: string;
  additionalData?: {
    key: string;
    initialValue: any;
    validationSchema: Yup.Schema<any>;
  }[];
  additionalContent?: (any: FormikProps<any>) => JSX.Element;
}) {
  const promiseRef = useRef<{
    resolve: (value: any) => void;
    reject: (value: any) => void;
  }>();

  const [dialogJSX, setDialogJSX] = useState<JSX.Element>(<></>);
  const [open, setOpen] = useState(false);

  React.useEffect(() => {
    setDialogJSX(
      <Confirmation
        persist
        title={props.title}
        description={props.description}
        visible={open}
        confirmLabel={props.submitButtonText}
        cancelLabel={props.cancelButtonText}
        onConfirm={() => {
          promiseRef.current!.resolve(null);
          setOpen(false);
        }}
        onCancel={() => {
          promiseRef.current!.reject(null);
          setOpen(false);
        }}
        isLoadingConfirm={false}
      />
    );
  }, [open]);

  function requestConfirmation() {
    setOpen(true);
    return new Promise<any>((resolve, reject) => {
      promiseRef.current = { resolve, reject };
    });
  }

  return {
    requestConfirmation,
    JSX: dialogJSX,
  };
}
