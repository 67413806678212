import React from "react";
import {Grid, InputLabel} from "@mui/material";
import {CustomFastSelect} from "../../Forms/components/form.legacy.component";

export function LocationComponent({
                               isViewOnly, filters, companyCodes,
                               marketState, setFilters, zoneState
                           }) {

    let [arr, setArr] = React.useState([]);
    const zoneData = filters.zones;
    const marketData = filters.markets;
    const companyCodeData = filters.companies
    const ZONE_ID = 3;
    const MARKET_ID = 1;
    const COMPANY_CODE_ID = 2;

    let options = [
        { value: ZONE_ID, label: "Zones" },
        { value: MARKET_ID, label: "Markets" },
        { value: COMPANY_CODE_ID, label: "Company Codes" },
    ]

    React.useEffect(() => {
        if (zoneData?.length) setArr([options[0]])
        if (marketData?.length) setArr([options[1]])
        if (companyCodeData?.length) setArr([options[2]])
    }, [zoneData, marketData, companyCodeData])

    function changeHandler(v) {
        const filtersCopy = {...filters}
        let selected = v ? v.map(el => ({
            id: el.value,
            title: el.label
        })) : []

        if (arr[0]?.value === COMPANY_CODE_ID) {
            filtersCopy.zones = [];
            filtersCopy.markets = [];
            filtersCopy.companies = selected
            setFilters(filtersCopy)
        }

        if (arr[0]?.value === MARKET_ID) {
            filtersCopy.zones = [];
            filtersCopy.companies = [];
            filtersCopy.markets = selected
            setFilters(filtersCopy)
        }

        if (arr[0]?.value === ZONE_ID) {
            filtersCopy.markets = [];
            filtersCopy.companies = [];
            filtersCopy.zones = selected
            setFilters(filtersCopy)
        }
    }

    function removeAllFilters(v) {
        if (!v) {
            let filtersCopy = {...filters};
            filtersCopy.markets = [];
            filtersCopy.companies = [];
            filtersCopy.zones = [];
            setFilters(filtersCopy)
        }
    }

    function getInitialValues() {
        switch (arr[0]?.value) {
            case ZONE_ID:
                return filters.zones?.map((el: { id: any; title: any; }) => ({
                    value: el.id.toString(),
                    label: el.title
                }))
            case MARKET_ID:
                return filters.markets?.map((el: { id: any; title: any; }) => ({
                    value: el.id.toString(),
                    label: el.title
                }))
            case COMPANY_CODE_ID:
                return filters.companies?.map((el: { id: any; title: any; }) => ({
                    value: el.id,
                    label: el.title
                }))
            default:
                return []
        }
    }

    function getOptions() {
        if (arr[0]?.value === ZONE_ID) {
            return zoneState.map(el => ({
                value: el.id.toString(), label: el.name
            }))
        }
        if (arr[0]?.value === MARKET_ID) {
            return marketState.map(v => ({
                value: v.id.toString(),
                label: v.title
            }))
        }
        if (arr[0]?.value === COMPANY_CODE_ID) {
            return companyCodes.map(v => ({
                value: v.id,
                label: `${v.code} - ${v.name}`
            }))
        }

        return []
    }

    function getPlaceholder() {
        switch (arr[0]?.value) {
            case COMPANY_CODE_ID:
                return 'Please select Company Codes'
            case MARKET_ID:
                return 'Please select Markets'
            case ZONE_ID:
                return 'Please select Zones'
            default:
                return ''
        }
    }

    return (<div>
        <Grid container spacing={2} wrap={"wrap"}>
            <Grid item sm={4}>
            </Grid>
            <Grid item sm={8}>
                <CustomFastSelect
                    disabled={isViewOnly}
                    options={options}
                    initialValue={arr}
                    onChange={(v: { value: any; label: any }) => {
                        setArr((v?.value && v?.label) ? [{ value: v.value, label: v.label }] : [])
                        removeAllFilters(v);
                    }}
                />
            </Grid>
        </Grid>
        <Grid item >
            <div
                style={{ marginTop: 10 }}
            >
                <CustomFastSelect
                    isMulti={true}
                    disabled={!arr.length}
                    options={getOptions()}
                    placeholder={getPlaceholder()}
                    initialValue={getInitialValues()}
                    onChange={(v: any[]) => {
                        changeHandler(v);
                    }}
                />
            </div>

        </Grid>
    </div>)
}