import { CircularProgress } from "@mui/material";
import SplashScreen from "modules/UI/components/SplashScreen.component";
import { UserService } from "modules/User/service/user.service";
import React, { useState, useEffect } from "react";
import { User } from "./service/user";

export const ActiveUserContext = React.createContext({
  activeUser: null,
  loading: true,
  error: null,
  fetchUser: () => {},
});

export const ActiveUserProvider = ({ children }) => {
  const [activeUser, setActiveUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const fetchUser = async () => {
    try {
      const user = await UserService.fetchLiteUser();
      const filters = await UserService.fetchUserAppFilters();
      setActiveUser({ ...user, filters });
    } catch (e) {
      console.log({ error: e });
      setError(e);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <ActiveUserContext.Provider
      value={{ activeUser, loading, error, fetchUser }}
    >
      {" "}
      <SplashScreen
        message="Loading User details..."
        status={!!loading ? "loading" : !!error ? "error" : "idle"}
      />
      {!loading && !error && children}
    </ActiveUserContext.Provider>
  );
};

export const useActiveUser = () => {
  const context = React.useContext(ActiveUserContext);
  if (context === undefined) {
    throw new Error("useActiveUser must be used within a ActiveUserProvider");
  }
  return User.FromBackend(context.activeUser);
};
