import {
  CodeNamePair,
  getFormStatus,
  IdNameCode,
  IdNamePair,
  PSL_AT_MARKET,
  PslStatus,
} from "./psl.domain";

export interface ICatRow {
  materialId?: any;
  parentId?: any;
  parentMaterialId?: any;
  pslId?: any;
  pslIdWithPrefix?: string;
  spendCat1Id?: number;
  spendCat1DisplayValue?: string;
  spendCat1Code?: string;
  spendCat2Id?: number;
  spendCat2DisplayValue?: string;
  spendCat2Code?: string;
  spendCat3Id?: number;
  spendCat3Code?: string;
  spendCat3DisplayValue?: string;
  languageId?: number;
  languageTag?: string;
  keyWords?: string[];
  unitOfMeasure?: string;
  itemDescription?: string;
  shortDescription?: string;
  unitPrice?: number;
  pricePer?: number;
  supplierLeadTime?: number;
  currencyCode?: string;
  matGrp?: string;
  materialNumber?: string;
  materialName?: string;
  supplierPartId?: string;
  manufacturerPartNumber?: string;
  manufacturerName?: string;
  parent?: IParentMaterial | any;
  deleted?: boolean;
  waysOfBuying?: number[];
  isParentApproved?: boolean;
  plants?: CodeNamePair[];
}

export interface IMaterial extends ICatRow {
  pslStatus?: PslStatus;
  statusId?: number;
  supplierCode?: string;
  supplierName?: string;
  childSupplierCode?: string;
  childSupplierName?: string;
  parentCode?: string;
  parentName?: string;
  isAboveMarket?: boolean;
  businessUnits?: IdNamePair[];
  zones?: IdNamePair[];
  markets?: IdNamePair[];
  companyCodes?: IdNameCode[];
  validFrom?: string;
  validTo?: string;
  priceList?: string;
  currencyId?: number;
  language?: string;
  systemAlias?: string;
  hideBuyer?: boolean;
  relatedToEasyBuy?: boolean;
  statusJustification?: string;
  statusAttachments?: Array<string>;
  strategicBuyerEmailAddress?: string;
  materialDeleted?: boolean;
  materialOrVendorDeleted?: boolean;
  createdOn?: string; //2020-05-21T07?:09?:18.877Z
  createdByUser?: { id: string; username: string; email: string };
  modifiedOn?: string; //2020-05-21T07?:09?:18.877Z
  modifiedByUser?: { id: string; username: string; email: string };
  scope?: number;
  approvers?: string[];
  diversity?: Array<{ code: string; text: string; defined: boolean }>;
  marketContactName?: string;
  isParentDeleted?: boolean;
  isFavorite?: boolean;
}

export interface IParentMaterial {
  materialNumber?: string;
  materialName?: string;
  shortDescription?: string;
  itemDescription?: string;
  supplierPartId?: string;
  manufacturerPartNumber?: string;
  manufacturerName?: string;
  supplierLeadTime?: number;
  keyWords?: string[];
  languageId?: number;
  modifiedOn?: string; //2020-05-21T07?:09?:18.877Z,
  unitPrice?: number;
}

export class PslMaterial {
  private readonly material: IMaterial;

  private constructor(material: IMaterial) {
    this.material = material;
  }

  static build(material: IMaterial) {
    return new PslMaterial(material);
  }

  isActive = () => getFormStatus(this.material, 0) === "Active";
  isAtMarket = () => this.material.scope === PSL_AT_MARKET;
  canBeRequested = () => this.isActive() && this.isAtMarket();
}

export const WaysOfBuyingText = {
  1: "Standard Purchase Order",
  2: "Framework Order",
  3: "Capex Purchase Order",
};
