import { Grid, Pagination, TablePagination } from "@mui/material";
import PromoItemCard from "../components/PromoItemCard.component";
import React from "react";

export const MaterialsGridContainer = (props: {
  data: any;
  onRefetch?: () => void;
  onPreview?: (promoItem: any) => void;
  count?: number;
  page?: number;
  onPageChange?: (event: React.ChangeEvent<unknown>, value: number) => void;
  rowsPerPage?: number;
  onRowsPerPageChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <Grid container spacing={2} alignItems={"stretch"}>
      {props.data?.map((material, index) => (
        <Grid item key={material.pslId + index} xs={12} sm={4} md={6} lg={3}>
          <PromoItemCard
            promoItem={material}
            onRefetch={props.onRefetch}
            onPreview={() => {
              if (!props.onPreview) return;
              props.onPreview(material);
            }}
          />
        </Grid>
      ))}
      <Grid item xs={12}>
        <TablePagination
          component="div"
          count={props.count}
          page={props.page}
          rowsPerPageOptions={[12, 24, 48, 96]}
          onPageChange={props.onPageChange}
          rowsPerPage={props.rowsPerPage}
          onRowsPerPageChange={props.onRowsPerPageChange}
          sx={{ width: "100%" }}
        />
      </Grid>
    </Grid>
  );
};

export default MaterialsGridContainer;
