import { TreeSelect } from "antd";
import * as React from "react";
import { useEffect } from "react";
import {
  IUserScope,
  IUserScopeCompany,
  UserService,
} from "modules/User/service/user.service";
import { includes } from "ramda";
// import { TreeSelectProps } from "antd/lib/tree-select";
// import { User } from "modules/User/service/user";
import { useQuery } from "@tanstack/react-query";
import { TextFilter } from "lib/odata";

interface IdTitlePair {
  id: number;
  title: string;
}

type Props = {
  company?: string;
  isViewOnly?: boolean;
  initialMarkets: TextFilter[] | IdTitlePair[];
  initialCompanies: TextFilter[] | IdTitlePair[];
  hasError: boolean;
  onChange: Function;
  disabled?: boolean;
  limitedLocation?: boolean;
  clear?: boolean;
  customSuccessStyle?: any;
  customErrorStyle?: any;
};

export const UserScopeComponent = ({
  company = "",
  isViewOnly = false,
  initialMarkets = [],
  initialCompanies = [],
  onChange,
  hasError,
  disabled = false,
  limitedLocation = false,
  clear = false,
  customSuccessStyle = null,
  customErrorStyle = null,
}: Props) => {
  const [userScope, setUserScope] = React.useState([]);
  const [selectedUserScope, setSelectedUserScope] = React.useState(
    []
      .concat(
        initialCompanies.map((el) => ({
          value: "company-" + el?.id,
          label: el?.title,
        }))
      )
      .concat(
        initialMarkets.map((el) => ({
          value: "market-" + el?.id,
          label: el?.title,
        }))
      )
  );
  const { data: response } = useQuery({
    queryKey: ["UserScope"],
    queryFn: () => UserService.fetchUserScope(false),
    // enabled: false,
  });
  useEffect(() => {
    let isSubscribed = true;
    const pslMarkets = initialMarkets.map((item) => item.id);
    const pslCompCodes = initialCompanies.map((comp) => comp.id);
    if (response && isSubscribed) {
      const marketsAndCompanies = limitedLocation
        ? (response as IUserScope[])
            .filter(
              (item) => item.fullMarketAccess || item.companyCodes.length > 0
            )
            .filter(
              (item) =>
                pslMarkets.includes(item.market.id) ||
                item.companyCodes.filter((comp) =>
                  pslCompCodes.includes(comp.id)
                ).length > 0
            )
        : (response as IUserScope[]).filter(
            (item) => item.fullMarketAccess || item.companyCodes.length > 0
          );

      const tree = marketsAndCompanies.map((el: IUserScope) => ({
        title: el.market?.name,
        disableCheckbox: !el.fullMarketAccess,
        value: "market-" + el.market?.id,
        key: "market-" + el.market?.id,
        children: el.companyCodes.map((c: IUserScopeCompany) => ({
          title: `${c.code} - ${c.name}`,
          value: "company-" + c.id,
          key: "company-" + c.id,
        })),
      }));
      const findCompany = (array) => {
        for (const item of array) {
          const result = item.title === company ? true : false;
          if (result) return item;
        }
      };
      const findParent = (array) => {
        for (const item of array) {
          const result = findCompany(item.children);
          if (result) {
            item.children = [result];
            return item;
          }
        }
      };
      if (isViewOnly) {
        const treeForViewOnly = findParent(tree);
        setUserScope([treeForViewOnly]);
      } else setUserScope(tree);
    }
    return () => {
      isSubscribed = false;
    };
  }, [response]);

  useEffect(() => {
    if (clear) {
      setSelectedUserScope([]);
    }
  }, [clear]);

  const onChangeUserScope = (
    originalValues: { value: string; label: string; code: string }[]
  ) => {
    setSelectedUserScope(originalValues);
    onChange({
      markets: originalValues
        .filter((el) => el && el.value?.indexOf("market-") !== -1)
        .map((el) => ({ id: el.value.split("market-")[1], title: el.label })),
      companyCodes: originalValues
        .filter((el) => el && el.value?.indexOf("company-") !== -1)
        .map((el) => ({
          id: el.value.split("company-")[1],
          title: el.label,
          code: el.label.split(" - ")[0],
        })),
    });
  };

  const userScopeProps = {
    treeData: userScope,
    value: selectedUserScope,
    onChange: onChangeUserScope,
    disabled: disabled,
    treeCheckable: true,
    treeCheckStrictly: false,
    dropdownMatchSelectWidth: false,
    treeNodeFilterProp: "title",
    showCheckedStrategy: TreeSelect.SHOW_PARENT,
    labelInValue: true,
    placeholder: "Type 2 characters to start searching",
    filterTreeNode: (inputValue: string, treeNode: any) => {
      if (inputValue.length < 2) return false;
      return includes(inputValue.toUpperCase(), treeNode.title.toUpperCase());
    },
  };

  const errorStyle = {
    border: "1px solid red",
    borderRadius: "4px",
    width: "100%",
    minHeight: "38px",
  };
  const successStyle = { width: "100%", minHeight: "38px" };
  const getStyle = () =>
    hasError
      ? customErrorStyle == null
        ? errorStyle
        : customErrorStyle
      : customSuccessStyle == null
      ? successStyle
      : customSuccessStyle;

  return (
    <TreeSelect
      {...userScopeProps}
      dropdownStyle={{ zIndex: 99999 }}
      style={getStyle()}
    />
  );
};
