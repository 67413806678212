import React from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useField } from "formik";
import moment from "moment";

export const DatePickerWrapper = (props) => {
  const { name, ...otherProps } = props;
  const [field, meta, helpers] = useField(name);
  return (
    <DatePicker
      {...otherProps}
      value={moment(field.value)}
      onChange={(value) => {
        helpers.setValue(value ? value.toISOString() : null);
      }}
    />
  );
};
