import * as React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { AttachFile } from "@mui/icons-material";
import { ListItemButton } from "@mui/material";

export const Attachment = (props: {
  icon?: React.ReactElement;
  fileName: string;
  secondary?: string;
  onDelete?: () => void;
  onClick?: () => void;
}) => {
  return (
    <ListItemButton onClick={props.onClick}>
      <ListItemIcon>{props.icon ? props.icon : <AttachFile />}</ListItemIcon>
      <ListItemText primary={props.fileName} secondary={props.secondary} />
      {props.onDelete && (
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            aria-label="delete"
            onClick={() => props.onDelete()}
          >
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </ListItemButton>
  );
};
