import React, { createContext, useContext, useState, useEffect } from "react";
import { stepContext } from "modules/Forms/components/formikStepperProvider.component";
import { StepperDirection } from "modules/Forms/models";

export const useStepper = () => {
  const {
    currentStep,
    setCurrentStep,
    direction,
    displayedCurrentStep,
    setDisplayedCurrentStep,
    skippedSteps,
    setSkippedSteps,
  } = useContext(stepContext);

  useEffect(() => {
    if (skippedSteps.includes(displayedCurrentStep)) {
      if (direction === StepperDirection.Right) {
        setDisplayedCurrentStep(displayedCurrentStep + 1);
      } else {
        setDisplayedCurrentStep(displayedCurrentStep - 1);
        setSkippedSteps(skippedSteps.filter((s) => s !== displayedCurrentStep));
      }
    }
  }, [displayedCurrentStep]);

  const skipStep = () => {
    const nextStep = currentStep + 1;
    const prevStep = currentStep - 1;
    setCurrentStep(direction === StepperDirection.Right ? nextStep : prevStep);
    if (
      direction === StepperDirection.Right &&
      !skippedSteps.includes(nextStep)
    ) {
      setSkippedSteps([...skippedSteps, nextStep]);
    }
  };

  const goForwards = () => {
    setCurrentStep(currentStep + 1);
    setDisplayedCurrentStep(displayedCurrentStep + 1);
  };

  const goBackwards = () => {
    setCurrentStep(currentStep - 1);
    setDisplayedCurrentStep(displayedCurrentStep - 1);
  };

  return { skipStep, direction, goForwards, goBackwards };
};
