import React, {useMemo, useRef, useState} from "react";
import {useMutation} from "@tanstack/react-query";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import {
  Avatar,
  ButtonGroup,
  Chip,
  Drawer,
  InputAdornment,
  Link,
  ListItemIcon,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import {
  MaterialReactTable,
  MRT_PaginationState,
  MRT_ShowHideColumnsButton,
  MRT_SortingState,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFullScreenButton,
  useMaterialReactTable,
} from "material-react-table";
import {
  Add,
  Close,
  Collections,
  Done,
  Download,
  Edit,
  Favorite,
  FavoriteBorder,
  FilterList,
  GpsFixedRounded,
  HistoryOutlined,
  Search,
  Upload,
} from "@mui/icons-material";
import {NavLink, useNavigate} from "react-router-dom";
import PromoItemsService from "../services/PromoItems.service";
import {capitalizeFirstLetter, toTitleCase} from "utils/utils";
import {SupplierStatus} from "./create.container";
import moment from "moment";
import {getFormStatus} from "../domains/psl.domain";
import {WaysOfBuyingText} from "../domains/pslMaterial.domain";
import StaticResources from "service/dummyData/resources";
import saveAs from "file-saver";
import {PromoHistory} from "../components/PromoHistory.component";
import ItemsCounter from "modules/UI/components/ItemsCounter.component";

export const MaterialsListContainer = (props: {
  onImport?: () => void;
  onManageMedia?: (promoItem: any) => void;
  rowCount: number;
  setRowCount: (rowCount: number) => void;
  data: any;
  refetch?: () => void;
  sorting: MRT_SortingState;
  setSorting: React.Dispatch<React.SetStateAction<MRT_SortingState>>;
  globalFilter?: string;
  setGlobalFilter?: (globalFilter: string) => void;
  pagination: MRT_PaginationState;
  setPagination: React.Dispatch<React.SetStateAction<MRT_PaginationState>>;
  isLoading?: boolean;
  isRefetching?: boolean;
  isError?: boolean;
  isChild?: boolean;
  onFilterClick?: () => void;
  filters?: any;
  setFilters?: Function
  filterBy?: string;
  setFilterBy?: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const navigate = useNavigate();
  const [historyItem, setHistoryItem] = useState<{
    materialId?: string | number;
    pslId?: string | number;
  } | null>(null);
  const {
    data,
    isLoading,
    isRefetching,
    isError,
    rowCount,
    setRowCount,
    sorting,
    setSorting,
    globalFilter,
    setGlobalFilter,
    pagination,
    setPagination,
    onFilterClick,
    filters,
    filterBy,
    setFilterBy,
      setFilters
  } = props;

  const { mutate: exportMaterials } = useMutation({
    mutationKey: [
      "export-materials",
      globalFilter,
      filters,
      filterBy,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
    ],
    mutationFn: async () => {
      // export logic here
      const response = await PromoItemsService.exportMaterials({
        ...filters,
        searchTerm: !!filterBy || !globalFilter ? null : globalFilter,
        take: pagination.pageSize,
        skip: pagination.pageIndex * pagination.pageSize,
        orderBy: sorting[0]
          ? {
              field: capitalizeFirstLetter(sorting[0].id),
              direction: sorting[0].desc ? "desc" : "asc",
            }
          : null,
        pslSearch:
          !!filterBy && !!globalFilter
            ? {
                [filterBy]: globalFilter,
              }
            : null,
      });
      saveAs(response, "materials.xlsx");
    },
  });

  const { mutateAsync: toggleFavorite, status } = useMutation<
    any,
    any,
    { pslId: string | number }
  >({
    mutationKey: ["toggleFavorite"],
    mutationFn: async ({ pslId }) => {
      await PromoItemsService.toggleFavoriteMaterial({ pslId });
    },
    onSuccess: () => {
      if (props.refetch) props.refetch();
    },
  });

  const columns = useMemo<any[]>(
    () => [
      {
        accessorKey: "mediaFiles",
        header: "Image",
        size: 150,
        enableSorting: false,
        Cell: ({ row }) => {
          const promoCategory = StaticResources.promoCategories.find(
            (c) => c.id?.toString() === row.original.promoCategoryId?.toString()
          );
          return (
            <Avatar
              variant="rounded"
              alt="Remy Sharp"
              src={row.original.mediaFiles[0]?.url}
              sx={{ width: 56, height: 56 }}
            >
              {promoCategory ? <promoCategory.icon /> : null}
            </Avatar>
          );
        },
      },
      {
        accessorKey: "isFavorite",
        header: "Favorite",
        size: 150,
        enableSorting: false,
        Cell: ({ row }) => {
          return (
            <Button
              aria-label="add to favorites"
              variant="text"
              onClick={() => toggleFavorite({ pslId: row.original.pslId })}
              disabled={status === "pending"}
            >
              {row.original.isFavorite ? <Favorite /> : <FavoriteBorder />}
            </Button>
          );
        },
      },
      {
        accessorKey: "pslId", //access nested data with dot notation
        header: "Promo ID",
        size: 150,
        Cell: ({ renderedValue, row }) => {
          return (
            <NavLink to={`/suppliers/${row.original.pslId}/edit`}>
              {row.original.pslIdWithPrefix || row.original.pslId}
            </NavLink>
          );
        },
      },
      {
        accessorKey: "promoCategoryId",
        header: "Promo Category",
        size: 150,
        Cell: ({ row }) => {
          const promoCategory = StaticResources.promoCategories.find(
            (c) => c.id?.toString() === row.original.promoCategoryId?.toString()
          );
          return !promoCategory ? (
            <></>
          ) : (
            <Chip
              size="small"
              icon={
                <promoCategory.icon style={{ width: "1em", height: "1em" }} />
              }
              label={toTitleCase(promoCategory.name)}
            />
          );
        },
      },
      {
        accessorKey: "supplierCode",
        header: "Parent Vendor",
        size: 250,
        Cell: ({ renderedValue, row }) => {
          if (row.original?.childSupplierCode && row.original?.scope === 1) {
            return (
              <span>
                {row.original?.childSupplierCode} -{" "}
                {row.original?.childSupplierName}
              </span>
            );
          }
          if (row.original?.parentCode && row.original?.scope === 0) {
            return (
              <span>
                {row.original?.parentCode} - {row.original?.parentName}
              </span>
            );
          }
          return "";
        },
      },
      {
        accessorKey: "childSupplierCode",
        header: "Local / Child Vendor",
        size: 250,
        Cell: ({ renderedValue, row }) => {
          return row.original?.childSupplierCode &&
            row.original?.scope !== 1 ? (
            <u>
              {row.original?.childSupplierCode} -{" "}
              {row.original?.childSupplierName}
            </u>
          ) : null;
        },
      },
      {
        accessorKey: "plants",
        header: "Plants",
        size: 150,
        enableSorting: false,
        Cell: ({ renderedValue, row }) => {
          return (
            <span>
              {row.original?.plants?.map((el, i) => (
                <div key={i}>
                  {el.code} - {el.name}
                </div>
              ))}
            </span>
          );
        },
      },
      {
        accessorKey: "keyWords",
        header: "Key Words",
        size: 150,
        enableSorting: false,
        Cell: ({ renderedValue, row }) => {
          return row.original?.keyWords?.length ? (
            <div style={{ maxWidth: "240px" }}>
              {row.original?.keyWords.map((el) => (
                <Chip key={el} label={el} variant="outlined" />
              ))}
            </div>
          ) : null;
        },
      },
      {
        accessorKey: "supplierPartId",
        header: "Supplier Part ID",
        size: 150,
      },
      {
        accessorKey: "shortDescription",
        header: "Short Description",
        size: 150,
        enableSorting: false,
      },
      {
        accessorKey: "itemDescription",
        header: "Item Description",
        size: 150,
        enableSorting: false,
      },
      {
        accessorKey: "unitOfMeasure",
        header: "Unit of Measure",
        size: 150,
      },
      {
        accessorKey: "unitPrice",
        header: "Unit Price",
        size: 150,
      },
      {
        accessorKey: "pricePer",
        header: "Price Per",
        size: 150,
      },
      {
        accessorKey: "currencyCode",
        header: "Currency Code",
        size: 150,
      },
      {
        accessorKey: "materialNumber",
        header: "Material",
        size: 150,
        Cell: ({ renderedValue, row }) => {
          return (
            <div>
              {row.original.materialNumber} - {row.original.materialName}
            </div>
          );
        },
      },
      {
        accessorKey: "spendCat1DisplayValue",
        header: "Category L1",
        size: 150,
      },
      {
        accessorKey: "spendCat2DisplayValue",
        header: "Category L2",
        size: 150,
      },
      {
        accessorKey: "spendCat3DisplayValue",
        header: "Category L3",
        size: 150,
      },
      {
        accessorKey: "languageId",
        header: "Language",
        size: 150,
        Cell: ({ row }) => {
          const languages = null; // TODO: get languages from backend
          return (
            <div>
              {(languages ?? []).find((el) => el.id === row.original.languageId)
                ?.name ?? row.original.languageTag}
            </div>
          );
        },
      },
      {
        accessorKey: "supplierLeadTime",
        header: "Supplier Lead Time",
        size: 250,
      },
      {
        accessorKey: "pslVendorContactName",
        header: "Supplier Contact",
        size: 250,
        enableSorting: false,
      },
      {
        accessorKey: "statusId",
        header: "Supplier Status",
        size: 250,
        Cell: ({ row }) => {
          return row.original.statusId !== SupplierStatus.BLANK ? (
            <Chip
              size="small"
              label={Object.keys(SupplierStatus)
                .find((key) => SupplierStatus[key] === row.original.statusId)
                ?.replace("_", " ")}
            />
          ) : null;
        },
      },
      {
        accessorKey: "businessUnits",
        header: "Business Unit",
        size: 250,
        enableSorting: false,
        Cell: ({ row }) => {
          return (
            <div style={{ maxWidth: "240px" }}>
              {row.original?.businessUnits?.map((el) => (
                <Chip key={el.id} label={el.name} variant="outlined" />
              ))}
            </div>
          );
        },
      },
      {
        accessorKey: "validFrom",
        header: "Valid From",
        size: 250,
        Cell: ({ row }) => {
          return <>{moment(row.original.validFrom).format("DD/MM/yyyy")}</>;
        },
      },
      {
        accessorKey: "validTo",
        header: "Valid To",
        size: 250,
        Cell: ({ row }) => {
          return <>{moment(row.original.validTo).format("DD/MM/yyyy")}</>;
        },
      },
      {
        accessorKey: "zones",
        header: "Zones",
        size: 250,
        enableSorting: false,
        Cell: ({ row }) => {
          return (
            <ItemsCounter
              data={row.original?.zones?.map((zone) => zone.name)}
              singularName="Zone"
              pluralName="Zones"
            />
          );
        },
      },
      {
        accessorKey: "markets",
        header: "Markets",
        size: 250,
        enableSorting: false,
        Cell: ({ row }) => {
          return (
            <ItemsCounter
              data={row.original?.markets?.map((market) => market.name)}
              singularName="Market"
              pluralName="Markets"
            />
          );
        },
      },
      {
        accessorKey: "companyCodes",
        header: "Company Codes",
        size: 250,
        enableSorting: false,
        Cell: ({ row }) => {
          return (
            <ItemsCounter
              data={row.original?.companyCodes?.map(
                (companyCode) => `[${companyCode.code}] ${companyCode.name}`
              )}
              singularName="Company Code"
              pluralName="Company Codes"
            />
          );
        },
      },
      {
        accessorKey: "agreements",
        header: "Agreements",
        size: 250,
        enableSorting: false,
      },
      {
        accessorKey: "strategicBuyerEmailAddress",
        header: "Buyer Name",
        size: 250,
        Cell: ({ row }) => {
          return (
            <div>
              {row.original.hideBuyer
                ? ""
                : row.original?.strategicBuyerEmailAddress}
            </div>
          );
        },
      },
      {
        accessorKey: "marketContactName",
        header: "Market Contact",
        size: 250,
      },
      {
        accessorKey: "teamName",
        header: "Team Name",
        size: 250,
      },
      {
        accessorKey: "noteRequester",
        header: "Note to Requester",
        size: 150,
        enableSorting: false,
      },
      {
        accessorKey: "noteLocalProcurement",
        header: "Note to Local Pr.",
        size: 150,
        enableSorting: false,
      },
      {
        accessorKey: "manufacturerPartNumber",
        header: "Manufacturer Part Number",
        size: 150,
      },
      {
        accessorKey: "manufacturerName",
        header: "Manufacturer Name",
        size: 150,
      },
      {
        accessorKey: "tenderNumber",
        header: "Tender Number",
        size: 150,
      },
      {
        accessorKey: "priceList",
        header: "Price List",
        size: 150,
        enableSorting: false,
        Cell: ({ row }) => {
          return row.original?.priceList?.startsWith("http") ? (
            <Link
              href={row.original?.priceList}
              target="_blank"
              rel="noreferrer noopener"
            >
              {row.original?.priceList}
            </Link>
          ) : (
            <>{row.original?.priceList}</>
          );
        },
      },
      {
        accessorKey: "statusJustification",
        header: "Status Justification",
        size: 250,
        enableSorting: false,
      },
      {
        accessorKey: "createdOn",
        header: "Created On",
        size: 250,
        Cell: ({ row }) => {
          return (
            <>
              {row.original.createdOn
                ? moment(row.original.createdOn).format("DD/MM/yyyy")
                : ""}
            </>
          );
        },
      },
      {
        accessorKey: "createdByUserEmail",
        header: "Created By",
        size: 250,
        Cell: ({ row }) => {
          return <span>{row.original?.createdByUser?.email}</span>;
        },
      },
      {
        accessorKey: "modifiedOn",
        header: "Updated On",
        size: 250,
        Cell: ({ row }) => {
          return (
            <>
              {row.original.modifiedOn
                ? moment(row.original.modifiedOn).format("DD/MM/yyyy")
                : ""}
            </>
          );
        },
      },
      {
        accessorKey: "modifiedByUserEmail",
        header: "Updated By",
        size: 250,
        Cell: ({ row }) => {
          return <span>{row.original?.modifiedByUser?.email}</span>;
        },
      },
      {
        accessorKey: "unitPrice",
        header: "Price Available",
        size: 150,
        enableSorting: false,
        Cell: ({ row }) => {
          return (
            <span>
              {row.original?.priceList || row.original?.unitPrice
                ? "Yes"
                : "No"}
            </span>
          );
        },
      },
      {
        accessorKey: "relatedToEasyBuy",
        header: "Easy Buy",
        size: 150,
        Cell: ({ row }) => {
          return <span>{row.original?.relatedToEasyBuy ? "Yes" : "No"}</span>;
        },
      },
      {
        accessorKey: "statusAttachments",
        header: "Attachments",
        size: 150,
        enableSorting: false,
        Cell: ({ row }) => {
          return <span>TODO</span>; // TODO: implement attachments
        },
      },
      {
        accessorKey: "formStatus",
        header: "Promo Status",
        size: 250,
        enableSorting: false,
        Cell: ({ row }) => {
          const status = getFormStatus(row.original, 60); // TODO: this 60 is the pslExpiryDays from the active user dto
          switch (status) {
            case "Pending Localization-VFT Maintenance":
              return (
                <Tooltip
                  placement={"right"}
                  title={
                    "There is no linkage existing between Parent & Child Vendor for the Market in Vendor Family Tree ( VFT) in Master Data"
                  }
                >
                  <div>{status}</div>
                </Tooltip>
              );
            default:
              return <span>{status}</span>;
          }
        },
      },
      {
        accessorKey: "Scope",
        header: "Scope",
        size: 250,
        enableSorting: false,
        Cell: ({ row }) => {
          const getScope = (id: number) =>
            id === 0 ? "At Market Level" : "Above Market Level";
          const findScopeLocalChild = (pslIdWithPrefix) => {
            if (pslIdWithPrefix.charAt(0) === "L") {
              return "- Local";
            } else if (pslIdWithPrefix.charAt(0) === "C") {
              return "- Child";
            } else {
              return "";
            }
          };
          return (
            <span>
              {getScope(row.original?.scope)}{" "}
              {findScopeLocalChild(row.original.pslIdWithPrefix)}
            </span>
          );
        },
      },
      {
        accessorKey: "approvers",
        header: "Approvers",
        size: 150,
        enableSorting: false,
        Cell: ({ renderedValue, row }) => {
          return (
            <ItemsCounter
              data={row.original?.approvers}
              singularName="Approver"
              pluralName="Approvers"
            />
          );
        },
      },
      {
        accessorKey: "diversity",
        header: "Supplier Diversity",
        size: 150,
        enableSorting: false,
        Cell: ({ renderedValue, row }) => {
          return row.original.diversity?.map((el, i) => (
            <Tooltip title={el.text}>
              <Chip
                key={i}
                variant="outlined"
                icon={
                  el.defined ? (
                    <Done style={{ color: "green", fontSize: ".8rem" }}></Done>
                  ) : (
                    <Close style={{ color: "red", fontSize: ".8rem" }}></Close>
                  )
                }
                label={el.code}
              ></Chip>
            </Tooltip>
          ));
        },
      },
      {
        accessorKey: "waysOfBuying",
        header: "Ways of Buying",
        size: 150,
        enableSorting: false,
        Cell: ({ row }) =>
          row.original.scope === 0
            ? row.original.waysOfBuying?.map((el) => (
                <div>{WaysOfBuyingText[el]}</div>
              ))
            : null,
      },
    ],
    []
  );
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    if (inputRef.current) {
      setFilters(filters => ({...filters, exactId: inputRef.current.value}));
    }
  };
  const table = useMaterialReactTable({
    columns,
    data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    enableTopToolbar: !props.isChild,
    enableRowActions: true,
    enableColumnOrdering: true,
    enableFilters: false,
    enableStickyHeader: true,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    mrtTheme: {
      baseBackgroundColor: "#fff",
    },
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,

    rowCount,
    state: {
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
    initialState: {
      columnPinning: {
        left: ["mrt-row-expand", "mrt-row-select"],
        right: ["mrt-row-actions"],
      },
    },
    renderTopToolbarCustomActions: () => (
      <ButtonGroup color="inherit">
        <Button onClick={() => navigate("/suppliers/new")} startIcon={<Add />}>
          New
        </Button>
        {props.onImport && (
          <Button onClick={() => props.onImport()} startIcon={<Upload />}>
            Import
          </Button>
        )}
        <Button onClick={() => exportMaterials()} startIcon={<Download />}>
          Export
        </Button>
      </ButtonGroup>
    ),
    renderToolbarInternalActions: ({ table }) => (
      <>
        <TextField
            size="small"
            placeholder="PROMO ID"
            inputRef={inputRef}
            InputProps={{
              endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                        onClick={handleClick}
                        edge="end"
                    >
                      <GpsFixedRounded />
                    </IconButton>
                  </InputAdornment>
              ),
            }}
        />
        <TextField
          size="small"
          placeholder="Start typing to search..."
          value={table.getState().globalFilter}
          onChange={(e) => table.setGlobalFilter(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Select
                  id="input-search-by"
                  sx={{ width: 150, ml: -2 }}
                  size="small"
                  variant="outlined"
                  label="Search By"
                  placeholder="Search By"
                  value={filterBy}
                  onChange={(e) => {
                    if (setFilterBy) setFilterBy(e.target.value);
                    table.setGlobalFilter("")
                  }}
                >
                  {[
                    { value: "all", label: "All" },
                    { value: "material", label: "Material" },
                    { value: "materialDescription", label: "Material Description" },
                    { value: "supplier", label: "Supplier" },
                    { value: "shortDescription", label: "Short Description" },
                    { value: "scopeOfApplication", label: "Scope of Application" },
                    { value: "keywords", label: "Keywords" },
                  ].map((option) => (
                    <MenuItem value={option.value}>{option.label}</MenuItem>
                  ))}
                </Select>
              </InputAdornment>
            ),

            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => table.setGlobalFilter("")}
                  edge="end"
                ></IconButton>
                <IconButton
                  onClick={() => table.setGlobalFilter("")}
                  edge="end"
                >
                  <Search />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {/* add your own custom print button or something */}
        {/* <IconButton onClick={() => {}}>
          <Print />
        </IconButton> */}
        {/* built-in buttons (must pass in table prop for them to work!) */}
        {/* <MRT_ToggleGlobalFilterButton table={table} /> */}
        {/* <MRT_ToggleFiltersButton table={table} /> */}
        {onFilterClick && (
          <Tooltip title="Filters">
            <IconButton onClick={() => onFilterClick()}>
              <FilterList />
            </IconButton>
          </Tooltip>
        )}
        <MRT_ShowHideColumnsButton table={table} />
        <MRT_ToggleDensePaddingButton table={table} />
        {/* <Tooltip title="Grid View">
          <IconButton onClick={() => setView("grid")}>
            <GridView />
          </IconButton>
        </Tooltip> */}
        <MRT_ToggleFullScreenButton table={table} />
      </>
    ),
    renderRowActionMenuItems: ({ closeMenu, row }) => [
      ...(props.onManageMedia
        ? [
            <MenuItem
              key={0}
              onClick={() => {
                props.onManageMedia(row.original);
                closeMenu();
              }}
              sx={{ m: 0 }}
            >
              <ListItemIcon>
                <Collections />
              </ListItemIcon>
              Manage Media
            </MenuItem>,
          ]
        : []),
      ...(!(
        row.original.materialOrVendorDeleted ||
        (row.original.validTo &&
          moment(row.original.validTo).isBefore(moment()))
      )
        ? [
            <MenuItem
              key={0}
              onClick={() => {
                navigate(`/suppliers/${row.original.pslId}/edit`);
              }}
              sx={{ m: 0 }}
            >
              <ListItemIcon>
                <Edit />
              </ListItemIcon>
              Edit Form
            </MenuItem>,
          ]
        : []),
      <MenuItem
        key={1}
        onClick={() => {
          setHistoryItem({ materialId: row.original.materialId });
        }}
        sx={{ m: 0 }}
      >
        <ListItemIcon>
          <HistoryOutlined />
        </ListItemIcon>
        History
      </MenuItem>,
    ],
    muiTablePaperProps: ({ table }) => ({
      //not sx
      style: {
        zIndex: table.getState().isFullScreen ? 1300 : undefined,
      },
    }),
  });

  return (
    <>
      <Drawer
        open={!!historyItem}
        anchor="right"
        PaperProps={{ sx: { width: 420 } }}
        onClose={() => setHistoryItem(null)}
      >
        <PromoHistory {...(historyItem || {})} />
      </Drawer>
      <MaterialReactTable table={table} />
    </>
  );
};

export default MaterialsListContainer;
