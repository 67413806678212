import { useQuery, QueryOptions } from "@tanstack/react-query";
import { IHistory, historyDataToHistory } from "../domains/pslHistory.domain";
import PromoItemsService from "./PromoItems.service";

const getPslHistory = (pslId: string | number) => async () =>
  PromoItemsService.fetchPSLHistory({ pslId }).then((res) =>
    historyDataToHistory(res)
  );
const getMaterialHistory = (materialId: string | number) => async () =>
  PromoItemsService.fetchMaterialHistory({ materialId }).then((res) =>
    historyDataToHistory(res)
  );

export function usePslHistory(
  pslId?: string | number,
  materialId?: string | number,
  otherOptions?: any
) {
  return useQuery<IHistory[]>({
    queryKey: ["pslHistory" + pslId + materialId],
    queryFn: materialId ? getMaterialHistory(materialId) : getPslHistory(pslId),
    initialData: [],
    ...(otherOptions || {}),
  });
}
