/*
Tutorial: Sign in users and call the Microsoft Graph API from a React single-page app (SPA) using auth code flow
https://docs.microsoft.com/en-us/azure/active-directory/develop/tutorial-v2-react
*/

/*global window, console, REACT_APP_CLIENT_ID, REACT_APP_TENANT_ID, CHATBOT_SECRET, ENV, NodeJS, BACKEND_URI, JSX, SIGNALR_HUBS_BASE_URI process */

import { Configuration, LogLevel } from "@azure/msal-browser";
import { environment } from "../env";

function getAppClientId() {
  // @ts-ignore
  // eslint-disable-next-line no-undef
  // return 'bd6bc09e-c56a-4f54-8901-f643e17d5436';
  // return "4e02ff4c-ff04-4d9f-b504-d4c3a783e55b";
  // return "8f7e6c7b-3a3d-4f9c-8a46-827735b9caea"; //promo dev env
  return typeof environment.REACT_APP_CLIENT_ID === "undefined"
    ? ""
    : environment.REACT_APP_CLIENT_ID;
}

function getTenantId() {
  // @ts-ignore
  // eslint-disable-next-line no-undef
  // return "59d6de52-9304-4f7f-8381-10448bdc6e61"; // nestle w2k test environment
  // return "12a3af23-a769-4654-847f-958f3d479f4a"; //  nestle prod environment
  return typeof environment.REACT_APP_TENANT_ID === "undefined"
    ? ""
    : environment.REACT_APP_TENANT_ID;
}

export const dev_backendId = getAppClientId();
export const tenantId = getTenantId();

// "user.read","openid","profile",
export const scopes = [`${dev_backendId}/.default`];
// export const scopes = [`${dev_backendId}/user_impersonation`];

export const msalConfig: Configuration = {
  auth: {
    authority: `https://login.microsoftonline.com/${tenantId}`,
    clientId: `${dev_backendId}`,
    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: "localStorage",
  },
  system: {
    loggerOptions: {
      logLevel: LogLevel.Warning,
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: scopes,
};

export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v2.0/me",
};
