import "./index.css";
import reportWebVitals from "./reportWebVitals";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { ReactComponent as PromoLogo } from "./assets/images/promo-logo.svg";

// MSAL imports
import {
  PublicClientApplication,
  EventType,
  EventMessage,
  AuthenticationResult,
  InteractionType,
} from "@azure/msal-browser";
import { loginRequest, msalConfig } from "./auth/authConfig";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { ThemeProvider } from "@mui/material";
import { muiTheme, nspMuiTheme, nspTheme } from "app.theme";
import { ActiveUserProvider } from "modules/User/activeUser";
import SplashScreen from "modules/UI/components/SplashScreen.component";
import { FeatureManagementProvider } from "modules/FeatureManagement/containers/FeatureManagementProvider.container";

export const msalInstance = new PublicClientApplication(msalConfig);

declare global {
  interface Window {
    // Global variables are to be defined here and in both .eslintrc.json and tsconfig.json
    REACT_APP_CLIENT_ID: string;
    REACT_APP_TENANT_ID: string;
    BACKEND_URI: string;
    PF_CLIENT_ID: string;
    APPINSIGHTS_INSTRUMENTATION_KEY: string;
    ENV: string;
    SIGNALR_HUBS_BASE_URI: string;
    BACKEND_GPS_URI: string;
    BACKEND_GPSFORM_URI: string;
    BACKEND_ADMB_URI: string;
    CHATBOT_SECRET: string;
  }
  namespace NodeJS {
    interface ProcessEnv {
      //Environmental variables are typed here:
    }
  }
}

msalInstance.initialize().then(() => {
  const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
  );

  root.render(
    <React.StrictMode>
      <MsalProvider instance={msalInstance}>
        <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          authenticationRequest={loginRequest}
          errorComponent={() => (
            <SplashScreen
              status="error"
              message="Something went wrong during authentication."
            />
          )}
          loadingComponent={() => (
            <SplashScreen status="loading" message="Authenticating..." />
          )}
        >
          <FeatureManagementProvider>
            <ActiveUserProvider>
              <ThemeProvider theme={muiTheme}>
                <App />
              </ThemeProvider>
            </ActiveUserProvider>
          </FeatureManagementProvider>
        </MsalAuthenticationTemplate>
      </MsalProvider>
    </React.StrictMode>
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
