import React from "react";
import { Card, Tabs, Tab } from "@mui/material";
import SuppliersListContainer from "modules/PromoItems/containers/suppliers.container";
import { MyTasksAtMarketTabs } from "modules/PromoItems/domains/psl.domain";
import { useActiveUser } from "modules/User/activeUser";

// const isRequestor = user.isRequestor();
//     const isViewOnly = user.isViewOnly();
//     const isFactoryAnalyst = user.isFactoryAnalyst();

//     let isShowTaskTab = !(isRequestor || isFactoryAnalyst || isViewOnly)
//     let isShowLocalization = (user.hasAtMarketRole() && isShowTaskTab)

export const TasksContainer = () => {
  const activeUser = useActiveUser();
  const [activeTab, setActiveTab] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newTab: number) => {
    setActiveTab(newTab);
  };
  return (
    <>
      <Card elevation={0} sx={{ p: 3, pb: 0, m: -3, mb: 3 }}>
        <Tabs value={activeTab} onChange={handleChange}>
          {/* <Tab
            label="Dashboard"
            sx={{
              fontSize: 22,
              textTransform: "none",
            }}
          /> */}
          <Tab
            label="Compliance"
            value={0}
            sx={{
              fontSize: 22,
              textTransform: "none",
            }}
          />
          {activeUser.hasAtMarketRole() &&
            !(
              activeUser.isRequestor() ||
              activeUser.isFactoryAnalyst() ||
              activeUser.isViewOnly()
            ) && (
              <Tab
                value={1}
                label="Localization"
                sx={{
                  fontSize: 22,
                  textTransform: "none",
                }}
              />
            )}
          {activeUser.isApprover && (
            <Tab
              value={2}
              label="Approval"
              sx={{
                fontSize: 22,
                textTransform: "none",
              }}
            />
          )}
        </Tabs>
      </Card>
      {[0, 1, 2].indexOf(activeTab) > -1 && (
        <SuppliersListContainer
          key={`taskKind${activeTab}`}
          staticFilters={{
            taskKind: {
              0: MyTasksAtMarketTabs.COMPLIANCE,
              1: MyTasksAtMarketTabs.LOCALIZATION,
              2: MyTasksAtMarketTabs.APPROVAL,
            }[activeTab],
            pslStatuses: [{ id: 0 }],
          }}
          onImport={function (): void {
            console.info("Import not supported on MyTasks");
          }}
          onManageMedia={function (promoItem: any): void {
            console.info("Managing media not supported on MyTasks");
          }}
        />
      )}
    </>
  );
};

export default TasksContainer;
