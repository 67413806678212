import { ReactComponent as foodContact } from "assets/icons/foodContact.svg";
import { ReactComponent as bags } from "assets/icons/bags.svg";
import { ReactComponent as ceramicBowl } from "assets/icons/ceramicBowl.svg";
import { ReactComponent as ceramicMug } from "assets/icons/ceramicMug.svg";
import { ReactComponent as decoration } from "assets/icons/decoration.svg";
import { ReactComponent as deskAccessories } from "assets/icons/deskAccessories.svg";
import { ReactComponent as electronics } from "assets/icons/electronics.svg";
import { ReactComponent as games } from "assets/icons/games.svg";
import { ReactComponent as giftBox } from "assets/icons/giftBox.svg";
import { ReactComponent as glassCup } from "assets/icons/glassCup.svg";
import { ReactComponent as nonPlasticToys } from "assets/icons/nonPlasticToys.svg";
import { ReactComponent as petAccessories } from "assets/icons/petAccessories.svg";
import { ReactComponent as petFoodBowl } from "assets/icons/petFoodBowl.svg";
import { ReactComponent as petFoodContainer } from "assets/icons/petFoodContainer.svg";
import { ReactComponent as petToys } from "assets/icons/petToys.svg";
import { ReactComponent as plasticBowl } from "assets/icons/plasticBowl.svg";
import { ReactComponent as plasticCups } from "assets/icons/plasticCups.svg";
import { ReactComponent as plasticToys } from "assets/icons/plasticToys.svg";
import { ReactComponent as plush } from "assets/icons/plush.svg";
import { ReactComponent as publishing } from "assets/icons/publishing.svg";
import { ReactComponent as textileKitchen } from "assets/icons/textileKitchen.svg";
import { ReactComponent as textileOthers } from "assets/icons/textileOthers.svg";
import { ReactComponent as textileWearing } from "assets/icons/textileWearing.svg";
import { ReactComponent as ticketsVouchers } from "assets/icons/ticketsVouchers.svg";
import { ReactComponent as travelMug } from "assets/icons/travelMug.svg";
import { ReactComponent as umbrellas } from "assets/icons/umbrellas.svg";

export const StaticResources = {
  promoCategories: [
    {
      type: "A",
      id: "1",
      name: "CERAMIC FOOD CONTACT",
      icon: foodContact,
      material: "Ceramic",
      color: "#DDC0DC",
    },
    {
      type: "A",
      id: "2",
      name: "CERAMIC MUG",
      icon: ceramicMug,
      material: "Ceramic",
      color: "#DDC0DC",
    },
    {
      type: "A",
      id: "3",
      name: "CERAMIC BOWL",
      icon: ceramicBowl,
      material: "Ceramic",
      color: "#DDC0DC",
    },
    {
      type: "A",
      id: "4",
      name: "GLASS CUP",
      icon: glassCup,
      material: "Glass",
      color: "#9ED9F3",
    },
    {
      type: "A",
      id: "5",
      name: "GLASS FOOD CONTACT",
      icon: foodContact,
      material: "Glass",
      color: "#9ED9F3",
    },
    {
      type: "A",
      id: "6",
      name: "METAL FOOD CONTACT",
      icon: foodContact,
      material: "Metal",
      color: "#E9B6B3",
    },
    {
      type: "A",
      id: "7",
      name: "TRAVEL MUG",
      icon: travelMug,
    },
    {
      type: "A",
      id: "8",
      name: "PLASTIC FOOD CONTACT",
      icon: foodContact,
      material: "Plastic",
      color: "#D2E5B3",
    },
    {
      type: "A",
      id: "9",
      name: "PLASTIC CUPS",
      icon: plasticCups,
      material: "Plastic",
      color: "#D2E5B3",
    },
    {
      type: "A",
      id: "10",
      name: "PLASTIC BOWLS",
      icon: plasticBowl,
      material: "Plastic",
      color: "#D2E5B3",
    },
    {
      type: "A",
      id: "11",
      name: "TIN BOX FOOD CONTACT",
      icon: foodContact,
      material: "Tin Box",
      color: "#F3E79E",
    },
    {
      type: "A",
      id: "12",
      name: "ALUMINUM FOOD CONTACT",
      icon: foodContact,
      material: "Aluminum",
      color: "#8c8c8c",
    },
    {
      type: "A",
      id: "13",
      name: "TABLEWARE / OTHER FOOD CONTACT",
      icon: foodContact,
    },
    {
      type: "A",
      id: "14",
      name: "PET FOOD CONTAINER",
      icon: petFoodContainer,
    },
    {
      type: "A",
      id: "15",
      name: "PET FOOD BOWL",
      icon: petFoodBowl,
    },
    {
      type: "B",
      id: "16",
      name: "PLUSH",
      icon: plush,
    },
    {
      type: "B",
      id: "17",
      name: "ELETRONICS",
      icon: electronics,
    },
    {
      type: "B",
      id: "18",
      name: "TEXTILE KITCHEN",
      icon: textileKitchen,
    },
    {
      type: "B",
      id: "19",
      name: "TEXTILE WEARING",
      icon: textileWearing,
    },
    {
      type: "B",
      id: "20",
      name: "TEXTILE OTHERS",
      icon: textileOthers,
    },
    {
      type: "B",
      id: "21",
      name: "PLASTIC TOYS",
      icon: plasticToys,
    },
    {
      type: "B",
      id: "22",
      name: "NON-PLASTIC TOYS",
      icon: nonPlasticToys,
    },
    {
      type: "B",
      id: "23",
      name: "PET TOYS",
      icon: petToys,
    },
    {
      type: "B",
      id: "24",
      name: "PET ACCESSORIES",
      icon: petAccessories,
    },
    {
      type: "C",
      id: "25",
      name: "BAGS",
      icon: bags,
    },
    {
      type: "C",
      id: "26",
      name: "UMBRELLAS",
      icon: umbrellas,
    },
    {
      type: "C",
      id: "27",
      name: "DECORATION",
      icon: decoration,
    },
    {
      type: "C",
      id: "28",
      name: "DESK ACCESSORIES",
      icon: deskAccessories,
    },
    {
      type: "C",
      id: "29",
      name: "GAMES",
      icon: games,
    },
    {
      type: "C",
      id: "30",
      name: "GIFT BOX",
      icon: giftBox,
    },
    {
      type: "C",
      id: "31",
      name: "PUBLISHING",
      icon: publishing,
    },
    {
      type: "C",
      id: "32",
      name: "TICKETS/ VOUCHERS",
      icon: ticketsVouchers,
    },
  ],
};

export default StaticResources;
