import { AttachFile } from "modules/UI/components/AttachFile.component";
import { Attachment } from "modules/UI/components/Attachment.component";
import AddIcon from "@mui/icons-material/Add";
import { List } from "@mui/material";
import moment from "moment";
import { useState } from "react";
import React from "react";

type Attachment = {
  creationDate: string;
  fileName: string;
  id: string;
  uploadedByUserEmail: string;
};

export const Attachments = ({
  attachments,
  enableDelete,
  enableUpload,
  isDeleting,
  isLoading,
  isUploading,
  onAttach,
  onClick,
  onDelete,
}: {
  attachments: Attachment[];
  enableDelete?: boolean;
  enableUpload?: boolean;
  isDeleting?: boolean;
  isLoading?: boolean;
  isUploading?: boolean;
  onAttach?: (files: any) => void;
  onClick?: (files: any) => void;
  onDelete?: (attachment: Attachment) => void;
}) => {
  const [deleteAttachment, setDeleteAttachment] = useState(null);

  return isLoading ? (
    <>loading attachments</>
  ) : (
    <List>
      {/* <NSPConfirmation
        description="This action will delete this file forever."
        confirmLabel="Yes, delete it."
        cancelLabel="No, keep it."
        isLoadingConfirm={isDeleting}
        visible={!!deleteAttachment}
        onCancel={() => {
          setDeleteAttachment(null);
        }}
        onConfirm={() => {
          onDelete(deleteAttachment);
          setDeleteAttachment(null);
        }}
      /> */}
      {attachments?.map((attachment) => (
        <Attachment
          key={attachment.id}
          fileName={attachment.fileName}
          secondary={`uploaded ${moment
            .utc(attachment.creationDate)
            .fromNow()} by ${attachment.uploadedByUserEmail}`}
          onDelete={
            !enableDelete || isDeleting
              ? null
              : () => setDeleteAttachment(attachment)
          }
          onClick={() => onClick(attachment)}
        />
      ))}
      {enableUpload && (
        <AttachFile
          isLoading={isUploading}
          icon={<AddIcon />}
          onAttach={onAttach}
        />
      )}
    </List>
  );
};
