// import "antd/dist/antd.css";
import { TreeSelect } from "antd";
import * as React from "react";
import { useEffect } from "react";
import Select from "react-select";

export const waysTreeData = [
  { title: "Standard Purchase Order", value: 1 },
  { title: "Framework Order", value: 2 },
  { title: "Capex Purchase Order", value: 3 },
];
export const WOBLabel = (value) =>
  waysTreeData.find((x) => x.value === value).title;

export const WaysOfBuyingComponent = ({
  onChange,
  initialValue,
  disabled = false,
  clear = false,
}) => {
  const [selectedpslScope, setSelectedpslScope] = React.useState(
    initialValue ? initialValue : []
  );

  useEffect(() => {
    if (clear) {
      setSelectedpslScope([]);
    }
  }, [clear]);

  const onChangeValues = (value: any[]) => {
    setSelectedpslScope(value);
    onChange(value);
  };

  //   const userScopeProps = {
  //     treeData: waysTreeData,
  //     value: selectedpslScope,
  //     onChange: onChangeValues,
  //     disabled: disabled,
  //     treeCheckable: true,
  //     treeCheckStrictly: false,
  //     dropdownMatchSelectWidth: false,
  //     treeNodeFilterProp: "title",
  //     showCheckedStrategy: TreeSelect.SHOW_PARENT,
  //     placeholder: "Click here to select Ways of Buying",
  //     style: { width: "100%", minHeight: "38px" },
  //   };

  return (
    <Select
    placeholder="Ways of Buying"
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          width: "100%",
          //   position: "relative",
          //   zIndex: 99999,
        }),
      }}
      isDisabled={disabled}
      isMulti
      onChange={(newValue: any) => {
        onChangeValues(newValue?.map((v) => v.value) || []);
      }}
      options={waysTreeData.map((o) => ({ value: o.value, label: o.title }))}
      value={waysTreeData
        .map((o) => ({ value: o.value, label: o.title }))
        .filter((o) => selectedpslScope.includes(o.value))}
    />
  );
  //   return <TreeSelect {...userScopeProps} dropdownStyle={{ zIndex: 99999 }} />;
};
