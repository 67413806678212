import { Alert, AlertTitle, List, ListItemButton } from "@mui/material";
import { FieldArray, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import MediaManager from "../components/MediaManager.component";
import PromoItemRow from "../components/PromoItemRow.component";
import { useMutation } from "@tanstack/react-query";
import PromoItemsService from "../services/PromoItems.service";
// import { useParams } from "react-router-dom";

export const CreateStepTwoContainer = (props: {
  defaultSpendCategories: { l1: any; l2: any; l3: any };
}) => {
  const { defaultSpendCategories } = props;
  const helpers = useFormikContext<any>();
  const [selectedPromoItem, setSelectedPromoItem] = useState(null);
  const { mutateAsync: uploadPromoMedia } = useMutation<any, any, any>({
    mutationKey: ["uploadPromoMedia"],
    mutationFn: async (data) => {
      const response = await PromoItemsService.uploadPromoMedia(data);
      return response;
    },
  });

  const { mutateAsync: uploadPromoMediaFromLink } = useMutation<any, any, any>({
    mutationKey: ["uploadPromoMediaFromLink"],
    mutationFn: async ({ link }) => {
      const response = await PromoItemsService.uploadPromoMediaFromLink({
        link,
      });
      return response;
    },
  });

  const { mutateAsync: deletePromoMedia } = useMutation<any, any, any>({
    mutationKey: ["deletePromoMedia"],
    mutationFn: async (fileId) => {
      await PromoItemsService.deletePromoMedia({ fileId });
      return fileId;
    },
  });


  return (
    <>
      <List>
        <FieldArray name="materials">
          {(arrayHelpers) => {
            return (
              <>
                <MediaManager
                  open={selectedPromoItem !== null}
                  onClose={() => setSelectedPromoItem(null)}
                  images={
                    selectedPromoItem !== null
                      ? helpers.values.materials[selectedPromoItem].mediaFiles
                      : []
                  }
                  onUploadFromUrl={(link) => {
                    uploadPromoMediaFromLink({
                      link,
                      materialId:
                        helpers.values.materials[selectedPromoItem]?.materialId,
                    })
                      .then((response) => {
                        arrayHelpers.replace(selectedPromoItem, {
                          ...arrayHelpers.form.values.materials[
                            selectedPromoItem
                          ],
                          mediaFiles:
                            arrayHelpers.form.values.materials[
                              selectedPromoItem
                            ].mediaFiles.concat(response),
                        });
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                  onUpload={(files) => {
                    uploadPromoMedia({
                      file: files[0],
                      materialId:
                        helpers.values.materials[selectedPromoItem]?.materialId,
                    })
                      .then((response) => {
                        arrayHelpers.replace(selectedPromoItem, {
                          ...arrayHelpers.form.values.materials[
                            selectedPromoItem
                          ],
                          mediaFiles:
                            arrayHelpers.form.values.materials[
                              selectedPromoItem
                            ].mediaFiles.concat(response),
                        });
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                  onDelete={(mediaId) => {
                    deletePromoMedia(mediaId).then((deletedFileId) => {
                      arrayHelpers.replace(selectedPromoItem, {
                        ...arrayHelpers.form.values.materials[
                          selectedPromoItem
                        ],
                        mediaFiles: arrayHelpers.form.values.materials[
                          selectedPromoItem
                        ].mediaFiles.filter((f) => f.fileId !== deletedFileId),
                      });
                    });
                  }}
                />
                {helpers.values.materials
                  .filter((m) => {
                    return !m.deleted;
                  })
                  .map((promoItem, index) => (
                    <PromoItemRow
                      defaultSpendCategories={defaultSpendCategories}
                      promoItem={promoItem}
                      promoItemIndex={index}
                      // errors={helpers.errors["materials"]?.[index] || {}}
                      onDuplicate={(promoItem) => {
                        arrayHelpers.push({
                          ...promoItem,
                          materialId: null,
                        });
                      }}
                      onRemove={(promoItem) => {
                        arrayHelpers.replace(index, {
                          ...promoItem,
                          deleted: true,
                        });
                      }}
                      onManageMedia={() => {
                        setSelectedPromoItem(index);
                      }}
                    />
                  ))}
              </>
            );
          }}
        </FieldArray>
        {typeof helpers.getFieldMeta("materials").error === "string" && (
          <Alert severity="error">
            <AlertTitle>
              {helpers.getFieldMeta("materials").error?.toString()}
            </AlertTitle>
          </Alert>
        )}
        <ListItemButton
          onClick={() => {
            helpers.setFieldValue("materials", [
              ...helpers.values.materials,
              {
                spendCat1Id: defaultSpendCategories.l1.id,
                spendCat1DisplayValue: defaultSpendCategories.l1.name,
                spendCat1Code: defaultSpendCategories.l1.code,
                spendCat2Id: defaultSpendCategories.l2.id,
                spendCat2DisplayValue: defaultSpendCategories.l2.name,
                spendCat2Code: defaultSpendCategories.l2.code,
                spendCat3Id: defaultSpendCategories.l3.id,
                spendCat3DisplayValue: defaultSpendCategories.l3.name,
                spendCat3Code: defaultSpendCategories.l3.code,
                materialNumber: defaultSpendCategories.l3.materialCode,
                materialName: defaultSpendCategories.l3.materialDescription,
                materialId: null,
                parentMaterialId: null,
                languageId: null,
                languageTag: null,
                keyWords: [],
                unitOfMeasure: "EA",
                itemDescription: defaultSpendCategories.l3.materialDescription,
                shortDescription:
                  defaultSpendCategories.l3.materialLongDescription,
                unitPrice: null,
                pricePer: null,
                supplierLeadTime: null,
                currencyCode: "",
                matGrp: "",
                supplierPartId: "",
                manufacturerPartNumber: "",
                manufacturerName: "",
                deleted: false,
                waysOfBuying: [1, 2, 3],
                marketContactName: null,
                mediaFiles: [],
                plants: [],
              },
            ]);
          }}
          sx={{
            justifyContent: "center !important",
            textTransform: "uppercase",
          }}
        >
          Add new material
        </ListItemButton>
      </List>
    </>
  );
};
export default CreateStepTwoContainer;
