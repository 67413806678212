import React, { useState, useEffect, CSSProperties } from "react";
import { Field, Formik, FastField } from "formik";
import { prop, remove } from "ramda";
// import { Provider, withContext } from "../../services/context.service";
import { debounce, get } from "lodash";
import { FixedSizeList as List } from "react-window";
// import ChipInput from "material-ui-chip-input";
import { DateTime } from "luxon";
import Select, { MenuPosition, OptionsOrGroups } from "react-select";
import AsyncSelect from "react-select/async";
import { muiTheme } from "app.theme";
import { Input, TextField, ThemeProvider } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

export const MAX_DATE = DateTime.local(9999, 12, 31);
export const MIN_DATE = DateTime.local(1900, 1, 1);

// export function ContextForm(props) {
//   const { children, ...other } = props;

//   return (
//     <Formik {...other}>
//       {(form) => {
//         return <Provider value={{ form }}>{children}</Provider>;
//       }}
//     </Formik>
//   );
// }

export const AsyncSelectField = ({
  name,
  labelName,
  customDisplay,
  options = [],
  limited = 0,
  customError = false,
  menuPosition = "absolute",
  components = null,
  clearable = false,
  disabled = false,
  isMulti = false,
  returnFullOnChange = false,
  returnFullSelected = false,
  selected = null,
  onLoad,
  onChange = null,
  placeholder = "Select",
  menuPortalTarget = null,
  form: { values, setFieldValue, errors },
}) => {
  const [value, setValue] = React.useState<any>(get(values, name));
  const getValue = () => {
    if (returnFullSelected) {
      return selected;
    }
    const value = get(values, name);
    const label = get(values, labelName);
    if (value) {
      return { label: label, value: value };
    }
    return null;
  };

  const getLabel = (option) => {
    if (customDisplay) {
      return customDisplay(option);
    }
    return option.label;
  };

  return (
    <AsyncSelect
      value={getValue()}
      isMulti={isMulti}
      defaultOptions={options}
      noOptionsMessage={({ inputValue: value }) => {
        if (value.length < limited)
          return `Type to start searching (${limited} chars min.)`;
        return value ? `No results for "${value}"` : "No results";
      }}
      menuPortalTarget={menuPortalTarget ? menuPortalTarget : null}
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
      menuPosition={menuPosition as MenuPosition}
      loadOptions={(value): Promise<OptionsOrGroups<any, any>> => {
        if (value.length >= limited) {
          return onLoad(value);
        }
        return Promise.resolve([]);
      }}
      isClearable={clearable}
      isDisabled={disabled}
      placeholder={placeholder}
      onChange={(option: any) => {
        const optValue = prop("value", option);
        const optLabel = prop("label", option);
        setFieldValue(name, optValue);
        setFieldValue(labelName, optLabel);

        if (onChange) {
          if (returnFullOnChange) {
            return onChange(option, setFieldValue);
          } else {
            return onChange(optValue);
          }
        }
      }}
      getOptionLabel={getLabel}
      onInputChange={setValue}
      components={components}
    />
  );
};
export const float13 = (float: string) =>
  /^[0-9]{1,13}(\.[0-9]{1,2})?$/.test(float);
export const float9 = (float: string) =>
  /^[0-9]{1,9}(\.[0-9]{1,2})?$/.test(float);
export const CustomFloatInput = ({
  type,
  onChange,
  initialValue,
  disabled = false,
  error = false,
}) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (e) => {
    if (e.target instanceof HTMLInputElement) {
      const value = e.target.value;
      if (value === "" || type(value)) {
        setValue(value);
      }
    }
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleBlur = () => {
    onChange(value);
  };

  return (
    <Input
      error={error}
      type={"number"}
      inputProps={{ min: "0" }}
      value={value}
      disabled={disabled}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};
export const CustomFastInput = ({
  type,
  onChange,
  initialValue,
  disabled = false,
  error = false,
  style = null,
  normalInput = false,
  placeholder = "",
}) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (e) => {
    if (e.target instanceof HTMLInputElement) {
      const value = e.target.value;
      setValue(value);
    }
  };
  const handleNumericChange = (e) => {
    const value = e.target.value;
    if (e.target instanceof HTMLInputElement && /^\d+$/.test(value)) {
      setValue(value);
    }
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleBlur = () => {
    onChange(value);
  };

  if (normalInput) {
    return (
      <input
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={placeholder}
        style={style}
      />
    );
  }

  if (type === "positive") {
    return (
      <Input
        error={error}
        type={"number"}
        inputProps={{ min: "0" }}
        value={value}
        disabled={disabled}
        onChange={handleNumericChange}
        onBlur={handleBlur}
      />
    );
  }

  return (
    <Input
      style={style}
      error={error}
      type={type}
      value={value}
      disabled={disabled}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};

export const CustomFastInputForMRPSignAgreeNo = ({
  type,
  onChange,
  initialValue,
  disabled = false,
  error = false,
  style = null,
  signedAgreementNumberError,
}) => {
  const [value, setValue] = useState(initialValue);
  const [inputError, setInputError] = useState("");

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleChange = (e) => {
    if (e.target instanceof HTMLInputElement) {
      const value = e.target.value;
      let regEx = /^$|^[0-9a-zA-Z]+$/;
      if (value.length > 12 || !value.match(regEx)) {
        setInputError("Field will allow 12 Alpha numeric characters only");
        setValue(value);
      } else {
        setInputError("");
        setValue(value);
      }
      // const value = (e.target.value) ? e.target.value.replace(/[^0-9a-zA-Z]+/ig, "") : '';

      // value.length <= 12 && setValue(value);
    }
  };

  const handleBlur = () => {
    onChange(value);
  };

  return (
    <div>
      <Input
        style={style}
        error={error || !!signedAgreementNumberError.error}
        type={type}
        value={value}
        disabled={disabled}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      {!!inputError && (
        <span
          style={{
            display: "block",
            color: "red",
            fontSize: 12,
            marginBottom: 5,
          }}
        >
          {inputError}
        </span>
      )}
    </div>
  );
  //   <Tooltip title = {`"Signed Agreement Number" field will allow 12 Alpha numeric characters only`}
  // placement = "bottom-end"
  // open = {(value.length >12 || !value.match(/^$|^[0-9a-zA-Z]+$/))? true: false}
  // arrow = {true}
  // >
  // </Tooltip>
};

export const CustomFastInputForPriceRange = ({
  type,
  onChange,
  initialValue,
  disabled = false,
  error = false,
  style = null,
}) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    if (initialValue === null) setValue("");
    else setValue(initialValue);
  }, [initialValue]);

  const handleChange = (e) => {
    if (e.target instanceof HTMLInputElement) {
      const value = e.target.value;
      setValue(value);
    }
  };

  const handleBlur = () => {
    onChange(value);
  };

  if (type === "positive") {
    return (
      <Input
        error={error}
        type={"number"}
        inputProps={{ min: "0" }}
        value={value}
        disabled={disabled}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    );
  }

  return (
    <Input
      style={style}
      error={error}
      type={type}
      value={value}
      disabled={disabled}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};

const InputField = ({
  name,
  onChange,
  disabled = false,
  placeholder = "Enter",
  type = "text",
  form: { values, setFieldValue, errors },
  ...props
}) => {
  let onDebounceChange = null;
  if (onChange) {
    onDebounceChange = debounce(onChange, 800);
  }
  const handleChange = (e) => {
    if (e.target instanceof HTMLInputElement) {
      const value = e.target.value;
      setFieldValue(name, value);
      if (onDebounceChange) {
        onDebounceChange(value);
      }
    }
  };

  return (
    <FastField
      {...props}
      name={name}
      margin="none"
      placeholder={placeholder}
      value={get(values, name)}
    >
      {({ field }) => (
        <Input
          {...field}
          type={type}
          disabled={disabled}
          onChange={handleChange}
          placeholder={placeholder}
          error={!!get(errors, name) ? true : false}
        />
      )}
    </FastField>
  );
};

const PositiveNumericInputField = ({
  name,
  onChange,
  disabled = false,
  placeholder = "Enter",
  form: { values, setFieldValue, errors },
}) => {
  const [value, setValue] = React.useState("");
  let onDebounceChange = null;
  if (onChange) {
    onDebounceChange = debounce(onChange, 800);
  }
  const handleChange = (e) => {
    if (e.target instanceof HTMLInputElement) {
      const targetValue = e.target.value;
      if (targetValue === "" || /^[0-9.]+$/.test(targetValue)) {
        setValue(targetValue);
        setFieldValue(name, targetValue);
        if (onDebounceChange) {
          onDebounceChange(targetValue);
        }
      }
    }
  };

  return (
    <FastField
      name={name}
      margin="none"
      placeholder={placeholder}
      value={value}
    >
      {({ field }) => (
        <Input
          {...field}
          disabled={disabled}
          onChange={handleChange}
          placeholder={placeholder}
          error={!!get(errors, name) ? true : false}
        />
      )}
    </FastField>
  );
};

const TextareaField = ({
  name,
  placeholder = "Enter",
  type = "text",
  form: { values, setFieldValue, errors },
}) => {
  const onChange = (e) => {
    if (e.target instanceof HTMLTextAreaElement) {
      setFieldValue(name, e.target.value);
    }
  };
  return (
    <Field
      name={name}
      render={({ field }) => (
        <textarea
          {...field}
          type="text"
          placeholder={placeholder}
          value={get(values, name)}
          onChange={onChange}
        />
      )}
    />
  );
};

export const CustomTextareaField = ({
  placeholder,
  rows,
  rowsMax,
  initialValue,
  onChange,
}) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (e) => {
    if (e.target instanceof HTMLTextAreaElement) {
      const value = e.target.value;
      setValue(value);
      onChange(value);
    }
  };

  return (
    <TextField
      placeholder={placeholder}
      fullWidth
      multiline
      rows={rows}
      value={value}
      onChange={handleChange}
    />
  );
};

const DateField = ({
  name,
  dateFormat = "dd/MM/yyyy",
  onChange,
  disabled = false,
  minDate = MIN_DATE,
  maxDate = MAX_DATE,
  form: { values, setFieldValue, errors },
}) => {
  return (
    <ThemeProvider theme={muiTheme}>
      <DatePicker
        disabled={disabled}
        format={dateFormat}
        value={get(values, name)}
        minDate={minDate}
        maxDate={maxDate}
        onChange={(value) => {
          setFieldValue(name, value);
          if (onChange) {
            onChange(value);
          }
        }}
      ></DatePicker>
    </ThemeProvider>
  );
};

export const CustomFastDate = ({
  dateFormat = "dd/MM/yyyy",
  hasError = false,
  initialValue = null,
  onChange,
  disabled = false,
  minDate = MIN_DATE,
  maxDate = MAX_DATE,
  disableToolbar = false,
}) => {
  const [value, setValue] = useState(initialValue);
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);
  return (
    <ThemeProvider theme={muiTheme}>
      <DatePicker
        disabled={disabled}
        format={dateFormat}
        value={value}
        minDate={minDate}
        maxDate={maxDate}
        onChange={onChange}
      ></DatePicker>
    </ThemeProvider>
  );
};

const SelectField = ({
  name,
  labelName,
  options,
  customDisplay = null,
  onChange = null,
  clearable = false,
  isMulti = false,
  returnFullOnChange = false,
  returnFullSelected = false,
  selected = null,
  disabled = false,
  menuPortalTarget = null,
  form: { values, setFieldValue, errors },
}) => {
  const [value, setValue] = React.useState<any>("");
  const getValue = () => {
    if (returnFullSelected) {
      return selected;
    }
    let selectedValue = get(values, name);
    if (!selectedValue && typeof selectedValue !== "boolean") {
      selectedValue = value;
    }
    const option = options.find((option) => option.value === selectedValue);
    if (labelName) {
      const oldLabel = get(values, labelName);
      const newLabel = prop("label", option);
      if (oldLabel !== newLabel) {
        setFieldValue(labelName, newLabel);
      }
    }
    return option;
  };
  const getLabel = (option) => {
    if (customDisplay) {
      return customDisplay(option);
    }
    return option.label;
  };
  const handleChange = (selected) => {
    const option = options.find(
      (option) => option.value === prop("value", selected)
    );
    const value = prop("value", option);
    const label = prop("label", option);
    setValue(value);

    if (name) {
      setFieldValue(name, value);
    }

    if (labelName) {
      setFieldValue(labelName, label);
    }

    if (onChange) {
      if (returnFullOnChange) {
        return onChange(selected);
      } else {
        return onChange(value);
      }
    }
  };

  return (
    <Select
      isMulti={isMulti}
      isClearable={clearable}
      isDisabled={disabled}
      menuPosition="fixed"
      styles={{
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        option: (provided, state) => ({
          ...provided,
          minHeight: "30px",
        }),
      }}
      menuPortalTarget={menuPortalTarget ? menuPortalTarget : null}
      options={options}
      value={getValue()}
      onChange={handleChange}
      getOptionLabel={getLabel}
    ></Select>
  );
};

const TypeAheadSelectField = ({
  name,
  labelName,
  loading,
  options = [],
  customDisplay = null,
  onChange = null,
  clearable = false,
  disabled = false,
  returnWholeOption = false,
  form: { values, setFieldValue, errors },
}) => {
  const handleChange = (e) => {
    const value = prop("value", e);
    setFieldValue(name, value);
    if (labelName) {
      setFieldValue(labelName, prop("label", e));
    }
    if (onChange) {
      onChange(returnWholeOption ? e : value);
    }
  };
  const getValue = () => {
    const value = options.find((option) => option.value === get(values, name));
    return value ? value : null;
  };
  const getLabel = (option) => {
    if (customDisplay) {
      return customDisplay(option);
    }
    return option.label;
  };
  return (
    <Select
      isLoading={loading}
      isClearable={clearable}
      isDisabled={disabled}
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
      options={options}
      value={getValue()}
      onChange={handleChange}
      getOptionLabel={getLabel}
    />
  );
};

export const CustomFastSelect = ({
  onChange,
  initialValue,
  options = [],
  customDisplay = null,
  hasError = false,
  onOpen = null,
  searchByKey = null,
  isMulti = false,
  disabled = false,
  clearable = true,
  menuPortalTarget = null,
  placeholder = "",
  changeBorderRadius = false,
  styles = null,
  loading = false,
  ...rest
}) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (value) => {
    setValue(value);
    onChange(value);
  };

  const getLabel = (option) => {
    if (customDisplay) {
      return customDisplay(option);
    }
    return option.label;
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <Select
      isLoading={loading}
      onMenuOpen={onOpen}
      placeholder={placeholder ? placeholder : "Select..."}
      isMulti={isMulti}
      filterOption={
        searchByKey
          ? (option: any, rawInput: string) => {
              return (
                option?.data[searchByKey]
                  ?.toUpperCase()
                  .indexOf(rawInput.toUpperCase()) !== -1
              );
            }
          : undefined
      }
      menuPortalTarget={menuPortalTarget}
      isClearable={clearable}
      isDisabled={disabled}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          borderColor: hasError ? "red" : baseStyles.borderColor,
        }),
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        ...styles,
      }}
      options={options}
      value={value}
      getOptionLabel={getLabel}
      onChange={handleChange}
      components={{
        MenuList: (props) => <MenuList {...props} maxHeight={250} />,
      }}
      {...rest}
    />
  );
};

export const CustomFastAsyncSelect = ({
  initialValue,
  onChange = (_) => {},
  defaultOptions = false,
  onLoad = null,
  loadOnOpen = false,
  hasError = false,
  isMulti = false,
  customDisplay = null,
  onLoadLimit = 0,
  disabled = false,
  clearable = true,
  styles = {},
  menuPortalTarget = null,
  customTooltipMessage = "",
}) => {
  const [value, setValue] = useState(initialValue);
  const [options, setOptions] = useState([]);
  let fetchDataTimer;

  const handleChange = (value) => {
    setValue(value);
    onChange(value);
  };
  const getLabel = (option) => {
    if (customDisplay) {
      return customDisplay(option);
    }
    return option.label;
  };
  useEffect(() => {
    setValue(initialValue);
    setOptions([initialValue]);
  }, [initialValue]);

  useEffect(() => {
    // clearing the timeout when component unmounts
    return () => {
      if (fetchDataTimer) clearTimeout(fetchDataTimer);
    };
  }, []);

  function newApicallMethod(onLoad, value, callback) {
    let result;
    if (fetchDataTimer) clearTimeout(fetchDataTimer);

    return new Promise((resolve, reject) => {
      fetchDataTimer = setTimeout(() => {
        result = onLoad(value, callback);
        resolve(result);
      }, 500);
    });
  }

  return (
    <AsyncSelect
      isMulti={isMulti}
      isClearable={clearable}
      loadOptions={(value, callback): Promise<OptionsOrGroups<any, any>> => {
        if (value.length >= onLoadLimit) {
          // return onLoad(value, callback)
          return newApicallMethod(onLoad, value, callback) as Promise<
            OptionsOrGroups<any, any>
          >;
        }
        return Promise.resolve([]);
      }}
      isDisabled={disabled}
      getOptionLabel={getLabel}
      noOptionsMessage={({ inputValue: value }) => {
        if (value.length < onLoadLimit) {
          return customTooltipMessage.length
            ? customTooltipMessage
            : `Type to start searching (${onLoadLimit} chars min.)`;
        }
        return value ? `No results for "${value}"` : "No results";
      }}
      onMenuOpen={loadOnOpen ? onLoad : () => {}}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          borderColor: hasError ? "red" : baseStyles.borderColor,
        }),
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        ...styles,
      }}
      options={options}
      menuPortalTarget={menuPortalTarget}
      value={value}
      onChange={handleChange}
      defaultOptions={defaultOptions}
    />
  );
};

export const CustomFastAsyncSelectWithoutState = ({
  initialValue,
  onChange = (_) => {},
  // only difference between this and CustomFastAsyncSelect, is that once value is selected its not saved in this component state
  defaultOptions = false,
  onLoad = null,
  loadOnOpen = false,
  hasError = false,
  isMulti = false,
  customDisplay = null,
  onLoadLimit = 0,
  disabled = false,
  clearable = true,
  styles = {},
  menuPortalTarget = null,
  customTooltipMessage = "",
}) => {
  const [value, setValue] = useState(initialValue);
  const [options, setOptions] = useState([]);
  let fetchDataTimer;

  const handleChange = (value) => {
    // setValue(value);
    onChange(value);
  };
  const getLabel = (option) => {
    if (customDisplay) {
      return customDisplay(option);
    }
    return option.label;
  };
  useEffect(() => {
    // setValue(initialValue);
    setOptions([initialValue]);
  }, [initialValue]);

  useEffect(() => {
    // clearing the timeout when component unmounts
    return () => {
      if (fetchDataTimer) clearTimeout(fetchDataTimer);
    };
  }, []);

  function newApicallMethod(onLoad, value, callback) {
    let result;
    if (fetchDataTimer) clearTimeout(fetchDataTimer);

    return new Promise((resolve, reject) => {
      fetchDataTimer = setTimeout(() => {
        result = onLoad(value, callback);
        resolve(result);
      }, 500);
    });
  }

  return (
    <AsyncSelect
      isMulti={isMulti}
      isClearable={clearable}
      loadOptions={(value, callback): Promise<OptionsOrGroups<any, any>> => {
        if (value.length >= onLoadLimit) {
          // return onLoad(value, callback)
          return newApicallMethod(onLoad, value, callback) as Promise<
            OptionsOrGroups<any, any>
          >;
        }
        return Promise.resolve([]);
      }}
      isDisabled={disabled}
      getOptionLabel={getLabel}
      noOptionsMessage={({ inputValue: value }) => {
        if (value.length < onLoadLimit) {
          return customTooltipMessage.length
            ? customTooltipMessage
            : `Type to start searching (${onLoadLimit} chars min.)`;
        }
        return value ? `No results for "${value}"` : "No results";
      }}
      onMenuOpen={loadOnOpen ? onLoad : () => {}}
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }), ...styles }}
      options={options}
      menuPortalTarget={menuPortalTarget}
      value={value}
      onChange={handleChange}
      defaultOptions={defaultOptions}
    />
  );
};

const MenuList = (props) => {
  const { children, options, getValue, maxHeight, limited, selectProps } =
    props;
  if (selectProps.inputValue.length < limited) {
    return <div>Type to start searching ({limited} chars min.)</div>;
  }
  if (options.length) {
    const height = 35;
    const [value] = getValue();
    const initialOffset = options.indexOf(value) * height;
    return (
      <List
        width={"100%"}
        height={Math.min(
          maxHeight,
          Math.max(children ? children.length : 1, 1) * height
        )}
        itemCount={children.length}
        itemSize={height}
        initialScrollOffset={initialOffset}
      >
        {({ index, style }) => (
          <div key={index} style={style}>
            {children[index]}
          </div>
        )}
      </List>
    );
  }

  return <div>No results</div>;
};

const LargeAsyncSelectField = (props) => {
  const { limited } = props;

  return (
    <AsyncSelectField
      {...props}
      components={{
        MenuList: (props) => <MenuList {...props} limited={limited} />,
      }}
    />
  );
};

// export const CustomFastChipInput = ({
//   onChange,
//   initialValue,
//   disabled = false,
//   error = false,
// }) => {
//   const [value, setValue] = useState(initialValue);

//   const handleAddChip = (chip) => {
//     const newValue = value.concat(chip);
//     setValue(newValue);
//     onChange(newValue);
//   };

//   const handleDeleteChip = (chip, index) => {
//     const newValues = remove(index, 1, value);
//     setValue(newValues);
//     onChange(newValues);
//   };

//   useEffect(() => {
//     setValue(initialValue);
//   }, [initialValue]);

//   return (
//     <ChipInput
//       style={{ width: "100%" }}
//       value={value}
//       error={error}
//       disabled={disabled}
//       onAdd={(chip) => handleAddChip(chip)}
//       onDelete={(chip, index) => handleDeleteChip(chip, index)}
//     />
//   );
// };

// const ChipInputField = ({
//   name,
//   placeholder = "Enter",
//   type = "text",
//   form: { values, setFieldValue, errors },
// }) => {
//   const getValue = () => {
//     const chips = get(values, name);
//     return Array.isArray(chips) ? chips : null;
//   };
//   return (
//     <FastField
//       name={name}
//       fullWidth
//       type={type}
//       margin="none"
//       placeholder={placeholder}
//       defaultValue={getValue()}
//       onChange={(chips) => setFieldValue(name, chips)}
//       component={(props) => <ChipInput {...props} fullWidthInput={true} />}
//     />
//   );
// };

// export const TextareaFieldWrapper = withContext(TextareaField);
// export const TextFieldWrapper = withContext(InputField);
// export const TypeaheadAsyncSelectWrapper = withContext(AsyncSelectField);
// export const LargeTypeaheadAsyncSelectWrapper = withContext(
//   LargeAsyncSelectField
// );
// export const TypeaheadSelectWrapper = withContext(TypeAheadSelectField);
// export const DateFieldWrapper = withContext(DateField);
// export const SelectFieldWrapper = withContext(SelectField);
// export const PositiveNumericInputFieldWrapper = withContext(
//   PositiveNumericInputField
// );
