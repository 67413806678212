import {
  createQueryService,
  createFormDataService,
  createMutationService,
} from "utils/factories";

export default class AdminService {
  static readonly getAllPromoCategories = createQueryService<never, never>(
    "/api/promo-categories/get-all"
  );

  static readonly deletePromoCategory = createMutationService<
    never,
    { id: string }
  >(({ id }) => `/api/promo-categories/delete?id=${id}`, "DELETE");

  static readonly createPromoCategory = createMutationService<
    never,
    {
      id: number | string;
      name: string;
      promoCategoryType: number | string;
      color: string;
      icon: string;
    }
  >(`api/promo-categories/create`, "POST");

  static readonly updatePromoCategory = createMutationService<
    never,
    {
      id: number | string;
      name: string;
      promoCategoryType: number | string;
      color: string;
      icon: string;
    }
  >(`api/promo-categories/update`, "PUT");
}
