import React from "react";
import styles from "./LogoCarousel.module.scss";
import { ReactComponent as BesterCapitalMedia } from "assets/logos/bester_capital_media.svg";
import { ReactComponent as Brocaw } from "assets/logos/brokaw.svg";
import { ReactComponent as EnthuseMarketing } from "assets/logos/enthuse_marketing.svg";
import { ReactComponent as FreedmanInternational } from "assets/logos/freedman_international.svg";
import { ReactComponent as Inspira } from "assets/logos/inspira.svg";
import { ReactComponent as PublicisWorldwide } from "assets/logos/publicis_worldwide.svg";
import { useTheme } from "@mui/material";

export const LogoCarousel = () => {
  const theme = useTheme();
  return (
    <div
      className={styles.logos}
      style={{ color: theme.palette.background.default }}
    >
      <div
        className={styles["logos-slide"]}
        style={{ color: theme.palette.text.primary }}
      >
        <BesterCapitalMedia />
        <Brocaw />
        <EnthuseMarketing />
        <FreedmanInternational />
        <Inspira />
        <PublicisWorldwide />
      </div>

      <div
        className={styles["logos-slide"]}
        style={{ color: theme.palette.text.primary }}
      >
        <BesterCapitalMedia />
        <Brocaw />
        <EnthuseMarketing />
        <FreedmanInternational />
        <Inspira />
        <PublicisWorldwide />
      </div>
    </div>
  );
};

export default LogoCarousel;
