import {
  InteractionRequiredAuthError,
  PublicClientApplication,
  AuthenticationResult,
} from "@azure/msal-browser";
import { msalConfig, scopes } from "./authConfig";

const tokenRequestQueue = [];
let isFetchingToken = false;

function processTokenQueue() {
  while (tokenRequestQueue.length > 0) {
    const { callback, fullResponse } = tokenRequestQueue.shift();
    let savedToken: any = window.localStorage.getItem("accessTokenResponse");
    try {
      savedToken = JSON.parse(savedToken);
    } catch (e) {
      console.error(e);
    }
    callback(!!fullResponse ? savedToken : savedToken.accessToken);
  }
}

export const getAccessToken = async (fullResponse?): Promise<any> => {
  // return Promise.resolve(null);
  const publicClientApplication = new PublicClientApplication(msalConfig);
  await publicClientApplication.initialize();
  // we used to have this, but apparently it caused some issues on PreProd:
  // BrowserAuthError: no_account_error: No account object provided to acquireTokenSilent and no active account has been set. Please call setActiveAccount or provide an account on the request.
  // according to this: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/FAQ.md#i-dont-understand-the-redirect-flow-how-does-the-handleredirectpromise-function-work
  // we should not need to call handleRedirectPromise() anymore, as it is called automatically by instantiating the PublicClientApplication
  // const redirectResponse = await publicClientApplication.handleRedirectPromise();

  // MSAL.js v2 exposes several account APIs, logic to determine which account to use is the responsibility of the developer
  let savedAccount: any = window.localStorage.getItem("selectedAccount");
  try {
    savedAccount = JSON.parse(savedAccount);
  } catch (e) {
    console.error(e);
  }

  let savedToken: any = window.localStorage.getItem("accessTokenResponse");

  try {
    savedToken = JSON.parse(savedToken);
  } catch (e) {
    console.error(e);
  }
  if (savedAccount && savedToken) {
    if (
      new Date(savedToken.expiresOn).getTime() > new Date().getTime() &&
      savedToken.account.homeAccountId === savedAccount.homeAccountId
    ) {
      return !!fullResponse ? savedToken : savedToken.accessToken;
    }
  }

  const preferedAccountIndex = !savedAccount
    ? -1
    : publicClientApplication
        .getAllAccounts()
        .map((acc) => acc.homeAccountId)
        .indexOf(savedAccount?.homeAccountId);
  const account =
    publicClientApplication.getAllAccounts()[
      preferedAccountIndex === -1 ? 0 : preferedAccountIndex
    ];

  const accessTokenRequest = {
    scopes: scopes,
    account: account,
  };

  return new Promise(async (resolve) => {
    tokenRequestQueue.push({
      callback: resolve,
      fullResponse: fullResponse ? fullResponse : false,
    });
    if (!isFetchingToken) {
      if (!account) {
        resolve(null);
        return;
      }
      isFetchingToken = true;
      publicClientApplication
        .acquireTokenSilent(accessTokenRequest)
        .then(function (accessTokenResponse) {
          window.localStorage.setItem(
            "accessTokenResponse",
            JSON.stringify(accessTokenResponse)
          );
          isFetchingToken = false;
          let accessToken = accessTokenResponse.accessToken;
          resolve(!!fullResponse ? accessTokenResponse : accessToken);
          processTokenQueue();
        })
        .catch(function (error) {
          //Acquire token silent failure, and send an interactive request
          console.error(error);
          isFetchingToken = false;
          if (error instanceof InteractionRequiredAuthError) {
            publicClientApplication.acquireTokenRedirect(accessTokenRequest);
          }
        });
    }
  });
};

export async function withAuthHeader(headers?: {
  [key: string]: any;
}): Promise<{ Authorization: string }> {
  const token = await getAccessToken();
  return { ...headers, Authorization: `Bearer ${token}` };
}

export default getAccessToken;
