import React from "react";
import EasyRequestService from "../service/easyRequest.service";
import { ShoppingCart } from "@mui/icons-material";
import { IconButton, Badge, Tooltip } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { environment } from "env";

export const EasyRequestCart = () => {
  const { data, isLoading } = useQuery<any>({
    queryKey: ["easyRequestCounter"],
    queryFn: async () => {
      const result = await EasyRequestService.getEasyRequestCounter();
      return result;
    },
    refetchInterval: 10000,
  });
  return (
    <Tooltip title="Easy Request">
      <IconButton
        onClick={() => {
          window.open(environment.EASY_REQUEST_URL, "_blank");
        }}
      >
        {!isLoading ? (
          <Badge
            sx={{ position: "relative" }}
            badgeContent={<>{data?.count || 0}</>}
            color="secondary"
          >
            <ShoppingCart />
          </Badge>
        ) : (
          <ShoppingCart />
        )}
      </IconButton>
    </Tooltip>
  );
};

export default EasyRequestCart;
