import React, { useEffect } from "react";
import { FormikConfig, FormikValues, useFormikContext } from "formik";
import { useStepper } from "modules/Forms/services/hooks";
import { Divider, Typography } from "@mui/material";

export interface FormikStepProps
  extends Pick<FormikConfig<FormikValues>, "children" | "validationSchema"> {
  title?: string;
  hideTitle?: boolean;
  description?: string;
  shouldSkip?: (values: any) => boolean;
  backButtonComponent?: React.Component;
  nextButtonComponent?: React.Component;
  [x: string]: any;
}

export function FormikStep({
  children,
  description,
  title,
  hideTitle,
  validationSchema,
  shouldSkip,
  confirmationOnNext = () => false,
}: FormikStepProps) {
  const context = useFormikContext<any>();
  const { values, errors } = context;
  const { skipStep } = useStepper();
  useEffect(() => {
    if (!!shouldSkip && shouldSkip(values)) {
      skipStep();
    }
  }, [values, shouldSkip]);
  return (
    <>
      <div>
        {title && !hideTitle && <Typography variant={"h6"}>{title}</Typography>}
        {description && <Typography variant="body1">{description}</Typography>}
      </div>
      <Divider sx={{ my: 4 }} />
      {typeof children === "function" ? children(context) : children}
      <Divider sx={{ my: 4 }} />
    </>
  );
}

export default FormikStep;
