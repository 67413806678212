export interface IEnvironment {
  REACT_APP_TENANT_ID: string;
  REACT_APP_CLIENT_ID: string;
  REACT_APP_API_BASE: string;
  REACT_APP_MRP_IMPORT_FILE_TIMEOUT: string;
  REACT_APP_MRP_EXPORT_FILE_TIMEOUT?: string;
  CHATBOT_URL?: string;
  REACT_APP_EASY_REQUEST?: string;
  DESCRIBE_YOUR_NEED?: string;
  AMC_URL?: string;
  EASY_REQUEST_URL: string;
}

export const environment: IEnvironment = {
  "PromoCatalog-DEV": {
    REACT_APP_TENANT_ID: "12a3af23-a769-4654-847f-958f3d479f4a",
    REACT_APP_CLIENT_ID: "8f7e6c7b-3a3d-4f9c-8a46-827735b9caea",
    REACT_APP_API_BASE:
      "https://glbl-dv-promocatalog-webapi-euwe-001-app.azurewebsites.net",
    REACT_APP_MRP_IMPORT_FILE_TIMEOUT: "15000",
    REACT_APP_MRP_EXPORT_FILE_TIMEOUT: "1500",
    CHATBOT_URL:
      "https://webchat.botframework.com/embed/qnaknowledgeservice-bot?s=SaYSGWmKWHI._Em3-AG2SnI9FGZv7h-H4cQiOkh4yGxcM86wRPnDK3Q",
    REACT_APP_EASY_REQUEST: "https://ezpr-codev.fluidapp.io",
    DESCRIBE_YOUR_NEED:
      "https://ezpr-codev.fluidapp.io/?#createNewRequest=true",
    AMC_URL: "https://amc-codev.fluidapp.io/",
    EASY_REQUEST_URL: "https://ezpr-codev.fluidapp.io/",
  },
  "PromoCatalog-RTE": {
    REACT_APP_TENANT_ID: "12a3af23-a769-4654-847f-958f3d479f4a",
    REACT_APP_CLIENT_ID: "8f7e6c7b-3a3d-4f9c-8a46-827735b9caea",
    REACT_APP_API_BASE:
      "https://glbl-ts-promocatalog-webapi-euwe-001-app.azurewebsites.net",
    REACT_APP_MRP_IMPORT_FILE_TIMEOUT: "25000",
    REACT_APP_MRP_EXPORT_FILE_TIMEOUT: "2500",
    CHATBOT_URL:
      "https://webchat.botframework.com/embed/qnaknowledgeservice-bot?s=SaYSGWmKWHI._Em3-AG2SnI9FGZv7h-H4cQiOkh4yGxcM86wRPnDK3Q",
    REACT_APP_EASY_REQUEST: "https://easy-request-preprod.fluidapp.io",
    DESCRIBE_YOUR_NEED:
      "https://easy-request-preprod.fluidapp.io/#createNewRequest=true",
    AMC_URL: "https://amc-preprod.fluidapp.io/",
    EASY_REQUEST_URL: "https://easy-request-preprod.fluidapp.io/",
  },
  "PromoCatalog-PP": {
    REACT_APP_TENANT_ID: "12a3af23-a769-4654-847f-958f3d479f4a",
    REACT_APP_CLIENT_ID: "8f7e6c7b-3a3d-4f9c-8a46-827735b9caea",
    REACT_APP_API_BASE:
      "https://glbl-pp-promocatalog-webapi-euwe-001-app.azurewebsites.net",
    REACT_APP_MRP_IMPORT_FILE_TIMEOUT: "25000",
    REACT_APP_MRP_EXPORT_FILE_TIMEOUT: "2500",
    CHATBOT_URL:
      "https://webchat.botframework.com/embed/qnaknowledgeservice-bot?s=SaYSGWmKWHI._Em3-AG2SnI9FGZv7h-H4cQiOkh4yGxcM86wRPnDK3Q",
    REACT_APP_EASY_REQUEST: "https://easy-request-preprod.fluidapp.io",
    DESCRIBE_YOUR_NEED:
      "https://easy-request-preprod.fluidapp.io/#createNewRequest=true",
    AMC_URL: "https://amc-preprod.fluidapp.io/",
    EASY_REQUEST_URL: "https://easy-request-preprod.fluidapp.io/",
  },
  "PromoCatalog-PROD": {
    REACT_APP_TENANT_ID: "12a3af23-a769-4654-847f-958f3d479f4a",
    REACT_APP_CLIENT_ID: "8f7e6c7b-3a3d-4f9c-8a46-827735b9caea",
    REACT_APP_API_BASE:
      "https://glbl-pr-promocatalog-webapi-euwe-001-app.azurewebsites.net",
    REACT_APP_MRP_IMPORT_FILE_TIMEOUT: "30000",
    REACT_APP_MRP_EXPORT_FILE_TIMEOUT: "3000",
    CHATBOT_URL:
      "https://webchat.botframework.com/embed/glbl-pr-contractbrowser-euwe-003-bot?s=JwlHfy6KLv4.5U8UrtULzbEyZaxBhVS7XN70HK5bqC2CThs5wUBqAVM",
    REACT_APP_EASY_REQUEST: "https://easy-request.fluidapp.io",
    DESCRIBE_YOUR_NEED:
      "https://easy-request.fluidapp.io/#createNewRequest=true",
    AMC_URL: "https://amc.fluidapp.io/",
    EASY_REQUEST_URL: "https://easy-request.fluidapp.io/",
  },
}[process.env.REACT_APP_ENV || "PromoCatalog-DEV"];
