import {
  Info,
  ArrowForwardIos,
  ExpandLess,
  ExpandMore,
  StarBorder,
  ArrowBackIos,
} from "@mui/icons-material";
import {
  Grid,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  IconButton,
  Typography,
  List,
  ListItemButton,
  ListItemText,
  Collapse,
  ListItemIcon,
  Breadcrumbs,
  Link,
} from "@mui/material";
import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import PromoCatalogService from "service/PromoCatalog.service";
import ListContainer from "./list.container";

function srcset(image: string, size: number, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

export const SpendCategoryItem = ({ category }) => {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <>
      <ListItemButton
        sx={{
          borderRadius: 2,
          background: "#f9f9fb",
          marginBottom: 1,
          paddingLeft: 4,
          paddingRight: 4,
          paddingTop: 1,
          paddingBottom: 1,
        }}
      >
        <ListItemText primary={category.name} />
        {!category.spendCategories ||
        category.spendCategories?.length === 0 ? null : open ? (
          <ExpandLess onClick={handleClick} />
        ) : (
          <ExpandMore onClick={handleClick} />
        )}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding sx={{ paddingLeft: 2 }}>
          {category.spendCategories?.map((subCategory) => (
            <SpendCategoryItem key={subCategory.code} category={subCategory} />
          ))}
        </List>
      </Collapse>
    </>
  );
};

export const CategoriesContainer = () => {
  const { spendCat1, spendCat2, spendCat3 } = useParams();
  const [breadCrumbs, setBreadcrumbs] = useState([]);
  const navigate = useNavigate();
  const { data: spendCategories, isLoading } = useQuery({
    queryKey: ["getSpendCategories"],
    queryFn: () => PromoCatalogService.getSpendCategories(),
  });
  return isLoading ? (
    <>loading...</>
  ) : (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          component={NavLink}
          underline="hover"
          color="inherit"
          to="/promo-items"
        >
          Categories
        </Link>
        {spendCat1 && (
          <Link
            component={NavLink}
            underline="hover"
            color="inherit"
            to={`/promo-items/${spendCat1}`}
          >
            {spendCat1}
          </Link>
        )}
        {spendCat2 && (
          <Link
            component={NavLink}
            underline="hover"
            color="inherit"
            to={`/promo-items/${spendCat1}/${spendCat2}`}
          >
            {spendCat2}
          </Link>
        )}
        {spendCat3 && (
          <Link
            component={NavLink}
            underline="hover"
            color="inherit"
            to={`/promo-items/${spendCat1}/${spendCat2}/${spendCat3}`}
          >
            {spendCat3}
          </Link>
        )}
      </Breadcrumbs>
      {spendCat1 ? (
        <ListContainer />
      ) : (
        <Grid container spacing={6}>
          <Grid item xs={8}>
            <ImageList
              // sx={{ width: 500, height: 450 }}
              variant="quilted"
              cols={4}
              rowHeight={250}
              gap={10}
            >
              {[
                {
                  img: "https://mir-s3-cdn-cf.behance.net/projects/404/d97b44181065609.Y3JvcCwxMDg3LDg1MSwxNzcsMA.jpg",
                  title: "Display stands",
                  rows: 2,
                  cols: 2,
                },
                {
                  img: "https://i.etsystatic.com/13359940/r/il/0eaa8b/5521506055/il_fullxfull.5521506055_81f0.jpg",
                  title: "T-shirts",
                },
                {
                  img: "https://c8.alamy.com/comp/R3EHYP/parasol-in-restaurant-garden-with-slogan-taste-the-summer-and-advertising-for-nestle-ice-cream-R3EHYP.jpg",
                  title: "Umbrellas",
                },
                {
                  img: "https://i.etsystatic.com/31962852/r/il/a7fc74/4226550175/il_570xN.4226550175_nhsg.jpg",
                  title: "Mugs",
                  cols: 2,
                },
                {
                  img: "https://i0.wp.com/packagingoftheworld.com/wp-content/uploads/2022/12/f1c9689e-205a-49c8-97d6-5b4216cdd358-2.jpg?fit=1920%2C960&ssl=1",
                  title: "Tin boxes",
                  cols: 2,
                },
                {
                  img: "https://insiteooh.com/storage/media/articles/9714/5fb52ff793919380541141.jpg",
                  title: "Billboards",
                  rows: 2,
                  cols: 2,
                },
                {
                  img: "https://moodiedavittreport.com/wp-content/uploads/2023/06/nestle_nesquik_bag.jpg",
                  title: "Bags",
                },
                {
                  img: "https://cdn.dribbble.com/userupload/4679663/file/original-e3778afc03ac78eafd2e319f691a4260.png",
                  title: "Promo pages",
                },
              ].map((item) => (
                <ImageListItem
                  key={item.img}
                  cols={item.cols || 1}
                  rows={item.rows || 1}
                  sx={{
                    borderRadius: 2,
                    overflow: "hidden",
                    cursor: "pointer",
                    ".MuiImageListItemBar-title": {
                      fontSize: 40,
                      lineHeight: "1.2",
                      fontWeight: "bold",
                    },
                  }}
                  onClick={() => {
                    navigate(`/browse?spendCat=S030005`);
                  }}
                >
                  <img
                    {...srcset(item.img, 121, item.rows, item.cols)}
                    alt={item.title}
                    loading="lazy"
                  />
                  <ImageListItemBar
                    title={item.title}
                    subtitle={"123 items"}
                    actionIcon={
                      <IconButton
                        sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                        aria-label={`info about ${item.title}`}
                      >
                        <Info />
                      </IconButton>
                    }
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </Grid>
          <Grid item xs={4}>
            <List sx={{ width: "100%" }} component="nav">
              {breadCrumbs[breadCrumbs.length - 1] && (
                <ListItemButton
                  sx={{
                    borderRadius: 2,
                    background: "#f9f9fb",
                    marginBottom: 1,
                    paddingLeft: 4,
                    paddingRight: 4,
                    paddingTop: 1,
                    paddingBottom: 1,
                  }}
                  onClick={(e) => {
                    navigate(
                      `/promo-items/${breadCrumbs[breadCrumbs.length - 1].code}`
                    );
                  }}
                >
                  <ArrowBackIos
                    onClick={(e) => {
                      e.stopPropagation();
                      const newBreadcrumbs = [...breadCrumbs];
                      newBreadcrumbs.pop();
                      setBreadcrumbs(newBreadcrumbs);
                    }}
                  />
                  <ListItemText
                    primary={breadCrumbs[breadCrumbs.length - 1].name}
                  />
                </ListItemButton>
              )}
              {(breadCrumbs[breadCrumbs.length - 1]
                ? breadCrumbs[breadCrumbs.length - 1].spendCategories
                : spendCategories
              )
                .sort((a, b) => (a.code > b.code ? 1 : -1))
                .map((category) => (
                  <ListItemButton
                    key={category.id}
                    sx={{
                      borderRadius: 2,
                      background: "#f9f9fb",
                      marginBottom: 1,
                      paddingLeft: 4,
                      paddingRight: 4,
                      paddingTop: 1,
                      paddingBottom: 1,
                    }}
                    onClick={() => {
                      navigate(
                        `/promo-items/${[...(breadCrumbs || []), category]
                          .map((cat) => cat.code)
                          .join("/")}`
                      );
                    }}
                  >
                    <ListItemText primary={category.name} />
                    {!category.spendCategories ||
                    category.spendCategories?.length === 0 ? null : (
                      <ArrowForwardIos
                        onClick={(e) => {
                          e.stopPropagation();
                          setBreadcrumbs([...(breadCrumbs || []), category]);
                        }}
                      />
                    )}
                  </ListItemButton>
                ))}
            </List>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default CategoriesContainer;
