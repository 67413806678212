import {
  createQueryService,
  createFormDataService,
  createMutationService,
} from "utils/factories";

export default class FeatureManagementService {
  static readonly getFeatureFlags = createQueryService<
    { features: { [key: string]: true }; configs: { [key: string]: string } },
    never
  >("/api/FeatureManagement/Initialize");
}
