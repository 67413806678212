import React from "react";
import { Box } from "@mui/material";

export const PUIRatioBox = (props: {xRatio: number, yRatio: number, children}) => {
    const {xRatio, yRatio} = props;
  return (
    <Box
      sx={{
        position: "relative",
        width: '100%',
        "&:before": {
          content: "''",
          display: "block",
          height: 0,
          paddingBottom: `${(yRatio / xRatio) * 100}%`,
          boxSizing: "border-box",
        },
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          overflow: "hidden",
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
};

export default PUIRatioBox;
