import React, { useState } from "react";
import {
  Box,
  Button,
  MobileStepper,
  Modal,
  Paper,
  SxProps,
  Typography,
  Zoom,
  useTheme,
} from "@mui/material";
import PUIRatioBox from "./RatioBox.component";
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Visibility,
  ZoomIn,
} from "@mui/icons-material";

type Slide = {
  type: "image" | "video" | "iframe";
  thumbnail: string;
  src: string;
  label?: string;
};

export const PUIContentCarousel = ({
  data,
  onPreview,
  sx,
}: {
  data: Slide[];
  onPreview?: (activeSlide: Slide) => void;
  sx?: SxProps;
}) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [activeSlide, setActiveSlide] = useState(0);
  const handleNext = (e) => {
    e.stopPropagation();
    setActiveSlide((prevActiveSlide) =>
      prevActiveSlide < data.length - 1 ? prevActiveSlide + 1 : 0
    );
  };

  const handleBack = (e) => {
    e.stopPropagation();
    setActiveSlide((prevActiveSlide) =>
      prevActiveSlide < 1 ? data.length - 1 : prevActiveSlide - 1
    );
  };
  const handleZoom = (e) => {
    e.stopPropagation();
    if (onPreview) {
      onPreview(data[activeSlide]);
    } else {
      setOpen(true);
    }
  };
  return !data || !data.length ? null : (
    <>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Zoom in={open}>
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              width: "100%",
              height: "100%",
              pointerEvents: "none",
            }}
          >
            <Paper
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                //   width: "100%",
                transform: "translate(-50%, -50%)",
                maxWidth: "85vw",
                maxHeight: "85vh",
                p: 4,
                overflow: "auto",
                pointerEvents: "all",
                // borderRadius: 2,
              }}
              elevation={24}
            >
              {
                {
                  image: (
                    <img
                      alt={data[activeSlide]?.label || "Image"}
                      title={data[activeSlide]?.label || "Image"}
                      style={{
                        width: "100%",
                        height: "auto",
                      }}
                      src={data[activeSlide]?.src}
                    />
                  ),
                  video: (
                    <video
                      src={data[activeSlide]?.src}
                      controls
                      style={{ width: "100%", height: "auto" }}
                      autoPlay
                    ></video>
                  ),
                  iframe: (
                    <iframe
                      src={data[activeSlide]?.src}
                      style={{
                        maxWidth: "100%",
                        maxHeight: "auto",
                        width: 1600,
                        height: 900,
                        border: 0,
                      }}
                      frameBorder="0"
                    ></iframe>
                  ),
                }[data[activeSlide]?.type]
              }
            </Paper>
          </Box>
        </Zoom>
      </Modal>
      <Box sx={{ position: "relative", overflow: "hidden", ...sx }}>
        <PUIRatioBox xRatio={16} yRatio={9}>
          {
            {
              image: (
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    backgroundImage: `url(${data[activeSlide]?.thumbnail})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                ></Box>
              ),
              video: (
                <video
                  src={data[activeSlide]?.src}
                  style={{ width: "100%", height: "auto" }}
                  poster={data[activeSlide]?.thumbnail}
                ></video>
              ),
              iframe: (
                <iframe
                  src={data[activeSlide]?.src}
                  style={{ width: "100%", height: "100%", border: 0 }}
                  frameBorder="0"
                ></iframe>
              ),
            }[data[activeSlide]?.type]
          }
        </PUIRatioBox>
        <Box
          sx={{
            position: "absolute",
            left: 0,
            bottom: 0,
            right: 0,
            top: 0,
            background:
              "linear-gradient(90deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.2) 50%, rgba(0,0,0,0.5) 100%)",
            zIndex: 100,
            display: "flex",
            justifyContent: "space-between",
            opacity: 0,
            transform: "scale(1.5)",
            transition: ".2s ease-in-out",
            "&:hover": {
              opacity: 1,
              transform: "scale(1)",
            },
          }}
        >
          <Button sx={{ color: "white" }} size="large" onClick={handleBack}>
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
          </Button>
          <Button sx={{ color: "white" }} size="large" onClick={handleZoom}>
            <Visibility />
          </Button>
          <Button sx={{ color: "white" }} size="large" onClick={handleNext}>
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        </Box>
      </Box>
    </>
  );
};
