import React from "react";
import { FeatureManagementContext } from "../containers/FeatureManagementProvider.container";

export const useFeatures = () => {
  const context = React.useContext(FeatureManagementContext);
  if (context === undefined) {
    throw new Error(
      "useFeatures must be used within a FeatureManagementProvider"
    );
  }
  return context.features;
};

export const useConfigs = () => {
  const context = React.useContext(FeatureManagementContext);
  if (context === undefined) {
    throw new Error(
      "useConfigs must be used within a FeatureManagementProvider"
    );
  }
  return context.configs;
};
