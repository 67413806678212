import moment from "moment";
import { Container, Grid, Tooltip } from "@mui/material";
import React, { useState } from "react";

export const ActivityLogInitiatorUserTooltip = ({
  email,
  name,
}: {
  email: string;
  name: string;
}) => {
  const [tooltip, setTooltip] = useState(email);

  const onClick = () => {
    // eslint-disable-next-line no-undef
    navigator.clipboard.writeText(email);
    setTooltip("Copied to clipboard");

    setTimeout(() => {
      setTooltip(email);
    }, 1500);
  };

  return (
    <Tooltip title={tooltip}>
      <span onClick={onClick}>{name}</span>
    </Tooltip>
  );
};

export const ActivityLog = ({ entries }: {entries: {performedAt: string, performedByEmail: string, performedByUserName: string, activityText: string, additionalInformation: string}[]}) => {
  return (
    <Container sx={{p: 4}}>
      {entries
        .sort((a, b) =>
          moment.utc(a.performedAt).isAfter(moment.utc(b.performedAt)) ? -1 : 1
        )
        .map((a, index) => {
          return (
            <Grid
            sx={{mb: 2}}
            spacing={4}
            container
            justifyContent={"space-between"}
              key={index}
            >
              <Grid item >
                <div>
                  {a.performedByEmail && (
                    <ActivityLogInitiatorUserTooltip
                      name={a.performedByUserName}
                      email={a.performedByEmail}
                    />
                  )}
                  {!a.performedByEmail && (
                    <span className="pr-1 italic">System</span>
                  )}
                  <span> {a.activityText}</span>
                </div>
                <span className="italic text-sm">
                  {a.additionalInformation}
                </span>
              </Grid>

              <Grid item>
                {moment.utc(a.performedAt).fromNow()}
              </Grid>
            </Grid>
          );
        })}
    </Container>
  );
};

export default ActivityLog;
