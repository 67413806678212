import { Engineering, FindInPageOutlined } from "@mui/icons-material";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography/Typography";
import React from "react";

export const MaintenanceView = () => {
  return (
    <Box>
      <Typography variant="h1" textAlign={"center"}>
        <Engineering sx={{ fontSize: "3rem" }} />
      </Typography>
      <Typography variant="h6" textAlign={"center"}>
        Nestlè's Promo Catalog is currently under maintenance.
      </Typography>
      <Typography variant="body2" textAlign={"center"}>
        We will be back shortly.
      </Typography>
    </Box>
  );
};

export default MaintenanceView;
