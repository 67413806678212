import React, { useState } from "react";
import {
  Accept,
  DropEvent,
  ErrorCode,
  FileRejection,
  useDropzone,
} from "react-dropzone";
import { CircularProgress, Tooltip, Alert, Box } from "@mui/material";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import CloudDoneOutlinedIcon from "@mui/icons-material/CloudDoneOutlined";
import CloseIcon from "@mui/icons-material/Close";
import AttachmentIcon from "@mui/icons-material/Attachment";

import classes from "./dropzone.module.scss";

export const File = ({
  fileName,
  onRemoveFile,
  onFileClick,
  fullHeight,
  size,
  type,
}: {
  fileName;
  onRemoveFile;
  onFileClick;
  size?: number;
  type?: string;
  fullHeight?: boolean;
}) => {
  return (
    <div
      className="flex justify-between w-full p-4 bg-main-back border text-main-text rounded-lg mt-1 mb-1"
      style={{ height: fullHeight ? "100%" : "auto" }}
    >
      <div
        className={`${
          onFileClick && "cursor-pointer"
        } flex items-center space-x-4`}
        style={{ maxWidth: "calc(100% - 20px)" }}
        onClick={() => onFileClick(fileName)}
      >
        <div className="w-10 h-10 text-white rounded bg-main-contrast flex items-center justify-center">
          <AttachmentIcon />
        </div>
        <div className="w-full">
          <div className="truncate">{fileName}</div>
          {!!size && <div>{Math.round(size / 1000000)}MB</div>}
        </div>
      </div>
      {onRemoveFile && (
        <div className="w-10 flex justify-end">
          <CloseIcon
            style={{ fontSize: "1.5rem", cursor: "pointer" }}
            onClick={() => {
              onRemoveFile(fileName);
            }}
          />
        </div>
      )}
    </div>
  );
};

export const Dropzone = (props: {
  onDrop: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => Promise<void>;
  uploadStatus?: string;
  fileName?: string;
  fileNames?: string[];
  accept?: Accept;
  readOnly?: boolean;
  multiple?: boolean;
  fullHeight?: boolean;
  minSize?: number;
  maxSize?: number;
  maxFiles?: number;
  placeholder?: string | React.Component | JSX.Element;
  className?: string;
  error?: any;
  rejectedFilesWarning?: string;
  onRemoveFile?: (fileName: string) => void;
  onFileClick?: (fileName: string) => void;
}) => {
  const {
    readOnly,
    onDrop,
    uploadStatus,
    fileName,
    fileNames,
    accept,
    multiple,
    minSize,
    maxSize,
    maxFiles,
    onRemoveFile,
    onFileClick,
  } = props;
  const [fileRejections, setFileRejections] = useState<FileRejection[]>(null);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles, fileRejections, dropEvent) => {
      setFileRejections(fileRejections);
      onDrop(acceptedFiles, fileRejections, dropEvent);
    },
    accept,
    multiple,
    maxSize,
    minSize: minSize || 1,
    maxFiles,
  });

  const Icon = () => {
    if (fileName || fileNames?.length > 0) {
      return (
        <CloudDoneOutlinedIcon
          className={classes.icon}
          style={{ pointerEvents: "none" }}
        />
      );
    }

    if (uploadStatus === "loading") {
      return (
        <div className="mt-2 mb-1" style={{ pointerEvents: "none" }}>
          <CircularProgress color="inherit" size={20} />
        </div>
      );
    }

    return (
      <div>
        <CloudUploadOutlinedIcon
          className={classes.icon}
          style={{ pointerEvents: "none" }}
        />
      </div>
    );
  };

  const Text = () => {
    if (isDragActive) {
      return <p style={{ pointerEvents: "none" }}>Drop files here</p>;
    }

    if (uploadStatus === "loading") {
      return <p style={{ pointerEvents: "none" }}>Uploading...</p>;
    }

    return (
      <p style={{ pointerEvents: "none" }}>
        {props.placeholder as React.ReactNode}
      </p>
    );
  };

  return (
    <div style={{ height: props.fullHeight ? "100%" : "auto" }}>
      {fileNames?.length > 0 && (
        <div>
          {fileNames.map((f) => {
            return (
              <File
                key={f}
                fileName={f}
                onRemoveFile={readOnly ? null : onRemoveFile}
                onFileClick={onFileClick}
              />
            );
          })}
        </div>
      )}
      {fileName ? (
        <File
          fileName={fileName}
          onRemoveFile={readOnly ? null : onRemoveFile}
          fullHeight={props.fullHeight}
          onFileClick={onFileClick}
        />
      ) : readOnly ? (
        <></>
      ) : (
        <Box
          {...getRootProps()}
          className={`${!fileName && "cursor-pointer"} ${props.className} ${
            classes.dropzone
          } ${!props.error ? "" : classes.error} bg-main-back`}
          sx={{
            margin: props.fullHeight ? "0" : "1rem 0",
            height: props.fullHeight ? "100%" : "auto",
          }}
        >
          <input
            // placeholder={props.placeholder ?? t("uploadFile")}
            {...getInputProps()}
            className="w-full h-full"
          />
          <Icon />
          <Text />
          {!!accept && (
            <div>
              <em>Accepted file types: {Object.values(accept).join(", ")}</em>{" "}
              <br />
            </div>
          )}
          {!!maxSize && (
            <div>
              <em>Max file size: {maxSize / 1000000}MB</em>
            </div>
          )}
        </Box>
      )}
      {!!props.error && <Alert severity="error">{props.error}</Alert>}
      {!!fileRejections && fileRejections.length > 0 && (
        <Alert severity="warning">
          <Tooltip
            title={fileRejections.map((fileRejection) => (
              <div key={fileRejection.file.name}>{fileRejection.file.name}</div>
            ))}
          >
            <Box sx={{ textDecoration: "underline", cursor: "help" }}>
              {fileRejections.length} Files
            </Box>
          </Tooltip>
          &nbsp; were rejected. &nbsp;
          {props.rejectedFilesWarning ||
            (fileRejections.some((r) =>
              r.errors.some((e) => e.code === ErrorCode.FileTooSmall)
            ) && <>Make sure you're not uploading empty files.</>)}
        </Alert>
      )}
    </div>
  );
};

export default Dropzone;
