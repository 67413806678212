export const SpendCategories = [
  {
    id: 3,
    level: 1,
    code: "S03",
    name: "3 Trade Assets",
    materialCode: null,
    materialDescription: null,
    materialLongDescription: null,
    parentSpendCatId: 0,
    parentSpendCatCode: null,
    active: true,
    spendCategories: [
      {
        id: 49,
        level: 2,
        code: "S030001",
        name: "3 Dispensers (incl. Vending)",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 3,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 372,
            level: 3,
            code: "S030002",
            name: "3 Cold Beverage Dispensers",
            materialCode: "90031329",
            materialDescription: "3 Cold Beverage Dispensers",
            materialLongDescription: "3 Cold Beverage Dispensers",
            parentSpendCatId: 49,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 373,
            level: 3,
            code: "S030003",
            name: "3 Hot/Cold Beverage Dispensers",
            materialCode: "90031330",
            materialDescription: "3 Hot/Cold Beverage Dispensers",
            materialLongDescription: "3 Hot/Cold Beverage Dispensers",
            parentSpendCatId: 49,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 374,
            level: 3,
            code: "S030004",
            name: "3 Ice Cream Dispensers",
            materialCode: "90031331",
            materialDescription: "3 Ice Cream Dispensers",
            materialLongDescription: "3 Ice Cream Dispensers",
            parentSpendCatId: 49,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 375,
            level: 3,
            code: "S030005",
            name: "3 Other Commercial Dispensers",
            materialCode: "90031332",
            materialDescription: "3 Other Commercial Dispensers",
            materialLongDescription: "3 Other Commercial Dispensers",
            parentSpendCatId: 49,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 376,
            level: 3,
            code: "S030006",
            name: "3 Cold Ambient Sauce Dispenser",
            materialCode: "90031333",
            materialDescription: "3 Cold Ambient Sauce Dispenser",
            materialLongDescription: "3 Cold Ambient Sauce Dispenser",
            parentSpendCatId: 49,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 377,
            level: 3,
            code: "S030007",
            name: "3 Soup Dispensers",
            materialCode: "90031334",
            materialDescription: "3 Soup Dispensers",
            materialLongDescription: "3 Soup Dispensers",
            parentSpendCatId: 49,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 378,
            level: 3,
            code: "S030008",
            name: "3 Mashed Potatoes Dispensers",
            materialCode: "90031335",
            materialDescription: "3 Mashed Potatoes Dispensers",
            materialLongDescription: "3 Mashed Potatoes Dispensers",
            parentSpendCatId: 49,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 379,
            level: 3,
            code: "S030009",
            name: "3 Dispenser Maintenance/Serv.",
            materialCode: "90031336",
            materialDescription: "3 Dispenser Maintenance/Serv.",
            materialLongDescription: "3 Dispenser Maintenance/Serv.",
            parentSpendCatId: 49,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 380,
            level: 3,
            code: "S030010",
            name: "3 Hot Beverage Dispensers",
            materialCode: "90001633",
            materialDescription: "3 Hot Beverage Dispensers",
            materialLongDescription: "3 Hot Beverage Dispensers",
            parentSpendCatId: 49,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 381,
            level: 3,
            code: "S030011",
            name: "3 Heated Sauce Dispensers",
            materialCode: "90001628",
            materialDescription: "3 Heated Sauce Dispensers",
            materialLongDescription: "3 Heated Sauce Dispensers",
            parentSpendCatId: 49,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 382,
            level: 3,
            code: "S030012",
            name: "3 Combined Snacks/Bev. Dispen.",
            materialCode: "90001624",
            materialDescription: "3 Combined Snacks/Bev. Dispen.",
            materialLongDescription: "3 Combined Snacks/Bev. Dispen.",
            parentSpendCatId: 49,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 50,
        level: 2,
        code: "S030013",
        name: "3 Food Warmers",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 3,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 383,
            level: 3,
            code: "S030014",
            name: "3 POS Food Warmers",
            materialCode: "90001622",
            materialDescription: "3 POS Food Warmers",
            materialLongDescription: "3 POS Food Warmers",
            parentSpendCatId: 50,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 51,
        level: 2,
        code: "S030015",
        name: "3 Freezers",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 3,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 384,
            level: 3,
            code: "S030016",
            name: "3 Freezer Maintenance/Service",
            materialCode: "90031337",
            materialDescription: "3 Freezer Maintenance/Service",
            materialLongDescription: "3 Freezer Maintenance/Service",
            parentSpendCatId: 51,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 385,
            level: 3,
            code: "S030017",
            name: "3 Other Commercial Freezers",
            materialCode: "90031338",
            materialDescription: "3 Other Commercial Freezers",
            materialLongDescription: "3 Other Commercial Freezers",
            parentSpendCatId: 51,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 386,
            level: 3,
            code: "S030018",
            name: "3 Upright Display Freezers",
            materialCode: "90031339",
            materialDescription: "3 Upright Display Freezers",
            materialLongDescription: "3 Upright Display Freezers",
            parentSpendCatId: 51,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 387,
            level: 3,
            code: "S030019",
            name: "3 Counter Top Freezers",
            materialCode: "90031340",
            materialDescription: "3 Counter Top Freezers",
            materialLongDescription: "3 Counter Top Freezers",
            parentSpendCatId: 51,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 388,
            level: 3,
            code: "S030020",
            name: "3 Scooping Display Cabinets",
            materialCode: "90031341",
            materialDescription: "3 Scooping Display Cabinets",
            materialLongDescription: "3 Scooping Display Cabinets",
            parentSpendCatId: 51,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 389,
            level: 3,
            code: "S030021",
            name: "3 Micro Freezer Cabinets",
            materialCode: "90031342",
            materialDescription: "3 Micro Freezer Cabinets",
            materialLongDescription: "3 Micro Freezer Cabinets",
            parentSpendCatId: 51,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 390,
            level: 3,
            code: "S030022",
            name: "3 Island Freezers",
            materialCode: "90031343",
            materialDescription: "3 Island Freezers",
            materialLongDescription: "3 Island Freezers",
            parentSpendCatId: 51,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 391,
            level: 3,
            code: "S030023",
            name: "3 Chest Freezers",
            materialCode: "90001631",
            materialDescription: "3 Chest Freezers",
            materialLongDescription: "3 Chest Freezers",
            parentSpendCatId: 51,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 52,
        level: 2,
        code: "S030024",
        name: "3 Other Point of Sales Assets",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 3,
        parentSpendCatCode: null,
        active: false,
        spendCategories: [],
      },
      {
        id: 53,
        level: 2,
        code: "S030027",
        name: "3 Refrigerators",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 3,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 394,
            level: 3,
            code: "S030028",
            name: "3 Counter Top Refrigerators",
            materialCode: "90031344",
            materialDescription: "3 Counter Top Refrigerators",
            materialLongDescription: "3 Counter Top Refrigerators",
            parentSpendCatId: 53,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 395,
            level: 3,
            code: "S030029",
            name: "3 Other Commercial Refriger.",
            materialCode: "90031345",
            materialDescription: "3 Other Commercial Refriger.",
            materialLongDescription: "3 Other Commercial Refriger.",
            parentSpendCatId: 53,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 396,
            level: 3,
            code: "S030030",
            name: "3 Refriger. Maintenance/Serv.",
            materialCode: "90031346",
            materialDescription: "3 Refriger. Maintenance/Serv.",
            materialLongDescription: "3 Refriger. Maintenance/Serv.",
            parentSpendCatId: 53,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 397,
            level: 3,
            code: "S030031",
            name: "3 Upright Refrigerators",
            materialCode: "90001630",
            materialDescription: "3 Upright Refrigerators",
            materialLongDescription: "3 Upright Refrigerators",
            parentSpendCatId: 53,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 54,
        level: 2,
        code: "S030032",
        name: "3 Boutiques",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 3,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 400,
            level: 3,
            code: "S030035",
            name: "3 Refurbishing Works",
            materialCode: "90057883",
            materialDescription: "3 Refurbishing Works",
            materialLongDescription: "3 Refurbishing Works",
            parentSpendCatId: 54,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 402,
            level: 3,
            code: "S030037",
            name: "3 Lighting",
            materialCode: "90057885",
            materialDescription: "3 Lighting",
            materialLongDescription: "3 Lighting",
            parentSpendCatId: 54,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 403,
            level: 3,
            code: "S030039",
            name: "3 Technical Equipment",
            materialCode: "90057887",
            materialDescription: "3 Technical Equipment",
            materialLongDescription: "3 Technical Equipment",
            parentSpendCatId: 54,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 404,
            level: 3,
            code: "S030040",
            name: "3 Repair",
            materialCode: "90057888",
            materialDescription: "3 Repair",
            materialLongDescription: "3 Repair",
            parentSpendCatId: 54,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 405,
            level: 3,
            code: "S030041",
            name: "3 Safety",
            materialCode: "90057889",
            materialDescription: "3 Safety",
            materialLongDescription: "3 Safety",
            parentSpendCatId: 54,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 406,
            level: 3,
            code: "S030042",
            name: "3 Maintenance and Cleaning",
            materialCode: "90057886",
            materialDescription: "3 Maintenance and Cleaning",
            materialLongDescription: "3 Maintenance and Cleaning",
            parentSpendCatId: 54,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
    ],
  },
  {
    id: 6,
    level: 1,
    code: "S06",
    name: "6 Administration & HR Services",
    materialCode: null,
    materialDescription: null,
    materialLongDescription: null,
    parentSpendCatId: 0,
    parentSpendCatCode: null,
    active: true,
    spendCategories: [
      {
        id: 85,
        level: 2,
        code: "S060001",
        name: "6 Business/Prof. Associations",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 6,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 549,
            level: 3,
            code: "S060002",
            name: "6 Business associations",
            materialCode: "90001941",
            materialDescription: "6 Business associations",
            materialLongDescription: "6 Business associations",
            parentSpendCatId: 85,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 550,
            level: 3,
            code: "S060003",
            name: "6 Professional associations",
            materialCode: "90001942",
            materialDescription: "6 Professional associations",
            materialLongDescription: "6 Professional associations",
            parentSpendCatId: 85,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 551,
            level: 3,
            code: "S060004",
            name: "6 Other Work related organiz.",
            materialCode: "90001940",
            materialDescription: "6 Other Work related organiz.",
            materialLongDescription: "6 Other Work related organiz.",
            parentSpendCatId: 85,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 86,
        level: 2,
        code: "S060005",
        name: "6 Company Vehicles & Services",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 6,
        parentSpendCatCode: null,
        active: false,
        spendCategories: [],
      },
      {
        id: 87,
        level: 2,
        code: "S060015",
        name: "6 Office Equipment",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 6,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 560,
            level: 3,
            code: "S060016",
            name: "6 Furnitures & Accessories",
            materialCode: "90001685",
            materialDescription: "6 Furnitures & Accessories",
            materialLongDescription: "6 Furnitures & Accessories",
            parentSpendCatId: 87,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 561,
            level: 3,
            code: "S060017",
            name: "6 Audio and visual equipment",
            materialCode: "90001606",
            materialDescription: "6 Audio and visual equipment",
            materialLongDescription: "6 Audio and visual equipment",
            parentSpendCatId: 87,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 562,
            level: 3,
            code: "S060018",
            name: "6 Printing and publish equip.",
            materialCode: "90001605",
            materialDescription: "6 Printing and publish equip.",
            materialLongDescription: "6 Printing and publish equip.",
            parentSpendCatId: 87,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 563,
            level: 3,
            code: "S060019",
            name: "6 Office time recording mach.",
            materialCode: "90001602",
            materialDescription: "6 Office time recording mach.",
            materialLongDescription: "6 Office time recording mach.",
            parentSpendCatId: 87,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 564,
            level: 3,
            code: "S060020",
            name: "6 Others Office machines",
            materialCode: "90001595",
            materialDescription: "6 Others Office machines",
            materialLongDescription: "6 Others Office machines",
            parentSpendCatId: 87,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 565,
            level: 3,
            code: "S060021",
            name: "6 Printer/photocopier/fax acc.",
            materialCode: "90001598",
            materialDescription: "6 Printer/photocopier/fax acc.",
            materialLongDescription: "6 Printer/photocopier/fax acc.",
            parentSpendCatId: 87,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 566,
            level: 3,
            code: "S060022",
            name: "6 Mailing machines",
            materialCode: "90001599",
            materialDescription: "6 Mailing machines",
            materialLongDescription: "6 Mailing machines",
            parentSpendCatId: 87,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 88,
        level: 2,
        code: "S060023",
        name: "6 Office Services",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 6,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 567,
            level: 3,
            code: "S060024",
            name: "6 Photocopying Services",
            materialCode: "90031350",
            materialDescription: "6 Photocopying Services",
            materialLongDescription: "6 Photocopying Services",
            parentSpendCatId: 88,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 568,
            level: 3,
            code: "S060025",
            name: "6 Publication printing",
            materialCode: "90001851",
            materialDescription: "6 Publication printing",
            materialLongDescription: "6 Publication printing",
            parentSpendCatId: 88,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 569,
            level: 3,
            code: "S060026",
            name: "6 Translation services",
            materialCode: "90001848",
            materialDescription: "6 Translation services",
            materialLongDescription: "6 Translation services",
            parentSpendCatId: 88,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 570,
            level: 3,
            code: "S060027",
            name: "6 Audio visual services",
            materialCode: "90001815",
            materialDescription: "6 Audio visual services",
            materialLongDescription: "6 Audio visual services",
            parentSpendCatId: 88,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 571,
            level: 3,
            code: "S060028",
            name: "6 Data archiving services",
            materialCode: "90001814",
            materialDescription: "6 Data archiving services",
            materialLongDescription: "6 Data archiving services",
            parentSpendCatId: 88,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 572,
            level: 3,
            code: "S060029",
            name: "6 Courier services",
            materialCode: "90001757",
            materialDescription: "6 Courier services",
            materialLongDescription: "6 Courier services",
            parentSpendCatId: 88,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 573,
            level: 3,
            code: "S060030",
            name: "6 Postal services",
            materialCode: "90001756",
            materialDescription: "6 Postal services",
            materialLongDescription: "6 Postal services",
            parentSpendCatId: 88,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 89,
        level: 2,
        code: "S060031",
        name: "6 Office Supplies",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 6,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 574,
            level: 3,
            code: "S060032",
            name: "6 Toner",
            materialCode: "90001601",
            materialDescription: "6 Toner",
            materialLongDescription: "6 Toner",
            parentSpendCatId: 89,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 575,
            level: 3,
            code: "S060033",
            name: "6 Stationary and Business card",
            materialCode: "90001338",
            materialDescription: "6 Stationary and Business card",
            materialLongDescription: "6 Stationary and Business card",
            parentSpendCatId: 89,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 576,
            level: 3,
            code: "S060034",
            name: "6 Printer or copier paper",
            materialCode: "90001337",
            materialDescription: "6 Printer or copier paper",
            materialLongDescription: "6 Printer or copier paper",
            parentSpendCatId: 89,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 577,
            level: 3,
            code: "S060035",
            name: "6 Computer printout paper",
            materialCode: "90001336",
            materialDescription: "6 Computer printout paper",
            materialLongDescription: "6 Computer printout paper",
            parentSpendCatId: 89,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 578,
            level: 3,
            code: "S060036",
            name: "6 Others Office supplies",
            materialCode: "90001604",
            materialDescription: "6 Others Office supplies",
            materialLongDescription: "6 Others Office supplies",
            parentSpendCatId: 89,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 90,
        level: 2,
        code: "S060037",
        name: "6 OA & HR Other Admin/HR Needs",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 6,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 579,
            level: 3,
            code: "S060038",
            name: "6 Art Purchasing",
            materialCode: "90001911",
            materialDescription: "6 Art Purchasing",
            materialLongDescription: "6 Art Purchasing",
            parentSpendCatId: 90,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 580,
            level: 3,
            code: "S060039",
            name: "6 Other Admin/HR Needs",
            materialCode: "90031351",
            materialDescription: "6 Other Admin/HR Needs",
            materialLongDescription: "6 Other Admin/HR Needs",
            parentSpendCatId: 90,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 91,
        level: 2,
        code: "S060040",
        name: "6 Publications",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 6,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 581,
            level: 3,
            code: "S060041",
            name: "6 Electronic publication/music",
            materialCode: "90001681",
            materialDescription: "6 Electronic publication/music",
            materialLongDescription: "6 Electronic publication/music",
            parentSpendCatId: 91,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 582,
            level: 3,
            code: "S060042",
            name: "6 Published Printed publicat.",
            materialCode: "90001677",
            materialDescription: "6 Published Printed publicat.",
            materialLongDescription: "6 Published Printed publicat.",
            parentSpendCatId: 91,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 583,
            level: 3,
            code: "S060043",
            name: "6 Editorial and support serv.",
            materialCode: "90001847",
            materialDescription: "6 Editorial and support serv.",
            materialLongDescription: "6 Editorial and support serv.",
            parentSpendCatId: 91,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 584,
            level: 3,
            code: "S060044",
            name: "6 Press release & deliver serv",
            materialCode: "90001849",
            materialDescription: "6 Press release & deliver serv",
            materialLongDescription: "6 Press release & deliver serv",
            parentSpendCatId: 91,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 92,
        level: 2,
        code: "S060045",
        name: "6 Recruitment",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 6,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 585,
            level: 3,
            code: "S060046",
            name: "6 Recruitment advertise serv.",
            materialCode: "90031352",
            materialDescription: "6 Recruitment advertise serv.",
            materialLongDescription: "6 Recruitment advertise serv.",
            parentSpendCatId: 92,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 586,
            level: 3,
            code: "S060047",
            name: "6 Pre-Employment Screening",
            materialCode: "90031353",
            materialDescription: "6 Pre-Employment Screening",
            materialLongDescription: "6 Pre-Employment Screening",
            parentSpendCatId: 92,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 587,
            level: 3,
            code: "S060048",
            name: "6 Outplacement Services",
            materialCode: "90031354",
            materialDescription: "6 Outplacement Services",
            materialLongDescription: "6 Outplacement Services",
            parentSpendCatId: 92,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 588,
            level: 3,
            code: "S060049",
            name: "6 Recruitment agency services",
            materialCode: "90001786",
            materialDescription: "6 Recruitment agency services",
            materialLongDescription: "6 Recruitment agency services",
            parentSpendCatId: 92,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 93,
        level: 2,
        code: "S060050",
        name: "6 Relocation Services",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 6,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 589,
            level: 3,
            code: "S060051",
            name: "6 Relocation Services Internat",
            materialCode: "90031355",
            materialDescription: "6 Relocation Services Internat",
            materialLongDescription: "6 Relocation Services Internat",
            parentSpendCatId: 93,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 590,
            level: 3,
            code: "S060052",
            name: "6 Relocation Services Local",
            materialCode: "90001784",
            materialDescription: "6 Relocation Services Local",
            materialLongDescription: "6 Relocation Services Local",
            parentSpendCatId: 93,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 94,
        level: 2,
        code: "S060053",
        name: "6 Temporary Labour",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 6,
        parentSpendCatCode: null,
        active: false,
        spendCategories: [],
      },
      {
        id: 95,
        level: 2,
        code: "S060058",
        name: "6 Training & Education",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 6,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 595,
            level: 3,
            code: "S060059",
            name: "6 Internal Training",
            materialCode: "90031359",
            materialDescription: "6 Internal Training",
            materialLongDescription: "6 Internal Training",
            parentSpendCatId: 95,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 596,
            level: 3,
            code: "S060060",
            name: "6 Training materials/communic.",
            materialCode: "90031360",
            materialDescription: "6 Training materials/communic.",
            materialLongDescription: "6 Training materials/communic.",
            parentSpendCatId: 95,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 597,
            level: 3,
            code: "S060061",
            name: "6 Training royalty/licence fee",
            materialCode: "90031361",
            materialDescription: "6 Training royalty/licence fee",
            materialLongDescription: "6 Training royalty/licence fee",
            parentSpendCatId: 95,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 598,
            level: 3,
            code: "S060062",
            name: "6 Education/Training  facilit.",
            materialCode: "90001907",
            materialDescription: "6 Education/Trainingfacilit.",
            materialLongDescription: "6 Education/Training  facilit.",
            parentSpendCatId: 95,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 599,
            level: 3,
            code: "S060063",
            name: "6 External Training",
            materialCode: "90001903",
            materialDescription: "6 External Training",
            materialLongDescription: "6 External Training",
            parentSpendCatId: 95,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 96,
        level: 2,
        code: "S060064",
        name: "6 Union Relations",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 6,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 600,
            level: 3,
            code: "S060065",
            name: "6 Trade unions",
            materialCode: "90001943",
            materialDescription: "6 Trade unions",
            materialLongDescription: "6 Trade unions",
            parentSpendCatId: 96,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 601,
            level: 3,
            code: "S060066",
            name: "6 Labor or union relations",
            materialCode: "90001782",
            materialDescription: "6 Labor or union relations",
            materialLongDescription: "6 Labor or union relations",
            parentSpendCatId: 96,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 97,
        level: 2,
        code: "S060067",
        name: "6 Welfare & Benefits",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 6,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 602,
            level: 3,
            code: "S060068",
            name: "6 Relocation Expenses",
            materialCode: "90031362",
            materialDescription: "6 Relocation Expenses",
            materialLongDescription: "6 Relocation Expenses",
            parentSpendCatId: 97,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 603,
            level: 3,
            code: "S060069",
            name: "6 Community and social service",
            materialCode: "90001929",
            materialDescription: "6 Community and social service",
            materialLongDescription: "6 Community and social service",
            parentSpendCatId: 97,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 604,
            level: 3,
            code: "S060070",
            name: "6 Personal assistance",
            materialCode: "90001915",
            materialDescription: "6 Personal assistance",
            materialLongDescription: "6 Personal assistance",
            parentSpendCatId: 97,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 605,
            level: 3,
            code: "S060071",
            name: "6 Medical research and control",
            materialCode: "90001898",
            materialDescription: "6 Medical research and control",
            materialLongDescription: "6 Medical research and control",
            parentSpendCatId: 97,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 606,
            level: 3,
            code: "S060072",
            name: "6 Health & Welfare Expenses",
            materialCode: "90001892",
            materialDescription: "6 Health & Welfare Expenses",
            materialLongDescription: "6 Health & Welfare Expenses",
            parentSpendCatId: 97,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 607,
            level: 3,
            code: "S060073",
            name: "6 Retirement Admin. Expenses",
            materialCode: "90001890",
            materialDescription: "6 Retirement Admin. Expenses",
            materialLongDescription: "6 Retirement Admin. Expenses",
            parentSpendCatId: 97,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 608,
            level: 3,
            code: "S060074",
            name: "6 Pension Asset Management",
            materialCode: "90001889",
            materialDescription: "6 Pension Asset Management",
            materialLongDescription: "6 Pension Asset Management",
            parentSpendCatId: 97,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 609,
            level: 3,
            code: "S060075",
            name: "6 Assistive/corrective devices",
            materialCode: "90001554",
            materialDescription: "6 Assistive/corrective devices",
            materialLongDescription: "6 Assistive/corrective devices",
            parentSpendCatId: 97,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 1569,
        level: 2,
        code: "S060011",
        name: "6 Corporate Donations & Gifts",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 6,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 557,
            level: 3,
            code: "S060012",
            name: "6 Corporate donations",
            materialCode: "90031348",
            materialDescription: "6 Corporate donations",
            materialLongDescription: "6 Corporate donations",
            parentSpendCatId: 1569,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 558,
            level: 3,
            code: "S060013",
            name: "6 Corporate gifts",
            materialCode: "90031349",
            materialDescription: "6 Corporate gifts",
            materialLongDescription: "6 Corporate gifts",
            parentSpendCatId: 1569,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 559,
            level: 3,
            code: "S060014",
            name: "6 Socio political conditions",
            materialCode: "90001926",
            materialDescription: "6 Socio political conditions",
            materialLongDescription: "6 Socio political conditions",
            parentSpendCatId: 1569,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
    ],
  },
  {
    id: 7,
    level: 1,
    code: "S07",
    name: "7 Other Professional Services",
    materialCode: null,
    materialDescription: null,
    materialLongDescription: null,
    parentSpendCatId: 0,
    parentSpendCatCode: null,
    active: true,
    spendCategories: [
      {
        id: 98,
        level: 2,
        code: "S070001",
        name: "7 Banking & Investment Service",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 7,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 610,
            level: 3,
            code: "S070002",
            name: "7 Credit Reporting Bureau",
            materialCode: "90001891",
            materialDescription: "7 Credit Reporting Bureau",
            materialLongDescription: "7 Credit Reporting Bureau",
            parentSpendCatId: 98,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 611,
            level: 3,
            code: "S070003",
            name: "7 Other Banking/Invest. Serv.",
            materialCode: "90001881",
            materialDescription: "7 Other Banking/Invest. Serv.",
            materialLongDescription: "7 Other Banking/Invest. Serv.",
            parentSpendCatId: 98,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 612,
            level: 3,
            code: "S070004",
            name: "7 Banking institutions Service",
            materialCode: "90001882",
            materialDescription: "7 Banking institutions Service",
            materialLongDescription: "7 Banking institutions Service",
            parentSpendCatId: 98,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 99,
        level: 2,
        code: "S070005",
        name: "7 Corp.Public Relation & Serv.",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 7,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 613,
            level: 3,
            code: "S070006",
            name: "7 Religious organizations",
            materialCode: "90001944",
            materialDescription: "7 Religious organizations",
            materialLongDescription: "7 Religious organizations",
            parentSpendCatId: 99,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 614,
            level: 3,
            code: "S070007",
            name: "7 Clubs and associations",
            materialCode: "90001945",
            materialDescription: "7 Clubs and associations",
            materialLongDescription: "7 Clubs and associations",
            parentSpendCatId: 99,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 615,
            level: 3,
            code: "S070008",
            name: "7 Civic org. & associations",
            materialCode: "90001946",
            materialDescription: "7 Civic org. & associations",
            materialLongDescription: "7 Civic org. & associations",
            parentSpendCatId: 99,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 616,
            level: 3,
            code: "S070009",
            name: "7 Other Public Relation Serv.",
            materialCode: "90001797",
            materialDescription: "7 Other Public Relation Serv.",
            materialLongDescription: "7 Other Public Relation Serv.",
            parentSpendCatId: 99,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 617,
            level: 3,
            code: "S070010",
            name: "7 Political affairs services",
            materialCode: "90001925",
            materialDescription: "7 Political affairs services",
            materialLongDescription: "7 Political affairs services",
            parentSpendCatId: 99,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 618,
            level: 3,
            code: "S070011",
            name: "7 International relations",
            materialCode: "90001927",
            materialDescription: "7 International relations",
            materialLongDescription: "7 International relations",
            parentSpendCatId: 99,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 619,
            level: 3,
            code: "S070012",
            name: "7 Humanitarian aid and relief",
            materialCode: "90001928",
            materialDescription: "7 Humanitarian aid and relief",
            materialLongDescription: "7 Humanitarian aid and relief",
            parentSpendCatId: 99,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 620,
            level: 3,
            code: "S070013",
            name: "7 Public admin./finance serv.",
            materialCode: "90001930",
            materialDescription: "7 Public admin./finance serv.",
            materialLongDescription: "7 Public admin./finance serv.",
            parentSpendCatId: 99,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 100,
        level: 2,
        code: "S070014",
        name: "7 Credit & P-Card Services",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 7,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 621,
            level: 3,
            code: "S070015",
            name: "7 PCI Assessment & Audit Serv.",
            materialCode: "90031363",
            materialDescription: "7 PCI Assessment & Audit Serv.",
            materialLongDescription: "7 PCI Assessment & Audit Serv.",
            parentSpendCatId: 100,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 101,
        level: 2,
        code: "S070018",
        name: "7 Financial Services (Audit)",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 7,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 624,
            level: 3,
            code: "S070019",
            name: "7 Payment Services",
            materialCode: "90031366",
            materialDescription: "7 Payment Services",
            materialLongDescription: "7 Payment Services",
            parentSpendCatId: 101,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 625,
            level: 3,
            code: "S070020",
            name: "7 Auditing Services",
            materialCode: "90031367",
            materialDescription: "7 Auditing Services",
            materialLongDescription: "7 Auditing Services",
            parentSpendCatId: 101,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 626,
            level: 3,
            code: "S070021",
            name: "7 Risk Management Services",
            materialCode: "90031368",
            materialDescription: "7 Risk Management Services",
            materialLongDescription: "7 Risk Management Services",
            parentSpendCatId: 101,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 627,
            level: 3,
            code: "S070022",
            name: "7 3rd Party Collections",
            materialCode: "90031369",
            materialDescription: "7 3rd Party Collections",
            materialLongDescription: "7 3rd Party Collections",
            parentSpendCatId: 101,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 628,
            level: 3,
            code: "S070023",
            name: "7 Lockbox (Retail/Wholesale)",
            materialCode: "90031370",
            materialDescription: "7 Lockbox (Retail/Wholesale)",
            materialLongDescription: "7 Lockbox (Retail/Wholesale)",
            parentSpendCatId: 101,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 629,
            level: 3,
            code: "S070024",
            name: "7 Customer Billing Services",
            materialCode: "90031371",
            materialDescription: "7 Customer Billing Services",
            materialLongDescription: "7 Customer Billing Services",
            parentSpendCatId: 101,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 630,
            level: 3,
            code: "S070025",
            name: "7 Cash vault services",
            materialCode: "90001886",
            materialDescription: "7 Cash vault services",
            materialLongDescription: "7 Cash vault services",
            parentSpendCatId: 101,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 631,
            level: 3,
            code: "S070026",
            name: "7 Online markets services",
            materialCode: "90001884",
            materialDescription: "7 Online markets services",
            materialLongDescription: "7 Online markets services",
            parentSpendCatId: 101,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 632,
            level: 3,
            code: "S070027",
            name: "7 Funds transfer/exchange serv",
            materialCode: "90001883",
            materialDescription: "7 Funds transfer/exchange serv",
            materialLongDescription: "7 Funds transfer/exchange serv",
            parentSpendCatId: 101,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 633,
            level: 3,
            code: "S070028",
            name: "7 Payment and auditing service",
            materialCode: "90001880",
            materialDescription: "7 Payment and auditing service",
            materialLongDescription: "7 Payment and auditing service",
            parentSpendCatId: 101,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 634,
            level: 3,
            code: "S070029",
            name: "7 Other Accounting services",
            materialCode: "90001879",
            materialDescription: "7 Other Accounting services",
            materialLongDescription: "7 Other Accounting services",
            parentSpendCatId: 101,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 635,
            level: 3,
            code: "S070030",
            name: "7 Economic analysis",
            materialCode: "90001825",
            materialDescription: "7 Economic analysis",
            materialLongDescription: "7 Economic analysis",
            parentSpendCatId: 101,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 636,
            level: 3,
            code: "S070031",
            name: "7 Trade policy and regulation",
            materialCode: "90001939",
            materialDescription: "7 Trade policy and regulation",
            materialLongDescription: "7 Trade policy and regulation",
            parentSpendCatId: 101,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 102,
        level: 2,
        code: "S070032",
        name: "7 Insurance & retirement serv.",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 7,
        parentSpendCatCode: null,
        active: false,
        spendCategories: [
          {
            id: 637,
            level: 3,
            code: "S070033",
            name: "7 Life/health/accident insur.",
            materialCode: "90001888",
            materialDescription: "7 Life/health/accident insur.",
            materialLongDescription: "Claims processing, workers' compensation",
            parentSpendCatId: 102,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 638,
            level: 3,
            code: "S070034",
            name: "7 Property/possess. Insur serv",
            materialCode: "90001887",
            materialDescription: "7 Property/possess. Insur serv",
            materialLongDescription: "Property insurance",
            parentSpendCatId: 102,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
        ],
      },
      {
        id: 103,
        level: 2,
        code: "S070035",
        name: "7 Legal Services",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 7,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 639,
            level: 3,
            code: "S070036",
            name: "7 Civic Affairs Fees (notary)",
            materialCode: "90031372",
            materialDescription: "7 Civic Affairs Fees (notary)",
            materialLongDescription: "7 Civic Affairs Fees (notary)",
            parentSpendCatId: 103,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 640,
            level: 3,
            code: "S070037",
            name: "7 Benefits Law",
            materialCode: "90031373",
            materialDescription: "7 Benefits Law",
            materialLongDescription: "7 Benefits Law",
            parentSpendCatId: 103,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 641,
            level: 3,
            code: "S070038",
            name: "7 Employment/Litigation Law",
            materialCode: "90031374",
            materialDescription: "7 Employment/Litigation Law",
            materialLongDescription: "7 Employment/Litigation Law",
            parentSpendCatId: 103,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 642,
            level: 3,
            code: "S070039",
            name: "7 General Litigation",
            materialCode: "90031375",
            materialDescription: "7 General Litigation",
            materialLongDescription: "7 General Litigation",
            parentSpendCatId: 103,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 643,
            level: 3,
            code: "S070040",
            name: "7 Food Law",
            materialCode: "90031376",
            materialDescription: "7 Food Law",
            materialLongDescription: "7 Food Law",
            parentSpendCatId: 103,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 644,
            level: 3,
            code: "S070041",
            name: "7 Intellectual Property",
            materialCode: "90031377",
            materialDescription: "7 Intellectual Property",
            materialLongDescription: "7 Intellectual Property",
            parentSpendCatId: 103,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 645,
            level: 3,
            code: "S070042",
            name: "7 Antitrust Counsel",
            materialCode: "90031378",
            materialDescription: "7 Antitrust Counsel",
            materialLongDescription: "7 Antitrust Counsel",
            parentSpendCatId: 103,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 646,
            level: 3,
            code: "S070043",
            name: "7 Environ., Energy, Safety Law",
            materialCode: "90031379",
            materialDescription: "7 Environ., Energy, Safety Law",
            materialLongDescription: "7 Environ., Energy, Safety Law",
            parentSpendCatId: 103,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 647,
            level: 3,
            code: "S070044",
            name: "7 Other legal Services",
            materialCode: "90001788",
            materialDescription: "7 Other legal Services",
            materialLongDescription: "7 Other legal Services",
            parentSpendCatId: 103,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 648,
            level: 3,
            code: "S070045",
            name: "7 Immigration Law Services",
            materialCode: "90027502",
            materialDescription: "7 Immigration Law Services",
            materialLongDescription: "7 Immigration Law Services",
            parentSpendCatId: 103,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 649,
            level: 3,
            code: "S070046",
            name: "7 Legal Research Services",
            materialCode: "90027500",
            materialDescription: "7 Legal Research Services",
            materialLongDescription: "7 Legal Research Services",
            parentSpendCatId: 103,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 650,
            level: 3,
            code: "S070047",
            name: "7 Patent/Tradem./Copyright Law",
            materialCode: "90027496",
            materialDescription: "7 Patent/Tradem./Copyright Law",
            materialLongDescription: "7 Patent/Tradem./Copyright Law",
            parentSpendCatId: 103,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 651,
            level: 3,
            code: "S070048",
            name: "7 Business Law Services",
            materialCode: "90027495",
            materialDescription: "7 Business Law Services",
            materialLongDescription: "7 Business Law Services",
            parentSpendCatId: 103,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 652,
            level: 3,
            code: "S070049",
            name: "7 Real Estate Law Services",
            materialCode: "90027497",
            materialDescription: "7 Real Estate Law Services",
            materialLongDescription: "7 Real Estate Law Services",
            parentSpendCatId: 103,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 104,
        level: 2,
        code: "S070050",
        name: "7 Management Consulting Serv.",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 7,
        parentSpendCatCode: null,
        active: false,
        spendCategories: [],
      },
      {
        id: 105,
        level: 2,
        code: "S070056",
        name: "7 Real Estate Services",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 7,
        parentSpendCatCode: null,
        active: false,
        spendCategories: [],
      },
      {
        id: 106,
        level: 2,
        code: "S070070",
        name: "7 Scientific Services",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 7,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 671,
            level: 3,
            code: "S070071",
            name: "7 Scientific Serv.",
            materialCode: "90031390",
            materialDescription: "7 Scientific Services",
            materialLongDescription: "7 Scientific Serv.",
            parentSpendCatId: 106,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 672,
            level: 3,
            code: "S070072",
            name: "7 Product testing Services",
            materialCode: "90001831",
            materialDescription: "7 Product testing Services",
            materialLongDescription: "7 Product testing Services",
            parentSpendCatId: 106,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 673,
            level: 3,
            code: "S070073",
            name: "7 Earth science services",
            materialCode: "90001832",
            materialDescription: "7 Earth science services",
            materialLongDescription: "7 Earth science services",
            parentSpendCatId: 106,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 674,
            level: 3,
            code: "S070074",
            name: "7 Disease prev. & control serv",
            materialCode: "90001893",
            materialDescription: "7 Disease prev. & control serv",
            materialLongDescription: "7 Disease prev. & control serv",
            parentSpendCatId: 106,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 675,
            level: 3,
            code: "S070075",
            name: "7 Other Medical Lab services",
            materialCode: "90001895",
            materialDescription: "7 Other Medical Lab services",
            materialLongDescription: "7 Other Medical Lab services",
            parentSpendCatId: 106,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 676,
            level: 3,
            code: "S070076",
            name: "7 Bacteriological Lab services",
            materialCode: "90001896",
            materialDescription: "7 Bacteriological Lab services",
            materialLongDescription: "7 Bacteriological Lab services",
            parentSpendCatId: 106,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 677,
            level: 3,
            code: "S070077",
            name: "7 Biological Lab services",
            materialCode: "90001897",
            materialDescription: "7 Biological Lab services",
            materialLongDescription: "7 Biological Lab services",
            parentSpendCatId: 106,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 678,
            level: 3,
            code: "S070078",
            name: "7 Alternative/holistic medicin",
            materialCode: "90001899",
            materialDescription: "7 Alternative/holistic medicin",
            materialLongDescription: "7 Alternative/holistic medicin",
            parentSpendCatId: 106,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 679,
            level: 3,
            code: "S070079",
            name: "7 Food and nutrition services",
            materialCode: "90001900",
            materialDescription: "7 Food and nutrition services",
            materialLongDescription: "7 Food and nutrition services",
            parentSpendCatId: 106,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 680,
            level: 3,
            code: "S070080",
            name: "7 Food technology services",
            materialCode: "90001901",
            materialDescription: "7 Food technology services",
            materialLongDescription: "7 Food technology services",
            parentSpendCatId: 106,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 681,
            level: 3,
            code: "S070081",
            name: "7 Food hygiene control service",
            materialCode: "90001902",
            materialDescription: "7 Food hygiene control service",
            materialLongDescription: "7 Food hygiene control service",
            parentSpendCatId: 106,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 682,
            level: 3,
            code: "S070096",
            name: "7 Health Care Professionals fees",
            materialCode: "90072307",
            materialDescription: "7 Health Care Professionals Fees",
            materialLongDescription: "7 Health Care Professionals fees",
            parentSpendCatId: 106,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 683,
            level: 3,
            code: "S070097",
            name: "7 Formulation Development",
            materialCode: "90072308",
            materialDescription: "7 Formulation Development",
            materialLongDescription: "7 Formulation Development",
            parentSpendCatId: 106,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 684,
            level: 3,
            code: "S070098",
            name: "7 Licensing",
            materialCode: "90072309",
            materialDescription: "7 Licensing",
            materialLongDescription: "7 Licensing",
            parentSpendCatId: 106,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 685,
            level: 3,
            code: "S070099",
            name: "7 Research Services",
            materialCode: "90072310",
            materialDescription: "7 Research Services",
            materialLongDescription: "7 Research Services",
            parentSpendCatId: 106,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 686,
            level: 3,
            code: "S070100",
            name: "7 Analytical Development",
            materialCode: "90072311",
            materialDescription: "7 Analytical Development",
            materialLongDescription: "7 Analytical Development",
            parentSpendCatId: 106,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 107,
        level: 2,
        code: "S070082",
        name: "7 Travel & Meeting Planning",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 7,
        parentSpendCatCode: null,
        active: false,
        spendCategories: [],
      },
      {
        id: 108,
        level: 2,
        code: "S070101",
        name: "7 Clinical Activities",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 7,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 700,
            level: 3,
            code: "S070102",
            name: "7 Clinical Services - Contract Research Organization",
            materialCode: "90072312",
            materialDescription: "7 Clinical Services - Contract Research",
            materialLongDescription:
              "7 Clinical Services - Contract Research Organization",
            parentSpendCatId: 108,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 701,
            level: 3,
            code: "S070103",
            name: "7 Clinical Supply Management",
            materialCode: "90072313",
            materialDescription: "7 Clinical Supply Management",
            materialLongDescription: "7 Clinical Supply Management",
            parentSpendCatId: 108,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 702,
            level: 3,
            code: "S070104",
            name: "7 Investigator Initiated Trial",
            materialCode: "90072314",
            materialDescription: "7 Investigator Initiated Trial",
            materialLongDescription: "7 Investigator Initiated Trial",
            parentSpendCatId: 108,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 703,
            level: 3,
            code: "S070105",
            name: "7 Investigator Site",
            materialCode: "90072315",
            materialDescription: "7 Investigator Site",
            materialLongDescription: "7 Investigator Site",
            parentSpendCatId: 108,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 704,
            level: 3,
            code: "S070106",
            name: "7 Clinical Activities - Pass through cost",
            materialCode: "90072316",
            materialDescription: "7 Clinical Activities -Pass through cost",
            materialLongDescription:
              "7 Clinical Activities - Pass through cost",
            parentSpendCatId: 108,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 705,
            level: 3,
            code: "S070107",
            name: "7 Phase I Unit & Self-Med - Contract Research Organization",
            materialCode: "90072317",
            materialDescription: "7 Phase I Unit&Self-Med Contract ResOrga",
            materialLongDescription:
              "7 Phase I Unit & Self-Med - Contract Research Organization",
            parentSpendCatId: 108,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 109,
        level: 2,
        code: "S070108",
        name: "7 Preclinical Activities",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 7,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 706,
            level: 3,
            code: "S070109",
            name: "7 Preclinical Services Contract Research Organization",
            materialCode: "90072318",
            materialDescription: "7 Preclinical Services Contract ResOrga",
            materialLongDescription:
              "7 Preclinical Services Contract Research Organization",
            parentSpendCatId: 109,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 1348,
        level: 2,
        code: "S070110",
        name: "7 After Sales Services",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 7,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 1362,
            level: 3,
            code: "S070111",
            name: "7 After Sales Service",
            materialCode: "90057921",
            materialDescription: "7 After Sales Service",
            materialLongDescription: "7 After Sales Service",
            parentSpendCatId: 1348,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 1349,
        level: 2,
        code: "S070112",
        name: "7 Development services for beverage machines",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 7,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 1363,
            level: 3,
            code: "S070113",
            name: "7 Development services for Beverage Machines",
            materialCode: null,
            materialDescription: "7 BEV DevelopService",
            materialLongDescription:
              "7 Development services for Beverage Machines",
            parentSpendCatId: 1349,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1364,
            level: 3,
            code: "S070114",
            name: "7 Soft tooling for beverage machines",
            materialCode: null,
            materialDescription: "7 BEV Soft Tooling",
            materialLongDescription: "7 Soft tooling for beverage machines",
            parentSpendCatId: 1349,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1365,
            level: 3,
            code: "S070115",
            name: "7 Prototyping for beverage machines",
            materialCode: null,
            materialDescription: "7 BEV Prototyping",
            materialLongDescription: "7 Prototyping for beverage machines",
            parentSpendCatId: 1349,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1431,
            level: 3,
            code: " S070113",
            name: "7 BEV DevelopService",
            materialCode: "90069729",
            materialDescription: "7 Development services for Beverage Machines",
            materialLongDescription: "",
            parentSpendCatId: 1349,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1432,
            level: 3,
            code: " S070114",
            name: "7 BEV Soft tooling",
            materialCode: "90069770",
            materialDescription: "7 Soft tooling for beverage machines",
            materialLongDescription: "",
            parentSpendCatId: 1349,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1433,
            level: 3,
            code: " S070115",
            name: "7 BEV Prototyping",
            materialCode: "90069771",
            materialDescription: "7 Prototyping for beverage machines",
            materialLongDescription: "",
            parentSpendCatId: 1349,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 1440,
        level: 2,
        code: "S070119",
        name: "7 Medical/Health insurance",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 7,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 1445,
            level: 3,
            code: "S070120",
            name: "7 Medical / Health insurance",
            materialCode: "90082711",
            materialDescription: "7 Medical/Health Insurance",
            materialLongDescription: "7 Medical / Health insurance",
            parentSpendCatId: 1440,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1446,
            level: 3,
            code: "S070121",
            name: "7 Medical insurance fee or commission",
            materialCode: "90082709",
            materialDescription: "7 Medical Insurance Fee Or Commission",
            materialLongDescription: "7 Medical insurance fee or commission",
            parentSpendCatId: 1440,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 1441,
        level: 2,
        code: "S070122",
        name: "7 Non-Life Insurance",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 7,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 1447,
            level: 3,
            code: "S070123",
            name: "7 Marine / Transport insurance",
            materialCode: "90082689",
            materialDescription: "7 Marine/Transport Insurance",
            materialLongDescription: "7 Marine / Transport insurance",
            parentSpendCatId: 1441,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1448,
            level: 3,
            code: "S070124",
            name: "7 Property / Construction insurance",
            materialCode: "90082710",
            materialDescription: "7 Property/Construction Insurance",
            materialLongDescription: "7 Property / Construction insurance",
            parentSpendCatId: 1441,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1449,
            level: 3,
            code: "S070125",
            name: "7 Liability / Casualty insurance",
            materialCode: "90082707",
            materialDescription: "7 Liability/Casualty Insurance",
            materialLongDescription: "7 Liability / Casualty insurance",
            parentSpendCatId: 1441,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1450,
            level: 3,
            code: "S070126",
            name: "7 Motor / Fleet insurance",
            materialCode: "90082706",
            materialDescription: "7 Motor/Fleet Insurance",
            materialLongDescription: "7 Motor / Fleet insurance",
            parentSpendCatId: 1441,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1451,
            level: 3,
            code: "S070127",
            name: "7 Cyber / Crime insurance",
            materialCode: "90082688",
            materialDescription: "7 Cyber/Crime Insurance",
            materialLongDescription: "7 Cyber / Crime insurance",
            parentSpendCatId: 1441,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1452,
            level: 3,
            code: "S070128",
            name: "7 Directors&Officers liability insurance",
            materialCode: "90082708",
            materialDescription: "7 Directors&Officers Liability Insurance",
            materialLongDescription: "7 Directors&Officers liability insurance",
            parentSpendCatId: 1441,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1453,
            level: 3,
            code: "S070129",
            name: "7 Credit / Bonds insurance",
            materialCode: "90082686",
            materialDescription: "7 Credit/Bonds Insurance",
            materialLongDescription: "7 Credit / Bonds insurance",
            parentSpendCatId: 1441,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1454,
            level: 3,
            code: "S070130",
            name: "7 Accident/Work Comp/ EL insurance",
            materialCode: "90082705",
            materialDescription: "7 Accident/Work Comp/EL Insurance",
            materialLongDescription: "7 Accident/Work Comp/ EL insurance",
            parentSpendCatId: 1441,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1455,
            level: 3,
            code: "S070131",
            name: "7 Business Travel insurance",
            materialCode: "90082687",
            materialDescription: "7 Business Travel Insurance",
            materialLongDescription: "7 Business Travel insurance",
            parentSpendCatId: 1441,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1456,
            level: 3,
            code: "S070132",
            name: "7 Other insurance",
            materialCode: "90082704",
            materialDescription: "7 Other Insurance",
            materialLongDescription: "7 Other insurance",
            parentSpendCatId: 1441,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1457,
            level: 3,
            code: "S070133",
            name: "7 Non-Life insurance fee or commission",
            materialCode: "90082685",
            materialDescription: "7 Non-Life Insurance Fee Or Commission",
            materialLongDescription: "7 Non-Life insurance fee or commission",
            parentSpendCatId: 1441,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 1442,
        level: 2,
        code: "S070134",
        name: "7 Retirement services",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 7,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 1458,
            level: 3,
            code: "S070135",
            name: "7 Retirement services",
            materialCode: "90082703",
            materialDescription: "7 Retirement Services",
            materialLongDescription: "7 Retirement services",
            parentSpendCatId: 1442,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 1470,
        level: 2,
        code: "S070116",
        name: "7 Life Insurance",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 7,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 1471,
            level: 3,
            code: "S070117",
            name: "7 Life, Disability Insurance",
            materialCode: "90082701",
            materialDescription: "7 Life Disability Insurance",
            materialLongDescription: "7 Life, Disability Insurance",
            parentSpendCatId: 1470,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1472,
            level: 3,
            code: "S070118",
            name: "7 Life insurance fee or commission",
            materialCode: "90082702",
            materialDescription: "7 Life Insurance Fee Or Commission",
            materialLongDescription: "7 Life insurance fee or commission",
            parentSpendCatId: 1470,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
    ],
  },
  {
    id: 8,
    level: 1,
    code: "S08",
    name: "8 Contract Manufacturing",
    materialCode: null,
    materialDescription: null,
    materialLongDescription: null,
    parentSpendCatId: 0,
    parentSpendCatCode: null,
    active: true,
    spendCategories: [
      {
        id: 112,
        level: 2,
        code: "S080001",
        name: "8 Co-Manufacturing fee Petcare",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 8,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 711,
            level: 3,
            code: "S080002",
            name: "8 Co-Man Petcare - Wet",
            materialCode: "90031395",
            materialDescription: "8 Co-Man Petcare - Wet",
            materialLongDescription: "8 Co-Man Petcare - Wet",
            parentSpendCatId: 112,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 712,
            level: 3,
            code: "S080003",
            name: "8 Co-Man Petcare - Dry",
            materialCode: "90031396",
            materialDescription: "8 Co-Man Petcare - Dry",
            materialLongDescription: "8 Co-Man Petcare - Dry",
            parentSpendCatId: 112,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 713,
            level: 3,
            code: "S080004",
            name: "8 Co-Man Pet Treat RawHide/Nov",
            materialCode: "90031397",
            materialDescription: "8 Co-Man Pet Treat RawHide/Nov",
            materialLongDescription: "8 Co-Man Pet Treat RawHide/Nov",
            parentSpendCatId: 112,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 113,
        level: 2,
        code: "S080005",
        name: "8 Co-Manufac. fee Petcare-Acc",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 8,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 714,
            level: 3,
            code: "S080006",
            name: "8 Co-Man Pet Acc. Pet toys",
            materialCode: "90001300",
            materialDescription: "8 Co-Man Pet Acc. Pet toys",
            materialLongDescription: "8 Co-Man Pet Acc. Pet toys",
            parentSpendCatId: 113,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 715,
            level: 3,
            code: "S080007",
            name: "8 Co-Man Pet Acc groom prod",
            materialCode: "90001301",
            materialDescription: "8 Co-Man Pet Acc groom prod",
            materialLongDescription: "8 Co-Man Pet Acc groom prod",
            parentSpendCatId: 113,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 716,
            level: 3,
            code: "S080008",
            name: "8 Co-Man Pet Acc. Pet litter",
            materialCode: "90001302",
            materialDescription: "8 Co-Man Pet Acc. Pet litter",
            materialLongDescription: "8 Co-Man Pet Acc. Pet litter",
            parentSpendCatId: 113,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 717,
            level: 3,
            code: "S080009",
            name: "8 Co-Man Pet Acc Pet Other Acc",
            materialCode: "90001303",
            materialDescription: "8 Co-Man Pet Acc Pet Other Acc",
            materialLongDescription: "8 Co-Man Pet Acc Pet Other Acc",
            parentSpendCatId: 113,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 114,
        level: 2,
        code: "S080010",
        name: "8 Co-Manufacturing fee Human",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 8,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 718,
            level: 3,
            code: "S080011",
            name: "8 Co-Man Coffee",
            materialCode: "90031398",
            materialDescription: "8 Co-Man Coffee",
            materialLongDescription: "8 Co-Man Coffee",
            parentSpendCatId: 114,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 719,
            level: 3,
            code: "S080012",
            name: "8 Co-Man Baking",
            materialCode: "90031399",
            materialDescription: "8 Co-Man Baking",
            materialLongDescription: "8 Co-Man Baking",
            parentSpendCatId: 114,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 720,
            level: 3,
            code: "S080013",
            name: "8 Co-Man Confections-Chocolate",
            materialCode: "90031400",
            materialDescription: "8 Co-Man Confections-Chocolate",
            materialLongDescription: "8 Co-Man Confections-Chocolate",
            parentSpendCatId: 114,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 721,
            level: 3,
            code: "S080014",
            name: "8 Co-Man Healthcare",
            materialCode: "90031401",
            materialDescription: "8 Co-Man Healthcare",
            materialLongDescription: "8 Co-Man Healthcare",
            parentSpendCatId: 114,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 722,
            level: 3,
            code: "S080015",
            name: "8 Co-Man Adult Nutrition",
            materialCode: "90031402",
            materialDescription: "8 Co-Man Adult Nutrition",
            materialLongDescription: "8 Co-Man Adult Nutrition",
            parentSpendCatId: 114,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 723,
            level: 3,
            code: "S080016",
            name: "8 Co-Man Infant Nutrition",
            materialCode: "90031403",
            materialDescription: "8 Co-Man Infant Nutrition",
            materialLongDescription: "8 Co-Man Infant Nutrition",
            parentSpendCatId: 114,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 724,
            level: 3,
            code: "S080017",
            name: "8 Co-Man Culinary",
            materialCode: "90031404",
            materialDescription: "8 Co-Man Culinary",
            materialLongDescription: "8 Co-Man Culinary",
            parentSpendCatId: 114,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 725,
            level: 3,
            code: "S080018",
            name: "8 Co-Man Chilled Products",
            materialCode: "90031405",
            materialDescription: "8 Co-Man Chilled Products",
            materialLongDescription: "8 Co-Man Chilled Products",
            parentSpendCatId: 114,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 726,
            level: 3,
            code: "S080019",
            name: "8 Co-Man Cookie/Dough",
            materialCode: "90031406",
            materialDescription: "8 Co-Man Cookie/Dough",
            materialLongDescription: "8 Co-Man Cookie/Dough",
            parentSpendCatId: 114,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 727,
            level: 3,
            code: "S080020",
            name: "8 Co-Man Ice Cream - Dairy",
            materialCode: "90031407",
            materialDescription: "8 Co-Man Ice Cream - Dairy",
            materialLongDescription: "8 Co-Man Ice Cream - Dairy",
            parentSpendCatId: 114,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 728,
            level: 3,
            code: "S080021",
            name: "8 Co-Man Ice Cream-Frozen Ice",
            materialCode: "90031408",
            materialDescription: "8 Co-Man Ice Cream-Frozen Ice",
            materialLongDescription: "8 Co-Man Ice Cream-Frozen Ice",
            parentSpendCatId: 114,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 729,
            level: 3,
            code: "S080022",
            name: "8 Co-Man Industrial Products",
            materialCode: "90031409",
            materialDescription: "8 Co-Man Industrial Products",
            materialLongDescription: "8 Co-Man Industrial Products",
            parentSpendCatId: 114,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 730,
            level: 3,
            code: "S080023",
            name: "8 Co-Man Other SFG/FG",
            materialCode: "90031410",
            materialDescription: "8 Co-Man Other SFG/FG",
            materialLongDescription: "8 Co-Man Other SFG/FG",
            parentSpendCatId: 114,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 731,
            level: 3,
            code: "S080024",
            name: "8 Co-Man Human - Beverage",
            materialCode: "90031411",
            materialDescription: "8 Co-Man Human - Beverage",
            materialLongDescription: "8 Co-Man Human - Beverage",
            parentSpendCatId: 114,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 732,
            level: 3,
            code: "S080025",
            name: "8 Co-Man Human Dairy",
            materialCode: "90001727",
            materialDescription: "8 Co-Man Human Dairy",
            materialLongDescription: "8 Co-Man Human Dairy",
            parentSpendCatId: 114,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 733,
            level: 3,
            code: "S080026",
            name: "8 Co-Man Human Juice",
            materialCode: "90001724",
            materialDescription: "8 Co-Man Human Juice",
            materialLongDescription: "8 Co-Man Human Juice",
            parentSpendCatId: 114,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 734,
            level: 3,
            code: "S080027",
            name: "8 Co-Man Confections-Sugar",
            materialCode: "90001728",
            materialDescription: "8 Co-Man Confections-Sugar",
            materialLongDescription: "8 Co-Man Confections-Sugar",
            parentSpendCatId: 114,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 735,
            level: 3,
            code: "S080028",
            name: "8 Co-Man Human Meat/seafood",
            materialCode: "90001725",
            materialDescription: "8 Co-Man Human Meat/seafood",
            materialLongDescription: "8 Co-Man Human Meat/seafood",
            parentSpendCatId: 114,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 736,
            level: 3,
            code: "S080029",
            name: "8 Co-Man Human Fruits & veget.",
            materialCode: "90001726",
            materialDescription: "8 Co-Man Human Fruits & veget.",
            materialLongDescription: "8 Co-Man Human Fruits & veget.",
            parentSpendCatId: 114,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 115,
        level: 2,
        code: "S080030",
        name: "8 Co-Packing fee Petcare",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 8,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 737,
            level: 3,
            code: "S080031",
            name: "8 Co-Pack Petcare - Wet",
            materialCode: "90031412",
            materialDescription: "8 Co-Pack Petcare - Wet",
            materialLongDescription: "8 Co-Pack Petcare - Wet",
            parentSpendCatId: 115,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 738,
            level: 3,
            code: "S080032",
            name: "8 Co-Pack Petcare Dry",
            materialCode: "90031413",
            materialDescription: "8 Co-Pack Petcare Dry",
            materialLongDescription: "8 Co-Pack Petcare Dry",
            parentSpendCatId: 115,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 739,
            level: 3,
            code: "S080033",
            name: "8 Co-Pack Petc. Treat RawHide",
            materialCode: "90031414",
            materialDescription: "8 Co-Pack Petc. Treat RawHide",
            materialLongDescription: "8 Co-Pack Petc. Treat RawHide",
            parentSpendCatId: 115,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 116,
        level: 2,
        code: "S080034",
        name: "8 Co-Packing fee Petcare-Acc.",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 8,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 740,
            level: 3,
            code: "S080035",
            name: "8 Co-Pack Petc. Acc. Pet toys",
            materialCode: "90031415",
            materialDescription: "8 Co-Pack Petc. Acc. Pet toys",
            materialLongDescription: "8 Co-Pack Petc. Acc. Pet toys",
            parentSpendCatId: 116,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 741,
            level: 3,
            code: "S080036",
            name: "8 Co-Pack Petc. Acc groom prod",
            materialCode: "90031416",
            materialDescription: "8 Co-Pack Petc. Acc groom prod",
            materialLongDescription: "8 Co-Pack Petc. Acc groom prod",
            parentSpendCatId: 116,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 742,
            level: 3,
            code: "S080037",
            name: "8 Co-Pack Petc Acc. Pet litter",
            materialCode: "90031417",
            materialDescription: "8 Co-Pack Petc Acc. Pet litter",
            materialLongDescription: "8 Co-Pack Petc Acc. Pet litter",
            parentSpendCatId: 116,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 743,
            level: 3,
            code: "S080038",
            name: "8 Co-Pack Peti Acc Others",
            materialCode: "90031418",
            materialDescription: "8 Co-Pack Peti Acc Others",
            materialLongDescription: "8 Co-Pack Peti Acc Others",
            parentSpendCatId: 116,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 117,
        level: 2,
        code: "S080039",
        name: "8 Co-Packing fee Human",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 8,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 744,
            level: 3,
            code: "S080040",
            name: "8 Co-Packing Human - Frozen",
            materialCode: "90031419",
            materialDescription: "8 Co-Packing Human - Frozen",
            materialLongDescription: "8 Co-Packing Human - Frozen",
            parentSpendCatId: 117,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 745,
            level: 3,
            code: "S080041",
            name: "8 Co-Packing Human - Ambient",
            materialCode: "90001730",
            materialDescription: "8 Co-Packing Human - Ambient",
            materialLongDescription: "8 Co-Packing Human - Ambient",
            parentSpendCatId: 117,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 118,
        level: 2,
        code: "S080042",
        name: "8 Re-Packing fee Petcare",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 8,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 746,
            level: 3,
            code: "S080043",
            name: "8 Re-Packing Petcare",
            materialCode: "90031420",
            materialDescription: "8 Re-Packing Petcare",
            materialLongDescription: "8 Re-Packing Petcare",
            parentSpendCatId: 118,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 119,
        level: 2,
        code: "S080046",
        name: "8 Ready2sell Petcare",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 8,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 748,
            level: 3,
            code: "S080047",
            name: "8 Ready to sell Petcare",
            materialCode: "",
            materialDescription: "",
            materialLongDescription: "",
            parentSpendCatId: 119,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
        ],
      },
      {
        id: 120,
        level: 2,
        code: "S080048",
        name: "8 Ready2sell Petcare-Acc.",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 8,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 749,
            level: 3,
            code: "S080049",
            name: "8 Ready2sell Pet Acc Pet toys",
            materialCode: "",
            materialDescription: "",
            materialLongDescription: "",
            parentSpendCatId: 120,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 750,
            level: 3,
            code: "S080050",
            name: "8 Ready2sell Pet Acc groomprod",
            materialCode: "",
            materialDescription: "",
            materialLongDescription: "",
            parentSpendCatId: 120,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 751,
            level: 3,
            code: "S080051",
            name: "8 Ready2sell Pet Acc litter",
            materialCode: "",
            materialDescription: "",
            materialLongDescription: "",
            parentSpendCatId: 120,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 752,
            level: 3,
            code: "S080052",
            name: "8 Ready2sell Pet Acc Others",
            materialCode: "",
            materialDescription: "",
            materialLongDescription: "",
            parentSpendCatId: 120,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
        ],
      },
      {
        id: 121,
        level: 2,
        code: "S080053",
        name: "8 Ready2sell Human",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 8,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 753,
            level: 3,
            code: "S080054",
            name: "8 Ready2sell Coffee",
            materialCode: "",
            materialDescription: "",
            materialLongDescription: "",
            parentSpendCatId: 121,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 754,
            level: 3,
            code: "S080055",
            name: "8 Ready2sell Baking",
            materialCode: "",
            materialDescription: "",
            materialLongDescription: "",
            parentSpendCatId: 121,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 755,
            level: 3,
            code: "S080056",
            name: "8 Ready2sell Confection-Choc",
            materialCode: "",
            materialDescription: "",
            materialLongDescription: "",
            parentSpendCatId: 121,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 756,
            level: 3,
            code: "S080057",
            name: "8 Ready2sell Novelties",
            materialCode: "",
            materialDescription: "",
            materialLongDescription: "",
            parentSpendCatId: 121,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 757,
            level: 3,
            code: "S080058",
            name: "8 Ready2sell Healthcare",
            materialCode: "",
            materialDescription: "",
            materialLongDescription: "",
            parentSpendCatId: 121,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 758,
            level: 3,
            code: "S080059",
            name: "8 Ready2sell Adult Nutrit.",
            materialCode: "",
            materialDescription: "",
            materialLongDescription: "",
            parentSpendCatId: 121,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 759,
            level: 3,
            code: "S080060",
            name: "8 Ready2sell Infant Nutrit.",
            materialCode: "",
            materialDescription: "",
            materialLongDescription: "",
            parentSpendCatId: 121,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 760,
            level: 3,
            code: "S080061",
            name: "8 Ready2sell Culinary",
            materialCode: "",
            materialDescription: "",
            materialLongDescription: "",
            parentSpendCatId: 121,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 761,
            level: 3,
            code: "S080062",
            name: "8 Ready2sell Chilled Prod.",
            materialCode: "",
            materialDescription: "",
            materialLongDescription: "",
            parentSpendCatId: 121,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 762,
            level: 3,
            code: "S080063",
            name: "8 Ready2sell Cookie/Dough",
            materialCode: "",
            materialDescription: "",
            materialLongDescription: "",
            parentSpendCatId: 121,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 763,
            level: 3,
            code: "S080064",
            name: "8 Ready2sell Frozen Food",
            materialCode: "",
            materialDescription: "",
            materialLongDescription: "",
            parentSpendCatId: 121,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 764,
            level: 3,
            code: "S080065",
            name: "8 Ready2sell Ice Cream Dairy",
            materialCode: "",
            materialDescription: "",
            materialLongDescription: "",
            parentSpendCatId: 121,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 765,
            level: 3,
            code: "S080066",
            name: "8 Ready2sell Ice Cream, Ice",
            materialCode: "",
            materialDescription: "",
            materialLongDescription: "",
            parentSpendCatId: 121,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 766,
            level: 3,
            code: "S080067",
            name: "8 Ready2sell Indust. Prod.",
            materialCode: "",
            materialDescription: "",
            materialLongDescription: "",
            parentSpendCatId: 121,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 767,
            level: 3,
            code: "S080068",
            name: "8 Ready2sell Other SFG/FG",
            materialCode: "",
            materialDescription: "",
            materialLongDescription: "",
            parentSpendCatId: 121,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 768,
            level: 3,
            code: "S080069",
            name: "8 Ready2sell Food Serv Bev.",
            materialCode: "",
            materialDescription: "",
            materialLongDescription: "",
            parentSpendCatId: 121,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 769,
            level: 3,
            code: "S080070",
            name: "8 Ready2sell Other Beverage",
            materialCode: "",
            materialDescription: "",
            materialLongDescription: "",
            parentSpendCatId: 121,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 770,
            level: 3,
            code: "S080071",
            name: "8 Ready2sell Dairy",
            materialCode: "",
            materialDescription: "",
            materialLongDescription: "",
            parentSpendCatId: 121,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 771,
            level: 3,
            code: "S080072",
            name: "8 Ready2sell Juice",
            materialCode: "",
            materialDescription: "",
            materialLongDescription: "",
            parentSpendCatId: 121,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 772,
            level: 3,
            code: "S080073",
            name: "8 Ready2sell Confection Sug",
            materialCode: "",
            materialDescription: "",
            materialLongDescription: "",
            parentSpendCatId: 121,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 773,
            level: 3,
            code: "S080074",
            name: "8 Ready2sell Meat/fish ambiant",
            materialCode: "",
            materialDescription: "",
            materialLongDescription: "",
            parentSpendCatId: 121,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 774,
            level: 3,
            code: "S080075",
            name: "8 Ready2sell Fruits & veget",
            materialCode: "",
            materialDescription: "",
            materialLongDescription: "",
            parentSpendCatId: 121,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
        ],
      },
      {
        id: 1570,
        level: 2,
        code: "S080044",
        name: "8 Re-Packing fee Human",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 8,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 747,
            level: 3,
            code: "S080045",
            name: "8 Re-Packing Human",
            materialCode: "90031421",
            materialDescription: "8 Re-Packing Human",
            materialLongDescription: "8 Re-Packing Human",
            parentSpendCatId: 1570,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
    ],
  },
  {
    id: 9,
    level: 1,
    code: "S09",
    name: "9 Logistics",
    materialCode: null,
    materialDescription: null,
    materialLongDescription: null,
    parentSpendCatId: 0,
    parentSpendCatCode: null,
    active: true,
    spendCategories: [
      {
        id: 122,
        level: 2,
        code: "S090001",
        name: "9 Air Frght not fast courier",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 9,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 775,
            level: 3,
            code: "S090002",
            name: "9 Air Freight not fast courier",
            materialCode: "90001748",
            materialDescription: "9 Air Freight not fast courier",
            materialLongDescription: "9 Air Freight not fast courier",
            parentSpendCatId: 122,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 123,
        level: 2,
        code: "S090003",
        name: "9 Freight Forwarding",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 9,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 776,
            level: 3,
            code: "S090004",
            name: "9 Freight Forwarding services",
            materialCode: "90001771",
            materialDescription: "9 Freight Forwarding Services",
            materialLongDescription: "9 Freight Forwarding services",
            parentSpendCatId: 123,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 777,
            level: 3,
            code: "S090005",
            name: "9 Customs brokerage services",
            materialCode: "90001772",
            materialDescription: "9 Customs brokerage services",
            materialLongDescription: "9 Customs brokerage services",
            parentSpendCatId: 123,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 778,
            level: 3,
            code: "S090006",
            name: "9 Inspection Services",
            materialCode: "90001773",
            materialDescription: "9 Inspection Services",
            materialLongDescription: "9 Inspection Services",
            parentSpendCatId: 123,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 779,
            level: 3,
            code: "S090007",
            name: "9 Transp. indus. support serv.",
            materialCode: "90001774",
            materialDescription: "9 Transp. indus. support serv.",
            materialLongDescription: "9 Transp. indus. support serv.",
            parentSpendCatId: 123,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 124,
        level: 2,
        code: "S090008",
        name: "9 Logistics serv.",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 9,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 780,
            level: 3,
            code: "S090009",
            name: "9 Logistics services",
            materialCode: "90031422",
            materialDescription: "9 Logistics services",
            materialLongDescription: "9 Logistics services",
            parentSpendCatId: 124,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 803,
            level: 3,
            code: "S090040",
            name: "9 Postal distribution",
            materialCode: "90057891",
            materialDescription: "9 Postal Distribution",
            materialLongDescription: "9 Postal distribution",
            parentSpendCatId: 124,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 125,
        level: 2,
        code: "S090010",
        name: "9 Hired pallets / lids",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 9,
        parentSpendCatCode: null,
        active: false,
        spendCategories: [
          {
            id: 781,
            level: 3,
            code: "S090011",
            name: "9 Pallets rental",
            materialCode: "90027503",
            materialDescription: "9 Pallets rental",
            materialLongDescription:
              "SERVICES invloved with the hire of re-usable pallets and containers used in the Supply Chain.  This includes Pallets, Crates, Dollies and trays, which could be made of wood, plastic, metal or cardboard.  ",
            parentSpendCatId: 125,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
        ],
      },
      {
        id: 126,
        level: 2,
        code: "S090012",
        name: "9 Inland water frght",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 9,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 782,
            level: 3,
            code: "S090013",
            name: "9 Inland water freight",
            materialCode: "90001752",
            materialDescription: "9 Inland water freight",
            materialLongDescription: "9 Inland water freight",
            parentSpendCatId: 126,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 127,
        level: 2,
        code: "S090014",
        name: "9 Intermodal Freight road/rail",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 9,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 783,
            level: 3,
            code: "S090015",
            name: "9 Intmod Full Load Powd Tank",
            materialCode: "90031423",
            materialDescription: "9 Intermodal Full Load Powder Tanker",
            materialLongDescription: "9 Intmod Full Load Powd Tank",
            parentSpendCatId: 127,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 784,
            level: 3,
            code: "S090016",
            name: "9 Intmod Full Load Liq Tank",
            materialCode: "90031424",
            materialDescription: "9 Intermodal Full Load Liquid Tanker",
            materialLongDescription: "9 Intmod Full Load Liq Tank",
            parentSpendCatId: 127,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 785,
            level: 3,
            code: "S090017",
            name: "9 Intmod Full/Part Tmp ctrl Ld",
            materialCode: "90031425",
            materialDescription: "9 Intermod Full/Part Temp control Loads",
            materialLongDescription: "9 Intmod Full/Part Tmp ctrl Ld",
            parentSpendCatId: 127,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 786,
            level: 3,
            code: "S090018",
            name: "9 Intmod Ful/Part Dry frght ld",
            materialCode: "90031426",
            materialDescription: "9 Intermodal Full/Part Dry frieght loads",
            materialLongDescription: "9 Intmod Ful/Part Dry frght ld",
            parentSpendCatId: 127,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 787,
            level: 3,
            code: "S090019",
            name: "9 Intermodal Groupage",
            materialCode: "90031427",
            materialDescription: "9 Intermodal Groupage",
            materialLongDescription: "9 Intermodal Groupage",
            parentSpendCatId: 127,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 788,
            level: 3,
            code: "S090020",
            name: "9 Intermodal cargo transport",
            materialCode: "90001755",
            materialDescription: "9 Intermodal cargo transport",
            materialLongDescription: "9 Intermodal cargo transport",
            parentSpendCatId: 127,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 128,
        level: 2,
        code: "S090021",
        name: "9 Ocean Frght deep/short sea",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 9,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 789,
            level: 3,
            code: "S090022",
            name: "9 Ocean Freight deep/short sea",
            materialCode: "90031428",
            materialDescription: "9 Ocean Freight deep/short sea",
            materialLongDescription: "9 Ocean Freight deep/short sea",
            parentSpendCatId: 128,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 129,
        level: 2,
        code: "S090023",
        name: "9 Rail Freight",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 9,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 790,
            level: 3,
            code: "S090024",
            name: "9 Rail Full Load Powder Tanker",
            materialCode: "90031429",
            materialDescription: "9 Rail Full Load Powder Tanker",
            materialLongDescription: "9 Rail Full Load Powder Tanker",
            parentSpendCatId: 129,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 791,
            level: 3,
            code: "S090026",
            name: "9 Rail Full/Part Temp ctrl Ld",
            materialCode: "90031431",
            materialDescription: "9 Rail Full/Part Temp control Loads",
            materialLongDescription: "9 Rail Full/Part Temp ctrl Ld",
            parentSpendCatId: 129,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 792,
            level: 3,
            code: "S090027",
            name: "9 Rail Full/Part Dry frght ld",
            materialCode: "90031432",
            materialDescription: "9 Rail Full/Part Dry frieght loads",
            materialLongDescription: "9 Rail Full/Part Dry frght ld",
            parentSpendCatId: 129,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 793,
            level: 3,
            code: "S090028",
            name: "9 Rail Groupage",
            materialCode: "90031433",
            materialDescription: "9 Rail Groupage",
            materialLongDescription: "9 Rail Groupage",
            parentSpendCatId: 129,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 794,
            level: 3,
            code: "S090029",
            name: "9 Rail cargo transport",
            materialCode: "90001749",
            materialDescription: "9 Rail cargo transport",
            materialLongDescription: "9 Rail cargo transport",
            parentSpendCatId: 129,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1571,
            level: 3,
            code: "S090025",
            name: "9 Rail Full Load Liquid Tanker",
            materialCode: "90031430",
            materialDescription: "9 Rail Full Load Liquid Tanker",
            materialLongDescription: "9 Rail Full Load Liquid Tanker",
            parentSpendCatId: 129,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 130,
        level: 2,
        code: "S090030",
        name: "9 Road Frght",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 9,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 795,
            level: 3,
            code: "S090031",
            name: "9 Road Full Load Powder Tanker",
            materialCode: "90031434",
            materialDescription: "9 Road Full Load Powder Tanker",
            materialLongDescription: "9 Road Full Load Powder Tanker",
            parentSpendCatId: 130,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 796,
            level: 3,
            code: "S090032",
            name: "9 Road Full Load Liquid Tanker",
            materialCode: "90031435",
            materialDescription: "9 Road Full Load Liquid Tanker",
            materialLongDescription: "9 Road Full Load Liquid Tanker",
            parentSpendCatId: 130,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 797,
            level: 3,
            code: "S090033",
            name: "9 Road Full/Part Temp ctrl Ld",
            materialCode: "90031436",
            materialDescription: "9 Road Full/Part Temp control Loads",
            materialLongDescription: "9 Road Full/Part Temp ctrl Ld",
            parentSpendCatId: 130,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 798,
            level: 3,
            code: "S090034",
            name: "9 Road Full/Part Dry frght ld",
            materialCode: "90031437",
            materialDescription: "9 Road Full/Part Dry frieght loads",
            materialLongDescription: "9 Road Full/Part Dry frght ld",
            parentSpendCatId: 130,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 799,
            level: 3,
            code: "S090035",
            name: "9 Road Groupage",
            materialCode: "90031438",
            materialDescription: "9 Road Groupage",
            materialLongDescription: "9 Road Groupage",
            parentSpendCatId: 130,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 800,
            level: 3,
            code: "S090036",
            name: "9 Road Freight",
            materialCode: "90001754",
            materialDescription: "9 Road Freight",
            materialLongDescription: "9 Road Freight",
            parentSpendCatId: 130,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 131,
        level: 2,
        code: "S090037",
        name: "9 Warehouse services",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 9,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 801,
            level: 3,
            code: "S090038",
            name: "9 Storage",
            materialCode: "90001769",
            materialDescription: "9 Storage",
            materialLongDescription: "9 Storage",
            parentSpendCatId: 131,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 802,
            level: 3,
            code: "S090039",
            name: "9 Mat. handl. & load. Services",
            materialCode: "90001768",
            materialDescription: "9 Mat. handl. & load. Services",
            materialLongDescription: "9 Mat. handl. & load. Services",
            parentSpendCatId: 131,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
    ],
  },
  {
    id: 11,
    level: 1,
    code: "S11",
    name: "11 Tax & Duties",
    materialCode: null,
    materialDescription: null,
    materialLongDescription: null,
    parentSpendCatId: 0,
    parentSpendCatCode: null,
    active: true,
    spendCategories: [
      {
        id: 136,
        level: 2,
        code: "S110001",
        name: "11 T&D Tax & Duties",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 11,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 827,
            level: 3,
            code: "S110002",
            name: "11 Other Taxation",
            materialCode: "90001931",
            materialDescription: "11 Other Taxation",
            materialLongDescription: "11 Other Taxation",
            parentSpendCatId: 136,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 828,
            level: 3,
            code: "S110003",
            name: "11 Income tax",
            materialCode: "90001932",
            materialDescription: "11 Income tax",
            materialLongDescription: "11 Income tax",
            parentSpendCatId: 136,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 829,
            level: 3,
            code: "S110004",
            name: "11 Land tax",
            materialCode: "90001934",
            materialDescription: "11 Land tax",
            materialLongDescription: "11 Land tax",
            parentSpendCatId: 136,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 830,
            level: 3,
            code: "S110005",
            name: "11 Value added tax (VAT)",
            materialCode: "90001935",
            materialDescription: "11 Value added tax (VAT)",
            materialLongDescription: "11 Value added tax (VAT)",
            parentSpendCatId: 136,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 831,
            level: 3,
            code: "S110006",
            name: "11 Payroll tax",
            materialCode: "90001936",
            materialDescription: "11 Payroll tax",
            materialLongDescription: "11 Payroll tax",
            parentSpendCatId: 136,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 832,
            level: 3,
            code: "S110007",
            name: "11 Sales tax",
            materialCode: "90001937",
            materialDescription: "11 Sales tax",
            materialLongDescription: "11 Sales tax",
            parentSpendCatId: 136,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 833,
            level: 3,
            code: "S110008",
            name: "11 Social security tax",
            materialCode: "90001938",
            materialDescription: "11 Social security tax",
            materialLongDescription: "11 Social security tax",
            parentSpendCatId: 136,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 834,
            level: 3,
            code: "S110009",
            name: "11 Property tax",
            materialCode: "90001933",
            materialDescription: "11 Property tax",
            materialLongDescription: "11 Property tax",
            parentSpendCatId: 136,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
    ],
  },
  {
    id: 12,
    level: 1,
    code: "S20",
    name: "20 Agricultural Supplies & Services",
    materialCode: null,
    materialDescription: null,
    materialLongDescription: null,
    parentSpendCatId: 0,
    parentSpendCatCode: null,
    active: true,
    spendCategories: [
      {
        id: 137,
        level: 2,
        code: "S200001",
        name: "20 Agricultural Equipment",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 12,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 835,
            level: 3,
            code: "S200002",
            name: "20 Agricultural Equipment",
            materialCode: "90072401",
            materialDescription: "20 Agricultural Equ.",
            materialLongDescription: "20 Agricultural Equipment",
            parentSpendCatId: 137,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 138,
        level: 2,
        code: "S200003",
        name: "20 Animals, Feed & Healthcare",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 12,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 836,
            level: 3,
            code: "S200004",
            name: "20 Animals, Feed & Healthcare",
            materialCode: "90072479",
            materialDescription: "20 Animals & Feed",
            materialLongDescription: "20 Animals, Feed & Healthcare",
            parentSpendCatId: 138,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 139,
        level: 2,
        code: "S200005",
        name: "20 Farming Supplies",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 12,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 837,
            level: 3,
            code: "S200006",
            name: "20 Farming Supplies",
            materialCode: "90072478",
            materialDescription: "20 Farming Supplies",
            materialLongDescription: "20 Farming Supplies",
            parentSpendCatId: 139,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
    ],
  },
  {
    id: 13,
    level: 1,
    code: "S21",
    name: "21 Filling & Packing Equipment",
    materialCode: null,
    materialDescription: null,
    materialLongDescription: null,
    parentSpendCatId: 0,
    parentSpendCatCode: null,
    active: true,
    spendCategories: [
      {
        id: 140,
        level: 2,
        code: "S210001",
        name: "21 Aseptic Carton Filling Equipment",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 13,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 838,
            level: 3,
            code: "S210002",
            name: "21 Aseptic Carton Filling Equipment/Line",
            materialCode: "90072437",
            materialDescription: "21 Aseptic Carton Filling Equipment/Line",
            materialLongDescription: "21 Aseptic Carton Filling Equipment/Line",
            parentSpendCatId: 140,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 839,
            level: 3,
            code: "S210003",
            name: "21 Aseptic Carton Filling Leasing Fee",
            materialCode: "90072438",
            materialDescription: "21 Aseptic Carton Filling Leasing Fee",
            materialLongDescription: "21 Aseptic Carton Filling Leasing Fee",
            parentSpendCatId: 140,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 141,
        level: 2,
        code: "S210004",
        name: "21 Blowing & Injection",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 13,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 840,
            level: 3,
            code: "S210005",
            name: "21 Blowing & Injection - Blow Molders",
            materialCode: "90072439",
            materialDescription: "21 Blowing & Injection - Blow Molders",
            materialLongDescription: "21 Blowing & Injection - Blow Molders",
            parentSpendCatId: 141,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 841,
            level: 3,
            code: "S210006",
            name: "21 Blowing & Injection - Mould Bottle",
            materialCode: "90072440",
            materialDescription: "21 Blowing & Injection - Mould Bottle",
            materialLongDescription: "21 Blowing & Injection - Mould Bottle",
            parentSpendCatId: 141,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 842,
            level: 3,
            code: "S210007",
            name: "21 Blowing & Injection - Mould Preform",
            materialCode: "90072441",
            materialDescription: "21 Blowing & Injection - Mould Preform",
            materialLongDescription: "21 Blowing & Injection - Mould Preform",
            parentSpendCatId: 141,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 843,
            level: 3,
            code: "S210008",
            name: "21 Preform Injection",
            materialCode: "90072442",
            materialDescription: "21 Preform Injection",
            materialLongDescription: "21 Preform Injection",
            parentSpendCatId: 141,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 142,
        level: 2,
        code: "S210009",
        name: "21 Canmaking",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 13,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 844,
            level: 3,
            code: "S210010",
            name: "21 Canmaking Equipment",
            materialCode: "90072443",
            materialDescription: "21 Canmaking Equ.",
            materialLongDescription: "21 Canmaking Equipment",
            parentSpendCatId: 142,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 143,
        level: 2,
        code: "S210011",
        name: "21 Cappers & Seamers",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 13,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 845,
            level: 3,
            code: "S210012",
            name: "21 Alum Can Seamer",
            materialCode: "90072444",
            materialDescription: "21 Alum Can Seamer",
            materialLongDescription: "21 Alum Can Seamer",
            parentSpendCatId: 143,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 846,
            level: 3,
            code: "S210013",
            name: "21 Cappers",
            materialCode: "90072445",
            materialDescription: "21 Cappers",
            materialLongDescription: "21 Cappers",
            parentSpendCatId: 143,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 847,
            level: 3,
            code: "S210014",
            name: "21 Metal Can Seamer",
            materialCode: "90072446",
            materialDescription: "21 Metal Can Seamer",
            materialLongDescription: "21 Metal Can Seamer",
            parentSpendCatId: 143,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 144,
        level: 2,
        code: "S210015",
        name: "21 Cartoner",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 13,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 848,
            level: 3,
            code: "S210016",
            name: "21 Carton Sleever",
            materialCode: "90072447",
            materialDescription: "21 Carton Sleever",
            materialLongDescription: "21 Carton Sleever",
            parentSpendCatId: 144,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 849,
            level: 3,
            code: "S210017",
            name: "21 Cartoner Side Loader - Horizontal",
            materialCode: "90072448",
            materialDescription: "21 Cartoner Side Loader - Horizontal",
            materialLongDescription: "21 Cartoner Side Loader - Horizontal",
            parentSpendCatId: 144,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 850,
            level: 3,
            code: "S210018",
            name: "21 Cartoner Side Loader - Vertical",
            materialCode: "90072449",
            materialDescription: "21 Cartoner Side Loader - Vertical",
            materialLongDescription: "21 Cartoner Side Loader - Vertical",
            parentSpendCatId: 144,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 851,
            level: 3,
            code: "S210019",
            name: "21 Cartoner Top Loader",
            materialCode: "90072450",
            materialDescription: "21 Cartoner Top Loader",
            materialLongDescription: "21 Cartoner Top Loader",
            parentSpendCatId: 144,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 145,
        level: 2,
        code: "S210020",
        name: "21 Case Packer",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 13,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 852,
            level: 3,
            code: "S210021",
            name: "21 Case Packer - Regular Standard Case",
            materialCode: "90072451",
            materialDescription: "21 RSC",
            materialLongDescription: "21 Case Packer - Regular Standard Case",
            parentSpendCatId: 145,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 853,
            level: 3,
            code: "S210022",
            name: "21 Case Packer - Stand-Alone Tray",
            materialCode: "90072452",
            materialDescription: "21 Case Packer - Stand-Alone Tray",
            materialLongDescription: "21 Case Packer - Stand-Alone Tray",
            parentSpendCatId: 145,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 854,
            level: 3,
            code: "S210023",
            name: "21 Case Packer - Tray & Hood",
            materialCode: "90072453",
            materialDescription: "21 Case Packer - Tray & Hood",
            materialLongDescription: "21 Case Packer - Tray & Hood",
            parentSpendCatId: 145,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 855,
            level: 3,
            code: "S210024",
            name: "21 Tray/Case Erector",
            materialCode: "90072454",
            materialDescription: "21 Tray/Case Erector",
            materialLongDescription: "21 Tray/Case Erector",
            parentSpendCatId: 145,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 856,
            level: 3,
            code: "S210025",
            name: "21 Case Packer - Wrap-Around",
            materialCode: "90072455",
            materialDescription: "21 Case Packer - Wrap-Around",
            materialLongDescription: "21 Case Packer - Wrap-Around",
            parentSpendCatId: 145,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1572,
            level: 3,
            code: "S210079",
            name: "21 Tray/Case Sealer",
            materialCode: "90102412",
            materialDescription: "21 Tray/Case Sealer",
            materialLongDescription: "21 Tray/Case Sealer",
            parentSpendCatId: 145,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 146,
        level: 2,
        code: "S210026",
        name: "21 Conveyors",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 13,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 857,
            level: 3,
            code: "S210027",
            name: "21 Conveyors - Accumulation",
            materialCode: "90072456",
            materialDescription: "21 Conveyors - Accumulation",
            materialLongDescription: "21 Conveyors - Accumulation",
            parentSpendCatId: 146,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 858,
            level: 3,
            code: "S210028",
            name: "21 Conveyors - Bucket",
            materialCode: "90072457",
            materialDescription: "21 Conveyors - Bucket",
            materialLongDescription: "21 Conveyors - Bucket",
            parentSpendCatId: 146,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 859,
            level: 3,
            code: "S210029",
            name: "21 Conveyors - Flat Belt",
            materialCode: "90072458",
            materialDescription: "21 Conveyors - Flat Belt",
            materialLongDescription: "21 Conveyors - Flat Belt",
            parentSpendCatId: 146,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 860,
            level: 3,
            code: "S210030",
            name: "21 Conveyors - Plastic Chain",
            materialCode: "90072459",
            materialDescription: "21 Conveyors - Plastic Chain",
            materialLongDescription: "21 Conveyors - Plastic Chain",
            parentSpendCatId: 146,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 861,
            level: 3,
            code: "S210031",
            name: "21 Conveyors - Powered Roller",
            materialCode: "90072460",
            materialDescription: "21 Conveyors - Powered Roller",
            materialLongDescription: "21 Conveyors - Powered Roller",
            parentSpendCatId: 146,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 862,
            level: 3,
            code: "S210032",
            name: "21 Conveyors - Sortation",
            materialCode: "90072461",
            materialDescription: "21 Conveyors - Sortation",
            materialLongDescription: "21 Conveyors - Sortation",
            parentSpendCatId: 146,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 863,
            level: 3,
            code: "S210033",
            name: "21 Conveyors - Vertical",
            materialCode: "90072462",
            materialDescription: "21 Conveyors - Vertical",
            materialLongDescription: "21 Conveyors - Vertical",
            parentSpendCatId: 146,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 864,
            level: 3,
            code: "S210034",
            name: "21 Conveyors - Vibrating",
            materialCode: "90072463",
            materialDescription: "21 Conveyors - Vibrating",
            materialLongDescription: "21 Conveyors - Vibrating",
            parentSpendCatId: 146,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 147,
        level: 2,
        code: "S210035",
        name: "21 De-palletizer",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 13,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 865,
            level: 3,
            code: "S210036",
            name: "21 De-palletizer",
            materialCode: "90072464",
            materialDescription: "21 De-palletizer",
            materialLongDescription: "21 De-palletizer",
            parentSpendCatId: 147,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 148,
        level: 2,
        code: "S210037",
        name: "21 Flexible Packs",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 13,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 866,
            level: 3,
            code: "S210038",
            name: "21 Flexible Packs - Flow Wrapper",
            materialCode: "90072465",
            materialDescription: "21 Flexible Packs - Flow Wrapper",
            materialLongDescription: "21 Flexible Packs - Flow Wrapper",
            parentSpendCatId: 148,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 867,
            level: 3,
            code: "S210039",
            name: "21 Flexible Packs - HFFS Dry",
            materialCode: "90072466",
            materialDescription: "21 Flexible Packs - HFFS Dry",
            materialLongDescription: "21 Flexible Packs - HFFS Dry",
            parentSpendCatId: 148,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 868,
            level: 3,
            code: "S210040",
            name: "21 Flexible Packs - HFFS Liquid",
            materialCode: "90072467",
            materialDescription: "21 Flexible Packs - HFFS Liquid",
            materialLongDescription: "21 Flexible Packs - HFFS Liquid",
            parentSpendCatId: 148,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 869,
            level: 3,
            code: "S210041",
            name: "21 Flexible Packs - Single Serve",
            materialCode: "90072468",
            materialDescription: "21 Flexible Packs - Single Serve",
            materialLongDescription: "21 Flexible Packs - Single Serve",
            parentSpendCatId: 148,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 870,
            level: 3,
            code: "S210042",
            name: "21 Flexible Packs - VFFS Dry",
            materialCode: "90072469",
            materialDescription: "21 Flexible Packs - VFFS Dry",
            materialLongDescription: "21 Flexible Packs - VFFS Dry",
            parentSpendCatId: 148,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 871,
            level: 3,
            code: "S210043",
            name: "21 Flexible Packs - VFFS Liquid",
            materialCode: "90072470",
            materialDescription: "21 Flexible Packs - VFFS Liquid",
            materialLongDescription: "21 Flexible Packs - VFFS Liquid",
            parentSpendCatId: 148,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1552,
            level: 3,
            code: "S210081",
            name: "21 Flexible Packs - Multi-head Weigher",
            materialCode: "90102414",
            materialDescription: "21 Flexible Packs - Multi-head Weigher",
            materialLongDescription: "21 Flexible Packs - Multi-head Weigher",
            parentSpendCatId: 148,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1553,
            level: 3,
            code: "S210082",
            name: "21 Flexible Packs - Dosing Unit",
            materialCode: "90102415",
            materialDescription: "21 Flexible Packs - Dosing Unit",
            materialLongDescription: "21 Flexible Packs - Dosing Unit",
            parentSpendCatId: 148,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1554,
            level: 3,
            code: "S210083",
            name: "21 Flexible Packs - Auto-splicer",
            materialCode: "90102416",
            materialDescription: "21 Flexible Packs - Auto-splicer",
            materialLongDescription: "21 Flexible Packs - Auto-splicer",
            parentSpendCatId: 148,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1555,
            level: 3,
            code: "S210084",
            name: "21 Flexible Packs - Sealing Unit",
            materialCode: "90102417",
            materialDescription: "21 Flexible Packs - Sealing Unit",
            materialLongDescription: "21 Flexible Packs - Sealing Unit",
            parentSpendCatId: 148,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1556,
            level: 3,
            code: "S210085",
            name: "21 Flexible Packs - Counting Unit",
            materialCode: "90102418",
            materialDescription: "21 Flexible Packs - Counting Unit",
            materialLongDescription: "21 Flexible Packs - Counting Unit",
            parentSpendCatId: 148,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1557,
            level: 3,
            code: "S210086",
            name: "21 Small Pre-made Bag Filler (VFFS)",
            materialCode: "90102419",
            materialDescription: "21 Small Pre-made Bag Filler (VFFS)",
            materialLongDescription: "21 Small Pre-made Bag Filler (VFFS)",
            parentSpendCatId: 148,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1558,
            level: 3,
            code: "S210087",
            name: "21 Large Pre-made Bag Filler (VFFS)",
            materialCode: "90102420",
            materialDescription: "21 Large Pre-made Bag Filler (VFFS)",
            materialLongDescription: "21 Large Pre-made Bag Filler (VFFS)",
            parentSpendCatId: 148,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1559,
            level: 3,
            code: "S210088",
            name: "21 Pre-made Bag (HFFS)",
            materialCode: "90102421",
            materialDescription: "21 Pre-made Bag (HFFS)",
            materialLongDescription: "21 Pre-made Bag (HFFS)",
            parentSpendCatId: 148,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1567,
            level: 3,
            code: "S210080",
            name: "21 Flexible Packs - Bag-in-a-Box",
            materialCode: "90102413",
            materialDescription: "21 Flexible Packs - Bag-in-a-Box",
            materialLongDescription: "21 Flexible Packs - Bag-in-a-Box",
            parentSpendCatId: 148,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 149,
        level: 2,
        code: "S210044",
        name: "21 Inspection",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 13,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 872,
            level: 3,
            code: "S210045",
            name: "21 Inspection - Checkweighers",
            materialCode: "90072471",
            materialDescription: "21 Inspection - Checkweighers",
            materialLongDescription: "21 Inspection - Checkweighers",
            parentSpendCatId: 149,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 873,
            level: 3,
            code: "S210046",
            name: "21 Inspection - Metal Detectors",
            materialCode: "90072472",
            materialDescription: "21 Inspection - Metal Detectors",
            materialLongDescription: "21 Inspection - Metal Detectors",
            parentSpendCatId: 149,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 874,
            level: 3,
            code: "S210047",
            name: "21 Inspection - Online Vision System",
            materialCode: "90072529",
            materialDescription: "21 Inspection - Online Vision System",
            materialLongDescription: "21 Inspection - Online Vision System",
            parentSpendCatId: 149,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 875,
            level: 3,
            code: "S210048",
            name: "21 Inspection - X-Ray Detection",
            materialCode: "90072528",
            materialDescription: "21 Inspection - X-Ray Detection",
            materialLongDescription: "21 Inspection - X-Ray Detection",
            parentSpendCatId: 149,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 150,
        level: 2,
        code: "S210049",
        name: "21 Labeler & Sleever",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 13,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 876,
            level: 3,
            code: "S210050",
            name: "21 Labelers",
            materialCode: "90072527",
            materialDescription: "21 Labelers",
            materialLongDescription: "21 Labelers",
            parentSpendCatId: 150,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 877,
            level: 3,
            code: "S210051",
            name: "21 Sleevers",
            materialCode: "90072526",
            materialDescription: "21 Sleevers",
            materialLongDescription: "21 Sleevers",
            parentSpendCatId: 150,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 151,
        level: 2,
        code: "S210052",
        name: "21 Pallet Shrink Wrapping",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 13,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 878,
            level: 3,
            code: "S210053",
            name: "21 Pallet Shrink Wrapping",
            materialCode: "90072525",
            materialDescription: "21 Pallet Shrink Wrapping",
            materialLongDescription: "21 Pallet Shrink Wrapping",
            parentSpendCatId: 151,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 152,
        level: 2,
        code: "S210054",
        name: "21 Pallet Stretch Wrapping",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 13,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 879,
            level: 3,
            code: "S210055",
            name: "21 Pallet Stretch Wrap - Auto Turn Table",
            materialCode: "90072524",
            materialDescription: "21 Pallet Stretch Wrap - Auto Turn Table",
            materialLongDescription: "21 Pallet Stretch Wrap - Auto Turn Table",
            parentSpendCatId: 152,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 880,
            level: 3,
            code: "S210056",
            name: "21 Pallet Stretch Wrapping - Ring",
            materialCode: "90072523",
            materialDescription: "21 Pallet Stretch Wrapping - Ring",
            materialLongDescription: "21 Pallet Stretch Wrapping - Ring",
            parentSpendCatId: 152,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 881,
            level: 3,
            code: "S210057",
            name: "21 Pallet Stretch Wrapping - Rotary Arm",
            materialCode: "90072522",
            materialDescription: "21 Pallet Stretch Wrapping - Rotary Arm",
            materialLongDescription: "21 Pallet Stretch Wrapping - Rotary Arm",
            parentSpendCatId: 152,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 882,
            level: 3,
            code: "S210058",
            name: "21 Pallet Stretch Wrap - Semi Auto",
            materialCode: "90072521",
            materialDescription: "21 Pallet Stretch Wrap - Semi Auto",
            materialLongDescription: "21 Pallet Stretch Wrap - Semi Auto",
            parentSpendCatId: 152,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 153,
        level: 2,
        code: "S210059",
        name: "21 Palletizer",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 13,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 883,
            level: 3,
            code: "S210060",
            name: "21 Palletizer - Collaborative Robot",
            materialCode: "90072520",
            materialDescription: "21 Palletizer - Collaborative Robot",
            materialLongDescription: "21 Palletizer - Collaborative Robot",
            parentSpendCatId: 153,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 884,
            level: 3,
            code: "S210061",
            name: "21 Palletizer - In Line",
            materialCode: "90072519",
            materialDescription: "21 Palletizer - In Line",
            materialLongDescription: "21 Palletizer - In Line",
            parentSpendCatId: 153,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 885,
            level: 3,
            code: "S210062",
            name: "21 Palletizer - Robotic",
            materialCode: "90072518",
            materialDescription: "21 Palletizer - Robotic",
            materialLongDescription: "21 Palletizer - Robotic",
            parentSpendCatId: 153,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 886,
            level: 3,
            code: "S210063",
            name: "21 Palletizer - Sweep Type",
            materialCode: "90072517",
            materialDescription: "21 Palletizer - Sweep Type",
            materialLongDescription: "21 Palletizer - Sweep Type",
            parentSpendCatId: 153,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 154,
        level: 2,
        code: "S210064",
        name: "21 Portioned Packs",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 13,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 887,
            level: 3,
            code: "S210065",
            name: "21 Portioned Packs - Horizontal",
            materialCode: "90072516",
            materialDescription: "21 Portioned Packs - Horizontal",
            materialLongDescription: "21 Portioned Packs - Horizontal",
            parentSpendCatId: 154,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 888,
            level: 3,
            code: "S210066",
            name: "21 Portioned Packs - Vertical",
            materialCode: "90072515",
            materialDescription: "21 Portioned Packs - Vertical",
            materialLongDescription: "21 Portioned Packs - Vertical",
            parentSpendCatId: 154,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 155,
        level: 2,
        code: "S210067",
        name: "21 Product Coding",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 13,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 889,
            level: 3,
            code: "S210068",
            name: "21 Product Coding Equipment",
            materialCode: "90072514",
            materialDescription: "21 Product Coding Equipment",
            materialLongDescription: "21 Product Coding Equipment",
            parentSpendCatId: 155,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 890,
            level: 3,
            code: "S210069",
            name: "21 Product Coding Consumables",
            materialCode: "90072513",
            materialDescription: "21 Product Coding Consumables",
            materialLongDescription: "21 Product Coding Consumables",
            parentSpendCatId: 155,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 891,
            level: 3,
            code: "S210070",
            name: "21 Product Coding Lease/Paypercode",
            materialCode: "90072552",
            materialDescription: "21 Product Coding Lease/Paypercode",
            materialLongDescription: "21 Product Coding Lease/Paypercode",
            parentSpendCatId: 155,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 156,
        level: 2,
        code: "S210071",
        name: "21 Rigid Container Filler",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 13,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 892,
            level: 3,
            code: "S210072",
            name: "21 Cans Filler",
            materialCode: "90072551",
            materialDescription: "21 Cans Filler",
            materialLongDescription: "21 Cans Filler",
            parentSpendCatId: 156,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 893,
            level: 3,
            code: "S210073",
            name: "21 Glass Filler",
            materialCode: "90072550",
            materialDescription: "21 Glass Filler",
            materialLongDescription: "21 Glass Filler",
            parentSpendCatId: 156,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 894,
            level: 3,
            code: "S210074",
            name: "21 PET HDPE Filler",
            materialCode: "90072549",
            materialDescription: "21 PET HDPE Filler",
            materialLongDescription: "21 PET HDPE Filler",
            parentSpendCatId: 156,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 157,
        level: 2,
        code: "S210075",
        name: "21 Shrink Packer",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 13,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 895,
            level: 3,
            code: "S210076",
            name: "21 Combined Shrink + Case Packer",
            materialCode: "90072548",
            materialDescription: "21 Combined Shrink + Case Packer",
            materialLongDescription: "21 Combined Shrink + Case Packer",
            parentSpendCatId: 157,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 896,
            level: 3,
            code: "S210077",
            name: "21 Shrink Packer",
            materialCode: "90072547",
            materialDescription: "21 Shrink Packer",
            materialLongDescription: "21 Shrink Packer",
            parentSpendCatId: 157,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 897,
            level: 3,
            code: "S210078",
            name: "21 Shrink Packer with Tray/Pad",
            materialCode: "90072546",
            materialDescription: "21 Shrink Packer with Tray/Pad",
            materialLongDescription: "21 Shrink Packer with Tray/Pad",
            parentSpendCatId: 157,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 1539,
        level: 2,
        code: "S210089",
        name: "21 Customized Solutions",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 13,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 1540,
            level: 3,
            code: "S210090",
            name: "21 Packing/Unpacking (customized)",
            materialCode: "90102422",
            materialDescription: "21 Packing/Unpacking (customized)",
            materialLongDescription: "21 Packing/Unpacking (customized)",
            parentSpendCatId: 1539,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1541,
            level: 3,
            code: "S210091",
            name: "21 Automatic Feeding System (customized)",
            materialCode: "90102423",
            materialDescription: "21 Automatic Feeding System (customized)",
            materialLongDescription: "21 Automatic Feeding System (customized)",
            parentSpendCatId: 1539,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 1542,
        level: 2,
        code: "S210092",
        name: "21 Turnkey Solutions",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 13,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 1543,
            level: 3,
            code: "S210093",
            name: "21 Multiple Filling & Packing Equipment",
            materialCode: "90102424",
            materialDescription: "21 Multiple Filling & Packing Equipment",
            materialLongDescription: "21 Multiple Filling & Packing Equipment",
            parentSpendCatId: 1542,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 1544,
        level: 2,
        code: "S210094",
        name: "21 Case Packer + Palletizer Workcell",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 13,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 1545,
            level: 3,
            code: "S210095",
            name: "21 Case Packer + Palletizer Workcell",
            materialCode: "90102425",
            materialDescription: "21 Case Packer + Palletizer Workcell",
            materialLongDescription: "21 Case Packer + Palletizer Workcell",
            parentSpendCatId: 1544,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 1546,
        level: 2,
        code: "S210096",
        name: "21 Adhesive Dispensing System",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 13,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 1547,
            level: 3,
            code: "S210097",
            name: "21 Adhesive Dispensing System-Cold Melt",
            materialCode: "90102426",
            materialDescription: "21 Adhesive Dispensing System-Cold Melt",
            materialLongDescription: "21 Adhesive Dispensing System-Cold Melt",
            parentSpendCatId: 1546,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1548,
            level: 3,
            code: "S210098",
            name: "21 Adhesive Dispensing System-Hot Melt",
            materialCode: "90102427",
            materialDescription: "21 Adhesive Dispensing System-Hot Melt",
            materialLongDescription: "21 Adhesive Dispensing System-Hot Melt",
            parentSpendCatId: 1546,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
    ],
  },
  {
    id: 14,
    level: 1,
    code: "S22",
    name: "22 Generic Equipment",
    materialCode: null,
    materialDescription: null,
    materialLongDescription: null,
    parentSpendCatId: 0,
    parentSpendCatCode: null,
    active: true,
    spendCategories: [
      {
        id: 158,
        level: 2,
        code: "S220001",
        name: "22 Automation",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 14,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 898,
            level: 3,
            code: "S220002",
            name: "22 Automation Equipment",
            materialCode: "90072545",
            materialDescription: "22 Automation Equipment",
            materialLongDescription: "22 Automation Equipment",
            parentSpendCatId: 158,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 899,
            level: 3,
            code: "S220003",
            name: "22 Automation Measuring Instrument",
            materialCode: "90072544",
            materialDescription: "22 Measuring Instr.",
            materialLongDescription: "22 Automation Measuring Instrument",
            parentSpendCatId: 158,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 900,
            level: 3,
            code: "S220004",
            name: "22 Industrial Software",
            materialCode: "90072543",
            materialDescription: "22 Industrial Softw.",
            materialLongDescription: "22 Industrial Software",
            parentSpendCatId: 158,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 901,
            level: 3,
            code: "S220005",
            name: "22 PLCs & Controllers",
            materialCode: "90072542",
            materialDescription: "22 PLCs & Controller",
            materialLongDescription: "22 PLCs & Controllers",
            parentSpendCatId: 158,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 902,
            level: 3,
            code: "S220006",
            name: "22 System upgrades",
            materialCode: "90072541",
            materialDescription: "22 System upgrades",
            materialLongDescription: "22 System upgrades",
            parentSpendCatId: 158,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 159,
        level: 2,
        code: "S220007",
        name: "22 Compressed air",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 14,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 903,
            level: 3,
            code: "S220008",
            name: "22 Air Compressor",
            materialCode: "90072540",
            materialDescription: "22 Air Compressor",
            materialLongDescription: "22 Air Compressor",
            parentSpendCatId: 159,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 904,
            level: 3,
            code: "S220009",
            name: "22 Air Dryer/Receiver",
            materialCode: "90072539",
            materialDescription: "22 Air Dryer/Receive",
            materialLongDescription: "22 Air Dryer/Receiver",
            parentSpendCatId: 159,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 160,
        level: 2,
        code: "S220010",
        name: "22 Electricity",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 14,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 905,
            level: 3,
            code: "S220011",
            name: "22 Batteries/Accessories",
            materialCode: "90072538",
            materialDescription: "22 Batterie&Accessor",
            materialLongDescription: "22 Batteries/Accessories",
            parentSpendCatId: 160,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 906,
            level: 3,
            code: "S220012",
            name: "22 Control Panels",
            materialCode: "90072537",
            materialDescription: "22 Control Panels",
            materialLongDescription: "22 Control Panels",
            parentSpendCatId: 160,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 907,
            level: 3,
            code: "S220013",
            name: "22 Electrical Motors & Drives",
            materialCode: "90072536",
            materialDescription: "22 Motors & Drives",
            materialLongDescription: "22 Electrical Motors & Drives",
            parentSpendCatId: 160,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 908,
            level: 3,
            code: "S220014",
            name: "22 Electrical/Lighting components",
            materialCode: "90072535",
            materialDescription: "22 LightingComponent",
            materialLongDescription: "22 Electrical/Lighting components",
            parentSpendCatId: 160,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 909,
            level: 3,
            code: "S220015",
            name: "22 Transformers/Switchgear",
            materialCode: "90072534",
            materialDescription: "22 Transfo/Switchgea",
            materialLongDescription: "22 Transformers/Switchgear",
            parentSpendCatId: 160,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 910,
            level: 3,
            code: "S220016",
            name: "22 Uninterruptable Power",
            materialCode: "90072533",
            materialDescription: "22 Uninterrup. Power",
            materialLongDescription: "22 Uninterruptable Power",
            parentSpendCatId: 160,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 161,
        level: 2,
        code: "S220017",
        name: "22 HVAC",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 14,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 911,
            level: 3,
            code: "S220018",
            name: "22 Air Filters",
            materialCode: "90072532",
            materialDescription: "22 Air Filters",
            materialLongDescription: "22 Air Filters",
            parentSpendCatId: 161,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 912,
            level: 3,
            code: "S220019",
            name: "22 Air Handling Unit",
            materialCode: "90072531",
            materialDescription: "22 Air Handling Unit",
            materialLongDescription: "22 Air Handling Unit",
            parentSpendCatId: 161,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 913,
            level: 3,
            code: "S220020",
            name: "22 Heating/Ventilating",
            materialCode: "90072530",
            materialDescription: "22 Heating/Ventilat.",
            materialLongDescription: "22 Heating/Ventilating",
            parentSpendCatId: 161,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 914,
            level: 3,
            code: "S220021",
            name: "22 Refrigerator/Chiller",
            materialCode: "90072567",
            materialDescription: "22 Refriger/Chiller",
            materialLongDescription: "22 Refrigerator/Chiller",
            parentSpendCatId: 161,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 162,
        level: 2,
        code: "S220022",
        name: "22 Industrial Cleaning",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 14,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 915,
            level: 3,
            code: "S220023",
            name: "22 Industrial Cleaning Commodity Chemical",
            materialCode: "90072566",
            materialDescription: "22 CommodityChemical",
            materialLongDescription:
              "22 Industrial Cleaning Commodity Chemical",
            parentSpendCatId: 162,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 916,
            level: 3,
            code: "S220024",
            name: "22 Industrial Cleaning Equipment",
            materialCode: "90072565",
            materialDescription: "22 Cleaning Equip.",
            materialLongDescription: "22 Industrial Cleaning Equipment",
            parentSpendCatId: 162,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 917,
            level: 3,
            code: "S220025",
            name: "22 Industrial Cleaning Formulated Chemical",
            materialCode: "90072564",
            materialDescription: "22 Formulated Chemic",
            materialLongDescription:
              "22 Industrial Cleaning Formulated Chemical",
            parentSpendCatId: 162,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 918,
            level: 3,
            code: "S220026",
            name: "22 Industrial Cleaning Services",
            materialCode: "90072473",
            materialDescription: "22 Cleaning Services",
            materialLongDescription: "22 Industrial Cleaning Services",
            parentSpendCatId: 162,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 919,
            level: 3,
            code: "S220027",
            name: "22 Industrial Cleaning Supplies",
            materialCode: "90072563",
            materialDescription: "22 Cleaning Supplies",
            materialLongDescription: "22 Industrial Cleaning Supplies",
            parentSpendCatId: 162,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 163,
        level: 2,
        code: "S220028",
        name: "22 Laboratory Equipment, Supplies & Services",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 14,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 920,
            level: 3,
            code: "S220029",
            name: "22 Laboratory Equipment",
            materialCode: "90072562",
            materialDescription: "22 Lab. Equipment",
            materialLongDescription: "22 Laboratory Equipment",
            parentSpendCatId: 163,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 921,
            level: 3,
            code: "S220030",
            name: "22 External Laboratory Services",
            materialCode: "90072716",
            materialDescription: "22 External Lab.",
            materialLongDescription: "22 External Laboratory Services",
            parentSpendCatId: 163,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 922,
            level: 3,
            code: "S220031",
            name: "22 Laboratory Equipment Maintenance & Repair",
            materialCode: "90072477",
            materialDescription: "22 Labo Maintenance",
            materialLongDescription:
              "22 Laboratory Equipment Maintenance & Repair",
            parentSpendCatId: 163,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 923,
            level: 3,
            code: "S220032",
            name: "22 Laboratory Supplies",
            materialCode: "90072717",
            materialDescription: "22 Lab. Supplies",
            materialLongDescription: "22 Laboratory Supplies",
            parentSpendCatId: 163,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 164,
        level: 2,
        code: "S220033",
        name: "22 Thermal",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 14,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 924,
            level: 3,
            code: "S220034",
            name: "22 Boiler",
            materialCode: "90072561",
            materialDescription: "22 Boiler",
            materialLongDescription: "22 Boiler",
            parentSpendCatId: 164,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 925,
            level: 3,
            code: "S220035",
            name: "22 Power Generation",
            materialCode: "90072560",
            materialDescription: "22 Power Generation",
            materialLongDescription: "22 Power Generation",
            parentSpendCatId: 164,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 165,
        level: 2,
        code: "S220036",
        name: "22 Warehousing Equipment",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 14,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 926,
            level: 3,
            code: "S220037",
            name: "22 Container/Bin/Bag",
            materialCode: "90072559",
            materialDescription: "22 Container/Bin/Bag",
            materialLongDescription: "22 Container/Bin/Bag",
            parentSpendCatId: 165,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 927,
            level: 3,
            code: "S220038",
            name: "22 Docking Equipment",
            materialCode: "90072558",
            materialDescription: "22 Docking Equipment",
            materialLongDescription: "22 Docking Equipment",
            parentSpendCatId: 165,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 928,
            level: 3,
            code: "S220039",
            name: "22 Forklift & Accessories",
            materialCode: "90072718",
            materialDescription: "22 Forklift&Accesso.",
            materialLongDescription: "22 Forklift & Accessories",
            parentSpendCatId: 165,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 929,
            level: 3,
            code: "S220040",
            name: "22 Industrial Doors",
            materialCode: "90072557",
            materialDescription: "22 Industrial Doors",
            materialLongDescription: "22 Industrial Doors",
            parentSpendCatId: 165,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 930,
            level: 3,
            code: "S220041",
            name: "22 Pallets",
            materialCode: "90072556",
            materialDescription: "22 Pallets",
            materialLongDescription: "22 Pallets",
            parentSpendCatId: 165,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 931,
            level: 3,
            code: "S220042",
            name: "22 Racking & Storage",
            materialCode: "90072555",
            materialDescription: "22 Racking & Storage",
            materialLongDescription: "22 Racking & Storage",
            parentSpendCatId: 165,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 932,
            level: 3,
            code: "S220043",
            name: "22 Tank/Silo/Cylinder",
            materialCode: "90072554",
            materialDescription: "22 Tank/Silo/Cylinder",
            materialLongDescription: "22 Tank/Silo/Cylinder",
            parentSpendCatId: 165,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1459,
            level: 3,
            code: "S220051",
            name: "22 Warehouse Automation",
            materialCode: null,
            materialDescription: "22 Warehouse Automation",
            materialLongDescription: "22 Warehouse Automation",
            parentSpendCatId: 165,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1536,
            level: 3,
            code: "S220052",
            name: "22 Rental Pallets",
            materialCode: "90102620",
            materialDescription: "22 Rental Pallets",
            materialLongDescription: "22 Rental Pallets",
            parentSpendCatId: 165,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1537,
            level: 3,
            code: "S220053",
            name: "22 White Pallets",
            materialCode: "90102621",
            materialDescription: "22 White Pallets",
            materialLongDescription: "22 White Pallets",
            parentSpendCatId: 165,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 166,
        level: 2,
        code: "S220044",
        name: "22 Water Treatment",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 14,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 933,
            level: 3,
            code: "S220045",
            name: "22 Effluent Water Treatment",
            materialCode: "90072553",
            materialDescription: "22 Effluent Treat.",
            materialLongDescription: "22 Effluent Water Treatment",
            parentSpendCatId: 166,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 934,
            level: 3,
            code: "S220046",
            name: "22 Water Discharge",
            materialCode: "90072579",
            materialDescription: "22 Water Discharge",
            materialLongDescription: "22 Water Discharge",
            parentSpendCatId: 166,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 935,
            level: 3,
            code: "S220047",
            name: "22 Water Supply",
            materialCode: "90072719",
            materialDescription: "22 Water Supply",
            materialLongDescription: "22 Water Supply",
            parentSpendCatId: 166,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 936,
            level: 3,
            code: "S220048",
            name: "22 Water Treatment Services",
            materialCode: "90072476",
            materialDescription: "22 Water Treat Serv",
            materialLongDescription: "22 Water Treatment Services",
            parentSpendCatId: 166,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 937,
            level: 3,
            code: "S220049",
            name: "22 Water Treatment Systems",
            materialCode: "90072578",
            materialDescription: "22 Water Treat. Syst",
            materialLongDescription: "22 Water Treatment Systems",
            parentSpendCatId: 166,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 938,
            level: 3,
            code: "S220050",
            name: "22 Water TreatmentChemical/Consumable",
            materialCode: "90072577",
            materialDescription: "22 Chemic/Consumable",
            materialLongDescription: "22 Water TreatmentChemical/Consumable",
            parentSpendCatId: 166,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
    ],
  },
  {
    id: 15,
    level: 1,
    code: "S23",
    name: "23 Land, Building & Structures",
    materialCode: null,
    materialDescription: null,
    materialLongDescription: null,
    parentSpendCatId: 0,
    parentSpendCatCode: null,
    active: true,
    spendCategories: [
      {
        id: 167,
        level: 2,
        code: "S230001",
        name: "23 Bldg Construction-Civil",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 15,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 939,
            level: 3,
            code: "S230002",
            name: "23 Bldg Construction-Civil",
            materialCode: "90072576",
            materialDescription: "23 Construct. Civil",
            materialLongDescription: "23 Bldg Construction-Civil",
            parentSpendCatId: 167,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 168,
        level: 2,
        code: "S230003",
        name: "23 Bldg Construction-Electrical",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 15,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 940,
            level: 3,
            code: "S230004",
            name: "23 Bldg Construction-Electrical",
            materialCode: "90072575",
            materialDescription: "23 Construct. Elect.",
            materialLongDescription: "23 Bldg Construction-Electrical",
            parentSpendCatId: 168,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 169,
        level: 2,
        code: "S230005",
        name: "23 Bldg Construction-Mechanical",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 15,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 941,
            level: 3,
            code: "S230006",
            name: "23 Bldg Construction-Mechanical",
            materialCode: "90072574",
            materialDescription: "23 Construct. Mech.",
            materialLongDescription: "23 Bldg Construction-Mechanical",
            parentSpendCatId: 169,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 170,
        level: 2,
        code: "S230007",
        name: "23 Bldg Infrastructure Equip.",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 15,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 942,
            level: 3,
            code: "S230008",
            name: "23 Bldg Infrastructure Equip.",
            materialCode: "90072573",
            materialDescription: "23 Infrastruct Equip",
            materialLongDescription: "23 Bldg Infrastructure Equip.",
            parentSpendCatId: 170,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 171,
        level: 2,
        code: "S230009",
        name: "23 Land",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 15,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 943,
            level: 3,
            code: "S230010",
            name: "23 Land",
            materialCode: "90072572",
            materialDescription: "23 Land",
            materialLongDescription: "23 Land",
            parentSpendCatId: 171,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
    ],
  },
  {
    id: 16,
    level: 1,
    code: "S24",
    name: "24 MRO Supplies and Services",
    materialCode: null,
    materialDescription: null,
    materialLongDescription: null,
    parentSpendCatId: 0,
    parentSpendCatCode: null,
    active: true,
    spendCategories: [
      {
        id: 172,
        level: 2,
        code: "S240001",
        name: "24 Industrial Gases",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 16,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 944,
            level: 3,
            code: "S240002",
            name: "24 CO2 Gas",
            materialCode: "90072571",
            materialDescription: "24 CO2 Gas",
            materialLongDescription: "24 CO2 Gas",
            parentSpendCatId: 172,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 945,
            level: 3,
            code: "S240003",
            name: "24 Nitrogen Gas",
            materialCode: "90072570",
            materialDescription: "24 Nitrogen Gas",
            materialLongDescription: "24 Nitrogen Gas",
            parentSpendCatId: 172,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 946,
            level: 3,
            code: "S240004",
            name: "24 Other Gases",
            materialCode: "90072569",
            materialDescription: "24 Other Gases",
            materialLongDescription: "24 Other Gases",
            parentSpendCatId: 172,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 947,
            level: 3,
            code: "S240005",
            name: "24 Refrigerant Gases",
            materialCode: "90072568",
            materialDescription: "24 Refrigerant Gases",
            materialLongDescription: "24 Refrigerant Gases",
            parentSpendCatId: 172,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 173,
        level: 2,
        code: "S240006",
        name: "24 MRO Services",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 16,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 948,
            level: 3,
            code: "S240007",
            name: "24 Automation Services",
            materialCode: "90072475",
            materialDescription: "24 Automation Serv.",
            materialLongDescription: "24 Automation Services",
            parentSpendCatId: 173,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 949,
            level: 3,
            code: "S240008",
            name: "24 Civil Services",
            materialCode: "90072608",
            materialDescription: "24 Civil Services",
            materialLongDescription: "24 Civil Services",
            parentSpendCatId: 173,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 950,
            level: 3,
            code: "S240009",
            name: "24 Electrical Services",
            materialCode: "90072607",
            materialDescription: "24 Electrical Serv.",
            materialLongDescription: "24 Electrical Services",
            parentSpendCatId: 173,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 951,
            level: 3,
            code: "S240010",
            name: "24 HVAC Services",
            materialCode: "90072606",
            materialDescription: "24 HVAC Services",
            materialLongDescription: "24 HVAC Services",
            parentSpendCatId: 173,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 952,
            level: 3,
            code: "S240011",
            name: "24 Mechanical Services",
            materialCode: "90072605",
            materialDescription: "24 Mechanical Serv.",
            materialLongDescription: "24 Mechanical Services",
            parentSpendCatId: 173,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1460,
            level: 3,
            code: "S240027",
            name: "24 OEM Installation & Commissioning Serv",
            materialCode: null,
            materialDescription: "24 OEM Installation & Commissioning Serv",
            materialLongDescription: "24 OEM Installation & Commissioning Serv",
            parentSpendCatId: 173,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 174,
        level: 2,
        code: "S240012",
        name: "24 MRO Supplies",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 16,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 953,
            level: 3,
            code: "S240013",
            name: "24 Adhesives & Sealants",
            materialCode: "90072604",
            materialDescription: "24 Adhesive/Sealant",
            materialLongDescription: "24 Adhesives & Sealants",
            parentSpendCatId: 174,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 954,
            level: 3,
            code: "S240014",
            name: "24 Format Change Parts",
            materialCode: "90072603",
            materialDescription: "24 Format ChangePart",
            materialLongDescription: "24 Format Change Parts",
            parentSpendCatId: 174,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 955,
            level: 3,
            code: "S240015",
            name: "24 Gears & Sprockets",
            materialCode: "90072602",
            materialDescription: "24 Gears & Sprockets",
            materialLongDescription: "24 Gears & Sprockets",
            parentSpendCatId: 174,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 956,
            level: 3,
            code: "S240016",
            name: "24 Industrial Filters",
            materialCode: "90072601",
            materialDescription: "24 Industr. Filters",
            materialLongDescription: "24 Industrial Filters",
            parentSpendCatId: 174,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 957,
            level: 3,
            code: "S240017",
            name: "24 Industrial Pumps",
            materialCode: "90072600",
            materialDescription: "24 Industrial Pumps",
            materialLongDescription: "24 Industrial Pumps",
            parentSpendCatId: 174,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 958,
            level: 3,
            code: "S240018",
            name: "24 Lubes,Oils&Greases",
            materialCode: "90072599",
            materialDescription: "24 Lubes/Oil/Grease",
            materialLongDescription: "24 Lubes,Oils&Greases",
            parentSpendCatId: 174,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 959,
            level: 3,
            code: "S240019",
            name: "24 OEM Spare Parts",
            materialCode: "90072598",
            materialDescription: "24 OEM Spare Parts",
            materialLongDescription: "24 OEM Spare Parts",
            parentSpendCatId: 174,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 960,
            level: 3,
            code: "S240020",
            name: "24 Pipes, Fittings & Valves",
            materialCode: "90072597",
            materialDescription: "24 PipeFittingValve",
            materialLongDescription: "24 Pipes, Fittings & Valves",
            parentSpendCatId: 174,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 961,
            level: 3,
            code: "S240021",
            name: "24 Power Transmission",
            materialCode: "90072596",
            materialDescription: "24 PowerTransmission",
            materialLongDescription: "24 Power Transmission",
            parentSpendCatId: 174,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 962,
            level: 3,
            code: "S240022",
            name: "24 Sealing",
            materialCode: "90072595",
            materialDescription: "24 Sealing",
            materialLongDescription: "24 Sealing",
            parentSpendCatId: 174,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 175,
        level: 2,
        code: "S240023",
        name: "24 Workshop Equipment&Supplies",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 16,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 963,
            level: 3,
            code: "S240024",
            name: "24 Hand & Power Tools",
            materialCode: "90072594",
            materialDescription: "24 Hand&Power Tools",
            materialLongDescription: "24 Hand & Power Tools",
            parentSpendCatId: 175,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 964,
            level: 3,
            code: "S240025",
            name: "24 Workshop Machinery & Tooling",
            materialCode: "90072593",
            materialDescription: "24 Workshop Mac&Tool",
            materialLongDescription: "24 Workshop Machinery & Tooling",
            parentSpendCatId: 175,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 965,
            level: 3,
            code: "S240026",
            name: "24 Workshop supplies",
            materialCode: "90072592",
            materialDescription: "24 Workshop supplies",
            materialLongDescription: "24 Workshop supplies",
            parentSpendCatId: 175,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
    ],
  },
  {
    id: 17,
    level: 1,
    code: "S25",
    name: "25 Processing Equipment",
    materialCode: null,
    materialDescription: null,
    materialLongDescription: null,
    parentSpendCatId: 0,
    parentSpendCatCode: null,
    active: true,
    spendCategories: [
      {
        id: 176,
        level: 2,
        code: "S250001",
        name: "25 2nd Hand Equipment",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 17,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 966,
            level: 3,
            code: "S250002",
            name: "25 Second Hand Equipment",
            materialCode: "90072591",
            materialDescription: "25 Second Hand Equipment",
            materialLongDescription: "25 Second Hand Equipment",
            parentSpendCatId: 176,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 177,
        level: 2,
        code: "S250003",
        name: "25 Coffee, Mixes and Creamers",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 17,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 967,
            level: 3,
            code: "S250004",
            name: "25 Coffee/Creamer - Booster Cells",
            materialCode: "90072590",
            materialDescription: "25 Coffee/Creamer - Booster Cells",
            materialLongDescription: "25 Coffee/Creamer - Booster Cells",
            parentSpendCatId: 177,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 968,
            level: 3,
            code: "S250005",
            name: "25 Coffee/Creamer - Cleaning in Place",
            materialCode: "90072589",
            materialDescription: "25 Coffee/Creamer - Cleaning in Place",
            materialLongDescription: "25 Coffee/Creamer - Cleaning in Place",
            parentSpendCatId: 177,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 969,
            level: 3,
            code: "S250006",
            name: "25 Coffee/Creamer - Clarification",
            materialCode: "90072588",
            materialDescription: "25 Coffee/Creamer - Clarification",
            materialLongDescription: "25 Coffee/Creamer - Clarification",
            parentSpendCatId: 177,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 970,
            level: 3,
            code: "S250007",
            name: "25 Coffee/Creamer - Coffee Aroma Recover",
            materialCode: "90072587",
            materialDescription: "25 Coffee/Creamer - Coffee Aroma Recover",
            materialLongDescription: "25 Coffee/Creamer - Coffee Aroma Recover",
            parentSpendCatId: 177,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 971,
            level: 3,
            code: "S250008",
            name: "25 Coffee/Creamer - Coffee Extraction",
            materialCode: "90072586",
            materialDescription: "25 Coffee/Creamer - Coffee Extraction",
            materialLongDescription: "25 Coffee/Creamer - Coffee Extraction",
            parentSpendCatId: 177,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 972,
            level: 3,
            code: "S250009",
            name: "25 Coffee/Creamer - Coffee Ground Handli",
            materialCode: "90072585",
            materialDescription: "25 Coffee/Creamer - Coffee Ground Handli",
            materialLongDescription: "25 Coffee/Creamer - Coffee Ground Handli",
            parentSpendCatId: 177,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 973,
            level: 3,
            code: "S250010",
            name: "25 Coffee/Creamer - Dry Grinding, Millin",
            materialCode: "90072584",
            materialDescription: "25 Coffee/Creamer - Dry Grinding, Millin",
            materialLongDescription: "25 Coffee/Creamer - Dry Grinding, Millin",
            parentSpendCatId: 177,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 974,
            level: 3,
            code: "S250011",
            name: "25 Coffee/Creamer - Dry Mixing, Feeding",
            materialCode: "90072583",
            materialDescription: "25 Coffee/Creamer - Dry Mixing, Feeding",
            materialLongDescription: "25 Coffee/Creamer - Dry Mixing, Feeding",
            parentSpendCatId: 177,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 975,
            level: 3,
            code: "S250012",
            name: "25 Coffee/Creamer - Evaporation",
            materialCode: "90072582",
            materialDescription: "25 Coffee/Creamer - Evaporation",
            materialLongDescription: "25 Coffee/Creamer - Evaporation",
            parentSpendCatId: 177,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 976,
            level: 3,
            code: "S250013",
            name: "25 Coffee/Creamer - Fluid Bed Drying",
            materialCode: "90072581",
            materialDescription: "25 Coffee/Creamer - Fluid Bed Drying",
            materialLongDescription: "25 Coffee/Creamer - Fluid Bed Drying",
            parentSpendCatId: 177,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 977,
            level: 3,
            code: "S250014",
            name: "25 Coffee/Creamer - Freeze Drying",
            materialCode: "90072580",
            materialDescription: "25 Coffee/Creamer - Freeze Drying",
            materialLongDescription: "25 Coffee/Creamer - Freeze Drying",
            parentSpendCatId: 177,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 978,
            level: 3,
            code: "S250015",
            name: "25 Coffee/Creamer - Freezing",
            materialCode: "90072629",
            materialDescription: "25 Coffee/Creamer - Freezing",
            materialLongDescription: "25 Coffee/Creamer - Freezing",
            parentSpendCatId: 177,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 979,
            level: 3,
            code: "S250016",
            name: "25 Coffee/Creamer - Coffee Decafeination",
            materialCode: "90072628",
            materialDescription: "25 Coffee/Creamer - Coffee Decafeination",
            materialLongDescription: "25 Coffee/Creamer - Coffee Decafeination",
            parentSpendCatId: 177,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 980,
            level: 3,
            code: "S250017",
            name: "25 Coffee/Creamer - Coffee Handling",
            materialCode: "90072627",
            materialDescription: "25 Coffee/Creamer - Coffee Handling",
            materialLongDescription: "25 Coffee/Creamer - Coffee Handling",
            parentSpendCatId: 177,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 981,
            level: 3,
            code: "S250018",
            name: "25 Coffee/Creamer - Powder Handling",
            materialCode: "90072626",
            materialDescription: "25 Coffee/Creamer - Powder Handling",
            materialLongDescription: "25 Coffee/Creamer - Powder Handling",
            parentSpendCatId: 177,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 982,
            level: 3,
            code: "S250019",
            name: "25 Coffee/Creamer - Roasting",
            materialCode: "90072625",
            materialDescription: "25 Coffee/Creamer - Roasting",
            materialLongDescription: "25 Coffee/Creamer - Roasting",
            parentSpendCatId: 177,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 983,
            level: 3,
            code: "S250020",
            name: "25 Coffee/Creamer - Spray Drying",
            materialCode: "90072624",
            materialDescription: "25 Coffee/Creamer - Spray Drying",
            materialLongDescription: "25 Coffee/Creamer - Spray Drying",
            parentSpendCatId: 177,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 984,
            level: 3,
            code: "S250021",
            name: "25 Coffee/Creamer - Wet Grinding, Milling",
            materialCode: "90072623",
            materialDescription: "25 Coffee/Creamer - Wet Grinding,Milling",
            materialLongDescription:
              "25 Coffee/Creamer - Wet Grinding, Milling",
            parentSpendCatId: 177,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 178,
        level: 2,
        code: "S250022",
        name: "25 Confectionery",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 17,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 985,
            level: 3,
            code: "S250023",
            name: "25 Confectionery - Biscuit Baking",
            materialCode: "90072622",
            materialDescription: "25 Confectionery - Biscuit Baking",
            materialLongDescription: "25 Confectionery - Biscuit Baking",
            parentSpendCatId: 178,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 986,
            level: 3,
            code: "S250024",
            name: "25 Confectionery - Biscuit dough making",
            materialCode: "90072621",
            materialDescription: "25 Confectionery - Biscuit dough making",
            materialLongDescription: "25 Confectionery - Biscuit dough making",
            parentSpendCatId: 178,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 987,
            level: 3,
            code: "S250025",
            name: "25 Confectionery - Biscuit Moulding",
            materialCode: "90072620",
            materialDescription: "25 Confectionery - Biscuit Moulding",
            materialLongDescription: "25 Confectionery - Biscuit Moulding",
            parentSpendCatId: 178,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 988,
            level: 3,
            code: "S250026",
            name: "25 Confectionery - Cereal Bar Forming",
            materialCode: "90072619",
            materialDescription: "25 Confectionery - Cereal Bar Forming",
            materialLongDescription: "25 Confectionery - Cereal Bar Forming",
            parentSpendCatId: 178,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 989,
            level: 3,
            code: "S250027",
            name: "25 Confectionery - Chocolate Conching",
            materialCode: "90072618",
            materialDescription: "25 Confectionery - Chocolate Conching",
            materialLongDescription: "25 Confectionery - Chocolate Conching",
            parentSpendCatId: 178,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 990,
            level: 3,
            code: "S250028",
            name: "25 Confectionery - Chocolate Mixing",
            materialCode: "90072617",
            materialDescription: "25 Confectionery - Chocolate Mixing",
            materialLongDescription: "25 Confectionery - Chocolate Mixing",
            parentSpendCatId: 178,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 991,
            level: 3,
            code: "S250029",
            name: "25 Confectionery - Chocolate Refining",
            materialCode: "90072616",
            materialDescription: "25 Confectionery - Chocolate Refining",
            materialLongDescription: "25 Confectionery - Chocolate Refining",
            parentSpendCatId: 178,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 992,
            level: 3,
            code: "S250030",
            name: "25 Confectionery - Chocolate Panning",
            materialCode: "90072615",
            materialDescription: "25 Confectionery - Chocolate Panning",
            materialLongDescription: "25 Confectionery - Chocolate Panning",
            parentSpendCatId: 178,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 993,
            level: 3,
            code: "S250031",
            name: "25 Confectionery - Chocolate Tempering",
            materialCode: "90072614",
            materialDescription: "25 Confectionery - Chocolate Tempering",
            materialLongDescription: "25 Confectionery - Chocolate Tempering",
            parentSpendCatId: 178,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 994,
            level: 3,
            code: "S250032",
            name: "25 Confectionery - Chocolate Depositing",
            materialCode: "90072613",
            materialDescription: "25 Confectionery - Chocolate Depositing",
            materialLongDescription: "25 Confectionery - Chocolate Depositing",
            parentSpendCatId: 178,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 995,
            level: 3,
            code: "S250033",
            name: "25 Confectionery - Chocolate Enrobing",
            materialCode: "90072612",
            materialDescription: "25 Confectionery - Chocolate Enrobing",
            materialLongDescription: "25 Confectionery - Chocolate Enrobing",
            parentSpendCatId: 178,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 996,
            level: 3,
            code: "S250034",
            name: "25 Confectionery - Chocolate Moulding",
            materialCode: "90072611",
            materialDescription: "25 Confectionery - Chocolate Moulding",
            materialLongDescription: "25 Confectionery - Chocolate Moulding",
            parentSpendCatId: 178,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 997,
            level: 3,
            code: "S250035",
            name: "25 Confectionery - Cocoa Beans Grinding",
            materialCode: "90072610",
            materialDescription: "25 Confectionery - Cocoa Beans Grinding",
            materialLongDescription: "25 Confectionery - Cocoa Beans Grinding",
            parentSpendCatId: 178,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 998,
            level: 3,
            code: "S250036",
            name: "25 Confectionery - Cocoa Beans Handling",
            materialCode: "90072609",
            materialDescription: "25 Confectionery - Cocoa Beans Handling",
            materialLongDescription: "25 Confectionery - Cocoa Beans Handling",
            parentSpendCatId: 178,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 999,
            level: 3,
            code: "S250037",
            name: "25 Confectionery - Cocoa Beans Roasting",
            materialCode: "90072656",
            materialDescription: "25 Confectionery - Cocoa Beans Roasting",
            materialLongDescription: "25 Confectionery - Cocoa Beans Roasting",
            parentSpendCatId: 178,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1000,
            level: 3,
            code: "S250038",
            name: "25 Confectionery - Cocoa Beans Winnowing",
            materialCode: "90072655",
            materialDescription: "25 Confectionery - Cocoa Beans Winnowing",
            materialLongDescription: "25 Confectionery - Cocoa Beans Winnowing",
            parentSpendCatId: 178,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1001,
            level: 3,
            code: "S250039",
            name: "25 Confectionery - Cocoa Separation",
            materialCode: "90072654",
            materialDescription: "25 Confectionery - Cocoa Separation",
            materialLongDescription: "25 Confectionery - Cocoa Separation",
            parentSpendCatId: 178,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1002,
            level: 3,
            code: "S250040",
            name: "25 Confectionery - Cocoa Cake Pulverizin",
            materialCode: "90072653",
            materialDescription: "25 Confectionery - Cocoa Cake Pulverizin",
            materialLongDescription: "25 Confectionery - Cocoa Cake Pulverizin",
            parentSpendCatId: 178,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1003,
            level: 3,
            code: "S250041",
            name: "25 Confectionery - Cream Preparation",
            materialCode: "90072652",
            materialDescription: "25 Confectionery - Cream Preparation",
            materialLongDescription: "25 Confectionery - Cream Preparation",
            parentSpendCatId: 178,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1004,
            level: 3,
            code: "S250042",
            name: "25 Confectionery - Starch Forming",
            materialCode: "90072651",
            materialDescription: "25 Confectionery - Starch Forming",
            materialLongDescription: "25 Confectionery - Starch Forming",
            parentSpendCatId: 178,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1005,
            level: 3,
            code: "S250043",
            name: "25 Confectionery - Dry Granulation",
            materialCode: "90072650",
            materialDescription: "25 Confectionery - Dry Granulation",
            materialLongDescription: "25 Confectionery - Dry Granulation",
            parentSpendCatId: 178,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1006,
            level: 3,
            code: "S250044",
            name: "25 Confectionery - Dry Mixing",
            materialCode: "90072649",
            materialDescription: "25 Confectionery - Dry Mixing",
            materialLongDescription: "25 Confectionery - Dry Mixing",
            parentSpendCatId: 178,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1007,
            level: 3,
            code: "S250045",
            name: "25 Confectionery - Liquid Handling",
            materialCode: "90072648",
            materialDescription: "25 Confectionery - Liquid Handling",
            materialLongDescription: "25 Confectionery - Liquid Handling",
            parentSpendCatId: 178,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1008,
            level: 3,
            code: "S250046",
            name: "25 Confectionery - Liquid Spraying",
            materialCode: "90072647",
            materialDescription: "25 Confectionery - Liquid Spraying",
            materialLongDescription: "25 Confectionery - Liquid Spraying",
            parentSpendCatId: 178,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1009,
            level: 3,
            code: "S250047",
            name: "25 Confectionery - Moulds Cleaning",
            materialCode: "90072646",
            materialDescription: "25 Confectionery - Moulds Cleaning",
            materialLongDescription: "25 Confectionery - Moulds Cleaning",
            parentSpendCatId: 178,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1010,
            level: 3,
            code: "S250048",
            name: "25 Confectionery - Powder Handling",
            materialCode: "90072645",
            materialDescription: "25 Confectionery - Powder Handling",
            materialLongDescription: "25 Confectionery - Powder Handling",
            parentSpendCatId: 178,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1011,
            level: 3,
            code: "S250049",
            name: "25 Confectionery - Solid Dispensing",
            materialCode: "90072644",
            materialDescription: "25 Confectionery - Solid Dispensing",
            materialLongDescription: "25 Confectionery - Solid Dispensing",
            parentSpendCatId: 178,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1012,
            level: 3,
            code: "S250050",
            name: "25 Confectionery - Sugar Grinding",
            materialCode: "90072643",
            materialDescription: "25 Confectionery - Sugar Grinding",
            materialLongDescription: "25 Confectionery - Sugar Grinding",
            parentSpendCatId: 178,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1013,
            level: 3,
            code: "S250051",
            name: "25 Confectionery - Sugar Pressing",
            materialCode: "90072642",
            materialDescription: "25 Confectionery - Sugar Pressing",
            materialLongDescription: "25 Confectionery - Sugar Pressing",
            parentSpendCatId: 178,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1014,
            level: 3,
            code: "S250052",
            name: "25 Confectionery - Candy making",
            materialCode: "90072641",
            materialDescription: "25 Confectionery - Candy making",
            materialLongDescription: "25 Confectionery - Candy making",
            parentSpendCatId: 178,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1015,
            level: 3,
            code: "S250053",
            name: "25 Confectionery - Wafer Baking",
            materialCode: "90072640",
            materialDescription: "25 Confectionery - Wafer Baking",
            materialLongDescription: "25 Confectionery - Wafer Baking",
            parentSpendCatId: 178,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1016,
            level: 3,
            code: "S250054",
            name: "25 Confectionery - Waffer batter making",
            materialCode: "90072639",
            materialDescription: "25 Confectionery - Waffer batter making",
            materialLongDescription: "25 Confectionery - Waffer batter making",
            parentSpendCatId: 178,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1017,
            level: 3,
            code: "S250055",
            name: "25 Confectionery - Wet Granulation",
            materialCode: "90072638",
            materialDescription: "25 Confectionery - Wet Granulation",
            materialLongDescription: "25 Confectionery - Wet Granulation",
            parentSpendCatId: 178,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 179,
        level: 2,
        code: "S250056",
        name: "25 Dairy and Cereals",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 17,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 1018,
            level: 3,
            code: "S250057",
            name: "25 Dairy/Cereals - Band Drying",
            materialCode: "90072637",
            materialDescription: "25 Dairy/Cereals - Band Drying",
            materialLongDescription: "25 Dairy/Cereals - Band Drying",
            parentSpendCatId: 179,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1019,
            level: 3,
            code: "S250058",
            name: "25 Dairy/Cereals - Cleaning in Place",
            materialCode: "90072636",
            materialDescription: "25 Dairy/Cereals - Cleaning in Place",
            materialLongDescription: "25 Dairy/Cereals - Cleaning in Place",
            parentSpendCatId: 179,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1020,
            level: 3,
            code: "S250059",
            name: "25 Dairy/Cereals - Clarification",
            materialCode: "90072635",
            materialDescription: "25 Dairy/Cereals - Clarification",
            materialLongDescription: "25 Dairy/Cereals - Clarification",
            parentSpendCatId: 179,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1021,
            level: 3,
            code: "S250060",
            name: "25 Dairy/Cereals - Dry Grinding, Milling",
            materialCode: "90072634",
            materialDescription: "25 Dairy/Cereals - Dry Grinding, Milling",
            materialLongDescription: "25 Dairy/Cereals - Dry Grinding, Milling",
            parentSpendCatId: 179,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1022,
            level: 3,
            code: "S250061",
            name: "25 Dairy/Cereals - Dry Mixing, Feeding",
            materialCode: "90072633",
            materialDescription: "25 Dairy/Cereals - Dry Mixing, Feeding",
            materialLongDescription: "25 Dairy/Cereals - Dry Mixing, Feeding",
            parentSpendCatId: 179,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1023,
            level: 3,
            code: "S250062",
            name: "25 Dairy/Cereals - Evaporation",
            materialCode: "90072632",
            materialDescription: "25 Dairy/Cereals - Evaporation",
            materialLongDescription: "25 Dairy/Cereals - Evaporation",
            parentSpendCatId: 179,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1024,
            level: 3,
            code: "S250063",
            name: "25 Dairy/Cereals - Extrusion",
            materialCode: "90072631",
            materialDescription: "25 Dairy/Cereals - Extrusion",
            materialLongDescription: "25 Dairy/Cereals - Extrusion",
            parentSpendCatId: 179,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1025,
            level: 3,
            code: "S250064",
            name: "25 Dairy/Cereals - Fluid Bed Drying",
            materialCode: "90072630",
            materialDescription: "25 Dairy/Cereals - Fluid Bed Drying",
            materialLongDescription: "25 Dairy/Cereals - Fluid Bed Drying",
            parentSpendCatId: 179,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1026,
            level: 3,
            code: "S250065",
            name: "25 Dairy/Cereals - Grains Flaking",
            materialCode: "90072683",
            materialDescription: "25 Dairy/Cereals - Grains Flaking",
            materialLongDescription: "25 Dairy/Cereals - Grains Flaking",
            parentSpendCatId: 179,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1027,
            level: 3,
            code: "S250066",
            name: "25 Dairy/Cereals - Grains Handling",
            materialCode: "90072682",
            materialDescription: "25 Dairy/Cereals - Grains Handling",
            materialLongDescription: "25 Dairy/Cereals - Grains Handling",
            parentSpendCatId: 179,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1028,
            level: 3,
            code: "S250067",
            name: "25 Dairy/Cereals - Homogenizing",
            materialCode: "90072681",
            materialDescription: "25 Dairy/Cereals - Homogenizing",
            materialLongDescription: "25 Dairy/Cereals - Homogenizing",
            parentSpendCatId: 179,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1029,
            level: 3,
            code: "S250068",
            name: "25 Dairy/Cereals - Liquid Handling",
            materialCode: "90072680",
            materialDescription: "25 Dairy/Cereals - Liquid Handling",
            materialLongDescription: "25 Dairy/Cereals - Liquid Handling",
            parentSpendCatId: 179,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1030,
            level: 3,
            code: "S250069",
            name: "25 Dairy/Cereals - Pasteurizing",
            materialCode: "90072679",
            materialDescription: "25 Dairy/Cereals - Pasteurizing",
            materialLongDescription: "25 Dairy/Cereals - Pasteurizing",
            parentSpendCatId: 179,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1031,
            level: 3,
            code: "S250070",
            name: "25 Dairy/Cereals - Powder Handling",
            materialCode: "90072678",
            materialDescription: "25 Dairy/Cereals - Powder Handling",
            materialLongDescription: "25 Dairy/Cereals - Powder Handling",
            parentSpendCatId: 179,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1032,
            level: 3,
            code: "S250071",
            name: "25 Dairy/Cereals - Retorting",
            materialCode: "90072677",
            materialDescription: "25 Dairy/Cereals - Retorting",
            materialLongDescription: "25 Dairy/Cereals - Retorting",
            parentSpendCatId: 179,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1033,
            level: 3,
            code: "S250072",
            name: "25 Dairy/Cereals - Roller Drying",
            materialCode: "90072676",
            materialDescription: "25 Dairy/Cereals - Roller Drying",
            materialLongDescription: "25 Dairy/Cereals - Roller Drying",
            parentSpendCatId: 179,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1034,
            level: 3,
            code: "S250073",
            name: "25 Dairy/Cereals - Sorting Equipment",
            materialCode: "90072675",
            materialDescription: "25 Dairy/Cereals - Sorting Equipment",
            materialLongDescription: "25 Dairy/Cereals - Sorting Equipment",
            parentSpendCatId: 179,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1035,
            level: 3,
            code: "S250074",
            name: "25 Dairy/Cereals - Spray Drying",
            materialCode: "90072674",
            materialDescription: "25 Dairy/Cereals - Spray Drying",
            materialLongDescription: "25 Dairy/Cereals - Spray Drying",
            parentSpendCatId: 179,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1036,
            level: 3,
            code: "S250075",
            name: "25 Dairy/Cereals - UHT",
            materialCode: "90072673",
            materialDescription: "25 Dairy/Cereals - UHT",
            materialLongDescription: "25 Dairy/Cereals - UHT",
            parentSpendCatId: 179,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1037,
            level: 3,
            code: "S250076",
            name: "25 Dairy/Cereals - Vacuum Band Drying",
            materialCode: "90072672",
            materialDescription: "25 Dairy/Cereals - Vacuum Band Drying",
            materialLongDescription: "25 Dairy/Cereals - Vacuum Band Drying",
            parentSpendCatId: 179,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1038,
            level: 3,
            code: "S250077",
            name: "25 Dairy/Cereals - Wet Grinding, Milling",
            materialCode: "90072671",
            materialDescription: "25 Dairy/Cereals - Wet Grinding, Milling",
            materialLongDescription: "25 Dairy/Cereals - Wet Grinding, Milling",
            parentSpendCatId: 179,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1039,
            level: 3,
            code: "S250078",
            name: "25 Dairy/Cereals - Wet Mixing, Feeding",
            materialCode: "90072670",
            materialDescription: "25 Dairy/Cereals - Wet Mixing, Feeding",
            materialLongDescription: "25 Dairy/Cereals - Wet Mixing, Feeding",
            parentSpendCatId: 179,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 180,
        level: 2,
        code: "S250079",
        name: "25 Dry Culinary",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 17,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 1040,
            level: 3,
            code: "S250080",
            name: "25 Dry Culinary - Dry Mixing, Feeding",
            materialCode: "90072669",
            materialDescription: "25 Dry Culinary - Dry Mixing, Feeding",
            materialLongDescription: "25 Dry Culinary - Dry Mixing, Feeding",
            parentSpendCatId: 180,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1041,
            level: 3,
            code: "S250081",
            name: "25 Dry Culinary - Hard Bouillon Pressing",
            materialCode: "90072668",
            materialDescription: "25 Dry Culinary - Hard Bouillon Pressing",
            materialLongDescription: "25 Dry Culinary - Hard Bouillon Pressing",
            parentSpendCatId: 180,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1042,
            level: 3,
            code: "S250082",
            name: "25 Dry Culinary - Hard Bouillon Wrapping",
            materialCode: "90072667",
            materialDescription: "25 Dry Culinary - Hard Bouillon Wrapping",
            materialLongDescription: "25 Dry Culinary - Hard Bouillon Wrapping",
            parentSpendCatId: 180,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1043,
            level: 3,
            code: "S250083",
            name: "25 Dry Culinary - Noodles Cake Forming",
            materialCode: "90072666",
            materialDescription: "25 Dry Culinary - Noodles Cake Forming",
            materialLongDescription: "25 Dry Culinary - Noodles Cake Forming",
            parentSpendCatId: 180,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1044,
            level: 3,
            code: "S250084",
            name: "25 Dry Culinary - Noodles Cooling",
            materialCode: "90072665",
            materialDescription: "25 Dry Culinary - Noodles Cooling",
            materialLongDescription: "25 Dry Culinary - Noodles Cooling",
            parentSpendCatId: 180,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1045,
            level: 3,
            code: "S250085",
            name: "25 Dry Culinary - Noodles Drying",
            materialCode: "90072664",
            materialDescription: "25 Dry Culinary - Noodles Drying",
            materialLongDescription: "25 Dry Culinary - Noodles Drying",
            parentSpendCatId: 180,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1046,
            level: 3,
            code: "S250086",
            name: "25 Dry Culinary - Noodles Frying",
            materialCode: "90072663",
            materialDescription: "25 Dry Culinary - Noodles Frying",
            materialLongDescription: "25 Dry Culinary - Noodles Frying",
            parentSpendCatId: 180,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1047,
            level: 3,
            code: "S250087",
            name: "25 Dry Culinary - Noodles Kneading",
            materialCode: "90072662",
            materialDescription: "25 Dry Culinary - Noodles Kneading",
            materialLongDescription: "25 Dry Culinary - Noodles Kneading",
            parentSpendCatId: 180,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1048,
            level: 3,
            code: "S250088",
            name: "25 Dry Culinary - Noodles Steaming",
            materialCode: "90072661",
            materialDescription: "25 Dry Culinary - Noodles Steaming",
            materialLongDescription: "25 Dry Culinary - Noodles Steaming",
            parentSpendCatId: 180,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1049,
            level: 3,
            code: "S250089",
            name: "25 Dry Culinary - Papyrus Dispensing",
            materialCode: "90072660",
            materialDescription: "25 Dry Culinary - Papyrus Dispensing",
            materialLongDescription: "25 Dry Culinary - Papyrus Dispensing",
            parentSpendCatId: 180,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1050,
            level: 3,
            code: "S250090",
            name: "25 Dry Culinary - Powder Handling",
            materialCode: "90072659",
            materialDescription: "25 Dry Culinary - Powder Handling",
            materialLongDescription: "25 Dry Culinary - Powder Handling",
            parentSpendCatId: 180,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1051,
            level: 3,
            code: "S250091",
            name: "25 Dry Culinary - Soft Bouillon Moulding",
            materialCode: "90072658",
            materialDescription: "25 Dry Culinary - Soft Bouillon Moulding",
            materialLongDescription: "25 Dry Culinary - Soft Bouillon Moulding",
            parentSpendCatId: 180,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1052,
            level: 3,
            code: "S250092",
            name: "25 Dry Culinary - Sorting Equipment",
            materialCode: "90072657",
            materialDescription: "25 Dry Culinary - Sorting Equipment",
            materialLongDescription: "25 Dry Culinary - Sorting Equipment",
            parentSpendCatId: 180,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 181,
        level: 2,
        code: "S250093",
        name: "25 Dry Pet Food",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 17,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 1053,
            level: 3,
            code: "S250094",
            name: "25 Dry Petfood - Band Cooling",
            materialCode: "90072715",
            materialDescription: "25 Dry Petfood - Band Cooling",
            materialLongDescription: "25 Dry Petfood - Band Cooling",
            parentSpendCatId: 181,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1054,
            level: 3,
            code: "S250095",
            name: "25 Dry Petfood - Band Drying",
            materialCode: "90072714",
            materialDescription: "25 Dry Petfood - Band Drying",
            materialLongDescription: "25 Dry Petfood - Band Drying",
            parentSpendCatId: 181,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1055,
            level: 3,
            code: "S250096",
            name: "25 Dry Petfood - Cleaning in Place",
            materialCode: "90072713",
            materialDescription: "25 Dry Petfood - Cleaning in Place",
            materialLongDescription: "25 Dry Petfood - Cleaning in Place",
            parentSpendCatId: 181,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1056,
            level: 3,
            code: "S250097",
            name: "25 Dry Petfood - Coating",
            materialCode: "90072712",
            materialDescription: "25 Dry Petfood - Coating",
            materialLongDescription: "25 Dry Petfood - Coating",
            parentSpendCatId: 181,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1057,
            level: 3,
            code: "S250098",
            name: "25 Dry Petfood - Cooking",
            materialCode: "90072711",
            materialDescription: "25 Dry Petfood - Cooking",
            materialLongDescription: "25 Dry Petfood - Cooking",
            parentSpendCatId: 181,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1058,
            level: 3,
            code: "S250099",
            name: "25 Dry Petfood - Dry Grinding, Milling",
            materialCode: "90072710",
            materialDescription: "25 Dry Petfood - Dry Grinding, Milling",
            materialLongDescription: "25 Dry Petfood - Dry Grinding, Milling",
            parentSpendCatId: 181,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1059,
            level: 3,
            code: "S250100",
            name: "25 Dry Petfood - Dry Mixing, Feeding",
            materialCode: "90072709",
            materialDescription: "25 Dry Petfood - Dry Mixing, Feeding",
            materialLongDescription: "25 Dry Petfood - Dry Mixing, Feeding",
            parentSpendCatId: 181,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1060,
            level: 3,
            code: "S250101",
            name: "25 Dry Petfood - Emulsifying",
            materialCode: "90072708",
            materialDescription: "25 Dry Petfood - Emulsifying",
            materialLongDescription: "25 Dry Petfood - Emulsifying",
            parentSpendCatId: 181,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1061,
            level: 3,
            code: "S250102",
            name: "25 Dry Petfood - Extrusion",
            materialCode: "90072707",
            materialDescription: "25 Dry Petfood - Extrusion",
            materialLongDescription: "25 Dry Petfood - Extrusion",
            parentSpendCatId: 181,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1062,
            level: 3,
            code: "S250103",
            name: "25 Dry Petfood - Grains Handling",
            materialCode: "90072706",
            materialDescription: "25 Dry Petfood - Grains Handling",
            materialLongDescription: "25 Dry Petfood - Grains Handling",
            parentSpendCatId: 181,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1063,
            level: 3,
            code: "S250104",
            name: "25 Dry Petfood - Liquid Handling",
            materialCode: "90072705",
            materialDescription: "25 Dry Petfood - Liquid Handling",
            materialLongDescription: "25 Dry Petfood - Liquid Handling",
            parentSpendCatId: 181,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1064,
            level: 3,
            code: "S250105",
            name: "25 Dry Petfood - Powder Handling",
            materialCode: "90072704",
            materialDescription: "25 Dry Petfood - Powder Handling",
            materialLongDescription: "25 Dry Petfood - Powder Handling",
            parentSpendCatId: 181,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1065,
            level: 3,
            code: "S250106",
            name: "25 Dry Petfood - Wet Mixing, Feeding",
            materialCode: "90072703",
            materialDescription: "25 Dry Petfood - Wet Mixing, Feeding",
            materialLongDescription: "25 Dry Petfood - Wet Mixing, Feeding",
            parentSpendCatId: 181,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 182,
        level: 2,
        code: "S250107",
        name: "25 Ice Cream",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 17,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 1066,
            level: 3,
            code: "S250108",
            name: "25 Ice Cream - Cleaning in Place",
            materialCode: "90072702",
            materialDescription: "25 Ice Cream - Cleaning in Place",
            materialLongDescription: "25 Ice Cream - Cleaning in Place",
            parentSpendCatId: 182,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1067,
            level: 3,
            code: "S250109",
            name: "25 Ice Cream - Extrusion",
            materialCode: "90072701",
            materialDescription: "25 Ice Cream - Extrusion",
            materialLongDescription: "25 Ice Cream - Extrusion",
            parentSpendCatId: 182,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1068,
            level: 3,
            code: "S250110",
            name: "25 Ice Cream - Freezing",
            materialCode: "90072700",
            materialDescription: "25 Ice Cream - Freezing",
            materialLongDescription: "25 Ice Cream - Freezing",
            parentSpendCatId: 182,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1069,
            level: 3,
            code: "S250111",
            name: "25 Ice Cream - Hardenning Tunnel",
            materialCode: "90072699",
            materialDescription: "25 Ice Cream - Hardenning Tunnel",
            materialLongDescription: "25 Ice Cream - Hardenning Tunnel",
            parentSpendCatId: 182,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1070,
            level: 3,
            code: "S250112",
            name: "25 Ice Cream - Homogenizing",
            materialCode: "90072698",
            materialDescription: "25 Ice Cream - Homogenizing",
            materialLongDescription: "25 Ice Cream - Homogenizing",
            parentSpendCatId: 182,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1071,
            level: 3,
            code: "S250113",
            name: "25 Ice Cream - Cone Filling",
            materialCode: "90072697",
            materialDescription: "25 Ice Cream - Cone Filling",
            materialLongDescription: "25 Ice Cream - Cone Filling",
            parentSpendCatId: 182,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1072,
            level: 3,
            code: "S250114",
            name: "25 Ice Cream - Cup Filling",
            materialCode: "90072696",
            materialDescription: "25 Ice Cream - Cup Filling",
            materialLongDescription: "25 Ice Cream - Cup Filling",
            parentSpendCatId: 182,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1073,
            level: 3,
            code: "S250115",
            name: "25 Ice Cream - Extraction",
            materialCode: "90072695",
            materialDescription: "25 Ice Cream - Extraction",
            materialLongDescription: "25 Ice Cream - Extraction",
            parentSpendCatId: 182,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1074,
            level: 3,
            code: "S250116",
            name: "25 Ice Cream - Wrapping",
            materialCode: "90072694",
            materialDescription: "25 Ice Cream - Wrapping",
            materialLongDescription: "25 Ice Cream - Wrapping",
            parentSpendCatId: 182,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1075,
            level: 3,
            code: "S250117",
            name: "25 Ice Cream - Liquid Handling",
            materialCode: "90072693",
            materialDescription: "25 Ice Cream - Liquid Handling",
            materialLongDescription: "25 Ice Cream - Liquid Handling",
            parentSpendCatId: 182,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1076,
            level: 3,
            code: "S250118",
            name: "25 Ice Cream - Moulding",
            materialCode: "90072692",
            materialDescription: "25 Ice Cream - Moulding",
            materialLongDescription: "25 Ice Cream - Moulding",
            parentSpendCatId: 182,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1077,
            level: 3,
            code: "S250119",
            name: "25 Ice Cream - Pasteurizing",
            materialCode: "90072691",
            materialDescription: "25 Ice Cream - Pasteurizing",
            materialLongDescription: "25 Ice Cream - Pasteurizing",
            parentSpendCatId: 182,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1078,
            level: 3,
            code: "S250120",
            name: "25 Ice Cream - Powder Handling",
            materialCode: "90072690",
            materialDescription: "25 Ice Cream - Powder Handling",
            materialLongDescription: "25 Ice Cream - Powder Handling",
            parentSpendCatId: 182,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1079,
            level: 3,
            code: "S250121",
            name: "25 Ice Cream - Wet Mixing, Feeding",
            materialCode: "90072689",
            materialDescription: "25 Ice Cream - Wet Mixing, Feeding",
            materialLongDescription: "25 Ice Cream - Wet Mixing, Feeding",
            parentSpendCatId: 182,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 183,
        level: 2,
        code: "S250122",
        name: "25 Wet Culinary",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 17,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 1080,
            level: 3,
            code: "S250123",
            name: "25 Wet Culinary - Baking",
            materialCode: "90072688",
            materialDescription: "25 Wet Culinary - Baking",
            materialLongDescription: "25 Wet Culinary - Baking",
            parentSpendCatId: 183,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1081,
            level: 3,
            code: "S250124",
            name: "25 Wet Culinary - Blanching",
            materialCode: "90072687",
            materialDescription: "25 Wet Culinary - Blanching",
            materialLongDescription: "25 Wet Culinary - Blanching",
            parentSpendCatId: 183,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1082,
            level: 3,
            code: "S250125",
            name: "25 Wet Culinary - Cleaning in Place",
            materialCode: "90072686",
            materialDescription: "25 Wet Culinary - Cleaning in Place",
            materialLongDescription: "25 Wet Culinary - Cleaning in Place",
            parentSpendCatId: 183,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1083,
            level: 3,
            code: "S250126",
            name: "25 Wet Culinary - Cooking",
            materialCode: "90072685",
            materialDescription: "25 Wet Culinary - Cooking",
            materialLongDescription: "25 Wet Culinary - Cooking",
            parentSpendCatId: 183,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1084,
            level: 3,
            code: "S250127",
            name: "25 Wet Culinary - Cutting",
            materialCode: "90072684",
            materialDescription: "25 Wet Culinary - Cutting",
            materialLongDescription: "25 Wet Culinary - Cutting",
            parentSpendCatId: 183,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1085,
            level: 3,
            code: "S250128",
            name: "25 Wet Culinary - Dry Mixing, Feeding",
            materialCode: "90072512",
            materialDescription: "25 Wet Culinary - Dry Mixing, Feeding",
            materialLongDescription: "25 Wet Culinary - Dry Mixing, Feeding",
            parentSpendCatId: 183,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1086,
            level: 3,
            code: "S250129",
            name: "25 Wet Culinary - Emulsifying",
            materialCode: "90072511",
            materialDescription: "25 Wet Culinary - Emulsifying",
            materialLongDescription: "25 Wet Culinary - Emulsifying",
            parentSpendCatId: 183,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1087,
            level: 3,
            code: "S250130",
            name: "25 Wet Culinary - Forming",
            materialCode: "90072510",
            materialDescription: "25 Wet Culinary - Forming",
            materialLongDescription: "25 Wet Culinary - Forming",
            parentSpendCatId: 183,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1088,
            level: 3,
            code: "S250131",
            name: "25 Wet Culinary - Freezing",
            materialCode: "90072509",
            materialDescription: "25 Wet Culinary - Freezing",
            materialLongDescription: "25 Wet Culinary - Freezing",
            parentSpendCatId: 183,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1089,
            level: 3,
            code: "S250132",
            name: "25 Wet Culinary - Liquid Handling",
            materialCode: "90072508",
            materialDescription: "25 Wet Culinary - Liquid Handling",
            materialLongDescription: "25 Wet Culinary - Liquid Handling",
            parentSpendCatId: 183,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1090,
            level: 3,
            code: "S250133",
            name: "25 Wet Culinary - Liquid Spraying",
            materialCode: "90072507",
            materialDescription: "25 Wet Culinary - Liquid Spraying",
            materialLongDescription: "25 Wet Culinary - Liquid Spraying",
            parentSpendCatId: 183,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1091,
            level: 3,
            code: "S250134",
            name: "25 Wet Culinary - Pasteurizing",
            materialCode: "90072506",
            materialDescription: "25 Wet Culinary - Pasteurizing",
            materialLongDescription: "25 Wet Culinary - Pasteurizing",
            parentSpendCatId: 183,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1092,
            level: 3,
            code: "S250135",
            name: "25 Wet Culinary - Powder Handling",
            materialCode: "90072505",
            materialDescription: "25 Wet Culinary - Powder Handling",
            materialLongDescription: "25 Wet Culinary - Powder Handling",
            parentSpendCatId: 183,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1093,
            level: 3,
            code: "S250136",
            name: "25 Wet Culinary - Solid Dispensing",
            materialCode: "90072504",
            materialDescription: "25 Wet Culinary - Solid Dispensing",
            materialLongDescription: "25 Wet Culinary - Solid Dispensing",
            parentSpendCatId: 183,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1094,
            level: 3,
            code: "S250137",
            name: "25 Wet Culinary - Sorting",
            materialCode: "90072503",
            materialDescription: "25 Wet Culinary - Sorting",
            materialLongDescription: "25 Wet Culinary - Sorting",
            parentSpendCatId: 183,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1095,
            level: 3,
            code: "S250138",
            name: "25 Wet Culinary - UHT",
            materialCode: "90072502",
            materialDescription: "25 Wet Culinary - UHT",
            materialLongDescription: "25 Wet Culinary - UHT",
            parentSpendCatId: 183,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1096,
            level: 3,
            code: "S250139",
            name: "25 Wet Culinary - Wet Grinding, Milling",
            materialCode: "90072501",
            materialDescription: "25 Wet Culinary - Wet Grinding, Milling",
            materialLongDescription: "25 Wet Culinary - Wet Grinding, Milling",
            parentSpendCatId: 183,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1097,
            level: 3,
            code: "S250140",
            name: "25 Wet Culinary - Wet Mixing, Feeding",
            materialCode: "90072500",
            materialDescription: "25 Wet Culinary - Wet Mixing, Feeding",
            materialLongDescription: "25 Wet Culinary - Wet Mixing, Feeding",
            parentSpendCatId: 183,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1550,
            level: 3,
            code: "S250156",
            name: "25 Wet Culinary - Extrusion",
            materialCode: "90102428",
            materialDescription: "25 Wet Culinary - Extrusion",
            materialLongDescription: "25 Wet Culinary - Extrusion",
            parentSpendCatId: 183,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1560,
            level: 3,
            code: "S250157",
            name: "25 Wet Culinary - Proofing, fermenting",
            materialCode: "90102429",
            materialDescription: "25 Wet Culinary - Proofing, fermenting",
            materialLongDescription: "25 Wet Culinary - Proofing, fermenting",
            parentSpendCatId: 183,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1561,
            level: 3,
            code: "S250158",
            name: "25 Wet Culinary - Sauce, topping applic",
            materialCode: "90102430",
            materialDescription: "25 Wet Culinary - Sauce, topping applic",
            materialLongDescription: "25 Wet Culinary - Sauce, topping applic",
            parentSpendCatId: 183,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1562,
            level: 3,
            code: "S250159",
            name: "25 Wet Culinary - Meals conveying",
            materialCode: "90102431",
            materialDescription: "25 Wet Culinary - Meals conveying",
            materialLongDescription: "25 Wet Culinary - Meals conveying",
            parentSpendCatId: 183,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1563,
            level: 3,
            code: "S250160",
            name: "25 Wet Culinary - Docking equipment",
            materialCode: "90102432",
            materialDescription: "25 Wet Culinary - Docking equipment",
            materialLongDescription: "25 Wet Culinary - Docking equipment",
            parentSpendCatId: 183,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1564,
            level: 3,
            code: "S250161",
            name: "25 Wet Culinary - Depanning, stacking",
            materialCode: "90102433",
            materialDescription: "25 Wet Culinary - Depanning, stacking",
            materialLongDescription: "25 Wet Culinary - Depanning, stacking",
            parentSpendCatId: 183,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1565,
            level: 3,
            code: "S250162",
            name: "25 Wet Culinary - Filling",
            materialCode: "90102434",
            materialDescription: "25 Wet Culinary - Filling",
            materialLongDescription: "25 Wet Culinary - Filling",
            parentSpendCatId: 183,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1566,
            level: 3,
            code: "S250163",
            name: "25 Wet Culinary - Slicing, shredding",
            materialCode: "90102435",
            materialDescription: "25 Wet Culinary - Slicing, shredding",
            materialLongDescription: "25 Wet Culinary - Slicing, shredding",
            parentSpendCatId: 183,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 184,
        level: 2,
        code: "S250141",
        name: "25 Wet Pet Food",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 17,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 1098,
            level: 3,
            code: "S250142",
            name: "25 Wet Petfood - Cooking",
            materialCode: "90072499",
            materialDescription: "25 Wet Petfood - Cooking",
            materialLongDescription: "25 Wet Petfood - Cooking",
            parentSpendCatId: 184,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1099,
            level: 3,
            code: "S250143",
            name: "25 Wet Petfood - Cutting",
            materialCode: "90072498",
            materialDescription: "25 Wet Petfood - Cutting",
            materialLongDescription: "25 Wet Petfood - Cutting",
            parentSpendCatId: 184,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1100,
            level: 3,
            code: "S250144",
            name: "25 Wet Petfood - Emulsifying",
            materialCode: "90072497",
            materialDescription: "25 Wet Petfood - Emulsifying",
            materialLongDescription: "25 Wet Petfood - Emulsifying",
            parentSpendCatId: 184,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1101,
            level: 3,
            code: "S250145",
            name: "25 Wet Petfood - Forming",
            materialCode: "90072496",
            materialDescription: "25 Wet Petfood - Forming",
            materialLongDescription: "25 Wet Petfood - Forming",
            parentSpendCatId: 184,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1102,
            level: 3,
            code: "S250146",
            name: "25 Wet Petfood - Grains Handling",
            materialCode: "90072495",
            materialDescription: "25 Wet Petfood - Grains Handling",
            materialLongDescription: "25 Wet Petfood - Grains Handling",
            parentSpendCatId: 184,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1103,
            level: 3,
            code: "S250147",
            name: "25 Wet Petfood - Grilling",
            materialCode: "90072494",
            materialDescription: "25 Wet Petfood - Grilling",
            materialLongDescription: "25 Wet Petfood - Grilling",
            parentSpendCatId: 184,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1104,
            level: 3,
            code: "S250148",
            name: "25 Wet Petfood - Liquid Handling",
            materialCode: "90072493",
            materialDescription: "25 Wet Petfood - Liquid Handling",
            materialLongDescription: "25 Wet Petfood - Liquid Handling",
            parentSpendCatId: 184,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1105,
            level: 3,
            code: "S250149",
            name: "25 Wet Petfood - Pouches Handling",
            materialCode: "90072492",
            materialDescription: "25 Wet Petfood - Pouches Handling",
            materialLongDescription: "25 Wet Petfood - Pouches Handling",
            parentSpendCatId: 184,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1106,
            level: 3,
            code: "S250150",
            name: "25 Wet Petfood - Powder Handling",
            materialCode: "90072491",
            materialDescription: "25 Wet Petfood - Powder Handling",
            materialLongDescription: "25 Wet Petfood - Powder Handling",
            parentSpendCatId: 184,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1107,
            level: 3,
            code: "S250151",
            name: "25 Wet Petfood - Pre-Breaking",
            materialCode: "90072490",
            materialDescription: "25 Wet Petfood - Pre-Breaking",
            materialLongDescription: "25 Wet Petfood - Pre-Breaking",
            parentSpendCatId: 184,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1108,
            level: 3,
            code: "S250152",
            name: "25 Wet Petfood - Retorting",
            materialCode: "90072489",
            materialDescription: "25 Wet Petfood - Retorting",
            materialLongDescription: "25 Wet Petfood - Retorting",
            parentSpendCatId: 184,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1109,
            level: 3,
            code: "S250153",
            name: "25 Wet Petfood - Steaming",
            materialCode: "90072488",
            materialDescription: "25 Wet Petfood - Steaming",
            materialLongDescription: "25 Wet Petfood - Steaming",
            parentSpendCatId: 184,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1110,
            level: 3,
            code: "S250154",
            name: "25 Wet Petfood - Wet Grinding, Milling",
            materialCode: "90072487",
            materialDescription: "25 Wet Petfood - Wet Grinding, Milling",
            materialLongDescription: "25 Wet Petfood - Wet Grinding, Milling",
            parentSpendCatId: 184,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1111,
            level: 3,
            code: "S250155",
            name: "25 Wet Petfood - Wet Mixing, Feeding",
            materialCode: "90072486",
            materialDescription: "25 Wet Petfood - Wet Mixing, Feeding",
            materialLongDescription: "25 Wet Petfood - Wet Mixing, Feeding",
            parentSpendCatId: 184,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
    ],
  },
  {
    id: 18,
    level: 1,
    code: "S26",
    name: "26 Beverages Systems",
    materialCode: null,
    materialDescription: null,
    materialLongDescription: null,
    parentSpendCatId: 0,
    parentSpendCatCode: null,
    active: true,
    spendCategories: [
      {
        id: 185,
        level: 2,
        code: "S260001",
        name: "26 Beverages machines for sales and spare parts",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 18,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 1112,
            level: 3,
            code: "S260002",
            name: "26 Tooling related to plastic injection molding",
            materialCode: "90072485",
            materialDescription: "26 BEV Tools Plastic",
            materialLongDescription:
              "26 Tooling related to plastic injection molding",
            parentSpendCatId: 185,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1113,
            level: 3,
            code: "S260003",
            name: "26 Tooling related to metal and die-casting production",
            materialCode: "90072484",
            materialDescription: "26 BEV Tool Die Cast",
            materialLongDescription:
              "26 Tooling related to metal and die-casting production",
            parentSpendCatId: 185,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1114,
            level: 3,
            code: "S260004",
            name: "26 Tooling related to other material production",
            materialCode: "90072483",
            materialDescription: "26 BEV Tool OtherPrd",
            materialLongDescription:
              "26 Tooling related to other material production",
            parentSpendCatId: 185,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1115,
            level: 3,
            code: "S260005",
            name: "26 Functional testing equipment",
            materialCode: "90072482",
            materialDescription: "26 BEV FuncTestEquip",
            materialLongDescription: "26 Functional testing equipment",
            parentSpendCatId: 185,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1116,
            level: 3,
            code: "S260006",
            name: "26 Assembly tool and jigs",
            materialCode: "90072481",
            materialDescription: "26 BEV AssemblyTools",
            materialLongDescription: "26 Assembly tool and jigs",
            parentSpendCatId: 185,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1117,
            level: 3,
            code: "S260007",
            name: "26 Automatic assembly equipment",
            materialCode: "90072480",
            materialDescription: "26 BEV AutomatEquip.",
            materialLongDescription: "26 Automatic assembly equipment",
            parentSpendCatId: 185,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1118,
            level: 3,
            code: "S260008",
            name: "26 Service provided with tooling and equipment",
            materialCode: "90072474",
            materialDescription: "26 BEV Tool Services",
            materialLongDescription:
              "26 Service provided with tooling and equipment",
            parentSpendCatId: 185,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
    ],
  },
  {
    id: 19,
    level: 1,
    code: "S30",
    name: "30 Activation",
    materialCode: null,
    materialDescription: null,
    materialLongDescription: null,
    parentSpendCatId: 0,
    parentSpendCatCode: null,
    active: false,
    spendCategories: [
      {
        id: 186,
        level: 2,
        code: "S300001",
        name: "30 Promotional items",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 19,
        parentSpendCatCode: null,
        active: false,
        spendCategories: [
          {
            id: 1119,
            level: 3,
            code: "S300002",
            name: "30 Ceramic food contact",
            materialCode: "90072174",
            materialDescription: "30 Ceramic food contact",
            materialLongDescription:
              "All ceramic items in contact with food.e.g. Mug, bowl, spoon, fork, saucer, pot.",
            parentSpendCatId: 186,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1120,
            level: 3,
            code: "S300003",
            name: "30 Glass food contact",
            materialCode: "90072153",
            materialDescription: "30 Glass food contact",
            materialLongDescription:
              "All glass items in contact with food.e.g cup,container, bowl, lunch-box, jar.",
            parentSpendCatId: 186,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1121,
            level: 3,
            code: "S300004",
            name: "30 Plastic food contact",
            materialCode: "90072154",
            materialDescription: "30 Plastic food contact",
            materialLongDescription:
              "All plastic items in contact with food.e.g.cup, tumbler, bottle, bowl, container, saucer, spoon, fork.",
            parentSpendCatId: 186,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1122,
            level: 3,
            code: "S300005",
            name: "30 Metal food contact",
            materialCode: "90072155",
            materialDescription: "30 Metal food contact",
            materialLongDescription:
              "All metal items in contact with food.e.g.tumbler, bottle, container, spoon, fork, pan.",
            parentSpendCatId: 186,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1123,
            level: 3,
            code: "S300006",
            name: "30 Tin box",
            materialCode: "90072156",
            materialDescription: "30 Tin box",
            materialLongDescription:
              "All kind of tin product. e.g. tin can, canister, saving pot, container.",
            parentSpendCatId: 186,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1124,
            level: 3,
            code: "S300007",
            name: "30 Tableware Other Food Contact",
            materialCode: "90072157",
            materialDescription: "30 Tableware Other Food Contact",
            materialLongDescription: "Wooden chopstick,wooden chopping board.",
            parentSpendCatId: 186,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1125,
            level: 3,
            code: "S300008",
            name: "30 Plush",
            materialCode: "90072158",
            materialDescription: "30 Plush",
            materialLongDescription:
              "All kind of plush toys, such as Blue bear, lint, cuddy toy.",
            parentSpendCatId: 186,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1126,
            level: 3,
            code: "S300009",
            name: "30 Publishing",
            materialCode: "90072159",
            materialDescription: "30 Publishing",
            materialLongDescription:
              "Already printed books - cooking books, or special notebooks (with pen, etc.). The rest are under commercial prints. ",
            parentSpendCatId: 186,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1127,
            level: 3,
            code: "S300010",
            name: "30 Decoration",
            materialCode: "90072180",
            materialDescription: "30 Decoration",
            materialLongDescription:
              "Decoration items that are used to pimp a room, such as: flowers, balloons, streamers.",
            parentSpendCatId: 186,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1128,
            level: 3,
            code: "S300011",
            name: "30 Electronics",
            materialCode: "90072181",
            materialDescription: "30 Electronics",
            materialLongDescription:
              "USB, fitness band, bluetooth speaker,headphone, mobile devices,froth makers,blenders,hand mixers",
            parentSpendCatId: 186,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1129,
            level: 3,
            code: "S300012",
            name: "30 Perishable/Food",
            materialCode: "90072182",
            materialDescription: "30 Perishable/Food",
            materialLongDescription:
              "Gastronomic products or any other food products offered to consumers as a gift. ",
            parentSpendCatId: 186,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1130,
            level: 3,
            code: "S300013",
            name: "30 Tickets/ vouchers",
            materialCode: "90072183",
            materialDescription: "30 Tickets/ vouchers",
            materialLongDescription:
              "Vouchers or tickets given away for promotional reasons. Example: voucher for electronics/voucher for a day in spa.",
            parentSpendCatId: 186,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1131,
            level: 3,
            code: "S300014",
            name: "30 Plastic toys",
            materialCode: "90072184",
            materialDescription: "30 Plastic toys",
            materialLongDescription:
              "All kind of pastic toys, such as princess, franchised items (minions, star wars), castle.",
            parentSpendCatId: 186,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1132,
            level: 3,
            code: "S300015",
            name: "30 Toys not made of plastic",
            materialCode: "90072185",
            materialDescription: "30 Toys not made of plastic",
            materialLongDescription: "Paper toys, wooden toys, metal toys.",
            parentSpendCatId: 186,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1133,
            level: 3,
            code: "S300016",
            name: "30 Games",
            materialCode: "90072186",
            materialDescription: "30 Games",
            materialLongDescription: "Puzzle, badminton, soccer ball, cards.",
            parentSpendCatId: 186,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1134,
            level: 3,
            code: "S300017",
            name: "30 Desk Accessories",
            materialCode: "90072187",
            materialDescription: "30 Desk Accessories",
            materialLongDescription:
              "Desk accessories/stationary, such as: pens, accessories, business card box.",
            parentSpendCatId: 186,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1135,
            level: 3,
            code: "S300018",
            name: "30 Textile",
            materialCode: "90072188",
            materialDescription: "30 Textile",
            materialLongDescription:
              "Uniform, Apon, t-shirt, cap (except bag).",
            parentSpendCatId: 186,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1136,
            level: 3,
            code: "S300019",
            name: "30 Bags",
            materialCode: "90072189",
            materialDescription: "30 Bags",
            materialLongDescription:
              "Bag made by all kind of materials (except paper bag).",
            parentSpendCatId: 186,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1137,
            level: 3,
            code: "S300020",
            name: "30 Eco friendly items",
            materialCode: "90072190",
            materialDescription: "30 Eco friendly items",
            materialLongDescription:
              "All promotional items made with sustainable materials, or with recycled or recyclable packaging.",
            parentSpendCatId: 186,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1138,
            level: 3,
            code: "S300021",
            name: "30 Umbrellas",
            materialCode: "90072191",
            materialDescription: "30 Umbrellas",
            materialLongDescription:
              "Umbrella, Parasol, tent as a gift not a POSM.",
            parentSpendCatId: 186,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1139,
            level: 3,
            code: "S300022",
            name: "30 Promotional fulfillment, logistic, warehouse",
            materialCode: "90072192",
            materialDescription:
              "30 Promotional fulfillment, logistic, warehouse",
            materialLongDescription:
              "All transportation cost, transport by sea, air, truck",
            parentSpendCatId: 186,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
        ],
      },
      {
        id: 187,
        level: 2,
        code: "S300023",
        name: "30 Print",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 19,
        parentSpendCatCode: null,
        active: false,
        spendCategories: [
          {
            id: 1140,
            level: 3,
            code: "S300024",
            name: "30 Leaflets/Posters",
            materialCode: "90072193",
            materialDescription: "30 Leaflets/Posters",
            materialLongDescription:
              "One page printed. It can be also one page of bigger format  e.g. A3 leaflet folded so it appears as 4 pages. \r\nEx. Poster, Sampling sleeve, Leaflet\r\n",
            parentSpendCatId: 187,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1141,
            level: 3,
            code: "S300025",
            name: "30 Brochure/Magazine",
            materialCode: "90072194",
            materialDescription: "30 Brochure/Magazine",
            materialLongDescription:
              "More pages bundled together - more complex to assemble.\r\nEx. Brochure, WIES Tool Kit, Catalogue, booklet\r\n",
            parentSpendCatId: 187,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1142,
            level: 3,
            code: "S300026",
            name: "30 Tags/Labels",
            materialCode: "90072195",
            materialDescription: "30 Tags/Labels",
            materialLongDescription:
              "Small formats, but without adhesive. \r\nEx. 3D sticker, pallet label, freezer sticker\r\n",
            parentSpendCatId: 187,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1143,
            level: 3,
            code: "S300027",
            name: "30 Business forms",
            materialCode: "90072196",
            materialDescription: "30 Business forms",
            materialLongDescription:
              "All the products related to the business correspondence. \r\nEx. Envelopes, letter heads, business cards, blocks, sticky notes, Contest form\r\n",
            parentSpendCatId: 187,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1144,
            level: 3,
            code: "S300028",
            name: "30 Bus/Lifts stickers",
            materialCode: "90072197",
            materialDescription: "30 Bus/Lifts stickers",
            materialLongDescription:
              "All kind of prints which are self-adhesive. Ex. stickers for the cars or other vehicles, declaration stickers, floor stickers, wobblers, totems, show window sticker, Pillar sticker, lift sticker\r\n",
            parentSpendCatId: 187,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1145,
            level: 3,
            code: "S300029",
            name: "30 Shopping bags",
            materialCode: "90072198",
            materialDescription: "30 Shopping bags",
            materialLongDescription:
              "Shopping bags made of paper. Excl. Plastic/Cotton/PP bag\r\n",
            parentSpendCatId: 187,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
        ],
      },
      {
        id: 188,
        level: 2,
        code: "S300030",
        name: "30 POSM",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 19,
        parentSpendCatCode: null,
        active: false,
        spendCategories: [
          {
            id: 1146,
            level: 3,
            code: "S300031",
            name: "30 Temp displays",
            materialCode: "90072199",
            materialDescription: "30 Temp displays",
            materialLongDescription:
              "POSM displays on which our products are placed. Used once for the period up to 6 months and after that disposed. \r\nEx. Pop-up display, Promo islands, special shape displays, Theater, Block display, standee, hang sell, Clip Strip\r\n",
            parentSpendCatId: 188,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1147,
            level: 3,
            code: "S300032",
            name: "30 Semi-perm displays",
            materialCode: "90072200",
            materialDescription: "30 Semi-perm displays",
            materialLongDescription:
              "POSM displays on which our products are placed. Can be re-used more than 1x or stand in the POS for a longer period (more than 6 months). \r\nEx. Metal parasites, metal racks, plastic display, PET Chiller Parasite, Chiller Tray, Metal standee, Hi-Impact Shelf extension\r\n",
            parentSpendCatId: 188,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1148,
            level: 3,
            code: "S300033",
            name: "30 POS Signage",
            materialCode: "90072201",
            materialDescription: "30 POS Signage",
            materialLongDescription:
              "POS materials used to attract the consumers by applying visual attributes of the brands or products, but not used to place the products on them. \r\nEx. Gondola Head, Stick out, Corporate Hanger, forex cube, Specific Hanger, Shelf Strips, Shelf liner, Shelf divider, Dangler, Wobbler, Parasite Privilege Panel, \r\nNestle Street, Topper, Roll-up, Wheel of Fortune, Totem, Pallet Wrapper  OOH: parasols, parasol base, dust bins, chairs\r\n",
            parentSpendCatId: 188,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1149,
            level: 3,
            code: "S300034",
            name: "30 POS Accessories",
            materialCode: "90072202",
            materialDescription: "30 POS Accessories",
            materialLongDescription:
              "Usually decorative items not placed on the shelf and not part of a display, used to disrupt and increase visibility to enhance sales. \r\nEx. balloons for decoration, other decoration items; shelf-pusher; shelf feeder Promo box, cube box\r\n",
            parentSpendCatId: 188,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1150,
            level: 3,
            code: "S300035",
            name: "30 Permanent furniture",
            materialCode: "90072203",
            materialDescription: "30 Permanent furniture",
            materialLongDescription:
              "Permanent furniture (counters, cabinets, tables, shelves, etc) to equip Nespresso boutiques.\r\n",
            parentSpendCatId: 188,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1151,
            level: 3,
            code: "S300036",
            name: "30 Retail & Trade coms",
            materialCode: "90072204",
            materialDescription: "30 Retail & Trade coms",
            materialLongDescription:
              "Show windows and retail communication campaign in store or boutique. \r\n",
            parentSpendCatId: 188,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1152,
            level: 3,
            code: "S300037",
            name: "30 POS Syndicated Placement",
            materialCode: "90072205",
            materialDescription: "30 POS Syndicated Placement",
            materialLongDescription: "",
            parentSpendCatId: 188,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
        ],
      },
      {
        id: 189,
        level: 2,
        code: "S300038",
        name: "30 Field activation",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 19,
        parentSpendCatCode: null,
        active: false,
        spendCategories: [
          {
            id: 1153,
            level: 3,
            code: "S300039",
            name: "30 Merchandisers",
            materialCode: "90072206",
            materialDescription: "30 Merchandisers",
            materialLongDescription:
              "Personnel responsible of assuring the availability of Nestlé products on shelve, at the appropriate time and in the correct quantities. Maintain and restock shelves and provides optimum display of products (picture of success). Complete call report by observing display and pricing of competitors' products. Helps field sales representatives with special promotions by setting-up displays at aisle ends",
            parentSpendCatId: 189,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1154,
            level: 3,
            code: "S300040",
            name: "30 Promoters",
            materialCode: "90072207",
            materialDescription: "30 Promoters",
            materialLongDescription:
              "Personnel demonstrating Nestle products, providing information on promoted products, persuading to purchase products and responsible for boosting sales through : e.g. direct contact with shoppers in POS, samples distribution, product testing, coupons distribution,…\r\nPromoters similar naming examples : Demo Activities (within Sales), Demovets (Purina Demos), Demoladies (Nutrition), Demoadvisors (Galderma), Ambassadors (Dolce and Nespresso), Demonstrators, Nutrition advisors, Push Girls, Samplers, Store Service Reps, Brand Advisors, …",
            parentSpendCatId: 189,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
        ],
      },
      {
        id: 190,
        level: 2,
        code: "S300041",
        name: "30 Design",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 19,
        parentSpendCatCode: null,
        active: false,
        spendCategories: [
          {
            id: 1155,
            level: 3,
            code: "S300042",
            name: "30 Brand Identity",
            materialCode: "90072208",
            materialDescription: "30 Brand Identity",
            materialLongDescription: "",
            parentSpendCatId: 190,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1156,
            level: 3,
            code: "S300043",
            name: "30 Shopper Activation",
            materialCode: "90072209",
            materialDescription: "30 creative design",
            materialLongDescription: "",
            parentSpendCatId: 190,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1157,
            level: 3,
            code: "S300044",
            name: "30 Strategic Design",
            materialCode: "90072210",
            materialDescription: "30 Packaging design",
            materialLongDescription: "",
            parentSpendCatId: 190,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1158,
            level: 3,
            code: "S300045",
            name: "30 Artwork & Prepress",
            materialCode: "90072211",
            materialDescription: "30 Artwork",
            materialLongDescription: "",
            parentSpendCatId: 190,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1159,
            level: 3,
            code: "S300046",
            name: "30 Adaptive design",
            materialCode: "90072212",
            materialDescription: "30 Design Local Adaptation",
            materialLongDescription: "",
            parentSpendCatId: 190,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1477,
            level: 3,
            code: "S300078",
            name: "30 CGI/E-commerce images",
            materialCode: "90093903",
            materialDescription: "30 CGI/E-commerce images",
            materialLongDescription: null,
            parentSpendCatId: 190,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
        ],
      },
      {
        id: 191,
        level: 2,
        code: "S300047",
        name: "30 Public Relations",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 19,
        parentSpendCatCode: null,
        active: false,
        spendCategories: [
          {
            id: 1160,
            level: 3,
            code: "S300048",
            name: "30 Brand Public relations",
            materialCode: "90072213",
            materialDescription: "30 Brand Public relations",
            materialLongDescription: "",
            parentSpendCatId: 191,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1161,
            level: 3,
            code: "S300049",
            name: "30 Corporate Public relations",
            materialCode: "90072214",
            materialDescription: "30 Corporate Public relations",
            materialLongDescription: "",
            parentSpendCatId: 191,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
        ],
      },
      {
        id: 192,
        level: 2,
        code: "S300050",
        name: "30 Shopper MKT",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 19,
        parentSpendCatCode: null,
        active: false,
        spendCategories: [
          {
            id: 1162,
            level: 3,
            code: "S300051",
            name: "30 Shopper Global agencies",
            materialCode: "90072215",
            materialDescription: "30 Shopper Global agencies",
            materialLongDescription:
              "Shopper marketing managed centrally at Global level. Shopper marketing is the process of understanding shoppers and using that understanding to develop a marketing mix which influences shopper behavior with the objective to impact consumption of the brand and/or BU in various channels (Omnichannel, Online, Offline). In terms of shopper Global Agency deliverables, this translates usually into proposal of shopper communication strategy and activation,  creative concept and execution, global toolkit development and deployment with markets.",
            parentSpendCatId: 192,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1163,
            level: 3,
            code: "S300052",
            name: "30 Shopper market - regional agencies",
            materialCode: "90072216",
            materialDescription: "30 Shopper market - regional agencies",
            materialLongDescription: "",
            parentSpendCatId: 192,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
        ],
      },
      {
        id: 193,
        level: 2,
        code: "S300053",
        name: "30 Promotional MKT",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 19,
        parentSpendCatCode: null,
        active: false,
        spendCategories: [
          {
            id: 1164,
            level: 3,
            code: "S300054",
            name: "30 Digital Coupon non targeted",
            materialCode: "90072217",
            materialDescription: "30 Digital Coupon non targeted",
            materialLongDescription: "",
            parentSpendCatId: 193,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1165,
            level: 3,
            code: "S300055",
            name: "30 Print Coupon non targeted",
            materialCode: "90072218",
            materialDescription: "30 Print Coupon non targeted",
            materialLongDescription: "",
            parentSpendCatId: 193,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1166,
            level: 3,
            code: "S300056",
            name: "30 Targeted digital coupon",
            materialCode: "90072219",
            materialDescription: "30 Targeted digital coupon",
            materialLongDescription: "",
            parentSpendCatId: 193,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1167,
            level: 3,
            code: "S300057",
            name: "30 Targeted Print Coupon",
            materialCode: "90072220",
            materialDescription: "30 Targeted Print Coupon",
            materialLongDescription: "",
            parentSpendCatId: 193,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1168,
            level: 3,
            code: "S300058",
            name: "30 Consumer call center",
            materialCode: "90072221",
            materialDescription: "30 Consumer call center",
            materialLongDescription: "",
            parentSpendCatId: 193,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1169,
            level: 3,
            code: "S300059",
            name: "30 Incentives",
            materialCode: "90072222",
            materialDescription: "30 Incentives",
            materialLongDescription: "",
            parentSpendCatId: 193,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1170,
            level: 3,
            code: "S300060",
            name: "30 Loyalty Program",
            materialCode: "90072223",
            materialDescription: "30 Loyalty Program",
            materialLongDescription: "",
            parentSpendCatId: 193,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1171,
            level: 3,
            code: "S300061",
            name: "30 Direct mail",
            materialCode: "90072224",
            materialDescription: "30 Direct mail",
            materialLongDescription: "",
            parentSpendCatId: 193,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1172,
            level: 3,
            code: "S300062",
            name: "30 Mail Sampling",
            materialCode: "90072225",
            materialDescription: "30 Mail Sampling",
            materialLongDescription: "",
            parentSpendCatId: 193,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1173,
            level: 3,
            code: "S300063",
            name: "30 Field sampling",
            materialCode: "90072226",
            materialDescription: "30 Field sampling",
            materialLongDescription: "",
            parentSpendCatId: 193,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1174,
            level: 3,
            code: "S300064",
            name: "30 Contests",
            materialCode: "90072227",
            materialDescription: "30 Contests",
            materialLongDescription: "",
            parentSpendCatId: 193,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1175,
            level: 3,
            code: "S300065",
            name: "30 Co- Marketing",
            materialCode: "90072228",
            materialDescription: "30 Co- Marketing",
            materialLongDescription: "",
            parentSpendCatId: 193,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
        ],
      },
      {
        id: 194,
        level: 2,
        code: "S300066",
        name: "30 Event Marketing",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 19,
        parentSpendCatCode: null,
        active: false,
        spendCategories: [
          {
            id: 1176,
            level: 3,
            code: "S300067",
            name: "30 Corporate Events",
            materialCode: "90072229",
            materialDescription: "30 Corporate Events",
            materialLongDescription: "",
            parentSpendCatId: 194,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1177,
            level: 3,
            code: "S300068",
            name: "30 Internal Events",
            materialCode: "90072230",
            materialDescription: "30 Internal Events",
            materialLongDescription: "",
            parentSpendCatId: 194,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1178,
            level: 3,
            code: "S300069",
            name: "30 Business Events",
            materialCode: "90072231",
            materialDescription: "30 Business Events",
            materialLongDescription: "",
            parentSpendCatId: 194,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1179,
            level: 3,
            code: "S300070",
            name: "30 Consumer Events",
            materialCode: "90072232",
            materialDescription: "30 Consumer Events",
            materialLongDescription: "",
            parentSpendCatId: 194,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1180,
            level: 3,
            code: "S300071",
            name: "30 Sponsorship",
            materialCode: "90072233",
            materialDescription: "30 Sponsorship",
            materialLongDescription: "",
            parentSpendCatId: 194,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1181,
            level: 3,
            code: "S300072",
            name: "30 Talents, Celebrities, influencers",
            materialCode: "90072234",
            materialDescription: "30 Talents, Celebrities, influencers",
            materialLongDescription: "",
            parentSpendCatId: 194,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1182,
            level: 3,
            code: "S300073",
            name: "30 Healthcare Medical Events",
            materialCode: "90072235",
            materialDescription: "30 Healthcare Medical Events",
            materialLongDescription: "",
            parentSpendCatId: 194,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
        ],
      },
      {
        id: 195,
        level: 2,
        code: "S300074",
        name: "ZZ 30 Prepress",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 19,
        parentSpendCatCode: null,
        active: false,
        spendCategories: [
          {
            id: 1183,
            level: 3,
            code: "S300075",
            name: "ZZ 30 Prepress (Do not Use)",
            materialCode: "90072236",
            materialDescription: "30 Prepress",
            materialLongDescription: "",
            parentSpendCatId: 195,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
        ],
      },
      {
        id: 196,
        level: 2,
        code: "S300076",
        name: "30 Total Trade Spend – TTS",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 19,
        parentSpendCatCode: null,
        active: false,
        spendCategories: [
          {
            id: 1184,
            level: 3,
            code: "S300077",
            name: "30 Total Trade Spend – TTS",
            materialCode: "90072797",
            materialDescription: "30 Total Trade Spend – TTS",
            materialLongDescription: "",
            parentSpendCatId: 196,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
        ],
      },
    ],
  },
  {
    id: 20,
    level: 1,
    code: "S31",
    name: "31 Content",
    materialCode: null,
    materialDescription: null,
    materialLongDescription: null,
    parentSpendCatId: 0,
    parentSpendCatCode: null,
    active: false,
    spendCategories: [
      {
        id: 197,
        level: 2,
        code: "S310001",
        name: "31 Creative Agency Remuneration",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 20,
        parentSpendCatCode: null,
        active: false,
        spendCategories: [
          {
            id: 1185,
            level: 3,
            code: "S310002",
            name: "31 Creative Agency Remuneration & Fees",
            materialCode: "90072160",
            materialDescription: "31 Creative Agency Remuneration & Fees",
            materialLongDescription:
              "Related to Annual or AdHoc Creative Agencies fees as result of Scope of Work process. If Non Aligned Agency (outside Publicis, WPP, IPG), then categorization in regard of Cost Estimates covering Agency Fees. ",
            parentSpendCatId: 197,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
        ],
      },
      {
        id: 198,
        level: 2,
        code: "S310003",
        name: "31 Digitial Agency Remuneration",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 20,
        parentSpendCatCode: null,
        active: false,
        spendCategories: [
          {
            id: 1186,
            level: 3,
            code: "S310004",
            name: "31 Digital Agency Fees",
            materialCode: "90072161",
            materialDescription: "31 Digital Agency Fees",
            materialLongDescription:
              "Related to Annual or AdHoc Digital Agencies fees as result of Scope of Work or Digital Menu Pricing process. If Non Aligned Agency.",
            parentSpendCatId: 198,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
        ],
      },
      {
        id: 199,
        level: 2,
        code: "S310005",
        name: "31 Advertising Production - Traditional / Offline",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 20,
        parentSpendCatCode: null,
        active: false,
        spendCategories: [
          {
            id: 1187,
            level: 3,
            code: "S310006",
            name: "31 Advertising Production Out of Home",
            materialCode: "90072162",
            materialDescription: "31 Advertising Production Out of Home",
            materialLongDescription:
              "Related to Production Houses cost (i.e. photoshooting) outside of Agency fees - for Outdoor Communication Deliverables (Billboards, Citylights....). Cost Estimate comes usually as direct submission from Production House, or as third party cost from Creative Agency.",
            parentSpendCatId: 199,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1188,
            level: 3,
            code: "S310007",
            name: "31 Advertising Production TV & Radio",
            materialCode: "90072164",
            materialDescription: "31 Advertising Production TV & Radio",
            materialLongDescription:
              "Related to Production Houses cost (i.e. photoshooting) outside of Agency fees - for Video and Radio deliverables - non Digital (TVCs, Radio Copies....). Cost Estimate comes usually as direct submission from Production House, or as third party cost from Creative Agency.",
            parentSpendCatId: 199,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1189,
            level: 3,
            code: "S310008",
            name: "31 Advertising Production Photography and Image Library",
            materialCode: "90072165",
            materialDescription:
              "31 Advertising Production Photography and Image Library",
            materialLongDescription:
              "Related to Production Houses cost (i.e. photoshooting) outside of Agency fees - for Images content production (Photography…). Cost Estimate comes usually as direct submission from Production House, or as third party cost from Creative Agency.",
            parentSpendCatId: 199,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1190,
            level: 3,
            code: "S310009",
            name: "31 Advertising Production Print ",
            materialCode: "90072166",
            materialDescription: "31 Advertising Production Print ",
            materialLongDescription:
              "Related to Production Houses cost (i.e. photoshooting) outside of Agency fees - for Print Ads Deliverables (resizing, formatting, photography for print). Cost Estimate comes usually as direct submission from Production House, or as third party cost from Creative Agency.",
            parentSpendCatId: 199,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1191,
            level: 3,
            code: "S310010",
            name: "31 Advertising Production Consultants",
            materialCode: "90072167",
            materialDescription: "31 Advertising Production Consultants",
            materialLongDescription:
              "Related to Production Houses cost (i.e. photoshooting) outside of Agency fees - for Production Consultancy services (independent consultants supportin negotiations of production costs). Cost Estimate comes usually as direct submission from Production House, or as third party cost from Creative Agency.",
            parentSpendCatId: 199,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
        ],
      },
      {
        id: 200,
        level: 2,
        code: "S310011",
        name: "31 Advertising Production - Digital",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 20,
        parentSpendCatCode: null,
        active: false,
        spendCategories: [
          {
            id: 1192,
            level: 3,
            code: "S310012",
            name: "31 Advertising Production Digital Display Ads",
            materialCode: "90072168",
            materialDescription:
              "31 Advertising Production Digital Display Ads",
            materialLongDescription:
              "Related to Production Houses cost (i.e. photoshooting) outside of Agency fees - for Digital Communication Deliverables (Banners, Gifs...). Cost Estimate comes usually as direct submission from Production House, or as third party cost from Creative Agency.",
            parentSpendCatId: 200,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1193,
            level: 3,
            code: "S310013",
            name: "31 Advertising Production Digital Web Sites (Owned)",
            materialCode: "90072169",
            materialDescription:
              "31 Advertising Production Digital Web Sites (Owned)",
            materialLongDescription:
              "Related to Production Houses cost (i.e. photoshooting) outside of Agency fees - for digital websites landing pages (EXCLUDING ANY FRONT END / BACK END activity). Cost Estimate comes usually as direct submission from Production House, or as third party cost from Creative Agency.",
            parentSpendCatId: 200,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1194,
            level: 3,
            code: "S310014",
            name: "31 Advertising Production Digital Social Media",
            materialCode: "90072170",
            materialDescription:
              "31 Advertising Production Digital Social Media",
            materialLongDescription:
              "Related to Production Houses cost (i.e. photoshooting) outside of Agency fees - for Social Digital Communication Deliverables (Posts, Gifs...). Cost Estimate comes usually as direct submission from Production House, or as third party cost from Creative Agency.",
            parentSpendCatId: 200,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1196,
            level: 3,
            code: "S310016",
            name: "31 Advertising Production Digital Video",
            materialCode: "90072172",
            materialDescription: "31 Advertising Production Digital Video",
            materialLongDescription:
              "Related to Production Houses cost (i.e. photoshooting) outside of Agency fees - for DIGITAL Video deliverables (i.e. Youtube). Cost Estimate comes usually as direct submission from Production House, or as third party cost from Creative Agency.",
            parentSpendCatId: 200,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
        ],
      },
    ],
  },
  {
    id: 21,
    level: 1,
    code: "S32",
    name: "32 Insight",
    materialCode: null,
    materialDescription: null,
    materialLongDescription: null,
    parentSpendCatId: 0,
    parentSpendCatCode: null,
    active: false,
    spendCategories: [
      {
        id: 201,
        level: 2,
        code: "S320001",
        name: "32 Custom Research",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 21,
        parentSpendCatCode: null,
        active: false,
        spendCategories: [
          {
            id: 1198,
            level: 3,
            code: "S320002",
            name: "32 Concept Tests",
            materialCode: "90072237",
            materialDescription: "32 Concept Tests",
            materialLongDescription:
              "The core objective being to identify the design principles. Involves the team creating rough prototypes and presenting them to consumers. The output is to select the highest ranking concept that resonate with our consumers . Methodology could be qualitative/ quantitative or a combination of both",
            parentSpendCatId: 201,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1199,
            level: 3,
            code: "S320003",
            name: "32 Vol. Concept Tests",
            materialCode: "90072238",
            materialDescription: "32 Vol. Concept Tests",
            materialLongDescription:
              "Similar to concept test, but quantitative in nature. Methodology would be mostly sequential monadic. Helps identify what volumes this concept would be able to generate if launched.",
            parentSpendCatId: 201,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1200,
            level: 3,
            code: "S320004",
            name: "32 Packgaging Evaluation Tests",
            materialCode: "90072239",
            materialDescription: "32 Packgaging Evaluation Tests",
            materialLongDescription:
              "The core objective is to identify packaging and design. Involves the team creating rough prototypes and presenting them to consumers. The output is to select the best design based on attractiveness to consumers and our ability to win on Nestle parameters. Methodology could be qualitative/ quantitative or a combination of both",
            parentSpendCatId: 201,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1201,
            level: 3,
            code: "S320005",
            name: "32 Usage & Atitude",
            materialCode: "90072240",
            materialDescription: "32 Usage & Atitude",
            materialLongDescription:
              "The key objective of a U&A is to help the team better understand the market by understanding patterns in consumer usage and correlated attitudes towards a product or service. The study has to identify growth opportunities by answering questions on whom to target, with what, how and Why.",
            parentSpendCatId: 201,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1202,
            level: 3,
            code: "S320006",
            name: "32 Shopper Behaviour",
            materialCode: "90072241",
            materialDescription: "32 Shopper Behaviour",
            materialLongDescription:
              "Shopper studies help understand who are the influencers and decision makers when it comes to shopping, and their behavior in side the shop.",
            parentSpendCatId: 201,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1203,
            level: 3,
            code: "S320007",
            name: "32 Communica Tests",
            materialCode: "90072242",
            materialDescription: "32 Communica Tests",
            materialLongDescription:
              "Communication studies are mostly studies done on copy-testing. The broad objectives being to evaluate the impact and persuasion of our advertising in comparison to other ads as well as help provide diagnostics (strengths, weaknesses, and opportunities for improvement). Some other studies that fall under this category are: Slogan test, Big Idea, Campaign Idea, etc...",
            parentSpendCatId: 201,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1204,
            level: 3,
            code: "S320008",
            name: "32 Corporate Equity Tests",
            materialCode: "90072243",
            materialDescription: "32 Corporate Equity Tests",
            materialLongDescription:
              "The key objective of this study is to Track knowledge, understanding and image of the Nestlé Corporate Brand, besides monitoring the Corporate Brand evolution as well as to provide a competitive assessment of the Corporate Brand health. CEMs are quantitative in nature.\r\n",
            parentSpendCatId: 201,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1205,
            level: 3,
            code: "S320009",
            name: "32 Marketing Segmentation",
            materialCode: "90072244",
            materialDescription: "32 Marketing Segmentation",
            materialLongDescription:
              "It is a mix of qualitative and quantitative in general, segmentation studies are done to segment consumers on different attitudes and motivations patterns to help target them in an efficient manner\r\n",
            parentSpendCatId: 201,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1206,
            level: 3,
            code: "S320010",
            name: "32 BR Health Tracker",
            materialCode: "90072245",
            materialDescription: "32 BR Health Tracker",
            materialLongDescription:
              "BHTS are done mainly to understand brand performance and track brand perception of our brands vis-à-vis competition. Besides the study also needs to assess marketing effectiveness of various marketing campaigns the brand has embarked upon and also provide a holistic landscape of the category. BHTs are quantitative in nature.",
            parentSpendCatId: 201,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1207,
            level: 3,
            code: "S320011",
            name: "32 Taste Test - 60/40",
            materialCode: "90072246",
            materialDescription: "32 Taste Test - 60/40",
            materialLongDescription:
              "A comprehensive review of our product taste against similar competitor products, what are the potential drivers of Liking and identify potential white spaces. Types of studies are 60/40, Preference Guided Test, Preferred Product Test, etc…",
            parentSpendCatId: 201,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1208,
            level: 3,
            code: "S320012",
            name: "32 Market Mix Model",
            materialCode: "90072247",
            materialDescription: "32 Market Mix Model",
            materialLongDescription:
              "Market Mix modelling is done to estimate impact of various marketing efforts on potential of sales",
            parentSpendCatId: 201,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
        ],
      },
      {
        id: 202,
        level: 2,
        code: "S320013",
        name: "32 Syndicated",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 21,
        parentSpendCatCode: null,
        active: false,
        spendCategories: [
          {
            id: 1209,
            level: 3,
            code: "S320014",
            name: "32 Retail Measure Services",
            materialCode: "90072248",
            materialDescription: "32 Retail Measure Services",
            materialLongDescription:
              "RMS track and measure what shoppers purchase our brand as well as competition from trade (Retailers – Modern and tradition trade) . The core objective is to measure our market shares versus the category. In some cases RMS also tracks sales though wholesalers.  ",
            parentSpendCatId: 202,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1210,
            level: 3,
            code: "S320015",
            name: "32 Consumer Measure Services",
            materialCode: "90072249",
            materialDescription: "32 Consumer Measure Services",
            materialLongDescription:
              "Consumer Household Panel provides monitor shopper and usage patterns (where they purchase, how much they purchase and how do they use) on a continual basis. ",
            parentSpendCatId: 202,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1211,
            level: 3,
            code: "S320016",
            name: "32 Market Intelligence Tool",
            materialCode: "90072250",
            materialDescription: "32 Market Intelligence Tool",
            materialLongDescription:
              "Mostly syndicates reports proving trends on market/ industry/ category overview. For. E.g. Market reports from Economist, BMI, Euromonitor etc.",
            parentSpendCatId: 202,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1212,
            level: 3,
            code: "S320017",
            name: "32 Name Account Data",
            materialCode: "90072251",
            materialDescription: "32 Name Account Data",
            materialLongDescription:
              "Any Retail data that is bought from Key Account Data such as Carrefour, COOP, Spinneys, etc… This data is bought through research agencies such as Nielsen, IRI, etc…",
            parentSpendCatId: 202,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
        ],
      },
    ],
  },
  {
    id: 22,
    level: 1,
    code: "S33",
    name: "33 Media",
    materialCode: null,
    materialDescription: null,
    materialLongDescription: null,
    parentSpendCatId: 0,
    parentSpendCatCode: null,
    active: false,
    spendCategories: [
      {
        id: 203,
        level: 2,
        code: "S330001",
        name: "33 Media Buying",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 22,
        parentSpendCatCode: null,
        active: false,
        spendCategories: [
          {
            id: 1213,
            level: 3,
            code: "S330002",
            name: "33 Offline Media Buying",
            materialCode: "90072115",
            materialDescription: "33 Offline Media Buying",
            materialLongDescription:
              "Traditional: TV, Cinema, Newspaper, billboard, Radio, Magazines media spend through our media agencies (e.g.: GroupM, Publicis, Dentsu, IPG) or in some cases to local media channels\r\n",
            parentSpendCatId: 203,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1214,
            level: 3,
            code: "S330003",
            name: "33 Online Media Buying",
            materialCode: "90072163",
            materialDescription: "33 Online Media Buying",
            materialLongDescription:
              "Digital: Online channels, programmatic media buying, display, search\r\n",
            parentSpendCatId: 203,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
        ],
      },
      {
        id: 204,
        level: 2,
        code: "S330004",
        name: "33 Media Agency Remuneration",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 22,
        parentSpendCatCode: null,
        active: false,
        spendCategories: [
          {
            id: 1215,
            level: 3,
            code: "S330005",
            name: "33 Media Agency Fee",
            materialCode: "90072116",
            materialDescription: "33 Media Agency Fee",
            materialLongDescription:
              "Remuneration negociated with the suppliers whether a flat fee or a % commison on media spend",
            parentSpendCatId: 204,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1216,
            level: 3,
            code: "S330006",
            name: "33 Media Agency Bonus",
            materialCode: "90072117",
            materialDescription: "33 Media Agency Bonus",
            materialLongDescription:
              "Yearly variable incentive based on performance KPIs",
            parentSpendCatId: 204,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
        ],
      },
      {
        id: 205,
        level: 2,
        code: "S330007",
        name: "33 Media Audit",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 22,
        parentSpendCatCode: null,
        active: false,
        spendCategories: [
          {
            id: 1217,
            level: 3,
            code: "S330008",
            name: "33 Media Audit",
            materialCode: "90072118",
            materialDescription: "33 Media Audit",
            materialLongDescription:
              "Expense that Nestle spends to hire firms (media audit or financial audit) to audit our media agencies\r\n",
            parentSpendCatId: 205,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
        ],
      },
      {
        id: 206,
        level: 2,
        code: "S330009",
        name: "33 Media Technical Online & Offline Cost",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 22,
        parentSpendCatCode: null,
        active: false,
        spendCategories: [
          {
            id: 1218,
            level: 3,
            code: "S330010",
            name: "33 Media Technical Online & Offline Cost",
            materialCode: "90072119",
            materialDescription: "33 Media Technical Online & Offline Cost",
            materialLongDescription:
              "This includes all third party costs that Nestlé pays as technical support to our media investments. \r\n\r\nFor example: \r\n- Ad verification (e.g MOAT)\r\n- Adserving: technology and service that places advertisements on Web sites (e.g. Google, Sizmek, Adform..)\r\n- Data Management Platform = store and manage audience data (e.g. Oracle, Google….)\r\n",
            parentSpendCatId: 206,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
        ],
      },
    ],
  },
  {
    id: 23,
    level: 1,
    code: "S34",
    name: "34 Mobility",
    materialCode: null,
    materialDescription: null,
    materialLongDescription: null,
    parentSpendCatId: 0,
    parentSpendCatCode: null,
    active: true,
    spendCategories: [
      {
        id: 207,
        level: 2,
        code: "S340001",
        name: "34 Leased - Company Car",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 23,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 1219,
            level: 3,
            code: "S340002",
            name: "34 Leasing and Management Services",
            materialCode: "90072371",
            materialDescription: "34 Leasing&MgemtServ",
            materialLongDescription: "34 Leasing and Management Services",
            parentSpendCatId: 207,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1220,
            level: 3,
            code: "S340003",
            name: "34 Maintenance",
            materialCode: "90072355",
            materialDescription: "34 L/CC Maintenance",
            materialLongDescription: "34 Maintenance",
            parentSpendCatId: 207,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1221,
            level: 3,
            code: "S340004",
            name: "34 Fuel",
            materialCode: "90072356",
            materialDescription: "34 L/CC Fuel",
            materialLongDescription: "34 Fuel",
            parentSpendCatId: 207,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1222,
            level: 3,
            code: "S340005",
            name: "34 Insurance",
            materialCode: "90072357",
            materialDescription: "34 L/CC Insurance",
            materialLongDescription: "34 Insurance",
            parentSpendCatId: 207,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 208,
        level: 2,
        code: "S340006",
        name: "34 Purchased/Owned - Company Car",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 23,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 1223,
            level: 3,
            code: "S340007",
            name: "34 Purchased/Owned Company Car",
            materialCode: "90072358",
            materialDescription: "34 PurchasedOwnedCar",
            materialLongDescription: "34 Purchased/Owned Company Car",
            parentSpendCatId: 208,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1224,
            level: 3,
            code: "S340008",
            name: "34 Maintenance",
            materialCode: "90072381",
            materialDescription: "34 P/O/CC Maintenance",
            materialLongDescription: "34 Maintenance",
            parentSpendCatId: 208,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1225,
            level: 3,
            code: "S340009",
            name: "34 Fuel",
            materialCode: "90072380",
            materialDescription: "34 P/O/CC Fuel",
            materialLongDescription: "34 Fuel",
            parentSpendCatId: 208,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1226,
            level: 3,
            code: "S340010",
            name: "34 Insurance",
            materialCode: "90072359",
            materialDescription: "34 P/O/CC Insurance",
            materialLongDescription: "34 Insurance",
            parentSpendCatId: 208,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 209,
        level: 2,
        code: "S340011",
        name: "34 Bus/ Shuttles/ Special Vehicles",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 23,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 1227,
            level: 3,
            code: "S340012",
            name: "34 Bus/ Shuttles/ Special Vehicles",
            materialCode: "90072382",
            materialDescription: "34 BusSpecialVehicle",
            materialLongDescription: "34 Bus/ Shuttles/ Special Vehicles",
            parentSpendCatId: 209,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1228,
            level: 3,
            code: "S340013",
            name: "34 Maintenance",
            materialCode: "90072383",
            materialDescription: "34 B/S/SV Maintenance",
            materialLongDescription: "34 Maintenance",
            parentSpendCatId: 209,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1229,
            level: 3,
            code: "S340014",
            name: "34 Fuel",
            materialCode: "90072384",
            materialDescription: "34 B/S/SV Fuel",
            materialLongDescription: "34 Fuel",
            parentSpendCatId: 209,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1230,
            level: 3,
            code: "S340015",
            name: "34 Insurance",
            materialCode: "90072385",
            materialDescription: "34 B/S/SV Insurance",
            materialLongDescription: "34 Insurance",
            parentSpendCatId: 209,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 210,
        level: 2,
        code: "S340016",
        name: "34 Truck Tractor & Truck Trailer",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 23,
        parentSpendCatCode: null,
        active: false,
        spendCategories: [
          {
            id: 1434,
            level: 3,
            code: "S340017",
            name: "34 Truck Tractor & Truck Trailer",
            materialCode: "90072386",
            materialDescription: "ZZ34 Truck Tractor & Truck Trailer",
            materialLongDescription: "",
            parentSpendCatId: 210,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1435,
            level: 3,
            code: "S340018",
            name: "34 Maintenance & Spare Parts",
            materialCode: "90072387",
            materialDescription: "ZZ34 Maintenance & Spare Parts",
            materialLongDescription: "",
            parentSpendCatId: 210,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1436,
            level: 3,
            code: "S340019",
            name: "34 Fuel",
            materialCode: "90072388",
            materialDescription: "ZZ34 Fuel",
            materialLongDescription: "",
            parentSpendCatId: 210,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
        ],
      },
      {
        id: 211,
        level: 2,
        code: "S340020",
        name: "34 Delivery/Service Van",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 23,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 1234,
            level: 3,
            code: "S340021",
            name: "34 Delivery/Service Van",
            materialCode: "90072389",
            materialDescription: "34 Delivery/Serv Van",
            materialLongDescription: "34 Delivery/Service Van",
            parentSpendCatId: 211,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1235,
            level: 3,
            code: "S340022",
            name: "34 Maintenance & Spare Parts",
            materialCode: "90072390",
            materialDescription: "34 MRO",
            materialLongDescription: "34 Maintenance & Spare Parts",
            parentSpendCatId: 211,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1236,
            level: 3,
            code: "S340023",
            name: "34 Fuel",
            materialCode: "90072391",
            materialDescription: "34 D/SLV Fuel",
            materialLongDescription: "34 Fuel",
            parentSpendCatId: 211,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1237,
            level: 3,
            code: "S340043",
            name: "34 Insurance",
            materialCode: "90073158",
            materialDescription: "34 D/SLV Insurance",
            materialLongDescription: "34 Insurance",
            parentSpendCatId: 211,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 212,
        level: 2,
        code: "S340024",
        name: "34 Travel",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 23,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 1238,
            level: 3,
            code: "S340025",
            name: "34 Travel Agency Fees",
            materialCode: "90072392",
            materialDescription: "34 TravelAgency Fees",
            materialLongDescription: "34 Travel Agency Fees",
            parentSpendCatId: 212,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1239,
            level: 3,
            code: "S340026",
            name: "34 Mgt. Support & Tools",
            materialCode: "90072393",
            materialDescription: "34 Mgt.Support&Tools",
            materialLongDescription: "34 Mgt. Support & Tools",
            parentSpendCatId: 212,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1240,
            level: 3,
            code: "S340027",
            name: "34 Airlines",
            materialCode: "90072394",
            materialDescription: "34 Airlines",
            materialLongDescription: "34 Airlines",
            parentSpendCatId: 212,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1241,
            level: 3,
            code: "S340028",
            name: "34 Chartered Lines & Private Jets",
            materialCode: "90072395",
            materialDescription: "34 Chartered/PvtJet",
            materialLongDescription: "34 Chartered Lines & Private Jets",
            parentSpendCatId: 212,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1242,
            level: 3,
            code: "S340029",
            name: "34 Car Rental",
            materialCode: "90072396",
            materialDescription: "34 Car Rental",
            materialLongDescription: "34 Car Rental",
            parentSpendCatId: 212,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1243,
            level: 3,
            code: "S340030",
            name: "34 Accommodation",
            materialCode: "90072397",
            materialDescription: "34 Accommodation",
            materialLongDescription: "34 Accommodation",
            parentSpendCatId: 212,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1244,
            level: 3,
            code: "S340031",
            name: "34 Rail & Boat Transportation",
            materialCode: "90072398",
            materialDescription: "34 Rail/BoatTransprt",
            materialLongDescription: "34 Rail & Boat Transportation",
            parentSpendCatId: 212,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1245,
            level: 3,
            code: "S340032",
            name: "34 Taxi & Exec. Limousine Services",
            materialCode: "90072399",
            materialDescription: "34 Taxi/ExecLimoSvcs",
            materialLongDescription: "34 Taxi & Exec. Limousine Services",
            parentSpendCatId: 212,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1246,
            level: 3,
            code: "S340033",
            name: "34 Food & Beverage",
            materialCode: "90072400",
            materialDescription: "34 Food & Beverage",
            materialLongDescription: "34 Food & Beverage",
            parentSpendCatId: 212,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 213,
        level: 2,
        code: "S340034",
        name: "34 Meetings & Events",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 23,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 1247,
            level: 3,
            code: "S340035",
            name: "34 Event Agency & Support Tools",
            materialCode: "90072402",
            materialDescription: "34 EvntAgcy&SupTools",
            materialLongDescription: "34 Event Agency & Support Tools",
            parentSpendCatId: 213,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1248,
            level: 3,
            code: "S340036",
            name: "34 Event Facility",
            materialCode: "90072403",
            materialDescription: "34 Event Facility",
            materialLongDescription: "34 Event Facility",
            parentSpendCatId: 213,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1249,
            level: 3,
            code: "S340037",
            name: "34 Event related accomodation",
            materialCode: "90072404",
            materialDescription: "34 EvntOvernightStay",
            materialLongDescription: "34 Event related accomodation",
            parentSpendCatId: 213,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1250,
            level: 3,
            code: "S340038",
            name: "34 Other event related services",
            materialCode: "90072405",
            materialDescription: "34 OtherEventServcs",
            materialLongDescription: "34 Other event related services",
            parentSpendCatId: 213,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1251,
            level: 3,
            code: "S340039",
            name: "34 Event related transportation",
            materialCode: "90072406",
            materialDescription: "34 EventRelatedTrnsp",
            materialLongDescription: "34 Event related transportation",
            parentSpendCatId: 213,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 214,
        level: 2,
        code: "S340040",
        name: "34 Corporate Credit Card Services",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 23,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 1252,
            level: 3,
            code: "S340041",
            name: "34 Corporate Credit Card",
            materialCode: "90072407",
            materialDescription: "34 Corp. Credit Card",
            materialLongDescription: "34 Corporate Credit Card",
            parentSpendCatId: 214,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1253,
            level: 3,
            code: "S340042",
            name: "34 Corporate P-card",
            materialCode: "90072408",
            materialDescription: "34 Corporate P-card",
            materialLongDescription: "34 Corporate P-card",
            parentSpendCatId: 214,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
    ],
  },
  {
    id: 24,
    level: 1,
    code: "S35",
    name: "35 External Workforce",
    materialCode: null,
    materialDescription: null,
    materialLongDescription: null,
    parentSpendCatId: 0,
    parentSpendCatCode: null,
    active: true,
    spendCategories: [
      {
        id: 215,
        level: 2,
        code: "S350001",
        name: "35 Contingent Workforce",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 24,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 1254,
            level: 3,
            code: "S350002",
            name: "35 Staff Augmentation Service",
            materialCode: "90072409",
            materialDescription: "35 Staff Augmen Serv",
            materialLongDescription: "35 Staff Augmentation Service",
            parentSpendCatId: 215,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1255,
            level: 3,
            code: "S350003",
            name: "35 Independent Contractors/Freelancers/Crowd",
            materialCode: "90072410",
            materialDescription: "35 Ind Co/Free/Crowd",
            materialLongDescription:
              "35 Independent Contractors/Freelancers/Crowd",
            parentSpendCatId: 215,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1256,
            level: 3,
            code: "S350004",
            name: "35 SOW",
            materialCode: "90072411",
            materialDescription: "35 SOW",
            materialLongDescription: "35 SOW",
            parentSpendCatId: 215,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 216,
        level: 2,
        code: "S350005",
        name: "35 Management Consulting Services",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 24,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 1257,
            level: 3,
            code: "S350006",
            name: "35 Strategic Consulting",
            materialCode: "90072412",
            materialDescription: "35 Strategic Consult",
            materialLongDescription: "35 Strategic Consulting",
            parentSpendCatId: 216,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1258,
            level: 3,
            code: "S350007",
            name: "35 Operations Consulting",
            materialCode: "90072413",
            materialDescription: "35 Operation Consult",
            materialLongDescription: "35 Operations Consulting",
            parentSpendCatId: 216,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1259,
            level: 3,
            code: "S350008",
            name: "35 HR Consulting",
            materialCode: "90072414",
            materialDescription: "35 HR Consulting",
            materialLongDescription: "35 HR Consulting",
            parentSpendCatId: 216,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1260,
            level: 3,
            code: "S350009",
            name: "35 Financial Consulting",
            materialCode: "90072415",
            materialDescription: "35 Financial Consult",
            materialLongDescription: "35 Financial Consulting",
            parentSpendCatId: 216,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1261,
            level: 3,
            code: "S350010",
            name: "35 ISIT Consulting",
            materialCode: "90072416",
            materialDescription: "35 ISIT Consulting",
            materialLongDescription: "35 ISIT Consulting",
            parentSpendCatId: 216,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
    ],
  },
  {
    id: 25,
    level: 1,
    code: "S36",
    name: "IS/IT & BPS",
    materialCode: null,
    materialDescription: null,
    materialLongDescription: null,
    parentSpendCatId: 0,
    parentSpendCatCode: null,
    active: true,
    spendCategories: [
      {
        id: 217,
        level: 2,
        code: "S360001",
        name: "36 Network Comm Serv",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 25,
        parentSpendCatCode: null,
        active: false,
        spendCategories: [],
      },
      {
        id: 218,
        level: 2,
        code: "S360011",
        name: "36 HostingPlatfrmSer",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 25,
        parentSpendCatCode: null,
        active: false,
        spendCategories: [],
      },
      {
        id: 219,
        level: 2,
        code: "S360020",
        name: "36 End user dev&serv",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 25,
        parentSpendCatCode: null,
        active: false,
        spendCategories: [],
      },
      {
        id: 220,
        level: 2,
        code: "S360029",
        name: "36 Business solapps",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 25,
        parentSpendCatCode: null,
        active: false,
        spendCategories: [],
      },
      {
        id: 221,
        level: 2,
        code: "S360039",
        name: "36 Project Pro Serv",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 25,
        parentSpendCatCode: null,
        active: false,
        spendCategories: [],
      },
      {
        id: 222,
        level: 2,
        code: "S360045",
        name: "36 Digital solut Ser",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 25,
        parentSpendCatCode: null,
        active: false,
        spendCategories: [],
      },
      {
        id: 223,
        level: 2,
        code: "S360054",
        name: "36 Security",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 25,
        parentSpendCatCode: null,
        active: false,
        spendCategories: [
          {
            id: 1474,
            level: 3,
            code: "S360055",
            name: "36 Security Solution",
            materialCode: null,
            materialDescription: null,
            materialLongDescription: null,
            parentSpendCatId: 223,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1475,
            level: 3,
            code: "S360056",
            name: "36 SecurityEquipment",
            materialCode: null,
            materialDescription: null,
            materialLongDescription: null,
            parentSpendCatId: 223,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1476,
            level: 3,
            code: "S360057",
            name: "36 Security Services",
            materialCode: null,
            materialDescription: null,
            materialLongDescription: null,
            parentSpendCatId: 223,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
        ],
      },
      {
        id: 224,
        level: 2,
        code: "S360058",
        name: "36 Busin ProcessServ",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 25,
        parentSpendCatCode: null,
        active: false,
        spendCategories: [],
      },
      {
        id: 1350,
        level: 2,
        code: "S360065",
        name: "36 Analytics",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 25,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 1366,
            level: 3,
            code: "S360066",
            name: "36 Analytical Service Lines (ASL)",
            materialCode: "90077215",
            materialDescription: "36 Analytical Service Lines (ASL)",
            materialLongDescription: "36 Analytical Service Lines (ASL)",
            parentSpendCatId: 1350,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1367,
            level: 3,
            code: "S360067",
            name: "36 Analytics Technology",
            materialCode: "90077220",
            materialDescription: "36 Analytics Technology",
            materialLongDescription: "36 Analytics Technology",
            parentSpendCatId: 1350,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1368,
            level: 3,
            code: "S360068",
            name: "36 ADI X-Stream",
            materialCode: "90077221",
            materialDescription: "36 ADI X-Stream",
            materialLongDescription: "36 ADI X-Stream",
            parentSpendCatId: 1350,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1369,
            level: 3,
            code: "S360069",
            name: "36 Business Solution Integration (BSI)",
            materialCode: "90077222",
            materialDescription: "36 Business Solution Integration (BSI)",
            materialLongDescription: "36 Business Solution Integration (BSI)",
            parentSpendCatId: 1350,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1370,
            level: 3,
            code: "S360070",
            name: "36 Data & Analytics Products & Services",
            materialCode: "90077223",
            materialDescription: "36 Data & Analytics Products & Services",
            materialLongDescription: "36 Data & Analytics Products & Services",
            parentSpendCatId: 1350,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1371,
            level: 3,
            code: "S360071",
            name: "36 IT Data Governance",
            materialCode: "90077224",
            materialDescription: "36 Master Data (MD)",
            materialLongDescription: "36 IT Data Governance",
            parentSpendCatId: 1350,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1372,
            level: 3,
            code: "S360072",
            name: "36 Nespresso Analytics",
            materialCode: "90077225",
            materialDescription: "36 Nespresso Analytics",
            materialLongDescription: "36 Nespresso Analytics",
            parentSpendCatId: 1350,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1551,
            level: 3,
            code: "S360147",
            name: "36 CENTER 4 ENABLEMENT (C4E)",
            materialCode: "90100920",
            materialDescription: "36 Center 4 Enablement (C4E)",
            materialLongDescription: null,
            parentSpendCatId: 1350,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
        ],
      },
      {
        id: 1351,
        level: 2,
        code: "S360073",
        name: "36 Finance & Legal",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 25,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 1373,
            level: 3,
            code: "S360074",
            name: "36 F&L Product Stream Operations",
            materialCode: "90077226",
            materialDescription: "36 F&L Product Stream Operations",
            materialLongDescription: "36 F&L Product Stream Operations",
            parentSpendCatId: 1351,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1374,
            level: 3,
            code: "S360075",
            name: "36 Intercompany Value Chain",
            materialCode: "90077227",
            materialDescription: "36 Intercompany Value Chain",
            materialLongDescription: "36 Intercompany Value Chain",
            parentSpendCatId: 1351,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1375,
            level: 3,
            code: "S360076",
            name: "36 Treasury, Pensions & Insurance",
            materialCode: "90077228",
            materialDescription: "36 Treasury, Pensions & Insurance",
            materialLongDescription: "36 Treasury, Pensions & Insurance",
            parentSpendCatId: 1351,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1376,
            level: 3,
            code: "S360077",
            name: "36 Management Accounting",
            materialCode: "90077229",
            materialDescription: "36 Management Accounting",
            materialLongDescription: "36 Management Accounting",
            parentSpendCatId: 1351,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 1352,
        level: 2,
        code: "S360078",
        name: "36 Human Resources",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 25,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 1377,
            level: 3,
            code: "S360079",
            name: "36 Employee Life Cycle & Total Rewards",
            materialCode: "90077230",
            materialDescription: "36 Employee Life Cycle & Total Rewards",
            materialLongDescription: "36 Employee Life Cycle & Total Rewards",
            parentSpendCatId: 1352,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1378,
            level: 3,
            code: "S360080",
            name: "36 HR,Analytics, Master data & Planning",
            materialCode: "90077285",
            materialDescription: "36 HR,Analytics, Master data & Planning",
            materialLongDescription: "36 HR,Analytics, Master data & Planning",
            parentSpendCatId: 1352,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1379,
            level: 3,
            code: "S360081",
            name: "36 Talent Management",
            materialCode: "90077231",
            materialDescription: "36 Talent Management",
            materialLongDescription: "36 Talent Management",
            parentSpendCatId: 1352,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 1353,
        level: 2,
        code: "S360082",
        name: "36 IT Platforms",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 25,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 1381,
            level: 3,
            code: "S360084",
            name: "36 Datacenter & Cloud Operations",
            materialCode: "90077233",
            materialDescription: "36 Datacenter & Cloud Operations",
            materialLongDescription: "36 Datacenter & Cloud Operations",
            parentSpendCatId: 1353,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1382,
            level: 3,
            code: "S360085",
            name: "36 IT Integrated Platform & x-Stream",
            materialCode: "90077234",
            materialDescription: "36 IT Integrated Platform & x-Stream",
            materialLongDescription: "36 IT Integrated Platform & x-Stream",
            parentSpendCatId: 1353,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1383,
            level: 3,
            code: "S360086",
            name: "36 SAP Landscape",
            materialCode: "90077235",
            materialDescription: "36 SAP Landscape",
            materialLongDescription: "36 SAP Landscape",
            parentSpendCatId: 1353,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1437,
            level: 3,
            code: "S360145",
            name: "36 Connectivity, Voice and Conferencing",
            materialCode: "90080909",
            materialDescription: "36 ConnectivityVoice",
            materialLongDescription: "36 Connectivity, Voice and Conferencing",
            parentSpendCatId: 1353,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 1354,
        level: 2,
        code: "S360087",
        name: "36 MarktgSalese&Bus",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 25,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 1384,
            level: 3,
            code: "S360088",
            name: "36 cCRM",
            materialCode: "90077236",
            materialDescription: "36 cCRM",
            materialLongDescription: "36 cCRM",
            parentSpendCatId: 1354,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1385,
            level: 3,
            code: "S360089",
            name: "36 Martech",
            materialCode: "90077237",
            materialDescription: "36 Martech",
            materialLongDescription: "36 Martech",
            parentSpendCatId: 1354,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1386,
            level: 3,
            code: "S360090",
            name: "36 Digital Experiences Tech",
            materialCode: "90077238",
            materialDescription: "36 Digital Experiences Tech",
            materialLongDescription: "36 Digital Experiences Tech",
            parentSpendCatId: 1354,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1387,
            level: 3,
            code: "S360091",
            name: "36 eCommerce and eContent",
            materialCode: "90077239",
            materialDescription: "36 eCommerce and eContent",
            materialLongDescription: "36 eCommerce and eContent",
            parentSpendCatId: 1354,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1388,
            level: 3,
            code: "S360092",
            name: "36 Mobile Applications Platform",
            materialCode: "90077240",
            materialDescription: "36 Mobile Applications Platform",
            materialLongDescription: "36 Mobile Applications Platform",
            parentSpendCatId: 1354,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1389,
            level: 3,
            code: "S360093",
            name: "36 MSE X-Stream",
            materialCode: "90077241",
            materialDescription: "36 MSE X-Stream",
            materialLongDescription: "36 MSE X-Stream",
            parentSpendCatId: 1354,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1390,
            level: 3,
            code: "S360094",
            name: "36 Nespresso Omni-Channel",
            materialCode: "90077242",
            materialDescription: "36 Nespresso Omni-Channel",
            materialLongDescription: "36 Nespresso Omni-Channel",
            parentSpendCatId: 1354,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1391,
            level: 3,
            code: "S360095",
            name: "36 Sales Tech",
            materialCode: "90077243",
            materialDescription: "36 Sales Tech",
            materialLongDescription: "36 Sales Tech",
            parentSpendCatId: 1354,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1438,
            level: 3,
            code: "S360146",
            name: "36 AdTech",
            materialCode: "90080912",
            materialDescription: "36 AdTech",
            materialLongDescription: "36 AdTech",
            parentSpendCatId: 1354,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 1355,
        level: 2,
        code: "S360096",
        name: "36 Office of the CIO",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 25,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 1392,
            level: 3,
            code: "S360097",
            name: "36 BRM Waters",
            materialCode: "90077244",
            materialDescription: "36 BRM Waters",
            materialLongDescription: "36 BRM Waters",
            parentSpendCatId: 1355,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1393,
            level: 3,
            code: "S360098",
            name: "36 OCIO Cross Stream",
            materialCode: "90077245",
            materialDescription: "36 OCIO Cross Stream",
            materialLongDescription: "36 OCIO Cross Stream",
            parentSpendCatId: 1355,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1394,
            level: 3,
            code: "S360099",
            name: "36 IT Quality & Pharma Service",
            materialCode: "90077246",
            materialDescription: "36 IT Quality & Pharma Service",
            materialLongDescription: "36 IT Quality & Pharma Service",
            parentSpendCatId: 1355,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1395,
            level: 3,
            code: "S360100",
            name: "36 CIO",
            materialCode: "90077247",
            materialDescription: "36 CIO",
            materialLongDescription: "36 CIO",
            parentSpendCatId: 1355,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1396,
            level: 3,
            code: "S360101",
            name: "36 IT Product & Portfolio Management",
            materialCode: "90077248",
            materialDescription: "36 IT Product & Portfolio Management",
            materialLongDescription: "36 IT Product & Portfolio Management",
            parentSpendCatId: 1355,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1397,
            level: 3,
            code: "S360102",
            name: "6 IT Transition / Transformation and Ways of working",
            materialCode: "90077286",
            materialDescription: "36 IT Transition / Transformation & WoW",
            materialLongDescription:
              "6 IT Transition / Transformation and Ways of working",
            parentSpendCatId: 1355,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1398,
            level: 3,
            code: "S360103",
            name: "36 Cutover, Testing, Migration, Projects",
            materialCode: "90077249",
            materialDescription: "36 Cutover, Testing, Migration, Projects",
            materialLongDescription: "36 Cutover, Testing, Migration, Projects",
            parentSpendCatId: 1355,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1399,
            level: 3,
            code: "S360104",
            name: "36 IT Vendor Management",
            materialCode: "90077250",
            materialDescription: "36 IT Vendor Management",
            materialLongDescription: "36 IT Vendor Management",
            parentSpendCatId: 1355,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1400,
            level: 3,
            code: "S360105",
            name: "36 Talent & Workforce Planning",
            materialCode: "90077251",
            materialDescription: "36 Talent & Workforce Planning",
            materialLongDescription: "36 Talent & Workforce Planning",
            parentSpendCatId: 1355,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 1356,
        level: 2,
        code: "S360106",
        name: "36 R&D, Innovation",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 25,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 1401,
            level: 3,
            code: "S360107",
            name: "36 Employee Innovation",
            materialCode: "90077252",
            materialDescription: "36 Employee Innovation",
            materialLongDescription: "36 Employee Innovation",
            parentSpendCatId: 1356,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1402,
            level: 3,
            code: "S360108",
            name: "36 Innovation Methodologies",
            materialCode: "90077253",
            materialDescription: "36 Innovation Methodologies",
            materialLongDescription: "36 Innovation Methodologies",
            parentSpendCatId: 1356,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1461,
            level: 3,
            code: "S360109",
            name: "36 IS/IT Research",
            materialCode: null,
            materialDescription: "36 IS/IT Research",
            materialLongDescription: "36 IS/IT Research",
            parentSpendCatId: 1356,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1462,
            level: 3,
            code: "S360110",
            name: "36 Tech Innovation",
            materialCode: null,
            materialDescription: "36 Tech Innovation",
            materialLongDescription: "36 Tech Innovation",
            parentSpendCatId: 1356,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1463,
            level: 3,
            code: "S360112",
            name: "36 R&D,Platform & Infra, X-Stream, Arch",
            materialCode: null,
            materialDescription: "36 R&D,Platform & Infra, X-Stream, Arch",
            materialLongDescription: "36 R&D,Platform & Infra, X-Stream, Arch",
            parentSpendCatId: 1356,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1473,
            level: 3,
            code: "S360111",
            name: "36 R&D Tech Enablement",
            materialCode: null,
            materialDescription: "36 R&D Tech Enablement",
            materialLongDescription: "36 R&D Tech Enablement",
            parentSpendCatId: 1356,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 1357,
        level: 2,
        code: "S360113",
        name: "36 Security Comp",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 25,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 1403,
            level: 3,
            code: "S360114",
            name: "36 Cyber Security & xStream",
            materialCode: "90077258",
            materialDescription: "36 Cyber Security & xStream",
            materialLongDescription: "36 Cyber Security & xStream",
            parentSpendCatId: 1357,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1404,
            level: 3,
            code: "S360115",
            name: "36 Digital & Technology Security",
            materialCode: "90077259",
            materialDescription: "36 Digital & Technology Security",
            materialLongDescription: "36 Digital & Technology Security",
            parentSpendCatId: 1357,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1405,
            level: 3,
            code: "S360116",
            name: "36 Info Security Mgt, Risk & Compliance",
            materialCode: "90077287",
            materialDescription: "36 Info Security Mgt, Risk & Compliance",
            materialLongDescription: "36 Info Security Mgt, Risk & Compliance",
            parentSpendCatId: 1357,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 1358,
        level: 2,
        code: "S360117",
        name: "36 SupplyChain&Proc.",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 25,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 1406,
            level: 3,
            code: "S360118",
            name: "36 Customer Service",
            materialCode: "90077260",
            materialDescription: "36 Customer Service",
            materialLongDescription: "36 Customer Service",
            parentSpendCatId: 1358,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1407,
            level: 3,
            code: "S360119",
            name: "36 Demand & Supply Planning",
            materialCode: "90077261",
            materialDescription: "36 Demand & Supply Planning",
            materialLongDescription: "36 Demand & Supply Planning",
            parentSpendCatId: 1358,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1408,
            level: 3,
            code: "S360120",
            name: "36 Global Logistics",
            materialCode: "90077262",
            materialDescription: "36 Global Logistics",
            materialLongDescription: "36 Global Logistics",
            parentSpendCatId: 1358,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1409,
            level: 3,
            code: "S360121",
            name: "36 Procurement",
            materialCode: "90077263",
            materialDescription: "36 Procurement",
            materialLongDescription: "36 Procurement",
            parentSpendCatId: 1358,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1410,
            level: 3,
            code: "S360122",
            name: "36 Product Stream Operations",
            materialCode: "90077264",
            materialDescription: "36 Product Stream Operations",
            materialLongDescription: "36 Product Stream Operations",
            parentSpendCatId: 1358,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 1359,
        level: 2,
        code: "S360123",
        name: "36 Tech.&Prod.",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 25,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 1411,
            level: 3,
            code: "S360124",
            name: "36 Agriculture, DMS and  x-T&P",
            materialCode: "90077196",
            materialDescription: "36 Agriculture, DMS and  x-T&P",
            materialLongDescription: "36 Agriculture, DMS and  x-T&P",
            parentSpendCatId: 1359,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1412,
            level: 3,
            code: "S360125",
            name: "36 Asset Management & SHE",
            materialCode: "90077197",
            materialDescription: "36 Asset Management & SHE",
            materialLongDescription: "36 Asset Management & SHE",
            parentSpendCatId: 1359,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1413,
            level: 3,
            code: "S360126",
            name: "36 Factory Floor IT",
            materialCode: "90077198",
            materialDescription: "36 Factory Floor IT",
            materialLongDescription: "36 Factory Floor IT",
            parentSpendCatId: 1359,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1414,
            level: 3,
            code: "S360127",
            name: "36 Digital Manufacturing and Analytics",
            materialCode: "90077199",
            materialDescription: "36 Digital Manufacturing and Analytics",
            materialLongDescription: "36 Digital Manufacturing and Analytics",
            parentSpendCatId: 1359,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1415,
            level: 3,
            code: "S360128",
            name: "36 NesTMS (R&D Trial Management System)",
            materialCode: "90077270",
            materialDescription: "36 NesTMS (R&D Trial Management System)",
            materialLongDescription: "36 NesTMS (R&D Trial Management System)",
            parentSpendCatId: 1359,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1416,
            level: 3,
            code: "S360129",
            name: "36 Quality Management",
            materialCode: "90077271",
            materialDescription: "36 Quality Management",
            materialLongDescription: "36 Quality Management",
            parentSpendCatId: 1359,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 1360,
        level: 2,
        code: "S360130",
        name: "36 Workforce 360",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 25,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 1380,
            level: 3,
            code: "S360148",
            name: "36 Common Tools ​& Services​",
            materialCode: "90077232",
            materialDescription: "36 Common Tools ​& Services​",
            materialLongDescription: "36 Common Tools ​& Services​",
            parentSpendCatId: 1360,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1417,
            level: 3,
            code: "S360131",
            name: "36 Business Continuity Management",
            materialCode: "90077272",
            materialDescription: "36 Business Continuity Management",
            materialLongDescription: "",
            parentSpendCatId: 1360,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1418,
            level: 3,
            code: "S360132",
            name: "36 Collaboration, Content and Portal",
            materialCode: "90077273",
            materialDescription: "36 Collaboration, Content And Portal",
            materialLongDescription: "36 Collaboration, Content and Portal",
            parentSpendCatId: 1360,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1419,
            level: 3,
            code: "S360133",
            name: "36 Connectivity, Voice and Conferencing",
            materialCode: "90077274",
            materialDescription: "36 Connectivity, Voice and Conferencing",
            materialLongDescription: "",
            parentSpendCatId: 1360,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1420,
            level: 3,
            code: "S360134",
            name: "36 Process Automation",
            materialCode: "90077275",
            materialDescription: "36 Process Automation",
            materialLongDescription: "",
            parentSpendCatId: 1360,
            parentSpendCatCode: null,
            active: false,
            spendCategories: null,
          },
          {
            id: 1421,
            level: 3,
            code: "S360135",
            name: "36 IT Service Management",
            materialCode: "90077276",
            materialDescription: "36 IT Service Management",
            materialLongDescription: "36 IT Service Management",
            parentSpendCatId: 1360,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1422,
            level: 3,
            code: "S360136",
            name: "36 Workforce Devices",
            materialCode: "90077277",
            materialDescription: "36 Workforce Devices",
            materialLongDescription: "36 Workforce Devices",
            parentSpendCatId: 1360,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1423,
            level: 3,
            code: "S360137",
            name: "36 Workforce Engagement",
            materialCode: "90077278",
            materialDescription: "36 Workforce Engagement",
            materialLongDescription: "36 Workforce Engagement",
            parentSpendCatId: 1360,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1424,
            level: 3,
            code: "S360138",
            name: "36 Workforce X-Stream",
            materialCode: "90077279",
            materialDescription: "36 Workforce X-Stream",
            materialLongDescription: "36 Workforce X-Stream",
            parentSpendCatId: 1360,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 1361,
        level: 2,
        code: "S360139",
        name: "36 BusinessProcServ",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 25,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 1425,
            level: 3,
            code: "S360140",
            name: "36 Hire to Retire",
            materialCode: "90077280",
            materialDescription: "36 Hire To Retire",
            materialLongDescription: "36 Hire to Retire",
            parentSpendCatId: 1361,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1426,
            level: 3,
            code: "S360141",
            name: "36 Order to Cash",
            materialCode: "90077281",
            materialDescription: "36 Order To Cash",
            materialLongDescription: "36 Order to Cash",
            parentSpendCatId: 1361,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1427,
            level: 3,
            code: "S360142",
            name: "36 Record to Report",
            materialCode: "90077282",
            materialDescription: "36 Record To Report",
            materialLongDescription: "36 Record to Report",
            parentSpendCatId: 1361,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1428,
            level: 3,
            code: "S360143",
            name: "36 Source to Pay",
            materialCode: "90077283",
            materialDescription: "36 Source To Pay",
            materialLongDescription: "36 Source to Pay",
            parentSpendCatId: 1361,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1429,
            level: 3,
            code: "S360144",
            name: "36 Digital and Social Media",
            materialCode: "90077284",
            materialDescription: "36 Digital And Social Media",
            materialLongDescription: "36 Digital and Social Media",
            parentSpendCatId: 1361,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 1573,
        level: 2,
        code: "S360149",
        name: "36 Business Architecture",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 25,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 1574,
            level: 3,
            code: "S360150",
            name: "36 Enterprise Architecture",
            materialCode: "90105066",
            materialDescription: "36 Enterprise Architecture",
            materialLongDescription: "36 Enterprise Architecture",
            parentSpendCatId: 1573,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1575,
            level: 3,
            code: "S360151",
            name: "36 Business Continuity Management",
            materialCode: "90077272",
            materialDescription: "36 Business Continuity Management",
            materialLongDescription: "36 Business Continuity Management",
            parentSpendCatId: 1573,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1576,
            level: 3,
            code: "S360152",
            name: "36 Process Automation",
            materialCode: "90077275",
            materialDescription: "36 Process Automation",
            materialLongDescription: "36 Process Automation",
            parentSpendCatId: 1573,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1577,
            level: 3,
            code: "S360153",
            name: "36 Center 4 Enablement (C4E)",
            materialCode: "90100920",
            materialDescription: "36 Center 4 Enablement (C4E)",
            materialLongDescription: "36 Center 4 Enablement (C4E)",
            parentSpendCatId: 1573,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
    ],
  },
  {
    id: 26,
    level: 1,
    code: "S37",
    name: "37 Workplace Solution",
    materialCode: null,
    materialDescription: null,
    materialLongDescription: null,
    parentSpendCatId: 0,
    parentSpendCatCode: null,
    active: true,
    spendCategories: [
      {
        id: 225,
        level: 2,
        code: "S370001",
        name: "37 Facilities Management",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 26,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 1318,
            level: 3,
            code: "S370002",
            name: "37 Facilities Administration, Account Management,",
            materialCode: "90072417",
            materialDescription: "37 Fac Adm Acc Mgmt",
            materialLongDescription:
              "37 Facilities Administration, Account Management,",
            parentSpendCatId: 225,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1319,
            level: 3,
            code: "S370003",
            name: "37 Uniforms, Laundry, and PPE",
            materialCode: "90072418",
            materialDescription: "37 Uni Laund PPE",
            materialLongDescription: "37 Uniforms, Laundry, and PPE",
            parentSpendCatId: 225,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1320,
            level: 3,
            code: "S370004",
            name: "37 Building Maintenance & Operations",
            materialCode: "90072419",
            materialDescription: "37 Build Maint & Ope",
            materialLongDescription: "37 Building Maintenance & Operations",
            parentSpendCatId: 225,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1321,
            level: 3,
            code: "S370005",
            name: "37 Environmental and Waste Management",
            materialCode: "90072420",
            materialDescription: "37 Enviro Waste Mgmt",
            materialLongDescription: "37 Environmental and Waste Management",
            parentSpendCatId: 225,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1322,
            level: 3,
            code: "S370006",
            name: "37 Cleaning Services",
            materialCode: "90072421",
            materialDescription: "37 Cleaning Services",
            materialLongDescription: "37 Cleaning Services",
            parentSpendCatId: 225,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1323,
            level: 3,
            code: "S370007",
            name: "37 Green Areas and Grounds Maintenance",
            materialCode: "90072422",
            materialDescription: "37 Green Ar Gr Maint",
            materialLongDescription: "37 Green Areas and Grounds Maintenance",
            parentSpendCatId: 225,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1324,
            level: 3,
            code: "S370008",
            name: "37 Pest Control Services",
            materialCode: "90072423",
            materialDescription: "37 Pest Control Serv",
            materialLongDescription: "37 Pest Control Services",
            parentSpendCatId: 225,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1325,
            level: 3,
            code: "S370009",
            name: "37 Security Services",
            materialCode: "90072424",
            materialDescription: "37 Security Services",
            materialLongDescription: "37 Security Services",
            parentSpendCatId: 225,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1326,
            level: 3,
            code: "S370010",
            name: "37 Office Services",
            materialCode: "90072425",
            materialDescription: "37 Office Services",
            materialLongDescription: "37 Office Services",
            parentSpendCatId: 225,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1327,
            level: 3,
            code: "S370011",
            name: "37 Restaurant/Cafeteria",
            materialCode: "90072426",
            materialDescription: "37 Restaurant/Cafet",
            materialLongDescription: "37 Restaurant/Cafeteria",
            parentSpendCatId: 225,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1328,
            level: 3,
            code: "S370012",
            name: "37 Space Management",
            materialCode: "90072427",
            materialDescription: "37 Space Management",
            materialLongDescription: "37 Space Management",
            parentSpendCatId: 225,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1329,
            level: 3,
            code: "S370013",
            name: "37 Small Works/Facilities Engineering Support",
            materialCode: "90072428",
            materialDescription: "37 SmallWo/FacEngSup",
            materialLongDescription:
              "37 Small Works/Facilities Engineering Support",
            parentSpendCatId: 225,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1330,
            level: 3,
            code: "S370014",
            name: "37 Other Services",
            materialCode: "90072429",
            materialDescription: "37 Other Services",
            materialLongDescription: "37 Other Services",
            parentSpendCatId: 225,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 226,
        level: 2,
        code: "S370015",
        name: "37 Real Estate Services",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 26,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 1331,
            level: 3,
            code: "S370016",
            name: "37 Land & Building Lease Payments",
            materialCode: "90072430",
            materialDescription: "37 LandBuildLeasePay",
            materialLongDescription: "37 Land & Building Lease Payments",
            parentSpendCatId: 226,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1332,
            level: 3,
            code: "S370017",
            name: "37 Land & Building Professional Fees",
            materialCode: "90072431",
            materialDescription: "37 Land BuildProFees",
            materialLongDescription: "37 Land & Building Professional Fees",
            parentSpendCatId: 226,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1333,
            level: 3,
            code: "S370018",
            name: "37 Land & Building Tax Payments",
            materialCode: "90072432",
            materialDescription: "37 Land Build TaxPay",
            materialLongDescription: "37 Land & Building Tax Payments",
            parentSpendCatId: 226,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 227,
        level: 2,
        code: "S370019",
        name: "37 Workplace Design",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 26,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 1334,
            level: 3,
            code: "S370020",
            name: "37 Land & Building Construction & Fit Out",
            materialCode: "90072433",
            materialDescription: "37 Land BuiConFitOut",
            materialLongDescription:
              "37 Land & Building Construction & Fit Out",
            parentSpendCatId: 227,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1335,
            level: 3,
            code: "S370021",
            name: "37 Land & Building Furniture",
            materialCode: "90072434",
            materialDescription: "37 Land Build Furn",
            materialLongDescription: "37 Land & Building Furniture",
            parentSpendCatId: 227,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1336,
            level: 3,
            code: "S370022",
            name: "37 Land & Building Architects & Project Management",
            materialCode: "90072435",
            materialDescription: "37 LandBuiArchiPMgmt",
            materialLongDescription:
              "37 Land & Building Architects & Project Management",
            parentSpendCatId: 227,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
    ],
  },
  {
    id: 27,
    level: 1,
    code: "S38",
    name: "38 Energy, Utilities & Environment",
    materialCode: null,
    materialDescription: null,
    materialLongDescription: null,
    parentSpendCatId: 0,
    parentSpendCatCode: null,
    active: true,
    spendCategories: [
      {
        id: 228,
        level: 2,
        code: "S380001",
        name: "38 Energy",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 27,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 1337,
            level: 3,
            code: "S380002",
            name: "38 Gas LNG & PNG",
            materialCode: "90073904",
            materialDescription: "38 Gas LNG & PNG",
            materialLongDescription: "38 Gas LNG & PNG",
            parentSpendCatId: 228,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1338,
            level: 3,
            code: "S380003",
            name: "38 Gas LPG",
            materialCode: "90073905",
            materialDescription: "38 Gas LPG",
            materialLongDescription: "38 Gas LPG",
            parentSpendCatId: 228,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1339,
            level: 3,
            code: "S380004",
            name: "38 Diesel",
            materialCode: "90073906",
            materialDescription: "38 Diesel",
            materialLongDescription: "38 Diesel",
            parentSpendCatId: 228,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1340,
            level: 3,
            code: "S380005",
            name: "38 Residual FuelOil",
            materialCode: "90073908",
            materialDescription: "38 Residual Fuel Oil",
            materialLongDescription: "38 Residual FuelOil",
            parentSpendCatId: 228,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1341,
            level: 3,
            code: "S380006",
            name: "38 Electricity",
            materialCode: "90073907",
            materialDescription: "38 Electricity",
            materialLongDescription: "38 Electricity",
            parentSpendCatId: 228,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1342,
            level: 3,
            code: "S380007",
            name: "38 Coal",
            materialCode: "90073909",
            materialDescription: "38 Coal",
            materialLongDescription: "38 Coal",
            parentSpendCatId: 228,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1343,
            level: 3,
            code: "S380008",
            name: "38 Biomass /Biofuels",
            materialCode: "90073930",
            materialDescription: "38 Biomass / Biofuels",
            materialLongDescription: "38 Biomass /Biofuels",
            parentSpendCatId: 228,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 229,
        level: 2,
        code: "S380009",
        name: "38 Utilities",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 27,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 1344,
            level: 3,
            code: "S380010",
            name: "38 Water",
            materialCode: "90073931",
            materialDescription: "38 Water",
            materialLongDescription: "38 Water",
            parentSpendCatId: 229,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1345,
            level: 3,
            code: "S380011",
            name: "38 Steam",
            materialCode: "90073932",
            materialDescription: "38 Steam",
            materialLongDescription: "38 Steam",
            parentSpendCatId: 229,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 230,
        level: 2,
        code: "S380012",
        name: "38 Environment",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 27,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 1346,
            level: 3,
            code: "S380013",
            name: "38 Energy Conservation Services & Consultancy",
            materialCode: "90073933",
            materialDescription: "38 Energy Conservation Services&Conslt",
            materialLongDescription:
              "38 Energy Conservation Services & Consultancy",
            parentSpendCatId: 230,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 231,
        level: 2,
        code: "S380014",
        name: "38 Equipment supply",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 27,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 1347,
            level: 3,
            code: "S380015",
            name: "38 Non-Boiler Fuel",
            materialCode: "90073934",
            materialDescription: "38 Non-Boiler Fuel",
            materialLongDescription: "38 Non-Boiler Fuel",
            parentSpendCatId: 231,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
    ],
  },
  {
    id: 28,
    level: 1,
    code: "S99",
    name: "99 R&D / PTC site use only",
    materialCode: null,
    materialDescription: null,
    materialLongDescription: null,
    parentSpendCatId: 0,
    parentSpendCatCode: null,
    active: true,
    spendCategories: [
      {
        id: 232,
        level: 2,
        code: "S990001",
        name: "99 R&D / PTC site use only",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 28,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [],
      },
    ],
  },
  {
    id: 1478,
    level: 1,
    code: "S39",
    name: "39 Marketing",
    materialCode: null,
    materialDescription: null,
    materialLongDescription: null,
    parentSpendCatId: 0,
    parentSpendCatCode: null,
    active: true,
    spendCategories: [
      {
        id: 1479,
        level: 2,
        code: "S390001",
        name: "39 Agency & Production Costs",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 1478,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 1489,
            level: 3,
            code: "S390002",
            name: "39 Creative Agency Fees (Consumer)",
            materialCode: "90102436",
            materialDescription: "39 Creative Agency Fees (Consumer)",
            materialLongDescription: "39 Creative Agency Fees (Consumer)",
            parentSpendCatId: 1479,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1490,
            level: 3,
            code: "S390003",
            name: "39 Campaign and Content Production",
            materialCode: "90102437",
            materialDescription: "39 Campaign and Content Production",
            materialLongDescription: "39 Campaign and Content Production",
            parentSpendCatId: 1479,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1491,
            level: 3,
            code: "S390004",
            name: "39 Media Fees",
            materialCode: "90102438",
            materialDescription: "39 Media Fees",
            materialLongDescription: "39 Media Fees",
            parentSpendCatId: 1479,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1492,
            level: 3,
            code: "S390005",
            name: "39 Retail Agency Fees (Shopper)",
            materialCode: "90102439",
            materialDescription: "39 Retail Agency Fees (Shopper)",
            materialLongDescription: "39 Retail Agency Fees (Shopper)",
            parentSpendCatId: 1479,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1493,
            level: 3,
            code: "S390006",
            name: "39 Content Studios and IBS Content Services",
            materialCode: "90102440",
            materialDescription: "39 Content Studios & IBS ContentServices",
            materialLongDescription:
              "39 Content Studios and IBS Content Services",
            parentSpendCatId: 1479,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 1480,
        level: 2,
        code: "S390007",
        name: "39 Traditional Paid Media",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 1478,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 1494,
            level: 3,
            code: "S390008",
            name: "39 Linear TV",
            materialCode: "90102441",
            materialDescription: "39 Linear TV",
            materialLongDescription: "39 Linear TV",
            parentSpendCatId: 1480,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1495,
            level: 3,
            code: "S390009",
            name: "39 Print, Radio, Outdoor and Cinema",
            materialCode: "90102442",
            materialDescription: "39 Print, Radio, Outdoor and Cinema",
            materialLongDescription: "39 Print, Radio, Outdoor and Cinema",
            parentSpendCatId: 1480,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 1481,
        level: 2,
        code: "S390010",
        name: "39 Pure Digital Paid Media",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 1478,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 1496,
            level: 3,
            code: "S390011",
            name: "39 Digital",
            materialCode: "90102443",
            materialDescription: "39 Digital",
            materialLongDescription: "39 Digital",
            parentSpendCatId: 1481,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1497,
            level: 3,
            code: "S390012",
            name: "39 Paid Search (Search Engines)",
            materialCode: "90102444",
            materialDescription: "39 Paid Search (Search Engines)",
            materialLongDescription: "39 Paid Search (Search Engines)",
            parentSpendCatId: 1481,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1498,
            level: 3,
            code: "S390013",
            name: "39 PFME Retail Media - Display and Other (Brand Building)",
            materialCode: "90102445",
            materialDescription: "39 PFME RtlMedia-Dspl&Oth(Brnd Building)",
            materialLongDescription:
              "39 PFME Retail Media - Display and Other (Brand Building)",
            parentSpendCatId: 1481,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1499,
            level: 3,
            code: "S390014",
            name: "39 PFME Retail Media - Paid Search (Brand Building)",
            materialCode: "90102446",
            materialDescription: "39 PFME RtlMedia-PaidSearch BrndBuilding",
            materialLongDescription:
              "39 PFME Retail Media - Paid Search (Brand Building)",
            parentSpendCatId: 1481,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 1482,
        level: 2,
        code: "S390015",
        name: "39 Owned and Earned Channels",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 1478,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 1500,
            level: 3,
            code: "S390016",
            name: "39 Websites and Managed Retail Channels",
            materialCode: "90102447",
            materialDescription: "39 Websites and Managed Retail Channels",
            materialLongDescription: "39 Websites and Managed Retail Channels",
            parentSpendCatId: 1482,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1501,
            level: 3,
            code: "S390017",
            name: "39 Loyalty and Lifecycle Programs (CRM)",
            materialCode: "90102448",
            materialDescription: "39 Loyalty and Lifecycle Programs (CRM)",
            materialLongDescription: "39 Loyalty and Lifecycle Programs (CRM)",
            parentSpendCatId: 1482,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1502,
            level: 3,
            code: "S390018",
            name: "39 Consumer Engagement Services",
            materialCode: "90102449",
            materialDescription: "39 Consumer Engagement Services",
            materialLongDescription: "39 Consumer Engagement Services",
            parentSpendCatId: 1482,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1503,
            level: 3,
            code: "S390019",
            name: "39 SEO (search engines and retailers)",
            materialCode: "90102450",
            materialDescription: "39 SEO (search engines and retailers)",
            materialLongDescription: "39 SEO (search engines and retailers)",
            parentSpendCatId: 1482,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1504,
            level: 3,
            code: "S390020",
            name: "39 PR and industry affairs",
            materialCode: "90102451",
            materialDescription: "39 PR and industry affairs",
            materialLongDescription: "39 PR and industry affairs",
            parentSpendCatId: 1482,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1505,
            level: 3,
            code: "S390021",
            name: "39 Influencer Relationship",
            materialCode: "90102452",
            materialDescription: "39 Influencer Relationship",
            materialLongDescription: "39 Influencer Relationship",
            parentSpendCatId: 1482,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 1483,
        level: 2,
        code: "S390022",
        name: "39 Market Research & Analytics",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 1478,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 1506,
            level: 3,
            code: "S390023",
            name: "39 Syndicated",
            materialCode: "90102454",
            materialDescription: "39 Syndicated",
            materialLongDescription: "39 Syndicated",
            parentSpendCatId: 1483,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1507,
            level: 3,
            code: "S390024",
            name: "39 Analytics",
            materialCode: "90102455",
            materialDescription: "39 Analytics",
            materialLongDescription: "39 Analytics",
            parentSpendCatId: 1483,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1508,
            level: 3,
            code: "S390025",
            name: "39 Strategic Consumer and Brand Insights",
            materialCode: "90102456",
            materialDescription: "39 Strategic Consumer and Brand Insights",
            materialLongDescription: "39 Strategic Consumer and Brand Insights",
            parentSpendCatId: 1483,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1509,
            level: 3,
            code: "S390026",
            name: "39 Product/Innovation Research",
            materialCode: "90102457",
            materialDescription: "39 Innovation and Renovation",
            materialLongDescription: "39 Product/Innovation Research",
            parentSpendCatId: 1483,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1510,
            level: 3,
            code: "S390027",
            name: "39 Pack Testing",
            materialCode: "90102458",
            materialDescription: "39 Product and Pack Testing",
            materialLongDescription: "39 Pack Testing",
            parentSpendCatId: 1483,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1511,
            level: 3,
            code: "S390028",
            name: "39 Retailer and Shopper Research",
            materialCode: "90102459",
            materialDescription: "39 Retailer and Shopper Research",
            materialLongDescription: "39 Retailer and Shopper Research",
            parentSpendCatId: 1483,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 1484,
        level: 2,
        code: "S390029",
        name: "39 Design and Visual Identity",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 1478,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 1512,
            level: 3,
            code: "S390030",
            name: "39 Strategic (includes analytics)",
            materialCode: "90102460",
            materialDescription: "39 Strategic (includes analytics)",
            materialLongDescription: "39 Strategic (includes analytics)",
            parentSpendCatId: 1484,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1513,
            level: 3,
            code: "S390031",
            name: "39 Adaptation and execution",
            materialCode: "90102461",
            materialDescription: "39 Adaptation and execution",
            materialLongDescription: "39 Adaptation and execution",
            parentSpendCatId: 1484,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 1485,
        level: 2,
        code: "S390032",
        name: "39 Consumer Experience",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 1478,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 1514,
            level: 3,
            code: "S390033",
            name: "39 Events",
            materialCode: "90102462",
            materialDescription: "39 Events",
            materialLongDescription: "39 Events",
            parentSpendCatId: 1485,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1515,
            level: 3,
            code: "S390034",
            name: "39 Sponsorship",
            materialCode: "90102463",
            materialDescription: "39 Sponsorship",
            materialLongDescription: "39 Sponsorship",
            parentSpendCatId: 1485,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1516,
            level: 3,
            code: "S390035",
            name: "39 Consumer Sampling and Demonstration",
            materialCode: "90102464",
            materialDescription: "39 Consumer Sampling and Demonstration",
            materialLongDescription: "39 Consumer Sampling and Demonstration",
            parentSpendCatId: 1485,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 1486,
        level: 2,
        code: "S390036",
        name: "39 Consumer Activation",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 1478,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 1517,
            level: 3,
            code: "S390037",
            name: "39 Couponing Fees",
            materialCode: "90102465",
            materialDescription: "39 Couponing Fees",
            materialLongDescription: "39 Couponing Fees",
            parentSpendCatId: 1486,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1518,
            level: 3,
            code: "S390038",
            name: "39 Value Packs",
            materialCode: "90102466",
            materialDescription: "39 Value Packs",
            materialLongDescription: "39 Value Packs",
            parentSpendCatId: 1486,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1519,
            level: 3,
            code: "S390039",
            name: "39 Consumer Contests and Promotions",
            materialCode: "90102399",
            materialDescription: "39 Consumer Contests and Promotions",
            materialLongDescription: "39 Consumer Contests and Promotions",
            parentSpendCatId: 1486,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1520,
            level: 3,
            code: "S390040",
            name: "39 Point of Sale Material",
            materialCode: "90102470",
            materialDescription: "39 Point of Sale Material",
            materialLongDescription: "39 Point of Sale Material",
            parentSpendCatId: 1486,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1521,
            level: 3,
            code: "S390041",
            name: "39 Printed Material and Premiums",
            materialCode: "90102471",
            materialDescription: "39 Printed Material and Premiums",
            materialLongDescription: "39 Printed Material and Premiums",
            parentSpendCatId: 1486,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1522,
            level: 3,
            code: "S390042",
            name: "39 PFME 3rd Party Royalties",
            materialCode: "90102472",
            materialDescription: "39 PFME 3rd Party Royalties",
            materialLongDescription: "39 PFME 3rd Party Royalties",
            parentSpendCatId: 1486,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 1487,
        level: 2,
        code: "S390043",
        name: "39 Trade & Professional Activation",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 1478,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 1523,
            level: 3,
            code: "S390044",
            name: "39 Medical and Vet Delegates",
            materialCode: "90102473",
            materialDescription: "39 Medical and Vet Delegates",
            materialLongDescription: "39 Medical and Vet Delegates",
            parentSpendCatId: 1487,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1524,
            level: 3,
            code: "S390045",
            name: "39 Medical and Paramedical Info",
            materialCode: "90102474",
            materialDescription: "39 Medical and Paramedical Info",
            materialLongDescription: "39 Medical and Paramedical Info",
            parentSpendCatId: 1487,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1525,
            level: 3,
            code: "S390046",
            name: "39 Medical and Paramedical Events",
            materialCode: "90102475",
            materialDescription: "39 Medical and Paramedical Events",
            materialLongDescription: "39 Medical and Paramedical Events",
            parentSpendCatId: 1487,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1526,
            level: 3,
            code: "S390047",
            name: "39 Brand Building with Trade",
            materialCode: "90102476",
            materialDescription: "39 Brand Building with Trade",
            materialLongDescription: "39 Brand Building with Trade",
            parentSpendCatId: 1487,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1527,
            level: 3,
            code: "S390048",
            name: "39 Machine Subsidies",
            materialCode: "90102477",
            materialDescription: "39 Machine Subsidies",
            materialLongDescription: "39 Machine Subsidies",
            parentSpendCatId: 1487,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1528,
            level: 3,
            code: "S390049",
            name: "39 Machine Partner Contribution",
            materialCode: "90102478",
            materialDescription: "39 Machine Partner Contribution",
            materialLongDescription: "39 Machine Partner Contribution",
            parentSpendCatId: 1487,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
      {
        id: 1488,
        level: 2,
        code: "S390050",
        name: "39 Consumer Price Reduction & Marketing Allowance",
        materialCode: null,
        materialDescription: null,
        materialLongDescription: null,
        parentSpendCatId: 1478,
        parentSpendCatCode: null,
        active: true,
        spendCategories: [
          {
            id: 1529,
            level: 3,
            code: "S390051",
            name: "39 Consumer Couponing",
            materialCode: "90102479",
            materialDescription: "39 Consumer Couponing",
            materialLongDescription: "39 Consumer Couponing",
            parentSpendCatId: 1488,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1530,
            level: 3,
            code: "S390052",
            name: "39 Media Comm- Customer Reimbursement",
            materialCode: "90102480",
            materialDescription: "39 Media Comm- Customer Reimbursement",
            materialLongDescription: "39 Media Comm- Customer Reimbursement",
            parentSpendCatId: 1488,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1531,
            level: 3,
            code: "S390053",
            name: "39 Sampling and Demo - Customer Reimbursement",
            materialCode: "90102481",
            materialDescription: "39 Sampling & Demo - Cust Reimbursement",
            materialLongDescription:
              "39 Sampling and Demo - Customer Reimbursement",
            parentSpendCatId: 1488,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1532,
            level: 3,
            code: "S390054",
            name: "39 Consumer Promo - Alternative Treat",
            materialCode: "90102482",
            materialDescription: "39 Consumer Promo - Alternative Treat",
            materialLongDescription: "39 Consumer Promo - Alternative Treat",
            parentSpendCatId: 1488,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1533,
            level: 3,
            code: "S390055",
            name: "39 PFME and Other Research Allowance",
            materialCode: "90102483",
            materialDescription: "39 PFME and Other Research Allowance",
            materialLongDescription: "39 PFME and Other Research Allowance",
            parentSpendCatId: 1488,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1534,
            level: 3,
            code: "S390056",
            name: "39 MA Retail Media - Display and other (Brand Building)",
            materialCode: "90102484",
            materialDescription: "39 MA Rtl Media-Dspl&Oth (Brnd Building)",
            materialLongDescription:
              "39 MA Retail Media - Display and other (Brand Building)",
            parentSpendCatId: 1488,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
          {
            id: 1535,
            level: 3,
            code: "S390057",
            name: "39 MA Retail Media - Paid Search (Brand Building",
            materialCode: "90102485",
            materialDescription: "39 MA RtlMedia-PaidSearch(Brnd Building)",
            materialLongDescription:
              "39 MA Retail Media - Paid Search (Brand Building",
            parentSpendCatId: 1488,
            parentSpendCatCode: null,
            active: true,
            spendCategories: null,
          },
        ],
      },
    ],
  },
  {
    id: 1568,
    level: 1,
    code: "S04",
    name: "4 Utilities, Environment & FM",
    materialCode: null,
    materialDescription: null,
    materialLongDescription: null,
    parentSpendCatId: 0,
    parentSpendCatCode: null,
    active: true,
    spendCategories: [],
  },
];

export default SpendCategories;
